import React from "react";
import { useNavigate } from "react-router-dom";
import Table from '../../../table/table.jsx'

export default function HistoryTable({id, data}) {
  const navigate = useNavigate();
  const handleChange = (val) => {
    navigate("/reviewers/change-form/"+id+'/' + val);
  };
  const columns = [
    {
      field:'trip_id',
      headerName:'REVIEW ID'
    },
    {
      field:'sub_type',
      headerName:'Type'
    },
    {
      field:'rev_date',
      headerName:'REVIEW DATES'
    },
    {
      field:'name',
      headerName:'GRANTEE'
    },
    {
      field:'componentarea',
      headerName:'COMP AREA'
    },
    {
      field:'status',
      headerName:'REVIEW STATUS',
      type:'action',
      cellRenderer: function({value, data}){
        return  <div
        className=" text-indigo-600 text-md cursor-pointer"
        onClick={() => handleChange(data?.trip_details_uid)}
      >
        {value ? value: 'Change'}
      </div>
      }
    }
  ]

  return (
    <Table columns={columns} data={data}  />
  );
}
