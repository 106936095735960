import React, { useEffect, useState } from "react";
import { get } from "../../../../services";
import MultiText from "../../../inputs/multi-text";

import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import Loader from '../../../loader'

export default function Summary({id}) {

  const [designatedData, setDesignatedData] = useState([])
  const [headerData, setHeaderData] = useState([])
  const [headerTop, setHeaderTop] = useState({})
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
   
   if(id){
    setLoading(true)
    get(`team_Summary/team_summary_date?trip_id=${id}`).then(() => {
        Promise.all([
          get(`team_Summary/designated_driver?trip_id=${id}`),
          get(
            `team_Summary/report_header_info?trip_id=${id}`
          ),
          get(`reviews/team_summary_reviewer_list?trip_id=${id}`),
        ]).then(([designated, headerInfo, tables]) => {
          
          if(designated && designated.length >0){
            setDesignatedData(designated)
          }
          if(headerInfo && headerInfo.length >0){
            setHeaderTop(headerInfo[0])
            setHeaderData(headerInfo.filter(header => header.hotel_number && Array.isArray(header.hotel_number)))
          }
          if(tables && tables.length >0){
            setTableData(tables)
          }
          setLoading(false)
        }).catch(err => {
          setLoading(false)
          console.error(err)
        })
    
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })
   
   }
  }, [])


  if(loading){
    return <Loader />
  }
  
  return (
    <div className="p-3 w-full text-left bg-white shadow-md text-[14px]">
      {/* <h6 className='font-semibold text-center text-xl mb-3'>Team Summary</h6> */}
      <div className="mt-5 flex flex-col gap-3 text-left">
        <div className="flex">
          <div className="flex gap-3 w-4/6">
            <div className="w-[200px]">Review ID/Grantee</div>
            <div>
              <b>{id}</b> {headerTop?.Name}
            </div>
          </div>
          <div className="ml-auto w-2/6">
            <div className="flex gap-3">
              <div className="w-[200px]">Review Dates</div>
              <div>
                <span>{headerTop.rev_date}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex gap-3 w-4/6">
            <div className="w-[200px] flex flex-none">RFL</div>
            <div className="text-xs flex gap-2">
            <MultiText value={['TITLE', 'FName', 'LName']} seperator=" " data={headerTop}/>  | {formatPhoneNumber(headerTop.Phone)} |
              {headerTop.email}
            </div>
          </div>
          <div className="ml-auto w-2/6">
            <div className="flex gap-3 ">
              <div className="w-[200px]">Review Type</div>
              <div>
                <span>{headerTop.sub_type}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex gap-3 w-4/6">
            <div className="w-[200px]">RFL Meeting Info</div>
            <div>{formatDate(headerTop.MeetingDate)} @ {formatTime(headerTop.MeetingTime)}, {headerTop.MeetingPlace}</div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {headerData.map((d, i) => {
          return (
            <div key={i} className="flex flex-col gap-3">
              <div className="flex">
                <div className="flex gap-3 w-4/6">
                  <div className="w-[200px]">{d.job_title}</div>
                  <div className="flex gap-3">
                    <MultiText value={['TITLE', 'FName', 'LName']} seperator=" " data={d}/> 
                    <div>Phone:{d.Phone}</div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-3 w-4/6">
                  <div className="w-[200px]">Hotel {i + 1}</div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold">{d.hotel}</div>
                    <div>{d.hotel_address}</div>
                    <div>
                      {d.hotel_city} {d.hotel_state} {d.Zip}
                    </div>
                    <div>{formatPhoneNumber(d.hotel_phone)}</div>
                    {d.confirmNumber && (
                      <div>
                        Conf:#<span className="font-semibold">{d.confirmNumber}</span>
                      </div>
                    )}
                    {d.Booked_rate && (
                      <div>
                        Booked Rate:$
                        <span className="font-semibold">{d.Booked_rate}</span>
                      </div>
                    )}
                    {d.taxes && (
                      <div>
                        Tax:$<span className="font-semibold">{d.taxes}</span>
                      </div>
                    )}
                   
                      <div>
                        At Cost Letter Required:
                        <span className="font-semibold">
                          {d.Booked_rate && d.Lodging ? d.Booked_rate > d.Lodging ? 'Yes' : 'No'  : 'No'}
                        </span>
                      </div>
                    {d.travel_ord_letter && (
                      <div>
                        Travel Order Letter Required:
                        <span className="font-semibold">
                          {d.travel_ord_letter}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ml-auto w-2/6 flex flex-col gap-2">
                  <div className="flex flex-col gap-3 ">
                    <div className="w-[200px]">Conus Info</div>
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-3">
                        <div className="w-[200px]">Lodging</div>
                        <div className="font-semibold">${d.Lodging}</div>
                      </div>
                      <div className="flex gap-3">
                        <div className="w-[200px]">Per Diem</div>
                        <div className="font-semibold">${d.Meals}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-6">
        <table className="w-full border-collapse">
          <thead>
            <tr className="text-left border-t border-b border-gray-500">
              <th className="p-1">Rental Cars/Rental Agency</th>
              <th>Traveler</th>
              <th className="w-2/12">Travel Mode/Flight Arrival Info</th>
              <th className="w-2/12">Component Area/Flight Departure Info</th>
              <th className="w-2/12">Hotel Nights</th>
              <th>Review ID</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((d, i) => {
              return (
                <tr
                  key={i}
                  className="text-left border-t border-b border-gray-500"
                >
                  <td>
                    <div className="flex flex-col gap-6">
                      <div>{d.CarSize}</div>
                      <div>
                        <b>{d.RentalAgency}</b>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-col">
                      <div>
                        <b><MultiText value={['Title', 'FName', 'LName']} seperator=" " data={d}/> </b>
                      </div>
                      <div>{d.HomeAddress1}</div>
                      <div className="mt-2">
                        <MultiText value={['HomeCity', 'HomeState', 'HomeZip']} seperator=" " data={d}/>
                        {/* {d.City} {d.state} {d.Zip} */}
                      </div>
                      <div>{formatPhoneNumber(d.HomePhone)} (H)</div>
                      <div>{formatPhoneNumber(d.WorkPhone)} (W)</div>
                      <div>{formatPhoneNumber(d.cellular_phone)} (C)</div>
                      <div className="flex gap-3">
                        <div>Email: {d.email}</div>
                        
                      </div>
                    </div>
                  </td>
                  <td className="relative">
                    <div className="absolute top-0">{d.TravelMode}</div>
                    <div className="mt-3">
                      <div>{formatDate(d.arrival_arrival_date)}</div>
                      <div>{d.arrival_airline}</div>
                      {d.arrival_flight_number && (
                        <div>Flight:#{d.arrival_flight_number}</div>
                      )}

                      <div>{formatTime(d.arrival_flight_time)}</div>
                      <div>Arrival City: {d.arrival_city}</div>
                    </div>
                  </td>
                  <td className="relative">
                    <div className="absolute top-0">{d.ComponentArea}</div>
                    <div className="mt-3">
                      <div>{formatDate(d.depart_depart_date)}</div>
                      <div>{d.departure_airline}</div>
                      {d.depart_flight_number && (
                        <div>Flight:#{d.depart_flight_number}</div>
                      )}

                      <div>{formatTime(d.depart_flight_time)}</div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {d.hotel_nights}
                    </div>
                  </td>
                  <td>
                    <div className="mt-auto">
                      <b>{id}</b>
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <div>Total Team Size: {tableData.length}</div>
                <div>Total Rental Cars: {tableData.filter(t => t.CarSize !==" " && t.CarSize !=="").length}</div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Review ID: {id}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h6 className="text-center font-semibold">DD Summary for Review: {id}</h6>
        <div className="py-2 flex flex-col gap-3">
              {
                designatedData.map((driver, i) => {
                  return <div key={i}>
                    <MultiText className="flex-row" label="Designated Driver" value={['Title', 'FName', 'LName']} seperator=" " data={driver} />
                  </div>
                })
              }
        </div>
      </div>

      <div className="mt-2">
      <h6 className="text-center font-normal">Special Instructions</h6>
      <div className="w-full p-2 my-2  border-double border border-black text-center text-xl italic">
        N/A
      </div>
      </div>
    </div>
  );
}
