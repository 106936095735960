import React from "react";
import Input from "../../../../inputs/input";

export default function CashAdvance({ data, errors }) {
  return (
    <div className="flex flex-col gap-3 text-left p-3">
      <div className="w-1/6">
        <Input label="Misc C/A Amt($)" name="MiscCAAmount" id="MiscCAAmount" value={data?.MiscCAAmount} className="w-[100px]" isErrorOccurred={errors.MiscCAAmount} />
      </div>
      <div className="w-full">
        <Input type="textArea" label="Misc C/A Desc " name="MiscCADescript" id="MiscCADescript" value={data?.MiscCADescript} isErrorOccurred={errors.MiscCADescript} />
      </div>
      <div className="w-1/6">
        <Input label="Misc Encumb($)" name="MiscEncumbAmount" id="MiscEncumbAmount" value={data?.MiscEncumbAmount} className="w-[100px]" isErrorOccurred={errors.MiscEncumbAmount} />
      </div>
      <div className="w-full">
        <Input type="textArea" label="Encumb Desc " name="MiscEncumbDescript" id="MiscEncumbDescript" value={data?.MiscEncumbDescript} isErrorOccurred={errors.MiscEncumbDescript} />
      </div>
      <div className="w-1/6">
        <Input label="Honororia Days" name="HonorariaDays" id="HonorariaDays" value={data?.HonorariaDays} className="w-[100px]" isErrorOccurred={errors.HonorariaDays} />
      </div>
    </div>
  );
}
