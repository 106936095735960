/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types'

const Select = ({ label, className, options, selected, required, isErrorOccurred, ...props }) => {
  return (
    <div className="flex flex-col gap-2">
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
          {required && <span className="text-red-600"> *</span>}
        </label>
      ) : (
        <></>
      )}
      <select className={` max-w-[400px] whitespace-normal text-[13px] border p-[5px] border-input-border bg-white h-[46px] rounded-md shadow-sm ${className ? className : ''}`} {...props}>
        {
          options && options.length > 0 && options.map((option, i) => {
            return <option key={i} className="max-w-[400px] whitespace-normal" selected={option.value == selected} value={option.value}>{option.label}</option>
          })
        }
      </select>
      {isErrorOccurred && <p className="text-red-500 text-xs italic">{isErrorOccurred}</p>}
    </div>
  );
};


Select.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string
}

export default Select;
