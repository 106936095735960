import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
// import DataTable from "react-data-table-component";
import PrimaryButton from "../../components/buttons/primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'
import { get } from "../../services";
import Table from "../../components/table/table";

export default function ProcessReviewsSendEmail() {
  const { id } = useParams();
  const [data, setData] = useState([])
  // const handleChange = ({ selectedRows }) => {
  //   // You can set state or dispatch with something like Redux so we can use the retrieved data
  //   console.log("Selected Rows: ", selectedRows);
  // };

  useEffect(() => {
    get(`reviews/getReviewSendEmailReviewerList?trip_id=${id}`).then(res => {
      if(res && res.length >0){
        setData(res)
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])
  

  const columns = [
    {
      field: "reviewer_name",
      headerName: "REVIEWER"
    },
    {
      field: "email",
      headerName: "EMAIL",
    },
    {
      field: "ChangeInStatus",
      headerName: "STATUS",
    },
    {
      field: "",
      headerName: "CA PROCESSED ON",
    },
    {
      field: "",
      headerName: "EMAILED ON",
    }
  ];




  return (
    <div className="flex flex-col my-10 text-left">
      <PrimaryBreadCrumbs label="Review Search" path={`/reviews/details/${id}?tab=2`} />
      <TitlePrimary title={"Send Emails"} />
      <div className="mt-10 flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Review ID" value={id} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Review Date" value={"10/30-23 - 11/3/23"} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Grantee" value={"Kids Are First, Inc."} />
        </div>
      </div>
      <div className="mt-10">
        <Table
          columns={columns}
          data={data}
          // pagination
          // selectableRows
          // onSelectedRowsChange={handleChange}
        />
      </div>
      <div>
        <PrimaryButton label="Send Email" className={"px-3 py-2"} />
      </div>
    </div>
  );
}
