import React, { useState, useEffect } from "react";
import LayoutSecondary from "../../components/layouts/secondary";
import { useParams, useNavigate } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
import PrimaryBox from "../../components/box/box-primary";
import Input from "../../components/inputs/input";
import TelePhone from "../../components/inputs/telephone";
import Select from "../../components/inputs/select";
import CheckBox from "../../components/inputs/checkbox";
// import CheckBoxGroup from "../../components/inputs/checkbox-group";
import RadioGroup from "../../components/inputs/radio-group";
import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import { post, get } from "../../services";
import { toast, Bounce } from "react-toastify";

export default function RTLDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    get(`ftls/getFtlDetails?ftl_uid=${id}`)
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          //setFormData({})
          setFormData(res[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    var temp = {
      ftlUid: formData.FTL_UID ? formData.FTL_UID : id,
      title: formData.TITLE ? formData.TITLE : "",
      fName: formData.FName ? formData.FName : "",
      mInitial: formData.MINITIAL ? formData.MINITIAL : "",
      lName: formData.LName ? formData.LName : "",
      phone: formData.Phone ? formData.Phone : "",
      fax: formData.fAX ? formData.fAX : "",
      region: formData.Region ? formData.Region : "",
      email: formData.Email ? formData.Email : "",
      emergencyPhone: formData.EmergencyPhone ? formData.EmergencyPhone : "",
      userId: formData.user_code ? formData.user_code : "",
      ftlStatus: formData.ftl_status ? formData.ftl_status : "",
      inactiveDate: null,
      inactiveReason: null,
      inactivatedBy: null,
      inactivatedOn: null,
    };

    post("ftls/update", temp)
      .then((res) => {
        console.log(res);
        if (res) {
          //setFormData({})
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          window.location.reload()
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  return (
    <LayoutSecondary
      bCrumpLabel={"RTL Search"}
      bCrumpPath={"/rtl"}
      title="RTL Info"
    >
      <PrimaryBox>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6"
        >
          <TextWithLabel label="ID" value={formData.FTL_UID} />
          <div className="flex gap-6">
            <Input
              label="Title"
              id="TITLE"
              name="TITLE"
              value={formData.TITLE}
              size="sm"
            />
            <Input
              label="First Name"
              id="FName"
              name="FName"
              value={formData.FName}
            />
            <Input
              label="Last Name"
              id="LName"
              name="LName"
              value={formData.LName}
            />
          </div>
          <div className="flex gap-6">
            <div className="w-[200px]">
              <TelePhone
                label="Phone"
                id="Phone"
                name="Phone"
                value={formData.Phone}
              />
            </div>
            <div className="w-[200px]">
              <TelePhone
                label="Emergency"
                id="EmergencyPhone"
                name="EmergencyPhone"
                value={formData.EmergencyPhone}
              />
            </div>
            <div className="w-[200px]">
              <TelePhone label="Fax" id="fAX" name="fAX" value={formData.fAX} />
            </div>
          </div>

          <div className="flex gap-6">
            <div className="w-[200px]">
              <Input label="Email" id="Email" name="Email" value={formData.Email} />
            </div>
            <div className="w-[100px]">
              <Select
                label="Region"
                name="Region"
                id="Region"
                options={[...Array(13)].map((_, i) => {
                  return { label: `${i + 1}`, value: i + 1 };
                })}
                selected={formData.Region}
              />
            </div>
            <CheckBox label="Skype Account" name="" id="" />
          </div>
          <div className="flex gap-6">
            <RadioGroup
              label="Status"
              groups={[
                {
                  label: "Active",
                  value: "ACTIVE",
                  name: "ftl_status",
                  id: "ftl_status",
                  checked: formData.ftl_status == "ACTIVE",
                },
                {
                  label: "InActive",
                  value: "INACTIVE",
                  name: "ftl_status",
                  id: "ftl_status",
                  checked: formData.ftl_status == "INACTIVE",
                },
              ]}
              //handleChange={handleChange}
            />
          </div>
          <div className="flex gap-2 mt-5">
            <SecondaryButton
              className={"py-3 px-10"}
              onClick={() => navigate("/rtl")}
              label={"Cancel"}
              type="button"
            />
            <PrimaryButton
              type="submit"
              className={"py-3 px-10"}
              label={"Update RTL Info"}
              //onClick={handleFormSubmit}
            />{" "}
          </div>
        </form>
      </PrimaryBox>
    </LayoutSecondary>
  );
}
