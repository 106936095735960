import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
// import TitlePrimary from "../../components/typhography/title-primary";
import { get, post } from "../../services";
import { useStore } from "../../store";
// import InputDate from "../../components/inputs/date";
import Select from "../../components/inputs/select";
import TextWithLabel from "../../components/inputs/text";

//import { toast, Bounce } from "react-toastify";
import TertiaryButton from "../../components/buttons/tertiary";
import ReconcBillSearchResults from "../../components/hotel-reconciliation/hotel-bill-reonc";
import LayoutSecondary from "../../components/layouts/secondary";

export default function HotelBillReconciiliation() {
  const { ssoUserID } = useStore();
  const { id } = useParams();
  const [hotelData, setHotelData] = useState({});
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [group, setGroup] = useState([1]);
  const [reviewId, setReviewId] = useState("");
  const [reviewerListData, setReviewerListData] = useState([]);

  useEffect(() => {
    get(`hotelReconsile/details?tripHotelUid=${id}`).then(res => {
      console.log(res, 'res')
      if (res) {
        setHotelData(() => {
          var d = { ...res[0] }
          return d
        })
      }
    }).catch(err => {
      console.error(err)
    })
    get(`hotelReconsile/getReviewId?tripHotelUid=${id}`).then(res => {
      console.log(res, 'res')
      if (res) {
        setReviewId(res[0]?.trip_id);
      }
    }).catch(err => {
      console.error(err)
    })
    const dataObj = {
      tripHotelUid: id,
      userId: ssoUserID
    }
    post(`hotelReconsile/hotelReconsileRecHotelReviewerList`, dataObj).then(res => {
      console.log("res as follows", res, data);
      setReviewerListData(res);
    }).catch(err => {
      console.error(err)
    })
  }, [id])

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "hotelReconsile/hotelReconsileUpdateHotel";
    const dataObj = {
      ...formData,
      trip_hotel_uid: id,
    }
    post(url, dataObj).then((res) => {
      console.log("update success", res);
    }).catch((err) => {
      console.log("error occurred", err);
    })
    console.log("finalDataOb", dataObj, url);
    setData([]);
    /*if (formData && formData.display_by) {
      console.log(formData);
      
      var url = "hotelReconsile/hotelReconsileCompletedReview";
      if (
        formData &&
        formData.display_by &&
        formData.display_by === "reviewer"
      ) {
        url = "hotelReconsile/hotelReconsileCompletedReviewer";
      }
      Object.keys(formData).forEach((key, i) => {
        //if(key !== 'display_by'){
        if (i === 0) {
          url += `?${key}=${formData[key]}`;
        } else {
          url += `&${key}=${formData[key]}`;
          // }
        }
      });
      get(url)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error("Select any Display By", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }*/
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const expand = () => {
    const itemToBeAdded = group[group.length - 1];
    setGroup([...group, itemToBeAdded + 1]);
  };
  const remove = () => {
    const itemToBeAdded = group[group.length - 1];
    setGroup(group.filter((d) => d != itemToBeAdded));
  };

  useEffect(() => {
    group.map((g, i) => {
      if (i == 0) {
        formData['year_no'] = formData['year_no'] ? formData['year_no'] : '2020';
        formData['month_no'] = formData['month_no'] ? formData['month_no'] : '1';
      } else {
        formData[`year_no${i + 1}`] = formData[`year_no${i + 1}`] ? formData[`year_no${i + 1}`] : '2020';
        formData[`month_no${i + 1}`] = formData[`month_no${i + 1}`] ? formData[`month_no${i + 1}`] : '1';
      }
    })
  }, [group]);

  return (
    <LayoutSecondary
      bCrumpLabel={"Search"}
      bCrumpPath={"/hotel-reconciliation"}
      title="Hotel Bill Reconciliation"
    >
      <div className="text-left">
        {/* <TitlePrimary title={`Hotel Bill Reconciliation`} /> */}
        <div className="">
          <h6 className="font-bold">{hotelData?.Name}</h6>
          <div className="flex  gap-6 bg-white rounded-sm p-2 shadow-md mt-5">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <TextWithLabel label="" value={hotelData?.Address} />

              </div>
              <div className="mt-10">
                <span>
                  {hotelData?.address2 && <span><TextWithLabel label="" value={hotelData?.address2} />, </span>}
                  <TextWithLabel label="" value={hotelData?.Zip} />
                </span>
                <TextWithLabel label="" value={hotelData?.Phone} />

              </div>
            </div>
            <div className="ml-auto flex gap-10 w-4/6">
              <div className="flex flex-col gap-2">
                <TextWithLabel label="Booked Rate" value={hotelData?.BookedRate} />
                <TextWithLabel label="Taxes" value={hotelData?.Taxes} />
                <TextWithLabel label="Meeting Room" value={hotelData?.meeting_room} />
                <TextWithLabel label="Reconciled On" value={hotelData?.rec_date} />
              </div>
              <div className="flex flex-col gap-2">
                <TextWithLabel label="Hotel Nights" value={hotelData?.hotel_nights} />
                <TextWithLabel label="Direct Billing" value="Yes" />
                <TextWithLabel label="Trip ID" value={reviewId} />
                <TextWithLabel label="Reconciled By" value={hotelData?.rev_name} />
              </div>
            </div>
          </div>
        </div>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6 mt-10"
        >
          <div className="flex flex-col gap-2">
            {group.map((g, i) => {
              return (
                <div key={i} className="flex gap-3">
                  {group.length - 1 === i && (
                    <div className="flex gap-3 mt-auto w-[100px]">
                      {i < 3 && (
                        <TertiaryButton
                          label={"+"}
                          className={
                            "p-2 w-[40px] h-[40px] text-indigo-600 flex items-center justify-center"
                          }
                          onClick={() => expand()}
                        ></TertiaryButton>
                      )}
                      {i > 0 && (
                        <TertiaryButton
                          label={"-"}
                          className={
                            "p-2 w-[40px] h-[40px] text-indigo-600 flex items-center justify-center"
                          }
                          onClick={() => remove()}
                        ></TertiaryButton>
                      )}
                    </div>
                  )}
                  <div
                    className={`flex gap-2 ${group.length - 1 === i ? "" : "ml-[112px]"}`}
                  >
                    <div className="w-[80px]">
                      <Select
                        label={i == 0 ? "Month" : ""}
                        id={i == 0 ? 'month_no' : `month_no${i + 1}`}
                        name={i == 0 ? 'month_no' : `month_no${i + 1}`}
                        value=""
                        options={[...Array(12)].map((_, i) => {
                          return { label: `${i + 1}`, value: i + 1 };
                        })}
                      />
                      {group.length - 1 === i && <h6 className="mt-2">Total </h6>}
                    </div>
                    <div className="w-[120px]">
                      <Select
                        name={i == 0 ? 'year_no' : `year_no${i + 1}`}
                        label={i == 0 ? "Year" : ""}
                        id={i == 0 ? 'year_no' : `year_no${i + 1}`}
                        value=""
                        options={[2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000].map((val) => {
                          return { label: `${val}`, value: val };
                        })}
                      />
                    </div>
                    <div className="w-[200px]">
                      <Input
                        id={i == 0 ? 'meeting_room' : `meeting_room${i + 1}`}
                        name={i == 0 ? 'meeting_room' : `meeting_room${i + 1}`}
                        label={i == 0 ? "Meeting Room ($)" : ""}
                        value={i == 0 ? formData?.meeting_room : `${formData[`meeting_room${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[200px]">
                      <Input
                        name={i == 0 ? 'total_lodging' : `total_lodging${i + 1}`}
                        id={i == 0 ? 'total_lodging' : `total_lodging${i + 1}`}
                        label={i == 0 ? "Sleeping Room ($)" : ""}
                        value={i == 0 ? formData?.total_lodging : `${formData[`total_lodging${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[200px]">
                      <Input
                        name={i == 0 ? 'total_other' : `total_other${i + 1}`}
                        id={i == 0 ? 'total_other' : `total_other${i + 1}`}
                        label={i == 0 ? "Other ($)" : ""}
                        value={i == 0 ? formData?.total_other : `${formData[`total_other${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2"> $ 0.00 </h6>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex gap-6">
            <div className="w-[200px]">
              <Select label={""} value="" options={[]} />
            </div>
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Update Now"
                className={"px-3 py-2 "}
              />
            </div>
          </div>
        </form>
        <div className="flex flex-col gap-10 text-left mt-10">
          <ReconcBillSearchResults data={reviewerListData} />
        </div>
      </div>
    </LayoutSecondary>
  );
}
