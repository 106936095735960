import React, { useEffect, useRef, useState } from "react";

const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    const group = currentItem[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(currentItem);
    return result;
  }, {});
};

export const DynamicTable = ({ groupByField, columns, data, updateColumnData }) => {
  const [tableData, setData] = useState(data); // State to manage the table tableData

  console.log(data, 'datasdsdsd', tableData)

  useEffect(() => {

    setData(data)
  }, [data])



  const groupedData = groupBy(tableData, groupByField);

  const finalTotal = columns.reduce((totals, col) => {
    if (col.aggregate === "sum") {
      totals[col.field] = tableData.reduce(
        (sum, item) => sum + (item[col.field] || 0),
        0
      );
    }
    return totals;
  }, {});

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    if (bodyRef.current) {
      setScrollbarWidth(
        bodyRef.current.offsetWidth - bodyRef.current.clientWidth
      );
    }
  }, []);

  const handleInputChange = (value, rowIndex, field) => {
    const updatedData = [...tableData];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [field]: parseFloat(value) || 0, // Ensure numeric values
    };
    setData(updatedData);
  };

  return (
    <div className="relative">
      {/* Header Table */}
      <div
        className="overflow-hidden"
        style={{
          paddingRight: scrollbarWidth, // Account for vertical scrollbar
        }}
      >
        <table
          className="table-auto w-full border-collapse border border-gray-400"
          style={{ tableLayout: "fixed" }}
        >
          <thead
            ref={headerRef}
            className="sticky top-0 bg-indigo-600 text-white"
          >
            <tr>
              {columns.map((col) => (
                <th
                  key={col.field}
                  className="p-2 border border-gray-400 text-left"
                  style={{ width: `${100 / columns.length}%` }}
                >
                  {col.headerName}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>

      {/* Body Table */}
      <div
        className="overflow-y-auto"
        ref={bodyRef}
        style={{
          maxHeight: "400px",
        }}
      >
        <table
          className="table-auto w-full border-collapse border border-gray-400"
          style={{ tableLayout: "fixed" }}
        >
          <tbody>
            {Object.keys(groupedData).map((groupKey) => {
              const items = groupedData[groupKey];
              const groupTotals = columns.reduce((acc, col) => {
                if (col.aggregate === "sum") {
                  acc[col.field] = items.reduce(
                    (sum, item) => sum + (item[col.field] || 0),
                    0
                  );
                }
                return acc;
              }, {});

              return (
                <React.Fragment key={groupKey}>
                  {items.map((item, index) => (
                    <tr
                      key={`${groupKey}-${index}`}
                      className="border border-gray-400"
                    >
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2 border border-gray-400 text-left"
                        >
                          {col.edit && col.field ? (
                            <input
                              type="number"
                              value={item[col.field] || ""}
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  index,
                                  col.field
                                )
                                updateColumnData(index, col.field, e.target.value);
                              }
                              }
                              className="w-full border border-gray-300 p-1"
                            />
                          ) : index === 0 && col.field === groupByField ? (
                            groupKey
                          ) : (
                            item[col.field]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {items.length > 1 && (
                    <tr className="font-bold border border-gray-400">
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2 border border-gray-400
text-left text-indigo-600"
                        >
                          {col.aggregate === "sum"
                            ? `$${groupTotals[col.field]}`
                            : ""}
                        </td>
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
            <tr className="font-bold ">
              {columns.map((col, indx) => (
                <td
                  key={col.field}
                  className="p-2 sticky bottom-0 bg-white border
border-gray-400 text-left text-indigo-600"
                >
                  {col.aggregate === "sum"
                    ? `$${finalTotal[col.field]}`
                    : indx === 0 ? "Total" : ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
