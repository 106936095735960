/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types'
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";


const TextPhone = ({ label,value, className }) => {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}
      <p className="font-semibold text-[14px]">{formatPhoneNumber(value)}</p> 
      
    </div>
  );
};


TextPhone.prototype = {
    value : PropTypes.string,
    label: PropTypes.string
}

export default TextPhone;
