import React from "react";
import Input from "../inputs/input";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import TelePhone from "../inputs/telephone";


export default function HotelHistory() {
  const navigate = useNavigate();
  const handleRedirect = (val) => {
    navigate("/reviewers/details/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">HOTEL NAME</div>,
      selector: (row) => row.training,
      sortable: true,
      cell: (row) => {
        return <div  onClick={() => handleRedirect(row.reviewID)} className="text-indigo-600">{row.training}</div>;
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600">CITY</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">ZIP CODE</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">PHONE NUMBER</div>,
      selector: (row) => row.types,
      sortable: true,
    }
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <form className="flex flex-col gap-6">
      <div className="flex flex-col gap-6">
        <div className="flex gap-8">
          <div className="w-[400px]">
            <Input label="Hotel Name" />
          </div>
          <div className="w-[400px]">
            <Input label="City" />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-[200px]">
            <Input label="Zip Code" />
          </div>
          <div className="w-[200px]">
            <TelePhone label="Phone Number" />
          </div>
        </div>
        <div className="w-[170px]">
          <PrimaryButton label="Search" className={"px-3 py-2"} />
        </div>
      </div>
      <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Search Results</h6>
        <DataTable columns={columns} data={data} />
      </div>
    </form>
  );
}
