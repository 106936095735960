import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from '@azure/msal-react';

import { ReactComponent as Profile} from '../assets/icons/menus/profile.svg'
import { ReactComponent as  Grantees} from '../assets/icons/menus/grantees.svg'
import { ReactComponent as Home } from '../assets/icons/menus/home.svg'
import { ReactComponent as Reports } from '../assets/icons/menus/reports.svg'
import { ReactComponent as Reviewer} from '../assets/icons/menus/reviewer.svg'
import { ReactComponent as Reviews } from '../assets/icons/menus/reviews.svg'
import { ReactComponent as Settings } from '../assets/icons/menus/settings.svg'
import { ReactComponent as Travel } from '../assets/icons/menus/travel.svg'
import { ReactComponent as TER } from '../assets/icons/menus/TER.svg'


const menuLists= [
  {
    label: "Home",
    icon: <Home className="text-white font-semibold"/>,
    path: ""
  },
  {
    label: "Reviews",
    icon: <Reviews />,
    path: "reviews"
  },
  {
    label: "Reviewers",
    icon: <Reviewer />,
    path: "reviewers"
  },
  {
    label: "RTL",
    icon: <Travel />,
    path: "rtl"
  },
  {
    label: "RP",
    icon: <Grantees />,
    path: "rrc"
  },
  {
    label: "Profile Maintenance",
    icon: <Profile />,
    path: "profile-maintenance"
  },
  {
    label: "Travel",
    icon: <Travel />,
    path: "travel"
  },
  {
    label: "TER",
    icon: <TER />,
    path: "ter"
  },
  {
    label: "Reports",
    icon: <Reports />,
    path: "reports1"
  },
  {
    label: "Grantees",
    icon: <Grantees />,
    path: "grantees"
  },
  {
    label: "Profile",
    icon: <Profile />,
    path: "Profile"
  },
  {
    label: "Settings",
    icon: <Settings />,
    path: "Settings"
  },
  {
    label: "Uploads",
    icon: <Grantees />,
    path: "uploads"
  },
  {
    label: "Logout",
    icon: <Travel />,
    path: "logout",
    clickType:"logout"
  }
]

const Sidebar = () => {

  const { instance } = useMsal();
  let navigate = useNavigate();
  const location = useLocation();
  const selectedPath = location.pathname.split('/')[1]

  const handleNav = (menu) => {
      if(menu && menu.clickType && menu.clickType === "logout" ){
       instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
      }
  }
  return (
    <div
      id="docs-sidebar"
      className=" h-full hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform   bg-custom-blue border-e border-gray-200 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 dark:bg-gray-800 dark:border-gray-700 "
    >
      <div className="px-6 text-left">
        <a
          className="flex-none text-[16px] font-semibold dark:text-white text-white"
          href="#"
          aria-label="Brand"
        >
          Head Start Reviewing Tracking System
        </a>
      </div>
      <nav
        className="hs-accordion-group p-6 w-full flex flex-col flex-wrap"
        data-hs-accordion-always-open=""
      >
        <ul className="space-y-1.5">

        {
          menuLists.map((menu, i) => {

              if(menu.clickType){
                return <button
                className={` cursor-pointer w-full flex items-center gap-x-3.5 py-2 px-2.5 text-white text-sm rounded-lg hover:bg-indigo-600 hover:text-blue dark:bg-indigo-600 font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${selectedPath === menu.path ? "bg-indigo-600" : ""}`}
                onClick={() => handleNav(menu)}
                key={i}
              >
                {/* {} */}
                <div className="menu-icons font-bold">{menu?.icon}</div>
                 
                {menu.label}
              </button>
              }
              return   <li key={i}>
              <a
                className={` cursor-pointer flex items-center gap-x-3.5 py-2 px-2.5 text-white text-sm rounded-lg hover:bg-indigo-600 hover:text-blue dark:bg-indigo-600 font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${selectedPath === menu.path ? "bg-indigo-600" : ""}`}
                onClick={() => navigate(`/${menu.path}`)}
              >
                {/* {} */}
                <div className="menu-icons font-bold">{menu?.icon}</div>
                 
                {menu.label}
              </a>
            </li>
          })
        }
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
