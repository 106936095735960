/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";


export default function AccordianPrimary({accordians, accordianAction = "expandAll"}) {
  const [selectedAccordian, setAccordian] = useState([0]);

  useEffect(() => {
    if(accordianAction === "expandAll"){
      setAccordian(accordians.map((_, i) => i))
    }else if(accordianAction == "collapseAll"){
      setAccordian([])
    }
    
  }, [accordianAction])

  

  const handleAccordian = (id, action) => {
    if (action === "expand") {
      setAccordian([...selectedAccordian, id]);
    } else {
      const filterItems = selectedAccordian.filter((d) => d !== id);
      setAccordian([...filterItems]);
    }
  };


  return (
    <div className="flex flex-col gap-2">
    
      {accordians.map((acc, i) => {
        return <div key={i}>
            <div className={` text-indigo-600 text-[16px] font-semibold gap-4 cursor-pointer flex items-center `} onClick={() => handleAccordian(i, selectedAccordian.includes(i) ? '' : 'expand')}>
                <div className="text-[20px]"> {selectedAccordian.includes(i) ? '-' : '+'} </div>
                <div className="w-full text-left">{acc?.label}</div>
                {
                  acc?.info ? <div className="text-[#1E40AF] bg-[#DBEAFE] text-[12px] px-3 py-1 rounded-md">{acc?.info}</div> : <></>
                }
            </div>
            {
                selectedAccordian.includes(i) ? <div>{acc?.content}</div> : <></>
            }
            
        </div>;
      })}
    </div>
  );
}
