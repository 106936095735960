import React from "react";
import SecondaryButton from "../../../buttons/secondary";
import { ReactComponent as TrashSvg } from "../../../../assets/icons/trash.svg";
import Table from "../../../table/table";
import { post } from "../../../../services";

export default function ReviewersTable({ reviewInfo, reviewid }) {
  const deleteReviewer = (id) => {
    post(`delete-reviewer?reviewer-id=${id}`)
      .then((res) => {
        if (res) {
          console.log(res, "res");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    {
      field: "Reviewer_Id",
      headerName: "DANYA ID",
    },
    {
      field: "rev_name",
      headerName: "REVIEWER",
      urlParams: ["trip_details_uid", "reviewMainID"],
      url: "/reviews/reviewer-status",
    },
    {
      field: "email",
      headerName: "EMAIL",
    },
    {
      field: "cashAdvance",
      headerName: "TIS",
      cellRenderer: function () {
        return (
          <div>
            <SecondaryButton label="ViewTIS" />
          </div>
        );
      },
    },
    {
      field: "ChangeInStatus",
      headerName: "",
      cellRenderer: function ({ data }) {
        if (data && data.ChangeInStatus == "") {
          return (
            <div onClick={() => deleteReviewer(data.Reviewer_Id)}>
              <TrashSvg className="text-xs text-red-600" width="20" />
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  console.log(reviewInfo, "reviewInforeviewInfo", reviewid);

  if (reviewInfo && reviewInfo.length > 0) {
    reviewInfo.map((d) => {
      d["reviewMainID"] = reviewid;
      return d;
    });
  }

  return (
    <div>
      {reviewInfo && reviewInfo.length > 0 ? (
        <Table columns={columns} data={reviewInfo} />
      ) : (
        <div>No Reviewers Found</div>
      )}
    </div>
  );
}
