import React from "react";
import Table from "../table/table";

const ReviewsDataTable = ({ reviewsData }) => {
 
  const columns = [
    {
      headerName: "Review ID",
      field: "review_id",
      url: "/reviews/details",
      urlParams: ["review_id"],
    },
    {
      headerName: "Grantee ID",
      field: "fed_grantee_id",
    },
    {
      headerName: "Grantee Name",
      field: "Name",
    },
    {
      headerName: "Review Date",
      field: "review_date",
      // dateFilter: true,
      // type: "date",
    }
  ];


 
  return <Table columns={columns} data={reviewsData}  />;
};



export default ReviewsDataTable;
