import React, { useState } from "react";
import CheckBoxGroup from "../../../../inputs/checkbox-group";

export default function PaymentValidation({ data }) {
  // Define state to manage the checkbox data
  const [formData, setFormData] = useState(data);

  // Handle checkbox change
  const handleChange = (e, value) => {
    const { checked } = e.target;

    // Update formData based on checkbox status
    setFormData((prevState) => ({
      ...prevState,
      [value]: checked, // Set to true if checked, false if unchecked
    }));
  };

  // Define groups for CheckBoxGroup
  const groups = [
    {
      label: "Generate Reviewer PO",
      value: "Generate_DeltekPo",
      checked: formData["Generate_DeltekPo"] || false,
      id: "Generate_DeltekPo",
      name: "Generate_DeltekPo",
    },
    {
      label: "Generate COP",
      value: "Generate_COP",
      checked: formData["Generate_COP"] || false,
      id: "Generate_COP",
      name: "Generate_COP",
    },
    {
      label: "Add Misc. CA",
      value: "Add_Misc_CA",
      checked: formData["Add_Misc_CA"] || false,
      id: "Add_Misc_CA",
      name: "Add_Misc_CA",
    },
    {
      label: "Designated Trainee",
      value: "designated_trainee",
      checked: formData["designated_trainee"] || false,
      id: "designated_trainee",
      name: "designated_trainee",
    },
    {
      label: "Designated Guide",
      value: "designated_coach",
      checked: formData["designated_coach"] || false,
      id: "designated_coach",
      name: "designated_coach",
    },
  ];

  return (
    <div className="w-full p-8">
      {/* Pass handleChange to CheckBoxGroup */}
      <CheckBoxGroup alignmentType="col" groups={groups} handleChange={handleChange} />
    </div>
  );
}
