import React, {useState, useEffect} from "react";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'
import { get, post } from "../../services";
import { useParams } from "react-router-dom";
import MultiText from "../../components/inputs/multi-text";

import { toast, Bounce } from "react-toastify";


export default function TerNotes() {
  const [ data, setData] = useState({});
  const [ data1, setData1] = useState({});
  const [ notes, setNotes] = useState('')

  const {id} = useParams();

  useEffect(() => {
   get(`ter/terViewNotesHeaderDetails?tripDetailsUid=${id}`).then(res => {
      if(res && res.length > 0){
        setData(res[0])
      }
   }).catch(err => {
    console.error(err)
   })
   get(`ter/terViewNotes?tripDetailsUid=${id}`).then(res => {
    if(res && res.length > 0){
      setData1(res[0])
      if(res[0].ter_notes){
        setNotes(res[0].ter_notes)
      }
    }
 }).catch(err => {
  console.error(err)
 })
  }, [])

  const update = () => {
    if(notes){
      post(`ter/terUpdateNotes`, {
        "tripDetailsUid": id,
        "notes": notes
    }).then(res => {
      if(res){
        toast.success('Notes Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }).catch(err => {
      console.error(err)
    })
    }else{
      toast.error('Enter note', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }
  

  return (
    <div className="flex flex-col gap-7 text-left bg-white shadow-md p-3">
      <PrimaryBreadCrumbs label="Enter TER" path="/ter" />
      <TitlePrimary title={"TER Notes"} />
      <div className="flex flex-col gap-6">
        <div className="flex gap-6">
          <div className="w-[400px]">
            <TextWithLabel
              label="Grantee"
              value={data.Name}
            />
          </div>
          <div className="w-[400px]">
            <TextWithLabel label="Meeting Date" type="date"  value={data?.MeetingDate} />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <MultiText label="FTL" value={["TITLE", "FName", "MINITIAL", "LName"]} seperator=" " data={data}/>
          </div>
          <div className="w-[200px]">
            <MultiText
              label="Reviewer"
              seperator=" "
              value={['Title', 'rev_fname', 'rev_lname', 'HomeAddress1','HomeCity', 'HomeState', 'HomeZip', 'HomePhone' ]} data={data}
            />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <TextWithLabel label="TER Date" type="date" value={data1.ter_date} />
          </div>
          <div className="w-[400px]">
            <TextWithLabel label="New TER Date" type="date" value={data1.new_ter_date} />
          </div>
        </div>
        <div className="">
          <div className="w-full">
            <Input type="textArea" label="Notes"  value={notes} onChange={(e) => setNotes(e.target.value)}/>
          </div>
        </div>
      </div>
      <div>
      <PrimaryButton label="Update" className={"px-3 py-2"} onClick={() => update()}/>
      </div>
    </div>
  );
}
