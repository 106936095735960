import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import { get, post } from "../../services";
import { useStore } from "../../store";
// import InputDate from "../../components/inputs/date";
import Select from "../../components/inputs/select";
import TextWithLabel from "../../components/inputs/text";

//import { toast, Bounce } from "react-toastify";
//import TertiaryButton from "../../components/buttons/tertiary";
import ReconcBillSearchResults from "../../components/hotel-reconciliation/hotel-bill-new-reonc";
// import InputDate from "../../components/inputs/date";
import ReconcBillNewMiscSearchResults from "../../components/hotel-reconciliation/hotel-bill-new-reonc-misc";

import CheckBox from "../../components/inputs/checkbox";
import LayoutSecondary from "../../components/layouts/secondary";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

const yearsList = [2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000];

export default function HotelBillNewReconciiliation() {
  const { ssoUserID } = useStore();
  const { id } = useParams();
  const [hotelData, setHotelData] = useState({});
  //const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [group, setGroup] = useState([]);
  const [reviewId, setReviewId] = useState("");
  const [reviewerListData, setReviewerListData] = useState([]);
  //const [detailedViewMisc, setDetailedViewMisc] = useState([]);
  const [miscData, setMiscData] = useState([]);

  console.log(ssoUserID, 'ssoUserID')

  useEffect(() => {
    if(ssoUserID){

    
    get(`hotelReconsile/details?tripHotelUid=${id}`).then(res => {
      console.log(res, 'res')
      if (res) {
        setHotelData(() => {
          var d = { ...res[0] }
          return d
        })
      }
    }).catch(err => {
      console.error(err)
    })
    const dataObj = {
      tripHotelUid: id,
      userId: ssoUserID
    }
    post(`hotelReconsile/reconsileHotelAddReconsile`, dataObj).then(res => {
      console.log("Detailed View Items as follows", res[0], data, Array.isArray(res));
      let grpArr = [];
      const records = res[0];
      grpArr.push({
        [`month_no`]: records[`month_no`] || null,
        [`year_no`]: records[`year_no`] || null,
        [`meeting_room`]: records[`meeting_room`] || null,
        [`total_lodging`]: records[`total_lodging`] || null,
        [`total_internet`]: records[`total_internet`] || null,
        [`total_noshow`]: records[`total_noshow`] || null,
        [`total_other`]: records[`total_other`] || null,
      })
      for (let i = 1; i < 4; i++) {
        grpArr.push({
          [`month_no${i + 1}`]: records[`month_no${i + 1}`] || null,
          [`year_no${i + 1}`]: records[`year_no${i + 1}`] || null,
          [`meeting_room${i + 1}`]: records[`meeting_room${i + 1}`] || null,
          [`total_lodging${i + 1}`]: records[`total_lodging${i + 1}`] || null,
          [`total_internet${i + 1}`]: records[`total_internet${i + 1}`] || null,
          [`total_noshow${i + 1}`]: records[`total_noshow${i + 1}`] || null,
          [`total_other${i + 1}`]: records[`total_other${i + 1}`] || null,
        })
      }
      console.log("final grp array", grpArr)
      setGroup(grpArr);
    }).catch(err => {
      console.log("Error Occurred While Fetching Detailed View Misc Items", err);
    })
    post(`hotelReconsile/hotelReconsileHotelNewReconcileView`, dataObj).then(res => {
      console.log("res records as follows", res[0]);
      let fetchedMiscData = {};
      Object.keys(res[0]).map(item => {
        if (['mtgrm_misc', 'slprm_misc', 'internet_misc', 'other_misc', 'noshow_misc'].indexOf(item) !== -1) {
          fetchedMiscData[item] = res[0][item]
        }
      })
      setMiscData([fetchedMiscData]);
    }).catch(err => {
      console.error("Error occurred while fetching records", err);
    })
    get(`hotelReconsile/getReviewId?tripHotelUid=${id}`).then(res => {
      console.log(res, 'res')
      if (res) {
        setReviewId(res[0]?.trip_id);
      }
    }).catch(err => {
      console.error(err)
    })
    post(`hotelReconsile/hotelReconsileRecHotelReviewerList`, dataObj).then(res => {
      console.log("res as follows", res);
      setReviewerListData(res);
    }).catch(err => {
      console.error(err)
    })
  }
  }, [id, ssoUserID])

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "hotelReconsile/hotelReconsileUpdateHotel";
    console.log("group prior", group);
    const newObj = {};
    group.map((item) => {
      Object.assign(newObj, { ...item })
    })
    console.log("new obj as follows", newObj);
    const dataObj = {
      ...newObj,
      trip_hotel_uid: id,
    }
    console.log("data ob before update", dataObj, url)
    post(url, dataObj).then((res) => {
      console.log("update success", res);
    }).catch((err) => {
      console.log("error occurred", err);
    })
    console.log("finalDataOb", dataObj, url);
    setData([]);
    /*if (formData && formData.display_by) {
      console.log(formData);
      setData([]);
      var url = "hotelReconsile/hotelReconsileCompletedReview";
      if (
        formData &&
        formData.display_by &&
        formData.display_by === "reviewer"
      ) {
        url = "hotelReconsile/hotelReconsileCompletedReviewer";
      }
      Object.keys(formData).forEach((key, i) => {
        //if(key !== 'display_by'){
        if (i === 0) {
          url += `?${key}=${formData[key]}`;
        } else {
          url += `&${key}=${formData[key]}`;
          // }
        }
      });
      get(url)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error("Select any Display By", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }*/
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    // if (e.target.name && e.target.value) {
    //   setFormData((prev) => {
    //     return { ...prev, [e.target.name]: e.target.value };
    //   });
    // } else if (e.target.name) {
    //   setFormData((prev) => {
    //     return { ...prev, [e.target.name]: "" };
    //   });
    // }
    if (e.target.name && e.target.value) {
      const findGrpKey = group.findIndex(item => Object.keys(item).indexOf(e.target.name) !== -1);
      console.log("replacement", e.target.name, e.target.value);
      let existingGroup = group;
      let selectedGroup = existingGroup[findGrpKey];
      console.log("selected group", selectedGroup);
      selectedGroup[e.target.name] = e.target.value;
      // if (['year_no', 'year_no1', 'year_no2', 'year_no3'].indexOf(e.target.name) === -1) {

      // } else {
      //   selectedGroup[e.target.name] = yearsList[e.target.value];
      // }
      existingGroup[findGrpKey] = { ...selectedGroup };
      setGroup(existingGroup);
      // setFormData((prev) => {
      //   return { ...prev, [e.target.name]: e.target.value };
      // });
    } else if (e.target.name) {
      const findGrpKey = group.findIndex(item => Object.keys(item).indexOf(e.target.name) !== -1);
      console.log("findGrpKey", findGrpKey);
      let existingGroup = group;
      let selectedGroup = existingGroup[findGrpKey];
      selectedGroup[e.target.name] = "";
      existingGroup[findGrpKey] = { ...selectedGroup };
      setGroup(existingGroup);
    }
  };

  const handleReviewerUpdate = () => {
    const allReviewers = [...reviewerListData];
    console.log("updated misc data", miscData);
    allReviewers.map(item => {
      const updateObj = {
        ...item, ...miscData[0], "userId": ssoUserID
      }
      console.log("updateObj as follows", updateObj);
      post(`hotelReconsile/hotelReconsileNewReconsileUpdateHotel`, updateObj).then(res => {
        console.log("Updated Successfully", res);
      }).catch(err => {
        console.error("Call Failed", err);
      })
    })
    console.log("efore updates", allReviewers);
  }

  const handleMiscDataChanges = (rowIndex, field, value) => {
    const miscDataObj = miscData[0];
    miscDataObj[field] = value;
    setMiscData([miscDataObj]);
  }
  const handleReviewerHotelData = (rowIndex, field, value) => {
    const existingReviewerList = reviewerListData;
    existingReviewerList[rowIndex] = {
      ...existingReviewerList[rowIndex],
      [field]: value
    }
    setReviewerListData(existingReviewerList);
  }

  /*const expand = () => {
    const itemToBeAdded = group[group.length - 1];
    setGroup([...group, itemToBeAdded + 1]);
  };
  const remove = () => {
    const itemToBeAdded = group[group.length - 1];
    setGroup(group.filter((d) => d != itemToBeAdded));
  };*/

  /*useEffect(() => {
    group.map((g, i) => {
      if (i == 0) {
        formData['year_no'] = formData['year_no'] ? formData['year_no'] : '2020';
        formData['month_no'] = formData['month_no'] ? formData['month_no'] : '1';
      } else {
        formData[`year_no${i + 1}`] = formData[`year_no${i + 1}`] ? formData[`year_no${i + 1}`] : '2020';
        formData[`month_no${i + 1}`] = formData[`month_no${i + 1}`] ? formData[`month_no${i + 1}`] : '1';
      }
    })
  }, [group]);*/

  const handleActual = () => {
   
    setReviewerListData(reviewerListData.map(d => {
      d["actual_amount"] = d.EstimatedAmount;

      return {...d}
    }))
  }

  return (
    <LayoutSecondary
      bCrumpLabel={"Search"}
      bCrumpPath={"/hotel-reconciliation"}
      title="Hotel Bill Reconciliation"
    >
      <div className=" text-left pb-10">
        {/* <TitlePrimary title={`Hotel Bill Reconciliation`} /> */}
        <div className="">
          {/* <h6 className="font-bold">{hotelData?.Name}</h6> */}
          <div className="flex  gap-3 bg-white rounded-sm p-2 shadow-md">
            <div className="flex flex-col gap-2">

              <div className="flex flex-col gap-1">
                <TextWithLabel label="" value={hotelData?.Name} />
                <TextWithLabel label="" value={hotelData?.Address} />

              </div>
              <div className="mt-3">
                <span>
                  {hotelData?.address2 && <span><TextWithLabel label="" value={hotelData?.address2} />, </span>}
                  <TextWithLabel label="" value={hotelData?.Zip} />
                </span>
                <TextWithLabel label="" value={formatPhoneNumber(hotelData?.Phone)} />

              </div>

            </div>
            <div className="ml-auto flex gap-3 w-4/6">
              <div className="flex flex-col gap-2">
                <TextWithLabel label="Booked Rate" value={`$${hotelData?.BookedRate}`} />
                <TextWithLabel label="Taxes" value={`$${hotelData?.Taxes}`} />
                <TextWithLabel label="Meeting Room" value={`$${hotelData?.meeting_room}`} />
                <TextWithLabel type="date" label="Reconciled On" value={hotelData?.rec_date} />
              </div>
              <div className="flex flex-col gap-2">
                <TextWithLabel label="Hotel Nights" value={hotelData?.hotel_nights} />
                <TextWithLabel label="Direct Billing" value="Yes" />
                <TextWithLabel label="Trip ID" value={reviewId} />
                <TextWithLabel label="Reconciled By" value={hotelData?.rev_name} />
              </div>
            </div>
          </div>
        </div>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-3 mt-5"
        >
          <div className="flex flex-col gap-2">
            {group.map((g, i) => {
              return (
                <div key={i} className="flex gap-3">
                  {/* {group.length - 1 === i && (
                    <div className="flex gap-3 mt-auto w-[100px]">
                      {i < 3 && (
                        <TertiaryButton
                          label={"+"}
                          className={
                            "p-2 w-[40px] h-[40px] text-indigo-600 flex items-center justify-center"
                          }
                          onClick={() => expand()}
                        ></TertiaryButton>
                      )}
                      {i > 0 && (
                        <TertiaryButton
                          label={"-"}
                          className={
                            "p-2 w-[40px] h-[40px] text-indigo-600 flex items-center justify-center"
                          }
                          onClick={() => remove()}
                        ></TertiaryButton>
                      )}
                    </div>
                  )} */}
                  <div
                    className={`flex gap-2 ml-[112px]`}
                  >
                    <div className="w-[80px]">
                      <Select
                        label={i == 0 ? "Month" : ""}
                        id={i == 0 ? 'month_no' : `month_no${i + 1}`}
                        name={i == 0 ? 'month_no' : `month_no${i + 1}`}
                        value={i == 0 ? g?.month_no : `${g[`month_no${i + 1}`] || ""}`}
                        options={[...Array(12)].map((_, i) => {
                          return { label: `${i + 1}`, value: i + 1 };
                        })}
                      />
                      {group.length - 1 === i && <h6 className="mt-2">Total </h6>}
                    </div>
                    <div className="w-[120px]">
                      <Select
                        name={i == 0 ? 'year_no' : `year_no${i + 1}`}
                        label={i == 0 ? "Year" : ""}
                        id={i == 0 ? 'year_no' : `year_no${i + 1}`}
                        value={i == 0 ? g?.year_no : `${g[`year_no${i + 1}`] || ""}`}
                        options={yearsList.map((val) => {
                          return { label: `${val}`, value: val };
                        })}
                      />
                    </div>
                    <div className="w-[150px]">
                      <Input
                        type="number"
                        id={i == 0 ? 'meeting_room' : `meeting_room${i + 1}`}
                        name={i == 0 ? 'meeting_room' : `meeting_room${i + 1}`}
                        label={i == 0 ? "Meeting Room ($)" : ""}
                        value={i == 0 ? g?.meeting_room : `${g[`meeting_room${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[150px]">
                      <Input
                        type="number"
                        name={i == 0 ? 'total_lodging' : `total_lodging${i + 1}`}
                        id={i == 0 ? 'total_lodging' : `total_lodging${i + 1}`}
                        label={i == 0 ? "Sleeping Room ($)" : ""}
                        value={i == 0 ? g?.total_lodging : `${g[`total_lodging${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[150px]">
                      <Input
                        type="number"
                        name={i == 0 ? 'total_internet' : `total_internet${i + 1}`}
                        id={i == 0 ? 'total_internet' : `total_internet${i + 1}`}
                        label={i == 0 ? "Internet ($)" : ""}
                        value={i == 0 ? g?.total_internet : `${g[`total_internet${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[150px]">
                      <Input
                        type="number"
                        name={i == 0 ? 'total_other' : `total_other${i + 1}`}
                        id={i == 0 ? 'total_other' : `total_other${i + 1}`}
                        label={i == 0 ? "Other ($)" : ""}
                        value={i == 0 ? g?.total_other : `${g[`total_other${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2"> $ 0.00 </h6>
                      )}
                    </div>
                    <div className="w-[150px]">
                      <Input
                        type="number"
                        name={i == 0 ? 'total_noshow' : `total_noshow${i + 1}`}
                        id={i == 0 ? 'total_noshow' : `total_noshow${i + 1}`}
                        label={i == 0 ? "NoShow ($)" : ""}
                        value={i == 0 ? g?.total_noshow : `${g[`total_noshow${i + 1}`] || ""}`}
                        options={[]}
                      />
                      {group.length - 1 === i && (
                        <h6 className="mt-2">$ 0.00 </h6>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex gap-3 items-center">
            <div className="">
              <CheckBox label={"Do Not Reconcile"} />
            </div>
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Update Now"
                className={"px-3 py-2 "}
              />
            </div>
          </div>
        </form>
        <div className="flex flex-col gap-3 text-left mt-5">
          <h6>Detailed View -  Misc</h6>
          <ReconcBillNewMiscSearchResults data={miscData} updateMiscData={(rowIndex, field, value) => handleMiscDataChanges(rowIndex, field, value)} />
        </div>
        <div className="flex flex-col gap-3 text-left mt-5">
          <h6 className="mb-0 pb-0">Detailed View -  Line Item <span className="ml-3 font-light text-[13px]">Number Of Reviewers: 3 </span></h6>
          <div className="flex flex-col gap-3 text-left">
            <ReconcBillSearchResults data={reviewerListData} updateReviewerHotel={(rowIndex, field, value) => handleReviewerHotelData(rowIndex, field, value)} />
          </div>
          <div className="ml-auto flex gap-3">
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Estimated -> Actual"
                className={"px-3 py-2 "}
                onClick={handleActual}
              />
            </div>
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Update Reviewer Info"
                className={"px-3 py-2 "}
                onClick={handleReviewerUpdate}
              />
            </div>
          </div>
        </div>
      </div>


    </LayoutSecondary>
  );
}
