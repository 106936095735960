import { useEffect, useState, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import "./App.css";
// import withAuth from "./config/withAuth";
import { pagesWithRoutes } from "./page-list";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Layout from "./components/Layout";

import { axiosInstance, get } from "./services";

import { useStore } from "./store";
import { setUserInfo, setRoles, setSSODetails } from "./store/actions";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import { useMsal } from "@azure/msal-react";

function App({ instance }) {
  const { dispatch } = useStore();
  const [isInActive, setIsInActive] = useState(false);
  // const { instance } = useMsal();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (instance) {
        const activeAccount = instance.getActiveAccount();

        if (activeAccount) {
          if (window.location.pathname === "/login") {
            window.location.replace("/");
          } else {
            if (activeAccount && activeAccount.idToken) {
              if (dispatch) {
                dispatch(setUserInfo(activeAccount));
              }
              axiosInstance.defaults.headers.Authorization = `Bearer ${activeAccount.idToken}`;
              console.log(activeAccount, 'activeAccont')
              if (
                activeAccount.idTokenClaims &&
                activeAccount.idTokenClaims.groups &&
                activeAccount.idTokenClaims.groups.length > 0
              ) {

                get(
                  `groupTasks?group_id=${activeAccount.idTokenClaims.groups[0].toString().toUpperCase()}`
                )
                  .then((res) => {
                    // get(`groupTasks?group_id=2AC341BD-B119-4CB5-B38B-B793CD0BA708`).then(res => {
                    if (res) {
                      dispatch(setRoles(res));
                    }
                    console.log(res, "res");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if(activeAccount && activeAccount.username){
                  get(`user/getSSOUser?userName=${activeAccount.username}`).then(res => {
                      if(res && res.length >0){
                        dispatch(setSSODetails(res[0]));
                      }
                  }).catch(error => {
                    console.error(error)
                  })
              }
            }
          }
        } else {
          if (window.location.pathname !== "/login") {
            window.location.replace("/login");
          }
        }

        console.log(activeAccount, "activeAccount ");
      }
      setLoading(false);
    }, 500);
  }, []);

  // console.log(userDetails, instance, 'userToken', dayjs(new Date()))

  // if(userDetails && userDetails.idTokenClaims && userDetails.idTokenClaims.exp && instance){
  //   //console.log(dayjs.unix(userDetails.idTokenClaims.exp), 'tokenqwqw', new Date(userDetails.idTokenClaims.exp * 1000) < new Date())
  //   if(new Date(userDetails.idTokenClaims.exp * 1000) < new Date()){

  //     instance.logoutRedirect({
  //       account: instance.getActiveAccount(),
  //   });

  //    }
  // }

  const activityWatcher = useCallback(() => {
    var secondsSinceLastActivity = 0;
    var activeAccount = instance.getActiveAccount();
    console.log(instance, "userDetailsuserDetails");

    var expiresIn = 3600;

    if (
      activeAccount &&
      activeAccount.idTokenClaims &&
      activeAccount.idTokenClaims.exp
    ) {
      const milliseconds =
        new Date(activeAccount.idTokenClaims.exp * 1000) - new Date();

      expiresIn = milliseconds / 1000;

      var maxInactivity = expiresIn;

      setInterval(async function () {


        var expiry =
          (new Date(activeAccount.idTokenClaims.exp * 1000) - new Date()) /
          1000;

        secondsSinceLastActivity++;

        if (secondsSinceLastActivity > maxInactivity) {
          console.log("logout started");
          console.log("logout intiated");
          //instance["browserStorage"].clear();
          if (instance.controller && instance.controller.browserStorage) {
            instance.controller.browserStorage.clear()
          }
          setTimeout(() => {
            instance.logoutRedirect({
              account: instance.getActiveAccount(),
            });
          }, 100);
          setIsInActive(true);
          secondsSinceLastActivity = 0;
        }

        if (secondsSinceLastActivity < maxInactivity && expiry <= 300) {
          console.log("refresh started");
          //refrsh

          var request = {
            //scopes: ["Mail.Read"],
            account: instance.getActiveAccount(),
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 7200, // 2 hours * 60 minutes * 60 seconds = 7200 seconds
          };

          const tokenResponse = await instance
            .acquireTokenSilent(request)
            .catch(async (error) => {
              if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                await instance.acquireTokenRedirect(request);
              }
            });

          if (
            tokenResponse &&
            tokenResponse.idTokenClaims &&
            tokenResponse.idTokenClaims.exp
          ) {
            activeAccount = tokenResponse;

            expiry =
              (new Date(tokenResponse.idTokenClaims.exp * 1000) - new Date()) /
              1000;
            if (dispatch) {
              dispatch(setUserInfo(tokenResponse));
            }
            axiosInstance.defaults.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
          } else {
            //instance["browserStorage"].clear();
            if (instance.controller && instance.controller.browserStorage) {
              instance.controller.browserStorage.clear()
            }
            setTimeout(() => {
              instance.logoutRedirect({
                account: instance.getActiveAccount(),
              });
            }, 100);
            console.log("logout intiated first");
          }
        }


      }, 1000);
    }

    function activity() {
      secondsSinceLastActivity = 0;
    }

    var activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];

    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activity, true);
    });
  }, []);

  useEffect(() => {
    activityWatcher();
    var activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];
    return () => {
      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, null, true);
      });
      // document.removeEventListener();
    };
  }, [activityWatcher]);

  useEffect(() => {
    if (isInActive) {
      //refreshTokenInterval();
      setIsInActive(false);
    }
  }, [isInActive]);

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <MsalProvider instance={instance}>
        <ToastContainer />
        <Routes>
          {!loading &&
            pagesWithRoutes.map((page, i) => {
              if (page.public) {
                return (
                  <Route
                    key={i}
                    path={page.path}
                    element={
                      <UnauthenticatedTemplate>
                        {page.component}
                      </UnauthenticatedTemplate>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={i}
                    path={page.path}
                    element={
                      <AuthenticatedTemplate>
                        <Layout>{page.component}</Layout>{" "}
                      </AuthenticatedTemplate>
                    }
                  />
                );
              }
            })}
        </Routes>

        {/* </BrowserRouter> */}
      </MsalProvider>
    </div>
  );
}

export default App;
