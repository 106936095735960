import React from "react";
import TextWithLabel from "../../../../inputs/text";

import { formatPhoneNumber } from '../../../../../utils/formatPhoneNumber'

export default function TripInfo({details}) {
  return (
    <div className="px-7 py-2 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Trip Notes" value={details?.notes} />
        </div>

        <div>
          <TextWithLabel
            label="Trip ID/Grantee"
            value={`${details?.trip_id || "N/A"} ${details?.NAME || "N/A"}`}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Trip Dates" value={details?.review_dates} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" time="off" label="Processed On" value={details?.advance_processed} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" label="Packets Mailed On" value={details?.packets_mailed} />
        </div>

        <div className="w-[200px]">
          <TextWithLabel label="Review Type" value={details?.sub_type} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Fellow" value={details?.fellow} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Work Phone" value="(000) 000-0000" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Traine/Presentor Component Area"
            value={details?.componentarea}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="RTL" value={`${details?.ftl_fname || "N/A"} ${details?.ftl_lname || "N/A"}`} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Phone" value={formatPhoneNumber(details?.Phone)} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="RP" value={details?.Rrc_name} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel type="date" label="TER Date" value={details.ter_date} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" label="New TER Date" value={details.new_ter_date} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="TER Processed By" value={details.processed_by} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" label="TER Processed On" time="off" value={details.processed_date} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-full">
          <TextWithLabel
            label="TER Notes"
            value={details?.ter_notes}
          />
        </div>
      </div>
    </div>
  );
}
