import React from "react";
import TextWithLabel from "../../../../inputs/text";

export default function Invoice({details}) {
  return (
    <div className="px-7 py-2 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel
            label="Airline/Train Ticket Total Cost"
            value={details?.Airfare}
          />
        </div>
        <div className="w-[200px]">
          <TextWithLabel
            label="Airline/Train Ticket Total Cost"
            vvalue={details?.Airfare1}
          />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="E-Ticket" value={details.e_tickets ? 'Yes' : ''} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Team Meeting" value="4/19/2016" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Exit Meeting" value="4/22/2016" />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Travel Mode" value="Rental Car" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Rental Car Agency" value="National" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Carsize" value="Compact" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Estimated Cost" value="$176.00" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Other Description" value="n/a" />
        </div>
      </div>
    </div>
  );
}
