import React, {useState, useEffect} from "react";
import LayoutSecondary from "../../components/layouts/secondary";
import PrimaryButton from "../../components/buttons/primary";
import Input from "../../components/inputs/input";
import TextWithLabel from "../../components/inputs/text";
import { useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";

import { get, post } from "../../services";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { useStore } from "../../store";

export default function EditUploadAirtTicket() {
  const {id} = useParams();
  const navigate = useNavigate()
  const { ssoUserID } = useStore();
  const [data, setData] = useState({})

  useEffect(() => {
    get(`upload/air_edit_data?upload_air_uid=${id}`).then(res => {
      if(res && res.length > 0){
        setData(res[0])
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    var temp = {
      trip_id: data.trip_id ? data.trip_id : '',
      deltek_vendor_id: data.deltek_vendor_id ? data.deltek_vendor_id : '',
      "userId": ssoUserID
    }

    post(`upload/air_validate_data`, temp).then(res =>{
      console.log(res)
      if(res && res.ErrorMessage){
        toast.error(res.ErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }else if(res){
        post(`upload/air_update_tickets`, {"userId": ssoUserID, "upload_air_uid":id,"trip_id":temp.trip_id,"deltek_vendor_id":temp.deltek_vendor_id }).then(res =>{
          console.log(res)
          if(res){
            toast.success('Updated Successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });

            navigate('/uploads/upload-air-ticket')


          }
        }).catch(err => {
          toast.error(err, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        })
      }else{
        toast.error('validation failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }).catch(() => {
      toast.error('validation failed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    })
  }

  const handleChange = (e) => {
    console.log(e.target, 'e.target')
    if(e.target.name && e.target.value){
        setData(prev=>{
        return {...prev, [e.target.name]: e.target.value}
      })
     
    }else if(e.target.name){
      setData(prev=>{
        return {...prev, [e.target.name]: ""}
      }) 
    }
  }
  return (
    <div >
      <LayoutSecondary
        bCrumpLabel={"Upload Air Ticket"}
        bCrumpPath={"/uploads/upload-air-ticket"}
        title="Edit Upload Air Ticket"
      >
        <form className="flex flex-col gap-8 mb-20 p-3 bg-white shadow-md rounded-md" onChange={handleChange} onSubmit={handleSubmit} >
          <div className="flex gap-8">
            <div >
              <TextWithLabel label="Passenger Name" value={data?.passenger_name} />
            </div>
            <div>
              <TextWithLabel label="Depart Date"  value={
                      data?.depart_date &&
                      dayjs.utc(data?.depart_date).format("MM/DD/YYYY")
                    } />
            </div>
          </div>
          <div className="w-[200px]">
            <Input label="Review ID" name="trip_id" id="trip_id" value={data.trip_id ? data.trip_id : ''} />
          </div>
          <div className="w-[200px]">
            <Input label="Vendor ID" name="deltek_vendor_id" id="deltek_vendor_id" value={data.deltek_vendor_id ? data.deltek_vendor_id : ''}/>
          </div>
          <div className="flex gap-6">
            <PrimaryButton typ="submit" label="Update Now" className="p-2" />
          </div>
        </form>
      </LayoutSecondary>
    </div>
  );
}
