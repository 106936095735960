import React, { useEffect, useState } from "react";
import Search from "../../inputs/search";
import NewReviews from "./new-reviews";
import Changes from "./changes";
import { get } from "../../../services";
import Others from "./others";
import NotSubmitted from "./not-submitted";
import Loader from "../../loader";

export default function ProjectInbox() {
  const [newReviewData, setNewReviewData] = useState([]);
  const [otherReviewData, setOtherReviewData] = useState([]);
  const [notSubmittedReviews, setNotSubmittedReviews] = useState([]);
  const [reviewChanges, setReviewChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  useEffect(() => {
    setLoading(true);
    get("projectInbox/getProjectInbox?inboxType=NewReview")
      .then((res) => {
        if (res) {
          console.log("success", res);
          setNewReviewData(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    setLoading1(true);
    get("projectInbox/getProjectInbox?inboxType=Other")
      .then((res) => {
        if (res) {
          console.log("success", res);
          setOtherReviewData(res);
        }
        setLoading1(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading1(false);
      });

    setLoading2(true);
    get("projectInbox/getProjectInbox?inboxType=NotSubmitted")
      .then((res) => {
        if (res) {
          console.log("success", res);
          setNotSubmittedReviews(res);
        }
        setLoading2(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading2(false);
      });

    setLoading3(true);
    get("projectInbox/getProjectInbox?inboxType=AddOn")
      .then((res) => {
        if (res) {
          console.log("success", res);
          setReviewChanges(res);
        }
        setLoading3(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading3(false);
      });
  }, []);

  return (
    <div className="flex flex-col text-left gap-3">
      <div className="flex flex-col gap-6 mt-6">
        <h6 className="font-semibold text-[20px]">Project Inbox</h6>
        <div className="w-[400px]">
          <Search searchLabel="Search" />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <NewReviews data={newReviewData} />
        </>
      )}
      {loading1 ? (
        <Loader />
      ) : (
        <>
          <Changes data={reviewChanges} />
        </>
      )}
      {loading2 ? (
        <Loader />
      ) : (
        <>
          <Others data={otherReviewData} />
        </>
      )}
      {loading3 ? (
        <Loader />
      ) : (
        <>
          <NotSubmitted data={notSubmittedReviews} />
        </>
      )}
    </div>
  );
}
