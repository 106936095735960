import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function formatDate(dateString, time){
    if(dateString){
        if(time){
            return dayjs.utc(dateString).format('YYYY-MM-DD  hh:mm A')
        }else{
            return dayjs.utc(dateString).format('YYYY-MM-DD')
        }
        
    }else{
        return ''
    }
    
}

export function formatTime(dateString){
    if(dateString){
        return dayjs.utc(dateString).format('hh:mm A')
        
    }else{
        return ''
    }
    
}