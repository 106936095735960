import React, {useState} from "react";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  const [isExpanded, setExpanded] = useState(true)
  return (
    <div className="flex w-full">
      {
        !isExpanded ? <button className="w-[25px] h-[25px] bg-gray-400 fixed cursor-pointer " onClick={() => setExpanded(true)}>{">"}</button> : <div className="lg:block w-[250px] h-screen fixed">
        <Sidebar setExpanded={setExpanded}/>
      </div>
      }
      
      <div className={`w-full bg-[#ECEEF0] min-h-screen overflow-auto ${isExpanded? 'ml-[235px]' : 'ml-[25px]'}`}>
      {/* <div className="w-full lg:pl-[235px] bg-bg-primary"> */}
        <div className="px-8 pt-4">{children}</div>
      </div>
    </div>
  );
};

Layout.prototype = {
  children: PropTypes.node,
};

export default Layout;
