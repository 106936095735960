import React from "react";
import Table from "../table/table.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const SearchResults = ({ data }) => {
  const columns = [
    {
      field: "user_code",
      headerName: "User ID",
      url: "/profile-maintenance/details",
      urlParams: ["user_code"],
    },
    {
      field: "fname",
      headerName: "User Name",
    },
    {
      field: "home_phone",
      headerName: "Phone",
      type: "phone",
    },
    {
      field: "email",
      headerName: "Email",
    },
  ];
 //data = tempData;

  return <Table columns={columns} data={data} />;
};

export default SearchResults;
