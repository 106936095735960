/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types';
import InputMask from "react-input-mask";



const TelePhone = ({ label,className, disabled, required, size, isErrorOccurred, ...props }) => {
  //const [data, setData] = useState('')
  // useEffect(() => {
  //   if(value){
  //     setData(formatTelephone(value))
  //   }
  // }, [value])
  
  // const formatTelephone = (input) => {
  //   if(input){
  //     console.log(input,  'inputjnsdjsd')
  //     var input1 = input.replace(/\D/g,'');
      
  //     var size = input1.length;
  //     console.log(input1, 'input', size)
  //     if (size>0) {input1="("+input1}
  //     if (size>3) {input1=input1.slice(0,4)+") "+input1.slice(4,11)}
  //     if (size>6) {input1=input1.slice(0,9)+"-" +input1.slice(9)}
  //     console.log(input1, 'input')
  //     return input1;
  //   }else{
  //     return ''
  //   }
   
  // }
  return (
    <div className="flex flex-col gap-2">
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
          {required && <span className="text-red-600"> *</span>}
        </label>
      ) : (
        <></>
      )}
      <InputMask mask="(999) 999-9999"   disabled={disabled} {...props} className={` ${disabled ? 'bg-gray-200' : 'bg-white'} border px-2 border-input-border  h-[46px] rounded-md shadow-sm ${isErrorOccurred ? 'border-red-500' : ''} ${className ? className : ''} ${size && size === 'sm' ? 'w-[50px]' : ''}`} />
    
      {isErrorOccurred && <p className="text-red-500 text-xs italic">{isErrorOccurred}</p>}
    </div>
  );
};


TelePhone.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string
}

export default TelePhone;
