/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types'
import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)


const TextWithLabel = ({ label,value,type,time, className }) => {
  if(type === "date" && value && !time){
    const date1 =  dayjs.utc(value).format("MM/DD/YYYY hh:mm A");

    value = date1 ? date1 != "Invalid Date" ? date1 : '' :''
  }else if(type === "date" && value && time && time=="off"){
    const date1 =  dayjs.utc(value).format("MM/DD/YYYY");

    value = date1 ? date1 != "Invalid Date" ? date1 : '' : ''
  }
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}
      {
        value ? <p className="font-semibold text-[14px]">{value}</p> : <></>
      }
      
    </div>
  );
};


TextWithLabel.prototype = {
    value : PropTypes.string,
    label: PropTypes.string
}

export default TextWithLabel;
