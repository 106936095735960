import ReviewsDataTable from "./reviews/ReviewsDataTable";
import React, { useState } from "react";
import { URI_reviews } from "../config/constants";
import Select from "./inputs/select";
import { get } from "../services";
import { useStore } from "../store/index";
import Loader from "./loader";

const searchByOptions = [
  {
    label: "Review ID",
    value: "review_id",
  },
  {
    label: " Grantee ID",
    value: "grantee_id",
  },
  {
    label: " Grantee Name",
    value: "grantee_name",
  },
];

const ReviewSearch = () => {
  const { roles } = useStore();

  const [reviewsData, setReviewsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchByType, setSearchByType] = useState("review_id");
  const [loading, setLoading] = useState(false);

  const handleSearchResults = () => {
    if (searchValue) {
      setLoading(true);

      get(`${URI_reviews}?${searchByType}=${searchValue}`)
        .then((res) => {
          console.log(res, "response");
          setReviewsData(res);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchByType(e.target.value);
    setSearchValue("");
  };

  const filterSearchByOption = searchByOptions.filter(
    (d) => d.value === searchByType
  );
  const searchByTypeLabel =
    filterSearchByOption.length > 0
      ? filterSearchByOption[0].label
      : "Review ID";

  return (
    <div>
      {roles && roles.includes("searchReview") ? (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <h5 className="font-semibold text-left text-xl">Review Search</h5>
            <div className="flex gap-3 w-full items-center">
              <div className="flex flex-col gap-3">
                <label className="block text-sm font-medium text-gray-600  text-left">
                  Search By
                </label>
                <Select
                  onChange={(val) => handleSearchChange(val)}
                  options={searchByOptions}
                />
              </div>
              <div className="flex gap-3 w-full items-center">
                <div className="flex flex-col gap-3">
                  <label className="block text-sm font-medium text-gray-600  text-left">
                    {searchByTypeLabel}
                  </label>
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => handleSearchValueChange(e)}
                    className="py-3 px-3 block w-96 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <button
                  type="button"
                  className=" mt-auto py-3 px-3 align-end inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-indigo-600 text-white hover:bg-gray-900"
                  onClick={handleSearchResults}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h6 className="font-semibold text-left text-xl">
              Results ({reviewsData.length})
            </h6>
            {/* <label className="block text-sm text-gray-600  font-semibold text-left">
              {reviewsData.length} Results
            </label> */}
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div>
                    {loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div>
                        <div className="border rounded-lg overflow-hidden dark:border-gray-700">
                          <ReviewsDataTable
                            reviewsData={reviewsData}
                            isLinkDisabled={
                              !(roles && roles.includes("changeReview"))
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewSearch;
