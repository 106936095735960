import { SET_ROLES, SET_USER_INFO, SET_SSO_DETAILS } from "./constants";

export const intialState = {
  userDetails: {},
  roles: [],
  ssoDetails: [],
};

export const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case SET_USER_INFO:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_SSO_DETAILS:
      return {
        ...state,
        ssoDetails: action.payload,
      };

    default:
      break;
  }
};
