import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
// import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useParams, useNavigate } from "react-router-dom";
import {  post, get } from "../../services";
import Select from "../../components/inputs/select";
import TelePhone from "../../components/inputs/telephone";
// import TextWithLabel from "../../components/inputs/text";
import PrimaryBox from "../../components/box/box-primary";
import RadioGroup from "../../components/inputs/radio-group";

import { toast, Bounce } from "react-toastify";
import TextWithLabel from "../../components/inputs/text";
import LayoutSecondary from "../../components/layouts/secondary";
import CheckBoxGroup from "../../components/inputs/checkbox-group";

// const roles = [
//   {
//     label: "RPS",
//     value: "RPS",
//   },
//   {
//     label: "RP",
//     value: "RP",
//   },
//   {
//     label: "RRC",
//     value: "RRC",
//   },
//   {
//     label: "LA",
//     value: "LA",
//   },
// ];

export default function AddProfileMaintenanceDetails() {
  const navigate = useNavigate()

  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
      get(`user/userProfileRoleList`)
        .then((res) => {
          if (res && res.length > 0) {
            setRoles(res.map((item) => ({
              label: item.UserRole,
              value: item.UserRole,
            })));
          }
        })
        .catch((err) => {
          console.error(err);
        });
        get(`user/userProfileRegionList`)
        .then((res) => {
          if (res && res.length > 0) {
            setRegions(res.map((item) => ({
              label: item.RegionDisplay,
              value: item.RegionValue,
            })));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setData([]);
    var url = "user/create";
    var temp = {
      "user_code": id,
      "title": formData.title ? formData.title : '',
      "fname":formData.fname ? formData.fname : '',
      "lname": formData.lname ? formData.lname : '',
      "mi": formData.mi ? formData.mi : '',
      "suffix": formData.suffix ? formData.suffix : '',
      "job_title": formData.job_title ? formData.job_title : '',
      "work_phone": formData.work_phone ? formData.work_phone : '',
      "work_fax": formData.work_fax ? formData.work_fax : '',
      "home_phone": formData.home_phone ? formData.home_phone : '',
      "address1": formData.address1 ? formData.address1 : '',
      "address2": formData.address2 ? formData.address2 : '',
      "city": formData.city ? formData.city : '',
      "state": formData.state ? formData.state : '',
      "zip": formData.zip ? formData.zip : '',
      "email": formData.email ? formData.email : '',
      "head_start": formData.head_start ? formData.head_start : '',
      "user_role": formData.user_role ? formData.user_role : '',
      "region": formData.region ? formData.region : '',
      "user_status": formData.user_status ? formData.user_status : '',
      "user_since": formData.user_since ? formData.user_since : null,
      "inactive_date": formData.inactive_date ? formData.inactive_date : null,
      "user_id": formData.user_id ? formData.user_id : null,
      "Password_Reset": formData.Password_Reset ? formData.Password_Reset : '',
      "Authorized_POandProject": formData.Authorized_POandProject ? 1 : 0,
      "Authorized_APVoucher": formData.Authorized_APVoucher ? 1 : 0,
      "Authorized_Vendor": formData.Authorized_Vendor ? 1 : 0,
      "Authorized_UndoCash":formData.Authorized_UndoCash ? 1 : 0,
      "Authorized_ReviewerAddDelete": formData.Authorized_ReviewerAddDelete ? 1 : 0,
      "SSOUserId": formData.SSOUserId ? formData.SSOUserId : null,
  }

    post(url, temp)
      .then((res) => {
        console.log(res);
        if (res) {
          // setFormData({})
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          navigate('/profile-maintenance')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");

    if(e.target.type === "checkbox" && e.target.name){
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.checked };
      });
    }else if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  console.log(data);

  return (
    <LayoutSecondary
    bCrumpLabel={"User Search"}
    bCrumpPath={"/profile-maintenance"}
    title="Create New User"
  >
      <PrimaryBox>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6"
        >
          <div>
            <TextWithLabel label="User Code" value={id} />
          </div>
          <div className="flex gap-6">
            <Input
              label="Title"
              id="title"
              name="title"
              value={formData.title}
              size="sm"
            />
            <Input
              label="First Name"
              id="fname"
              name="fname"
              value={formData.fname}
            />
            <Input
              label="Middle Name"
              id="mi"
              name="mi"
              className="w-[100px]"
              value={formData.mi}
            />
            <Input
              label="Last Name"
              id="lname"
              name="lname"
              value={formData.lname}
            />
          </div>
          <div className="flex flex-col gap-6 w-3/6">
            <Input
              label="Address Line 1"
              id="address1"
              name="address1"
              value={formData.address1}
            />
            <Input
              label="Address Line 2"
              id="address2"
              name="address2"
              value={formData.address2}
            />
            <div className="flex gap-6">
              <Input label="City" id="city" name="city" value={formData.city} />
              <Input
                label="State"
                id="state"
                name="state"
                className="w-[80px]"
                value={formData.state}
              />
              <Input label="Zip" id="zip" name="zip" value={formData.zip} />
            </div>
            <div className="flex gap-6">
              <div className="w-[200px]">
                <TelePhone
                  label="Phone"
                  id="home_phone"
                  name="home_phone"
                  value={formData.work_phone}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Fax"
                  id="work_fax"
                  name="work_fax"
                  value={formData.work_fax}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Emergency"
                  id="emergency"
                  name="emergency"
                  value={formData.emergency}
                />
              </div>
            </div>
            <div className="flex gap-6">
              <Input
                name="email"
                id="email"
                label="Email"
                className="w-[200px]"
                value={formData.email}
              />
              <RadioGroup
                label="Head Start Team"
                groups={[
                  {
                    label: "Yes",
                    value: "Yes",
                    name: "head_start",
                    id: "head_start",
                    checked: formData.head_start && formData.head_start == 'Yes' || false,
                  },
                  {
                    label: "No",
                    value: "No",
                    name: "head_start",
                    id: "head_start",
                    checked: formData.head_start && formData.head_start == 'No' || false,
                  },
                ]}
                // handleChange={handleChange}
              />
            </div>

            <div className="flex gap-6">
              <Select
                label="User Role"
                name=""
                id=""
                className="w-[150px]"
                options={roles}
              />
              <Select
                label="Region"
                name=""
                id=""
                className="w-[150px]"
                options={regions}
              />
            </div>

            {/* <div className="flex gap-6">
              <RadioGroup
                label="User Status"
                groups={[
                  {
                    label: "Active",
                    value: "Active",
                    name: "user_status",
                    id: "user_status",
                    checked: formData.user_status && formData.user_status == 'Active' || false,
                  },
                  {
                    label: "In Active",
                    value: "InActive",
                    name: "user_status",
                    id: "user_status",
                    checked: formData.user_status && formData.user_status == 'InActive'|| false,
                  },
                ]}
                // handleChange={handleChange}
              />

              <InputDate
                label="In Active Date"
                id="inactive_date"
                name="inactive_date"
                className="w-[200px]"
                value={formData.inactive_date}
              />
            </div> */}
            <div className="flex flex-col gap-6">
              <CheckBoxGroup
                label="Cash Advance"
                groups={[
                  {
                    label: "Undo Cash Avance",
                    //value: true,
                    name: "Authorized_UndoCash",
                    id: "Authorized_UndoCash",
                    checked: formData.Authorized_UndoCash || false,
                  },
                  {
                    label: "Allowed To Send AP Voucher",
                   // value: true,
                    name: "Authorized_APVoucher",
                    id: "Authorized_APVoucher",
                    checked: formData.Authorized_APVoucher || false,
                  },
                  {
                    label: "Allowed To Send POs",
                    //value: true,
                    name: "Authorized_POandProject",
                    id: "Authorized_POandProject",
                    checked: formData.Authorized_POandProject || false,
                  },
                  {
                    label: "Allowed To Vendor ID",
                    //value: false,
                    name: "Authorized_Vendor",
                    id: "Authorized_Vendor",
                    checked: formData.Authorized_Vendor || false,
                  },
                ]}
                // handleChange={handleChange}
              />
              <CheckBoxGroup
                label="Team Build"
                groups={[
                  {
                    label: "Reviewer Add/Delete",
                    value: true,
                    name: "hea",
                    id: "hea",
                    //checked: data.ecd || false,
                  },
                ]}
                // handleChange={handleChange}
              />
            </div>
            
          </div>

          <div className="flex gap-6">
            <div className="w[200px]">
              <PrimaryButton
                type="submit"
                label="Update"
                className={"px-3 py-2"}
              />
            </div>
            {/* <div className="w[200px]">
              <TertiaryButton
                type="button"
                label="Add New"
                className={"px-3 py-2"}
                //onClick={() => navigate('/rtl/add-new')}
              />
            </div> */}
          </div>
        </form>
      </PrimaryBox>
    </LayoutSecondary>
  );
}
