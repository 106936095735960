import React from "react";
import TextWithLabel from "../../../../inputs/text";
import dayjs from "dayjs";

export default function TravelerInfo({details}) {
  return (
    <div className="px-7 py-2 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="SSN" value={details?.SSN} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Traveler" value={details?.tr}/>
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Traveler's Address"
            value={details?.tr}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Home" value={details?.HomePhone} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Work Phone" value={details?.WorkPhone} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Request" value={details.Request_Date? dayjs(details.Request_Date).format("MM/DD/YYYY") : ''} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Check Needed" value={details.Check_Needed? dayjs(details.Check_Needed).format("MM/DD/YYYY") : ''}/>
        </div>
      </div>
    </div>
  );
}
