import React from "react";
import Input from "../inputs/input";
import RadioGroup from "../inputs/radio-group";
import Select from "../inputs/select";
import TelePhone from "../inputs/telephone";

export default function GranteesEdit({ granteeSearchData }) {
 
  return (
    <div className="flex flex-col gap-4 justify-between py-4">
      <div className="flex flex-col gap-4 text-left">
        <div className="flex flex-col gap-8 text-left">
          <div className="flex gap-8">
            <div className="w-60">
              <Input
                label="Grantee Id"
                name="GranteeID"
                id="GranteeID"
                value={granteeSearchData?.GranteeID}
              />
            </div>
            <div className="w-60">
              <Input
                label="Label"
                name="Name"
                id="Name"
                value={granteeSearchData?.Name}
              />
            </div>
          </div>
          <div>
            <Input
              label="Address"
              name="Address"
              id="Address"
              value={granteeSearchData?.Address}
            />
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <Input
                label="City"
                name="City"
                id="City"
                value={granteeSearchData?.City}
              />
            </div>
            <div className="w-60">
              <Input
                label="State"
                name="State"
                id="State"
                value={granteeSearchData?.State}
                maxLength="2"
              />
            </div>
            <div className="w-60">
              <Input
                label="Zip Code"
                name="Zip"
                id="Zip"
                value={granteeSearchData?.Zip}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <Input
                label="County"
                name="County"
                id="County"
                value={granteeSearchData?.County}
              />
            </div>
            <div className="w-60">
              <Input
                label="Airport"
                name="airport"
                id="airport"
                value={granteeSearchData?.airport}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <TelePhone
                label="Phone"
                name="Phone"
                id="Phone"
                value={granteeSearchData?.Phone}
              />
            </div>
            <div className="w-60">
              <Input
                label="Fax"
                name="Fax"
                id="Fax"
                value={granteeSearchData?.Fax}
              />
            </div>
          </div>

          <div className="flex gap-8">
            <div className="w-60">
              <Input
                label="Contact"
                name="Contact"
                id="Contact"
                value={granteeSearchData?.Contact}
              />
            </div>
            <div className="w-60">
              <Input
                label="Director"
                name="Director"
                id="Director"
                value={granteeSearchData?.Director}
              />
            </div>
          </div>
          <div className="w-60">
            <RadioGroup
              label="Has Delegate"
              name="hasdelegate"
              id="hasdelegate"
              checked={granteeSearchData?.hasdelegate}
              groups={[
                {
                  id: "Yes",
                  label: "Yes",
                  name: "hasdelegate",
                  value:"Yes"
                },
                {
                  id: "No",
                  label: "No",
                  name: "hasdelegate",
                  value:"No"
                },
              ]}
            />
          </div>

          <div className="w-60">
            <Input
              label="Delegate of"
              name="isdelegateof"
              id="isdelegateof"
              value={granteeSearchData?.isdelegateof}
            />
          </div>

          <div className="w-60">
            <Input
              label="Email"
              name="email"
              id="email"
              value={granteeSearchData?.email}
            />
          </div>
          <div className="w-60">
            <Select
              label="Region"
              name="Region"
              id="Region"
              options={[...Array(13)].map((_, i) => {
                return { label: `${i + 1}`, value: i + 1 };
              })}
              value={granteeSearchData?.Region}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
