import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { currencyFormatter } from "../../utils/formatCurrency";
import InputDate from "../inputs/date";

const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    const group = currentItem[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(currentItem);
    return result;
  }, {});
};

const getInputElement = (col, value, onChangeHandler) => {
  console.log(col, value, "tableData");
  switch (col.type) {
    case "date":
      return (
        <InputDate
          type="date"
          // value={value ? dayjs(value).format("YYYY-MM-DD") : new Date()}
          defaultValue={
            value ? dayjs.utc(value).format("YYYY-MM-DD") : new Date()
          }
          onChange={(e) => onChangeHandler(e.target.value)}
          className="w-full border bg-white border-gray-300 p-1"
        />
      );
    case "checkbox":
      return (
        <input
          type="checkbox"
          checked={!!value}
          onChange={(e) => onChangeHandler(e.target.checked)}
          className="w-5 h-5"
        />
      );
    case "select":
      return (
        <select
          value={value || ""}
          onChange={(e) => onChangeHandler(e.target.value)}
          className="w-full border bg-white border-gray-300 p-1"
        >
          {(col.options || []).map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    default:
      return (
        <input
          type="text"
          value={value || ""}
          onChange={(e) => onChangeHandler(e.target.value)}
          className="w-full border bg-white border-gray-300 p-1"
        />
      );
  }
};

export const DynamicTable = ({
  groupByField,
  columns,
  data,
  updateColumnData,
}) => {
  const [tableData, setData] = useState(data);
  const [filters, setFilters] = useState({});
  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: null,
  });
  const initialWidths = columns.reduce((acc, col) => {
    acc[col.field] = col.width || 150;
    return acc;
  }, {});
  const [colWidths, setColWidths] = useState(initialWidths);

  useEffect(() => {
    setData(data);
  }, [data]);

  const groupedData = groupBy(tableData, groupByField);

  const finalTotal = columns.reduce((totals, col) => {
    if (col.aggregate === "sum") {
      totals[col.field] = tableData.reduce(
        (sum, item) => sum + (item[col.field] || 0),
        0
      );
    }
    return totals;
  }, {});

  const bodyRef = useRef(null);
  // const [scrollbarWidth, setScrollbarWidth] = useState(0);

  // useEffect(() => {
  //   if (bodyRef.current) {
  //     setScrollbarWidth(
  //       bodyRef.current.offsetWidth - bodyRef.current.clientWidth
  //     );
  //   }
  // }, []);

  const handleInputChange = (value, rowIndex, field) => {
    const updatedData = [...tableData];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [field]: value,
    };
    setData(updatedData);
  };

  const currentColumnRef = useRef(null);
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);

  const handleMouseDown = (e, columnKey) => {
    currentColumnRef.current = columnKey;
    startXRef.current = e.clientX;
    startWidthRef.current = colWidths[columnKey];

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!currentColumnRef.current) return;
    const diffX = e.clientX - startXRef.current;
    const newWidth = startWidthRef.current + diffX;
    // Set a minimum width (e.g., 50px)
    if (newWidth > 50) {
      setColWidths((prev) => ({
        ...prev,
        [currentColumnRef.current]: newWidth,
      }));
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    currentColumnRef.current = null;
  };

  // Handle Filtering
  const handleFilterChange = (field, value, type = "text") => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [field]: { value, type } };

      const filteredData = data.filter((item) => {
        return Object.keys(newFilters).every((filterField) => {
          const filterValue = newFilters[filterField].value;
          const filterType = newFilters[filterField].type;

          if (!filterValue) return true;

          const itemValue = item[filterField];
          if (filterType === "date") {
            return (
              dayjs.utc(itemValue).format("YYYY-MM-DD") ===
              dayjs.utc(filterValue).format("YYYY-MM-DD")
            );
          } else if (filterType === "text") {
            return itemValue
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          }
          return true;
        });
      });

      setData(
        filteredData.length > 0 ||
          Object.values(newFilters).some((f) => f.value)
          ? filteredData
          : data
      );

      return newFilters;
    });
  };

  // Handle Sorting
  const handleSort = (field) => {
    const direction =
      sortConfig.field === field && sortConfig.direction === "asc"
        ? "desc"
        : "asc";

    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (typeof aValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }
    });

    setData(sortedData);
    setSortConfig({ field, direction });
  };

  return (
    <div className="relative">
      {/* <div
        className="overflow-hidden"
        style={{
          paddingRight: scrollbarWidth,
        }}
      >
        <table
          className="table-auto w-full border-collapse  bg-white
text-sm rounded-md"
          style={{ tableLayout: "fixed", paddingRight: scrollbarWidth }}
        >
          <thead
            ref={headerRef}
            className=" top-0 border text-white bg-indigo-600"
          >
        

          
          </thead>
        </table>
      </div> */}

      {/* Body Table */}
      <div
        className="overflow-y-auto"
        ref={bodyRef}
        style={{
          maxHeight: "400px",
        }}
      >
        <table
          className="table-auto w-full border-collapse border bg-white
border-gray-300 text-sm"
          style={{ tableLayout: "fixed" }}
        >
          <thead className="">
          <tr className="border text-white bg-indigo-600">
              {columns.map((col) => (
                <th
                  key={col.field}
                  className="p-2  text-left cursor-pointer"
                  // style={{
                  //   width: col.width ? col.width : `${100 / columns.length}%`,
                  // }}
                  style={{
                    width: colWidths[col.field],
                    position: "relative",
                    border: "1px solid #ccc",
                    padding: "8px",
                    //display: "flex",
                  }}
                >
                  <div onClick={() => handleSort(col.field)}>
                    {col.headerName}
                    {sortConfig.field === col.field && (
                      <span className="ml-2">
                        {sortConfig.direction === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>

                  <div
                    onMouseDown={(e) => handleMouseDown(e, col.field)}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "100%",
                      width: "5px",
                      cursor: "ew-resize",
                      marginLeft: "auto",
                    }}
                  />
                </th>
              ))}
            </tr>
          <tr className="bg-gray-50 border-gray-300">
              {columns.map((col) => (
                <th
                  key={`filter-${col.field}`}
                  className="text-left h-full border-gray-300"
                  // style={{
                  //   width: col.width ? col.width : `${100 / columns.length}%`,
                  // }}
                  style={{
                    width: colWidths[col.field],
                    position: "relative",
                    border: "1px solid #ccc",
                    padding: "8px",
                  }}
                >
                  {col.searchable ? (
                    col.type === "date" ? (
                      <InputDate
                        type="date"
                        value={filters[col.field]?.value || ""}
                        onChange={(e) =>
                          handleFilterChange(col.field, e.target.value, "date")
                        }
                        className="w-full border h-[36px] bg-white text-black m-1
border-gray-300 font-[400]"
                      />
                    ) : (
                      <input
                        type="text"
                        value={filters[col.field]?.value || ""}
                        onChange={(e) =>
                          handleFilterChange(col.field, e.target.value, "text")
                        }
                        className="w-full border text-black bg-white border-gray-300 rounded-md font-[400] p-1 m-1 h-[36px]"
                        //laceholder={`Search ${col.headerName}`}
                      />
                    )
                  ) : null}
                </th>
              ))}
            </tr>
{/*             
            <tr className="">
            {columns.map((col,i) => {
              return <th key={i}  style={{
                width: colWidths[col.field],
               position: "relative",
                border: "1px solid #ccc",
                padding: "8px",
                height:0
              }}></th>
            })}
            </tr> */}
          </thead>
          <tbody>
            {Object.keys(groupedData).map((groupKey) => {
              const items = groupedData[groupKey];
              const groupTotals = columns.reduce((acc, col) => {
                if (col.aggregate === "sum") {
                  acc[col.field] = items.reduce(
                    (sum, item) => sum + (item[col.field] || 0),
                    0
                  );
                }
                return acc;
              }, {});

              return (
                <React.Fragment key={groupKey}>
                  {items.map((item, index) => (
                    <tr
                      key={`${groupKey}-${index}`}
                      className=" bg-white border-gray-300"
                    >
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2 bg-white border truncate max-w-[150px] overflow-hidden whitespace-nowrap
border-gray-300 text-left"
                          title={item[col.field]}
                          // style={{
                          //   width: col.width
                          //     ? col.width
                          //     : `${100 / columns.length}%`,
                          // }}
                          style={{
                            width: colWidths[col.field],
                            position: "relative",
                            border: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          {col.edit && col.field ? (
                            getInputElement(
                              col,
                              item[col.field],
                              (newValue) => {
                                handleInputChange(newValue, index, col.field);
                                updateColumnData &&
                                  updateColumnData(newValue, index, col.field);
                              }
                            )
                          ) : index === 0 && col.field === groupByField ? (
                            col.amount ? (
                              currencyFormatter(groupKey)
                            ) : (
                              groupKey
                            )
                          ) : col.type === "date" ? (
                            item[col.field] ? (
                              col.time ? (
                                dayjs
                                  .utc(item[col.field])
                                  .format("MM/DD/YYYY hh:mm:ss A")
                              ) : (
                                dayjs.utc(item[col.field]).format("MM/DD/YYYY")
                              )
                            ) : (
                              ""
                            )
                          ) : col.amount ? (
                            currencyFormatter(item[col.field])
                          ) : col.cellRenderer ? (
                            col.cellRenderer(col, item)
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item[col.field],
                              }}
                            ></span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {items.length > 1 && groupByField && (
                    <tr className="font-bold  bg-white border-gray-300">
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2  bg-white border
border-gray-300 text-left text-indigo-600"
                          style={{
                            width: colWidths[col.field],
                            position: "relative",
                            border: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          {col.aggregate === "sum"
                            ? col.amount
                              ? currencyFormatter(groupTotals[col.field])
                              : groupTotals[col.field]
                            :  col.totalLabel ? col.totalLabel : ""}
                        </td>
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
            {columns && columns.filter((d) => d.aggregate).length > 0 && (
              <tr className="font-bold ">
                {columns.map((col, indx) => (
                  <td
                    style={{
                      width: colWidths[col.field],
                      position: "relative",
                      border: "1px solid #ccc",
                      padding: "8px",
                    }}
                    key={col.field}
                    className="p-2 sticky bottom-0 border  bg-white
border-gray-300 text-left text-indigo-600"
                  >
                    {col.aggregate === "sum"
                      ? col.amount
                        ? currencyFormatter(finalTotal[col.field])
                        : finalTotal[col.field]
                      : col.totalLabel ? col.totalLabel : indx === 0
                        ? "Total"
                        : ""}
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
