import React from "react";
import Table from '../table/table.jsx'

const SearchResults = ({ data }) => {

  const columns = [
    {
      field:"fname",
      headerName:"Name",
      url:'/reviewers/details',
      urlParams:['REVIEWERS_UID']
    },
    {
      field:"reviewer_type",
      headerName:"Type",
    },
    {
      field:"coach_trainee",
      headerName:"C/T",
    },
    {
      field:"status",
      headerName:"Status",
      cellRenderer:function({value}){
        if(value && value === "INACTIVE"){
          return <div className="bg-indigo-100 flex items-center justify-center rounded-xl font-semibold text-indigo-800  w-[80px] h-[30px]">{value}</div>
        }else{
          return <div className="bg-indigo-100 flex items-center justify-center rounded-xl font-semibold text-indigo-800  w-[80px] h-[30px]">{value}</div>
        }
      }
    },
    {
      field:"Temp_Hold",
      headerName:"TEMP HOLD",
    },
    {
      field:"foreignLanguage",
      headerName:"FOREIGN LANGUAGE",
    },
    {
      field:"email",
      headerName:"Email",
    }
  ]
 
  return <Table columns={columns} data={data} />;
};



export default SearchResults;
