import React from "react";
import Table from "../../table/table";

export default function Changes() {
  const columns = [
   
    {
      field:"trip_id",
      headerName:"REVIEW ID",
      url:'/accept-a-case',
      urlParams:['trip_id']
    },
    {
      field:"review_date",
      headerName:"REVIEW DATE"
    },
    {
      field:"rev_name",
      headerName:"REVIEWER"
    },
    {
      field:"qc_status",
      headerName:"QC STATUS",
      cellRenderer: function ({value}){
        const type=value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "In Progress"? 'bg-[#FEF3C7] text-[#92400E]' : type==="Not Started"? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
            {type}
        </div>
      }
    },
    {
      field:"creation_ts",
      headerName:"RECEIVED ON"
    },
    {
      field:"tran_type",
      headerName:"OPERATION",
      cellRenderer: function({value}){
        const type=value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "Final"? 'bg-[#E0E7FF]' : type==="Partial"? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
            {type}
        </div>
      }
    }
  ]

  const data = [
    {
      tran_type: "Final",
      trip_id: "01234F2C",
      review_date: "3/1/24, 11:45 AM",
      Name: "Putnam County Board of Education",
      qc_status: "In Progress",
      user_id: "AJOHNSTON",
      creation_ts: "3/1/24, 11:45 AM",
      rev_name:'XXX-xx-Elizabeth Baker'
    },
    {
      tran_type: "Partial",
      trip_id: "01234F2C",
      review_date: "3/1/24, 11:45 AM",
      Name: "Putnam County Board of Education",
      qc_status: "Not Started",
      user_id: "AJOHNSTON",
      creation_ts: "3/1/24, 11:45 AM",
      rev_name:'XXX-xx-Elizabeth Baker'
    },
  ];
  return (
    <div className="flex flex-col gap-6 mb-20">
      <h6 className="font-semibold text-[20px]">Changes</h6>
    
      <Table columns={columns} data={data} />
    </div>
  );
}
