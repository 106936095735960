import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { DynamicTable } from "../../../table/tableWithTotal.jsx";

import { get } from "../../../../services/index.js";
import Loader from "../../../loader.jsx";
import { useNavigate } from "react-router-dom";

export default function CurrentSchedules({ id }) {
  // const navigate = useNavigate();
  const [historyData, setHistoryDatya] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    get(`reviewers/fetchReviewersHistory?historyType=past&reviewers_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setHistoryDatya(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  // const handleReviewClick = (reviewId) => {
  //   navigate("/reviews/details/" + reviewId);
  // };
  const columns = [
    {
      field: "trip_id",
      headerName: "REVIEW ID",
      searchable: true,
      cellRenderer: function (_, data) {
        console.log("rendering row data", data)
        return (
          <div className=" text-indigo-600 text-md" onClick={() => {navigate("/reviews/details/"+data?.trip_id)}}>{data?.trip_id}</div>
        )
      }
    },
    {
      field: "sub_type",
      headerName: "Type",
      searchable: true,
    },
    {
      field: "rev_date",
      headerName: "REVIEW DATES",

      searchable: true,
    },
    {
      field: "name",
      headerName: "GRANTEE",
      searchable: true,
    },
    {
      field: "rrc_name",
      headerName: "Review Planner",
      searchable: true,
    },
    {
      field: "CreateTs",
      headerName: "Assigned To Team On",
      searchable:true,
      type:"date"
    },
    {
      field: "componentarea", 
      headerName: "COMP AREA",
      searchable: true,
    },
    {
      field: "header_status",
      headerName: "Cancelled Review",
      searchable: true,
    },
    {
      field: "dd",
      headerName: "DD",
      searchable: true,
    },
    {
      field: "changeinstatusDesc",
      headerName: "REVIEW STATUS",
      //type: "action",
      cellRenderer: function (_, data) {
        if (data && data.changeinstatusDesc) {
          return (
            <div
              //className=" text-indigo-600 text-md cursor-pointer"
             // onClick={() => handleChange(data?.trip_details_uid)}
            >
              {data.changeinstatusDesc}
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  if (loading) {
    return <Loader />;
  }
  return <DynamicTable columns={columns} data={historyData} />;
}
