import React from "react";
import Table from "../../table/table";

export default function Changes() {
  const columns = [
   
    {
      field:"reviewID",
      headerName:"REVIEW ID",
      url:'/accept-a-case',
      urlParams:['reviewID']
    },
    {
      field:"reviewDate",
      headerName:"REVIEW DATE"
    },
    {
      field:"reviewer",
      headerName:"REVIEWER"
    },
    {
      field:"qcStatus",
      headerName:"QC STATUS",
      cellRenderer: function ({value}){
        const type=value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "In Progress"? 'bg-[#FEF3C7] text-[#92400E]' : type==="Not Started"? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
            {type}
        </div>
      }
    },
    {
      field:"recievedOn",
      headerName:"RECEIVED ON"
    },
    {
      field:"submitType",
      headerName:"OPERATION",
      cellRenderer: function({value}){
        const type=value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "Final"? 'bg-[#E0E7FF]' : type==="Partial"? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
            {type}
        </div>
      }
    }
  ]

  const data = [
    {
      submitType: "Final",
      reviewID: "01234F2C",
      reviewDate: "01234F2C",
      granteeName: "Putnam County Board of Education",
      qcStatus: "In Progress",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
      reviewer:'XXX-xx-Elizabeth Baker'
    },
    {
      submitType: "Partial",
      reviewID: "01234F2C",
      reviewDate: "01234F2C",
      granteeName: "Putnam County Board of Education",
      qcStatus: "Not Started",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
      reviewer:'XXX-xx-Elizabeth Baker'
    },
  ];
  return (
    <div className="flex flex-col gap-6 mb-20">
      <h6 className="font-semibold text-[20px]">Changes</h6>
    
      <Table columns={columns} data={data} />
    </div>
  );
}
