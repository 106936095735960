import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "../../../inputs/select";
import AccordianPrimary from "../../../accordian/primary";
import TravelerInfo from "./tabs/traveler-info";
import TripInfo from "./tabs/trip-info";
import Invoice from "./tabs/invoice";
import Honoraria from "./tabs/honoraria";
// import HotelExpenses from "./tabs/hotel-expenses";
// import CarExpenses from "./tabs/car-expenses";
// import MiscellaneousExpense from "./tabs/miscellaneous-expense";
import CashAdvanceDetails from "./tabs/cash-advance";
import TERDetails from "./tabs/ter-details";
// import TextWithLabel from "../../../inputs/text";
import PrimaryButton from "../../../buttons/primary";
import AccordianAction from "../../../accordian/accordian-action";
import { get, post } from "../../../../services";
import { useStore } from "../../../../store";

import { toast, Bounce } from "react-toastify";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default function ProcessTER() {
  const { id } = useParams();
  const { ssoUserID } = useStore();
  const [accordianAction, setAccordianAction] = useState("expandAll");
  const [reviewerList, setReviewerList] = useState([]);
  const [reviewerDetails, setReviewerDetails] = useState({});
  const [selectedReviewer, setSelectReviewer] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [processStatus, setProcessStatus] = useState([]);
  const [selectedProcessStatus, setSelectProcessStatus] = useState(null);

  useEffect(() => {
    get(`ter/terProcessReviewerList?tripId=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setReviewerList(
            res.map((d) => {
              return {
                label: d.rev_name,
                value: d.trip_details_uid,
              };
            })
          );
          getDetails(res[0].trip_details_uid);
          setSelectReviewer(res[0].trip_details_uid);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    get(`ter/travelExpenseReportProcessOption`)
      .then((res) => {
        if (res && res.length > 0) {
          setProcessStatus(
            res.map((d) => {
              return {
                label: d.ProcessOptDesc,
                value: d.ProcessOpt,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getDetails =  (trip_details) => {
    get(`ter/terProcessHeaderDetails?tripDetailsUid=${trip_details}`)
      .then( async (res) => {
        if (res && res.length > 0) {
          console.log(res);
          setReviewerDetails(res[0]);

          const temp1 = res[0];
          var temp2 = []

          await get(`ter/terProcessHotelDetails?tripDetailsUid=${trip_details}`)
            .then((res1) => {
              if (res1 && res1.length > 0) {
                console.log(res1);
                temp2 = res1;

               
              } else {
                setTableData([]);
              }
            })
            .catch((err) => {
              console.error(err);
              setTableData([]);
            });

            const miCashAdvTotal = temp2.reduce(
              (acc, num) => acc + num.meals_amt,
              0
            );
            const hotelCashAdvTotal = temp2.reduce(
              (acc, num) => acc + num.hotel_amt,
              0
            );

            const miCashAdvTotal1 = temp2.reduce(
              (acc, num) => acc + num.adv_meals_amt,
              0
            );
            const hotelCashAdvTotal1 = temp2.reduce(
              (acc, num) =>
                acc +
                parseInt(num.Sleeping_Room_InternetCharge.replace("$", "")),
              0
            );

            const total1 =
              temp1.hon_amt +
              miCashAdvTotal +
              hotelCashAdvTotal +
              temp1.POV_amt +
              temp1.MiscCAAmount +
              temp1.MiscEncumbAmount;
            const total2 =
              miCashAdvTotal1 +
              hotelCashAdvTotal1 +
              temp1.POV_adv_amt +
              temp1.MiscCAAmount;

            const formatData = (val) => {
              return val ? val : "-";
            };

            const tableData1 = [
              {
                id: 1,
                name: "Honoraria",
                encumbrance: (
                  <div>
                    <span className="text-indigo-700 font-bold">
                      $ {formatData(temp1.hon_amt)}{" "}
                    </span>
                  </div>
                ),
                cashAdvance: "",
                details: (
                  <div className="px-4 py-2">
                    <span className="text-indigo-700 font-bold">
                      $ {formatData(temp1.honorariaDays)}{" "}
                    </span>
                    days X $250 =
                  </div>
                ),
              },
              {
                id: 2,
                name: "M & I",
                encumbrance: <></>,
                cashAdvance: <></>,
                details: (
                  <div>
                    <table className="w-full">
                      <tbody>
                        {temp2.map((t2, i) => {
                          return (
                            <tr key={i}>
                              <td className="px-4 py-2 w-4/6">
                                <div>
                                  {" "}
                                  <div>
                                    <span className="text-indigo-700 font-bold">
                                      {formatData(t2.mealsamt1)}{" "}
                                    </span>
                                    per day X{" "}
                                    <span className="text-indigo-700 font-bold">
                                      {formatData(t2.midays)}
                                    </span>{" "}
                                    days =
                                  </div>
                                </div>
                              </td>
                              <td className="w-1/6 px-4 py-2">
                                <div key={i}>
                                  {" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.meals_amt1)}{" "}
                                  </span>
                                  X 0.85
                                </div>
                              </td>
                              <td className="w-1/6 px-4 py-2">
                                <div
                                  className="text-indigo-700 font-bold"
                                  key={i}
                                >
                                  {" "}
                                  {formatData(t2.adv_meals_amt1)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <div className="px-4 py-2">
                            <div>
                              Departure Time{" "}
                              <span className="text-indigo-700 font-bold">
                              {temp1.Depart_Date ? dayjs.utc(temp1.Depart_Time).format("hh:mm A"):'-'}
                              </span>{" "}
                              Date{" "}
                              <span className="text-indigo-700 font-bold">
                                {temp1.Depart_Date ? dayjs.utc(temp1.Depart_Date).format("MM/DD/YYYY"):'-'}
                              </span>
                            </div>
                            <div>
                              Return Time{" "}
                              <span className="text-indigo-700 font-bold">
                              {temp1.Depart_Date ? dayjs.utc(temp1.Depart_Time).format("hh:mm A"):'-'}
                              </span>{" "}
                              Date{" "}
                              <span className="text-indigo-700 font-bold">
                              {temp1.Return_Date ? dayjs.utc(temp1.Return_Date).format("MM/DD/YYYY"):'-'}
                              </span>
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ),
              },
              {
                id: 3,
                name: "Hotel",
                encumbrance: <></>,
                cashAdvance: <></>,
                details: (
                  <div>
                    <div>
                      <table className="w-full">
                        <tbody>
                          {temp2.map((t2, i) => {
                           return  <tr key={i}>
                              <td className="px-4 py-2 w-3/6">
                                <div>
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.hotel1)}{" "}
                                  </span>
                                  per Night X{" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.NRHotelNights)}{" "}
                                  </span>
                                  Nights =
                                </div>
                                <div>
                                  Sleeping Room Internet{" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(
                                      t2.Sleeping_Room_InternetCharge
                                    )}{" "}
                                  </span>
                                  per Night X{" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.NRHotelNights)}{" "}
                                  </span>
                                  Nights =
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.slpingIntChrgAmnt[0])}
                                  </span>
                                </div>
                                <div>
                                  Allowable per Diem{" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.lodging1)}{" "}
                                  </span>
                                </div>
                              </td>
                              <td className="px-4 py-2 w-1/6 ">
                              <span className="text-indigo-700 font-bold">{t2.hotel_nights}</span></td>
                              <td className="px-4 py-2 w-1/6">
                                <div>
                                  {" "}
                                  <span className="text-indigo-700 font-bold">
                                    {formatData(t2.hotel_amt1)}{" "}
                                  </span>
                                </div>
                              </td>
                              <td className="px-4 py-2 w-1/6 ">
                                <div
                                  className="text-indigo-700 font-bold"
                                  key={i}
                                >
                                  {" "}
                                  {formatData(
                                     t2.add_hotel ? t2.hotel_amt1 : t2.Sleeping_Room_InternetCharge
                                  )}
                                </div>
                                <div className="mt-auto  w-full">
                                  <span>Add hotel</span>  <input checked={t2.add_hotel} type="checkbox" disabled={true}/>
                                </div>
                              </td>
                            </tr>;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ),
              },
              {
                id: 4,
                name: "POV Miles",
                encumbrance: (
                  <div>
                    <span className="text-indigo-700 font-bold">
                      {formatData(temp1.POV_amt1)}{" "}
                    </span>
                    X $0.60
                  </div>
                ),
                cashAdvance: (
                  <div className="flex flex-col text-indigo-700 font-bold">
                    {temp1.POV_Miles > 200 ? formatData(temp1.POV_adv_amt1) : formatData("$0.00")}
                  </div>
                ),
                details: (
                  <div className="px-4 py-2">
                    <div>
                      <span className="text-indigo-700 font-bold">
                        { temp1.POV_Miles? formatData(temp1.POV_Miles) : '0'}{" "}
                      </span>{" "}
                      X $0.345 per mile
                    </div>
                  </div>
                ),
              },
              {
                id: 5,
                name: "Misc.CA",
                encumbrance: (
                  <div>
                    <span className="text-indigo-700 font-bold">
                      {formatData(temp1.MiscCAAmount1)}{" "}
                    </span>
                  </div>
                ),
                cashAdvance: (
                  <div className="flex flex-col text-indigo-700 font-bold">
                    {formatData(temp1.MiscCAAmount1)}
                  </div>
                ),
                details: (
                  <div className="px-4 py-2">
                    <div>
                      Description:{" "}
                      <span className="text-indigo-700 font-bold">
                        {formatData(temp1.MiscCADescript)}{" "}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                id: 6,
                name: "Misc.Encumberance",
                encumbrance: (
                  <div className="py-2">
                    <span className="text-indigo-700 font-bold">
                      {formatData(temp1.MiscEncumbAmount1)}{" "}
                    </span>
                  </div>
                ),
                cashAdvance: "",
                details: (
                  <div className="px-4 py-2">
                    <div>
                      Description:{" "}
                      <span className="text-indigo-700 font-bold">
                        {formatData(temp1.MiscEncumbDescript)}{" "}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                id: 7,
                name: <div>Total</div>,
                encumbrance: (
                  <span className="text-indigo-700 font-bold">
                    {"$" + total1}
                  </span>
                ),
                cashAdvance: (
                  <div>
                    Requested Amount: <br></br>
                    <span className="text-indigo-700 font-bold">
                      $ {total2 < 75 ? "$0.00" : total2}
                    </span>
                  </div>
                ),
                // details: "Michael's encumbrance relates to data analytics tools.",
              },
            ];
            setTableData(tableData1);
        } else {
          setReviewerDetails({});
        }
      })
      .catch((err) => {
        console.error(err);
        setReviewerDetails({});
      });
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target && e.target.value) {
      setSelectReviewer(e.target.value);
      getDetails(e.target.value);
    }
  };
  const processUpdate = () => {
    post(`ter/terProcessUpdateStatus`, {
      userId: ssoUserID,
      tripDetailsUid: selectedReviewer,
      option: selectedProcessStatus ? selectedProcessStatus : "TER",
    })
      .then((res) => {
        if (res) {
          console.log(res);

          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Update Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const accordians = [
    {
      label: "Traveler Info",
      content: (
        <div>
          <TravelerInfo
            details={reviewerDetails}
            selectedReviewer={selectedReviewer}
          />
        </div>
      ),
    },
    {
      label: "Trip Info",
      content: (
        <div>
          <TripInfo details={reviewerDetails} />
        </div>
      ),
    },
    {
      label: "Invoice",
      content: (
        <div>
          <Invoice details={reviewerDetails} />
        </div>
      ),
    },
    {
      label: "Expenses",
      content: (
        <div>
          <Honoraria data={tableData} />
        </div>
      ),
    },
    // {
    //   label: "Hotel Expenses",
    //   content: (
    //     <div>
    //       <HotelExpenses />
    //     </div>
    //   ),
    // },
    // {
    //   label: "Car Expenses",
    //   content: (
    //     <div>
    //       <CarExpenses id={selectedReviewer}/>
    //     </div>
    //   ),
    // },
    // {
    //   label: "Miscellaneous Expense",
    //   content: (
    //     <div>
    //       <MiscellaneousExpense />
    //     </div>
    //   ),
    // },
    {
      label: "Cash Advance Details",
      content: (
        <div>
          <CashAdvanceDetails id={selectedReviewer} />
        </div>
      ),
    },
    {
      label: "TER Details",
      content: (
        <div>
          <TERDetails id={selectedReviewer} />
        </div>
      ),
    },
  ];
  return (
    <div className="py-5 text-left">
      <div className="mb-2 flex">
        <h5 className="font-semibold text-xl">Process TER</h5>
        <div className="ml-auto">
          <AccordianAction
            accordianAction={accordianAction}
            handleAccordian={(d) => setAccordianAction(d)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-[548px]">
          <Select
            label="Reviewer"
            name=""
            id=""
            options={reviewerList}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="border rounded bg-white border-[#E5E7EB] p-2">
          <AccordianPrimary
            accordians={accordians}
            accordianAction={accordianAction}
          />
          {/* <div className="flex flex-col gap-2 p-6">
            <div className="flex w-full">
              <div>
                <h6 className="font-semibold text-[14px]">Totals</h6>
              </div>
              <div className="ml-auto flex gap-4">
                <div className="w-[200px]">
                  <TextWithLabel label="ENCUMBRANCE" value="$2,337.90" />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="CASH ADVANCE" value="$0.00" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex gap-10">
          <div className="w-[400px]">
            <Select
              label="Process Status"
              onChange={(e) => setSelectProcessStatus(e.target.value)}
              selected={selectedProcessStatus}
              options={processStatus}
            />
          </div>
          <div className="mt-auto">
            <PrimaryButton
              label="Update"
              onClick={() => processUpdate()}
              className={"px-4 py-3"}
            />
          </div>
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
      </div>
    </div>
  );
}
