import React from "react";
import TextWithLabel from "../../../inputs/text";
import HistoryTable from "./history-table";
import CurrentSchedules from "./current-schedules";

export default function ReviewerHistory({id, reviewer}) {
  

  console.log(reviewer, 'historyData')
  var name = ''
  if(reviewer.Title){
    name += reviewer.Title
  }
  if(reviewer.FName){
    name += ' '+reviewer.FName
  }
  if(reviewer.LName){
    name += ' '+reviewer.LName
  }
  return (
    <div className="flex flex-col gap-3 text-left">
      <h6 className="font-[500] text-[18px] mt-3">Reviewer History</h6>
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="Danya ID" value={reviewer?.SSN} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Name" value={name} />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h6 className="font-[500] text-[16px]">Current Schedules</h6>
        <CurrentSchedules id={id}/>
      </div>
      <div className="flex flex-col gap-3 mb-10">
        <h6 className="font-[500] text-[16px]">History</h6>
        <HistoryTable id={id}/>
      </div>
    </div>
  );
}
