import React, {useEffect, useState} from "react";
import { get } from "../../../../../services";
// import DataTable from "react-data-table-component";

export default function Honoraria() {
  const[tableData, setData] = useState([])
  useEffect(() => {
   get('test/api').then(res => {
      if(res && res.length > 0){
        setData([])
      }
   }).catch(err => {
    console.error(err)
   })
  }, [])
  console.log(tableData)

  // const columns = [
  //   {
  //     name: <div className="uppercase text-md text-gray-600">ITEM</div>,
  //     selector: (row) => row.item,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">RATE</div>,
  //     selector: (row) => row.rate,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">DAYS/FACTOR</div>,
  //     selector: (row) => row.days,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">ENCUMBRANCE</div>,
  //     selector: (row) => row.encumbrance,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">CASH ADVANCE</div>,
  //     selector: (row) => row.cashAdvance,
  //     sortable: true,
  //   },
  // ];
  // const data = [
  //   {
  //     item: "Honoraria",
  //     rate: "$375.00",
  //     days: "4",
  //     encumbrance: "$1,500",
  //     cashAdvance: "$95.20",
  //   },
  //   {
  //     item: "M&I",
  //     rate: "$64",
  //     days: "1.75",
  //     encumbrance: "$112.00 X 0.85",
  //     cashAdvance: "",
  //   },
  // ];
  return (
    <div className="p-7 flex flex-col gap-10">
      <div>
        <CollapsibleTable />
        {/* <DataTable columns={columns} data={data} pagination /> */}
      </div>
    </div>
  );
}




const ExpandedDetails = ({ details }) => {
  return (
    <div className="p-4 bg-gray-50 rounded-md shadow">
      <p className="text-gray-700">{details}</p>
    </div>
  );
};

const CollapsibleTable = () => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const tableData = [
    {
      id: 1,
      name: "Honoraria",
      encumbrance: "$10,000",
      cashAdvance: "$2,000",
      details: <div><span className="text-indigo-700 font-bold">5.5 </span>days X $250 =</div>,
    },
    {
      id: 2,
      name: "M & I",
      encumbrance: "$15,000",
      cashAdvance: "$3,500",
      details: <div>
        <div><span className="text-indigo-700 font-bold">$0.00 </span>per day X 0 days =</div>
        <div>Departure Time <span  className="text-indigo-700 font-bold">1:00:00 PM</span> Date <span  className="text-indigo-700 font-bold">12/3/2024</span></div>
        <div>Return Time <span  className="text-indigo-700 font-bold">1:00:00 PM</span> Date <span  className="text-indigo-700 font-bold">12/3/2024</span></div>

      </div>
    },
    {
      id: 3,
      name: "Hotel",
      encumbrance: "$8,000",
      cashAdvance: "$1,500",
      details: <div>
      <div><span className="text-indigo-700 font-bold">$0.00 </span>per day X 0 days =</div>
      <div>Allowable per Diem <span  className="text-indigo-700 font-bold">$0.00 </span></div>

    </div>,
    },
    {
      id: 4,
      name: "POV Miles",
      encumbrance: "$8,000",
      cashAdvance: "$1,500",
      details:<div>
      <div><span className="text-indigo-700 font-bold">250 </span> X $0.345 per mile =</div>
      
    </div>,
    },
    {
      id: 5,
      name: "Misc.CA",
      encumbrance: "$8,000",
      cashAdvance: "$1,500",
      details: <div>
      <div>Description: <span className="text-indigo-700 font-bold">Tolls and Hotel Parking </span></div>
      
    </div>
    },
    {
      id: 6,
      name: "Misc.Encumberance",
      encumbrance: "$8,000",
      cashAdvance: "$1,500",
      details: <div>
      <div>Description: <span className="text-indigo-700 font-bold">Possibly more costs for Tolls and Hotel Parking </span></div>
      
    </div>
    },
    {
      id: 7,
      name: <div>Total</div>,
      encumbrance: "$9090",
      cashAdvance: <div>Requested Amount: <span>$7799.00</span></div>,
      // details: "Michael's encumbrance relates to data analytics tools.",
    },
  ];

  return (
    <div className="w-full mx-autos">
      <table className="min-w-full border-collapse border border-gray-300 text-sm">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 w-4/6"></th> {/*
Empty Header */}
            <th className="border border-gray-300 px-4 py-2
text-left">Encumbrance</th>
            <th className="border border-gray-300 px-4 py-2
text-left">Cash Advance</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <React.Fragment key={row.id}>
              <tr className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2 flex
items-center space-x-2">
                  <button
                    className="text-gray-500 hover:text-gray-800
transform transition-transform"
                    onClick={() => toggleRow(row.id)}
                  >
                    {expandedRows[row.id] ? (
                      <span>−</span> // Collapse icon
                    ) : (
                      <span>+</span> // Expand icon
                    )}
                  </button>
                  <span>{row.name}</span>
                </td>
                <td className="border border-gray-300 px-4
py-2">{row.encumbrance}</td>
                <td className="border border-gray-300 px-4
py-2">{row.cashAdvance}</td>
              </tr>

              {expandedRows[row.id] && (
                <tr className="bg-gray-50">
                  <td colSpan={3} className="border border-gray-300 px-4 py-2">
                    <ExpandedDetails details={row.details} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
