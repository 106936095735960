import React, {useState} from "react";
import PrimaryButton from "../../../buttons/primary";
import Input from "../../../inputs/input";
import { useStore } from "../../../../store";

import { toast, Bounce } from "react-toastify";
import { post } from "../../../../services";

export default function UpdateHotel({hotelData}) {

  const { ssoUserID } = useStore();

  const [reason, setReason] = useState(null)
  const [confirmationText, setConfirmationText] = useState(null)


  const deleteAll = () => {
    if(reason){
      if(confirmationText && confirmationText.toLowerCase() == 'yes'){
          post(`reviews/batch_update_delete_all_hotels`, {
            "trip_id": hotelData.trip_id,
            "reason": reason,
            "user_id": ssoUserID
        }).then(res => {
            if(res){
              toast.success("Deleted Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            }
          }).catch(err => {
            console.error(err)
            toast.error("Delete Failed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          })
      }else{
        toast.error("Kindly Check before deleting", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }else{
      toast.error("Enter Reason", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }

  return (
    <div className="flex flex-col gap-3">
    
      <div>
        <h6 className="mt-5 font-semibold">Delete All Hotels</h6>
      </div>
      <div className="w-full">
        <Input type="textArea" onChange={(e) => setReason(e.target.value)} value={reason} label="" />
      </div>
      <div className="flex flex-col gap-3">
        <p>
          Check and Click Delete All Hotels{" "}
        </p>
        <Input label="" type="checkbox" value={confirmationText} onChange={(e) => setConfirmationText(e.target.checked ? "yes" : "No")} className="w-[70px]" />
      </div>
      <div>
        
        <PrimaryButton
          label={"Delete All Hotels"}
          type="button"
          onClick = {deleteAll}
          className={"px-3 py-2 mt-5"}
        />
      </div>
    </div>
  );
}
