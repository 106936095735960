import React, {useEffect, useState} from "react";
import { get } from "../../../../../services";
import TextWithLabel from "../../../../inputs/text";

export default function CashAdvanceDetails({id}) {
  const[tableData, setData] = useState({})
  useEffect(() => {
   get(`ter/terProcessCashAdvances?tripDetailsUid=${id}`).then(res => {
      if(res && res.length > 0){
        setData(res[0])
      }else{
        setData([])
      }
   }).catch(err => {
    console.error(err)
    setData([])
   })
  }, [id])
  console.log(tableData)
  
  return (
    <div className="p-7 flex flex-col gap-3 w-full">
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="Check #" value={tableData?.check_number} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Check Date" type="date" value={tableData?.check_date} />
        </div>
        <div className="w-[200px] ml-auto">
          <TextWithLabel className="text-red-700 font-bold"
            label="Check Amount"
            value={`$ ${tableData.check_amount ? tableData?.check_amount : '0'}`}
          />
        </div>
      </div>
    </div>
  );
}
