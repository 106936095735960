import React, { useState, useEffect } from "react";
import Table from "../../../../table/table";

import { get } from "../../../../../services/index";

const CarRentals = ({ uid }) => {

    const [ data, setData] = useState([]);

    useEffect(() => {
        if(uid){
            get(`reviews/tis_carrental_upload?trip_details_uid=${uid}`).then(res => {
                if(res && res.length >0){
                    setData(res)
                }
            }).catch(err => {
                console.error(err)
            })
        }
    
    }, [uid])
    
 
  const columns = [
    {
      headerName: "Booked Date",
      field: "booked_date",
      type:"date",
      time:"off"
    },
    {
      headerName: "Confirmation #",
      field: "confirmation_number",
    },
    {
      headerName: "Agency",
      field: "vendor_name",
    },
    {
      headerName: "Pickup Details",
      field: "pickUpDate",
      type: "date",
      time: "off",
    },
    {
        headerName: "Total Days",
        field: "total_days",
    },
    {
        headerName: "Estimated Amount",
        field: "estimatedCost",
    },
    {
        headerName: "Message/Warning",
        field: "message_warning",
    },
    {
        headerName: "Uploaded On",
        field: "createts",
        type:"date"
    },
  ];


 
  return <div className="flex flex-col gap-6 text-left">
  <h6 className="font-semibold">Car Rentals</h6>
  <Table columns={columns} data={data}  />
</div>;
};



export default CarRentals;
