import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Input from "../../inputs/input";
import TertiaryButton from "../../buttons/tertiary";
import Select from "../../inputs/select";
import InputDate from "../../inputs/date";
import InputTime from "../../inputs/time";
import { getAPIWithPositive as get, put } from "../../../services";
import TextWithLabel from "../../inputs/text";
import { toast, Bounce } from "react-toastify";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

function ReviewInfoEdit({ reviewInfoData, errorFields }) {
  const { id } = useParams();

  const [subTypes, setSubTypes] = useState([])
  const [rrcs, setRRCS] = useState([])
  const [rtls, setRTLs] = useState([])
  const [specialist, setSpecialist] = useState([])
  const [loading, setLoading] = useState(true)
  const [nfrtlError, setNfrtlError] = useState("");
  const [nfrtlId, setNfrtlId] = useState(reviewInfoData?.nfrtl_id || '');

  const modifyDate =(dateRes) => {
    if(dateRes && dateRes !== "Invalid Date"){
      return dateRes
    }else{
      return ""
    }
  }

  useEffect(() => {
    Promise.all([
      get(`lookup/subtype_list?review_id=${id}`),
      get(`lookup/subtype_list?review_id=${id}`), //add program sp here
      get(`lookup/rrc_list?review_id=${id}`),
      get(`lookup/ftl_list?review_id=${id}`),
    ])
      .then(([subTypeRes, specialistRes, rrcRes, rtlRes]) => {

        if (subTypeRes instanceof Array && subTypeRes.length > 0) {
          const tempData = getFormattedData('sub_type', 'sub_type', subTypeRes)
          setSubTypes(tempData)
        }
        if (specialistRes instanceof Array && specialistRes.length > 0) {
          const tempData = getFormattedData('sub_type', 'sub_type', specialistRes)
          setSpecialist(tempData)
        }
        if (rrcRes instanceof Array && rrcRes.length > 0) {
          const tempData = getFormattedData(['Title', 'FirstName', 'LastName'], 'rrc_uid', rrcRes)
          setRRCS(tempData)
        }
        if (rtlRes instanceof Array && rtlRes.length > 0) {
          const tempData = getFormattedData(['Title', 'FName', 'LName'], 'FTL_UID', rtlRes)
          setRTLs(tempData)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
      });
  }, []);

  const getFormattedData = (label, value, dataset) => {

    if (dataset && dataset.length > 0) {
      const tempData = dataset.map(d => {
        var cData = {}
        cData["value"] = d[value]
        if (label instanceof Array) {
          var tempLabel = ""
          label.forEach(l => {
            if (d[l]) {
              tempLabel += d[l] + " "
            }
          })
          cData["label"] = tempLabel
        } else {
          cData["label"] = d[label]
        }
        return cData

      })
      return tempData
    } else {
      return []
    }

  }


  const updateNFRTL = () => {
    if (!reviewInfoData.nfrtl_id != "") {
      setNfrtlError("Error - Please Enter NFRT/RTL ID to Process Update");
      return;
    }
    setNfrtlError("");
    if (reviewInfoData && reviewInfoData.nfrtl_id) {
      put(`reviews/update_ftl_uid`, {
        "FTL_UID": nfrtlId,
        "review_id": id
      }).then(() => {
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }).then(() => {
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        setNfrtlId('')
      }).catch(err => {
        toast.error(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })
    }
  }
  //   const getDateTime =(time) => {

  //     const date = '2023-04-04'
  // //const time = '13:02:00.963+00:00'

  // const datetime = new Date(`${date}T${time}`).toISOString()

  // console.log(datetime, 'datetime')

  //     return datetime
  //   }

  console.log(specialist, "reviewInfoDatareviewInfoData", reviewInfoData);
  return (
    <div>
      {
        loading ? <div>Please wait while we fetch the data</div> :
          <div className="flex flex-col gap-4 justify-between py-4">
            <div className="flex flex-col gap-4 justify-between py-4">
              <div className="flex flex-row gap-8 px-20 text-left">
                <div className="w-60">
                  <TextWithLabel
                    label="Grantee Name"
                    name="Name"
                    id="Name"
                    value={reviewInfoData?.Name}
                  />
                </div>
                <div className="w-60 text-sm">
                  <div>Review Dates</div>
          <div className='flex gap-1'>
                  <div>{modifyDate(dayjs.utc(reviewInfoData?.MeetingDate).format("MM/DD/YYYY"))}</div>
                  <div>-</div>
                  <div>{modifyDate(dayjs.utc(reviewInfoData?.ExitDate).format("MM/DD/YYYY"))}</div>
                </div>
                </div>
                <div className="w-60 text-sm">
                  <div>Review/Training</div>
                  <button type="button" className="bg-indigo-600/20  text-indigo-600 py-2 px-3 text-[16px] rounded-full"><div>{reviewInfoData?.review_type}</div></button>
                  
                </div>
                <div className="w-60">
                  <Select
                    label="Review/Training Type"
                    name="reviewSubType"
                    id="reviewSubType"
                    selected={reviewInfoData?.sub_type}
                    options={subTypes}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 px-20 text-left">
                <div className="flex gap-3">
                  <div className="w-60">
                    <Select label="RP" name="rrc_uid" id="rrc_uid" selected={reviewInfoData?.rrc_uid} options={rrcs} />
                  </div>
                </div>
                <div className="flex gap-3">
                  <div className="w-60">
                    <Select label="RTL" name="FTL_UID" id="FTL_UID" selected={reviewInfoData?.FTL_UID} options={rtls} required isErrorOccurred={errorFields.FTL_UID ?? ""} />
                  </div>
                  <div className="w-60">
                    <Input label="RTL/NFRTL ID" name="nfrtl_id" id="nfrtl_id" value={reviewInfoData?.nfrtl_id} required isErrorOccurred={nfrtlError} />
                  </div>
                  <TertiaryButton
                    label={"Update"}
                    className={"h-[46px] w-[110px] mt-auto"}
                    onClick={updateNFRTL}
                    type="button"
                  />
                </div>

                <div className="w-60">
                  <Select
                    label="Grantee Program Specialist"
                    name="PS_FName"
                    id="PS_FName"
                    selected={reviewInfoData?.PS_FName}
                    options={rtls}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-8 px-20 text-left">
                <div className="w-60">
                  <InputDate label="Arrival Date" name="arrival_date" id="arrival_date"
                    defaultValue={dayjs.utc(reviewInfoData?.arrival_date).format("YYYY-MM-DD")} required isErrorOccurred={errorFields.arrival_date ?? ''} />
                </div>
                <div className="w-60">
                  <InputTime label="Arrival Time" name="arrival_time" id="arrival_time"
                    value={dayjs.utc(reviewInfoData?.arrival_time).format("HH:mm")}
                    required
                    isErrorOccurred={errorFields.arrival_time ?? ''}
                  />
                </div>
                <div className="w-60">
                  <Input label="Arrival Place" name="arrival_city" id="arrival_city"
                    defaultValue={reviewInfoData?.arrival_city}
                    required
                    isErrorOccurred={errorFields.arrival_city ? errorFields.arrival_city : ""}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-8 px-20 text-left">
                <div className="w-60">
                  <InputDate label="Departure Date" name="departure_date" id="departure_date"
                    defaultValue={dayjs.utc(reviewInfoData?.departure_date).format("YYYY-MM-DD")}
                    required
                    isErrorOccurred={errorFields.departure_date ?? ""}
                  />
                </div>
                <div className="w-60">
                  <InputTime label="Departure Time" name="departure_time" id="departure_time" value={reviewInfoData?.departure_time ? dayjs.utc(reviewInfoData?.departure_time).format("HH:mm") : new Date()} required isErrorOccurred={errorFields.departure_time ? errorFields.departure_time : ""} />
                </div>
                <div className="w-60">
                  <Input label="Departure Place" name="departure_city" id="departure_city" value={reviewInfoData?.departure_city} required isErrorOccurred={errorFields.departure_city ? errorFields.departure_city : ""} />
                </div>
              </div>
              <div className="flex flex-row gap-8 px-20 text-left">
                <div className="w-60">
                  <InputDate
                    label="Meeting Date"
                    name="MeetingDate"
                    id="MeetingDate"
                    defaultValue={dayjs.utc(reviewInfoData?.MeetingDate).format("YYYY-MM-DD")}
                    required
                    isErrorOccurred={errorFields.MeetingDate ? errorFields.MeetingDate : ''}
                  />
                </div>
                <div className="w-60">
                  <InputTime
                    label="Meeting Time"
                    name="Meetingtime"
                    id="Meetingtime"
                    value={dayjs.utc(reviewInfoData?.Meetingtime).format("HH:mm")}
                    required
                    isErrorOccurred={errorFields.Meetingtime ? errorFields.Meetingtime : ''}
                  />
                </div>
                <div className="w-60">
                  <Input
                    label="Meeting Place"
                    name="MeetingPlace"
                    id="MeetingPlace"
                    value={reviewInfoData?.MeetingPlace}
                    required
                    isErrorOccurred={errorFields.MeetingPlace ? errorFields.MeetingPlace : ''}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-8 px-20 text-left">
                <div className="w-60">
                  <InputDate
                    label="Exit Date"
                    name="ExitDate"
                    id="ExitDate"
                    defaultValue={dayjs.utc(reviewInfoData?.ExitDate).format("YYYY-MM-DD")}
                    required
                    isErrorOccurred={errorFields.ExitDate ? errorFields.ExitDate : ''}
                  />
                </div>
                <div className="w-60">
                  <InputTime
                    label="Exit Time"
                    name="ExitTime"
                    id="ExitTime"
                    value={dayjs.utc(reviewInfoData?.ExitTime).format("HH:mm")}
                    required
                    isErrorOccurred={errorFields.ExitTime ? errorFields.ExitTime : ''}
                  />
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default ReviewInfoEdit;
