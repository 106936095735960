import React, {useState, useEffect} from "react";
import Select from "../../../inputs/select";
import { DynamicTable } from "../../../table/tableWithTotal";
import PrimaryButton from "../../../buttons/primary";
import { get, post } from "../../../../services";
import Loader from '../../../loader'
import MultiText from "../../../inputs/multi-text";
import Input from "../../../inputs/input";
import { useStore } from "../../../../store";

import { toast, Bounce } from "react-toastify";
export default function UpdateHotel({id}) {

  const { ssoUserID } = useStore();

  const [reviewers, setReviwers] = useState([]);

  const [selectedReviwer, setReviewer] = useState(null)
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [reason, setReason] = useState(null)

  useEffect(() => {
      if(id){
        get(`reviews/batch_update_hotel_list?trip_id=${id}`).then(res => {
            if(res && res.length >0){
              setReviwers(res.map(d => {
                var temp = {}
                temp['label'] = d.hotel_name;
                temp['value'] = d.trip_hotel_uid

                return temp

              }))

              getData(res[0].trip_hotel_uid)
            }
        }).catch(err => {
          console.error(err)
        })
      }
  },[])


  const columns = [
    {
      field: "firstnight",
      headerName: "First Night",
      edit: true,
      type:"date",
      searchable: true
    },
    {
      field: "lastnight",
      headerName: "Last Night",
      edit: true,
      type:"date",
      searchable: true
    },
    {
      field: "noofnights",
      headerName: "# of Days",
      edit: true,
      searchable: true
    },
    {
      field: "MIDays",
      headerName: "MI Days",
      edit: true,
      searchable: true
    },
    {
      field: "Booked_rate",
      headerName: "Booked Rate($)",
      edit: true,
      searchable: true
    },
    {
      field: "Lodging",
      headerName: "Lodging ($)",
      edit: true,
      searchable: true
    },
    {
      field: "taxes",
      headerName: "Taxes ($)",
      edit: true,
      searchable: true
    },
    {
      field: "Meals",
      headerName: "MI ($)",
      edit: true,
      searchable: true
    },
    {
      field: "confirmNumber",
      headerName: "Confirmation Number",
      edit: true,
      searchable: true
    },
    {
      field: "isUpdate",
      headerName: "Update",
      edit: true,
      searchable: true
    },
  ];
  const handleReviwer = (e) => {
    if(e && e.target.value){
      getData(e.target.value)
    }
  }

  const getData = (uid) => {
      if(uid){
        setReviewer(uid)
        setLoading(true)
        setData([])
        get(`reviews/batch_update_hotel_details?trip_hotel_uid=${uid}`).then(res => {
          if(res && res.length >0){
            setData(res)
          }else{
            setData([])
          }
          setLoading(false)
        }).catch(err => {
          console.error(err)
          setLoading(false)
          setData([])
        })

        setTableData([])
        get(`reviews/getBatchUpdateTripHotelView?tripHotelUid=${uid}`).then(res => {
          if(res && res.length >0){
            setTableData(res)
          }else{
            setTableData([])
          }
          setLoading(false)
        }).catch(err => {
          console.error(err)
          setLoading(false)
          setTableData([])
        })
      }
  }

  const updateColumnData = (value, rowIndex, field) => {
    console.log(value, rowIndex, field);
    setTableData(tableData.map((d,i) => {
      if(rowIndex == i){
        d[field] = value

        return d
      }else{
        return d
      }
    }))
  };

  const submit = () => {
    if(reason){
      
      if(tableData && tableData.length >0){
        const temp = tableData[0];
        if(temp && temp.isUpdate && temp.isUpdate.toLowerCase() == 'yes'){
            const reqData = {
              "tripHotelUid": selectedReviwer,
              "firstNight": temp.firstnight,
              "lastNight": temp.lastnight,
              "confirmNumber": temp.confirmNumber,
              "bookedRate": temp.Booked_rate,
              "taxes": temp.taxes,
              "noOfNights": temp.noofnights,
              "reason": reason,
              'lodging':temp.Lodging,
              "meals": temp.Meals,
              "miDays": temp.MIDays,
              "userId": ssoUserID
          }

          post(`reviews/updateBatchUpdateTripHotelUpdate`, reqData)
          .then((res) => {
            if (res) {
              console.log(res);
              post('reviews/batch_update_hotel_review', {...reqData,"trip_hotel_uid": selectedReviwer}).then((res) => {
                if (res) {
                  toast.success('Updated', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                  });
                }
              }).catch((err) => {
                console.error(err);
                toast.error('Batch Update Failed', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  transition: Bounce,
                });
              })
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error('Update Failed', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          });



        }else{
          toast.error("Enter Update Column YES", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      }
    }else{
      toast.error("Enter Reason", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }


  return (
    <div className="flex flex-col gap-3">
      {
        loading && <Loader />
      }
      <div className="w-6/12">
        <Select className="max-w-full" label="Select A Reviewer" options={reviewers} selected={selectedReviwer} onChange={(e) => handleReviwer(e)} />
      </div>
      <div className="flex flex-col gap-">
      {data && data.length >0  &&  <MultiText label="" seperator=" " value={['Name']} data={data[0]}/>}
      {data && data.length >0  &&  <MultiText label="" seperator=" " value={['Address']} data={data[0]}/>}
        {data && data.length >0  &&  <MultiText label="" seperator=" " value={[ 'City', 'State', 'County','Zip']} data={data[0]}/>}
      </div>
      <div>
        <DynamicTable data={tableData} columns={columns} updateColumnData={updateColumnData}/>
      </div>
      <Input type="textArea" value={reason} name="reason" onChange={(e) => setReason(e.target.value)} />
      <div>
        <PrimaryButton onClick={submit} label={"Update Now"} className={"px-3 py-2 mt-5"} />
      </div>
     
    </div>
  );
}
