// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-layout-auto-height{
    min-height: 0 !important;
}

.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-container{
    min-height: 42px !important;
}

.ag-header-row-column{
    background-color: #4f46e5 !important;
    color:white !important;
}

.ag-header-cell{
    height: 40px !important;
}
.ag-header-row{
    height: 40px !important;
}

.center-align-column .ag-header-cell-label{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/table/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".ag-layout-auto-height{\n    min-height: 0 !important;\n}\n\n.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-container{\n    min-height: 42px !important;\n}\n\n.ag-header-row-column{\n    background-color: #4f46e5 !important;\n    color:white !important;\n}\n\n.ag-header-cell{\n    height: 40px !important;\n}\n.ag-header-row{\n    height: 40px !important;\n}\n\n.center-align-column .ag-header-cell-label{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
