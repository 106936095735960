import React from "react";
import { useLocation } from "react-router-dom";
import PrimaryTab from "../../components/tabs/primary";
import Hotels from "../../components/travel/hotels";
import CarRentals from "../../components/travel/car-rentals";
import TertiaryButton from "../../components/buttons/tertiary";
import TitlePrimary from '../../components/typhography/title-primary'


export default function Travel() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get("tab");

  const tabs = [
    {
      label: "Hotels",
      tab: <Hotels />,
    },
    {
      label: "Car Rentals",
      tab: <CarRentals />,
    },
  ];
  return (
    <div className="flex flex-col gap-10 my-10">
      <div className="flex">
      <TitlePrimary title={"Travel"} />
        <div className="ml-auto">
            <TertiaryButton label={"Upload Date"} className={"px-3 py-2"} />
        </div>
      </div>
      <PrimaryTab tabs={tabs} selected={defaultTab} />
    </div>
  );
}
