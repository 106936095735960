import React, { useState, useEffect } from "react";
import TertiaryButton from "../../components/buttons/tertiary";
import Select from "../../components/inputs/select";
import Input from "../../components/inputs/input";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import PrimaryButton from "../../components/buttons/primary";
import SearchResults from "../../components/reviewers/search-results";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import { useNavigate } from "react-router-dom";

const searchOptions = [
  {
    label: "Reviewer name",
    value: "NAME",
  },
  {
    label: "Vendor ID",
    value: "VendorID",
  },
  {
    label: "Review ID",
    value: "REVIEWID",
  },
  {
    label: "Grantee ID",
    value: "GranteeID",
  },
  {
    label: "Service area",
    value: "ServiceArea",
  },
  {
    label: "Email address",
    value: "Email",
  },
  {
    label: "NFRTL",
    value: "NFRTL",
  },
];

export default function Reviewers() {
  const [selectedOption, setOption] = useState("NAME");
  const [states, setStates] = useState([]);
  const [data, setData] = useState({});
  //const [stateCode, setStateCode] = useState("ALL");
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    get("reviewers/stateSearch")
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "ress");
          const temp = res
            .map((r) => {
              return {
                label: r?.Name,
                value: r?.Code,
              };
            })
            .filter((d) => d?.Name !== "ALL");
          console.log(temp, "temp");
          setStates(temp);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOptions = (e) => {
    setOption(e.target.value);
    setData({})
  };

  const handleChange = (e) => {
    if (e.target.name && e.target.value) {
      if (e.target.type === "checkbox") {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.checked,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      }
    } else if (e.target.name) {
      setData((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var tempData = {};
    if (selectedOption) {
      tempData["search_by"] = selectedOption;
      if (selectedOption == "NAME") {
        if (data.firstName) {
          tempData["firstName"] = data.firstName ? data.firstName : "";
        }

        if (data.lastName) {
          tempData["lastName"] = data.lastName ? data.lastName : "";
        }
        if (data.stateCode) {
          tempData["stateCode"] = data.stateCode;
        }

        if (data?.excludeNonReviewers) {
          tempData["excludeNonReviewers"] = data.excludeNonReviewers;
        }
        if (data?.excludeInactive) {
          tempData["excludeInactive"] = data.excludeInactive;
        }
        if (data?.excludeAdminHold) {
          tempData["excludeAdminHold"] = data.excludeAdminHold;
        }
      } else if (selectedOption == "VendorID" && data.vendorId) {
        tempData["vendorId"] = data?.vendorId;
      } else if (selectedOption == "REVIEWID" && data.reviewer_id) {
        tempData["reviewer_id"] = data?.reviewer_id;
      } else if (selectedOption == "GranteeID" && data.granteeId) {
        tempData["granteeId"] = data?.granteeId;
      } else if (selectedOption == "Email" && data.emailId) {
        tempData["emailId"] = data?.emailId;
      } else if(selectedOption == 'ServiceArea'){
        if (data?.hea) {
          tempData["hea"] = data.hea ? 1 : 0;
        }
        if (data?.ecd) {
          tempData["ecd"] = data.ecd ? 1 : 0;
        }
        if (data.stateCode) {
          tempData["stateCode"] = data.stateCode;
        }
        if (data?.excludeNonReviewers) {
          tempData["excludeNonReviewers"] = data.excludeNonReviewers;
        }
        if (data?.excludeInactive) {
          tempData["excludeInactive"] = data.excludeInactive;
        }
        if (data?.excludeAdminHold) {
          tempData["excludeAdminHold"] = data.excludeAdminHold;
        }
      }

      var url = "reviewers/search";
      Object.keys(tempData).forEach((key, i) => {
        if (i === 0) {
          url += `?${key}=${tempData[key]}`;
        } else {
          url += `&${key}=${tempData[key]}`;
        }
      });

      console.log(url, "params");

      get(url)
        .then((res) => {
          console.log(res, "raksjvahsas");
          if (res && res.length > 0) {
            setSearchData(res);
          }else{
            setSearchData([])
          }
        })
        .catch((err) => {
          setSearchData([])
          console.error(err);
        });
    }
  };

  // const handleChangeStateCode = (e) => {
  //   if (e.target && e.target.value && e.target.value != "  ") {
  //     setStateCode(e.target.value);
  //   } else {
  //     setStateCode("ALL");
  //   }
  // };

  console.log(data, 'datasasa')

  return (
    <div className="flex flex-col gap-8 my-10">
      <div className="flex items-center">
        <TitlePrimary title={"Reviewers"} />
        <div className="ml-auto">
          <TertiaryButton
            label={"Add New"}
            onClick={() => navigate("/reviewers/add")}
            className={"px-4 py-2"}
          />
        </div>
      </div>
      <form
        className="flex flex-col gap-10 text-left"
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <h6 className="font-semibold text-[18px] m-0 p-0">Reviewer Search</h6>
        <div className="w-[200px]">
          <Select
            onChange={handleOptions}
            options={searchOptions}
            label="Search By"
            selected={selectedOption}
          />
        </div>
        {selectedOption === "NAME" ? (
          <div className="flex flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[200px]">
                <Input
                  label="First Name"
                  name="firstName"
                  id="firstName"
                  value={data?.firstName}
                />
              </div>
              <div className="w-[200px]">
                <Input
                  label="Last Name"
                  name="lastName"
                  id="lastName"
                  value={data?.lastName}
                />
              </div>
            </div>
            <div>
              <CheckBoxGroup
                label="Exclude"
                groups={[
                  {
                    label: "Non-Reviewers",
                    value: data.excludeNonReviewers || false,
                    name: "excludeNonReviewers",
                    id: "excludeNonReviewers",
                    checked: data.excludeNonReviewers || false,
                  },
                  {
                    label: "Inactive Reviewers",
                    value: data.excludeInactive || false,
                    name: "excludeInactive",
                    id: "excludeInactive",
                    checked: data.excludeInactive || false,
                  },
                  {
                    label: "Admin Hold Reviewers",
                    value: data.excludeAdminHold || false,
                    name: "excludeAdminHold",
                    id: "excludeAdminHold",
                    checked: data.excludeAdminHold || false,
                  },
                ]}
                handleChange={handleChange} // Pass the handleChange function
              />
            </div>
            <div className="w-[200px]">
              <Select
                options={states}
                name="stateCode"
                selected={data?.stateCode}
                id="stateCode"
                //onChange={handleChangeStateCode}
                label="Search By State"
              />
            </div>
          </div>
        ) : selectedOption === "VendorID" ? (
          <div className="w-[200px]">
            <Input
              label="Vendor ID"
              name="vendorId"
              id="vendorId"
              value={data.vendorId || ""}
            />
          </div>
        ) : selectedOption === "REVIEWID" ? (
          <div className="w-[200px]">
            <Input
              label="Review ID"
              name="reviewer_id"
              id="reviewer_id"
              value={data.reviewer_id || ""}
            />
          </div>
        ) : selectedOption === "GranteeID" ? (
          <div className="w-[200px]">
            <Input
              label="Grantee ID"
              name="granteeId"
              id="granteeId"
              value={data.granteeId || ""}
            />
          </div>
        ) : selectedOption === "ServiceArea" ? (
          <div className="flex flex-col gap-10">
            <div>
              <CheckBoxGroup
                label="Service Area"
                groups={[
                  {
                    label: "ECD",
                    value: "ECD",
                    name:"ecd",
                    id:"ecd",
                    checked: data.ecd || false,
                  },
                  {
                    label: "HEA",
                    value: "HEA",
                    name:"hea",
                    id:"hea",
                    checked: data.hea || false,
                  },
                ]}
                handleChange={handleChange}
              />
            </div>
            <div>
            <CheckBoxGroup
                label="Exclude"
                groups={[
                  {
                    label: "Non-Reviewers",
                    value: data.excludeNonReviewers || false,
                    name: "excludeNonReviewers",
                    id: "excludeNonReviewers",
                    checked: data.excludeNonReviewers || false,
                  },
                  {
                    label: "Inactive Reviewers",
                    value: data.excludeInactive || false,
                    name: "excludeInactive",
                    id: "excludeInactive",
                    checked: data.excludeInactive || false,
                  },
                  {
                    label: "Admin Hold Reviewers",
                    value: data.excludeAdminHold || false,
                    name: "excludeAdminHold",
                    id: "excludeAdminHold",
                    checked: data.excludeAdminHold || false,
                  },
                ]}
                handleChange={handleChange} // Pass the handleChange function
              />
            </div>
            <div className="w-[200px]">
            <Select
                options={states}
                name="stateCode"
                selected={data?.stateCode}
                id="stateCode"
                //onChange={handleChangeStateCode}
                label="Search By State"
              />
            </div>
          </div>
        ) : selectedOption === "Email" ? (
          <div className="w-[200px]">
            <Input
              label="Email Address"
              name="emailId"
              id="emailId"
              value={data?.emailId}
            />
          </div>
        ) : selectedOption === "NFRTL" ? (
          <div className="w-[200px]">
            {/* <Input label="NFRTL" name="reviewer_id" id="reviewer_id" value={data?.reviewer_id}/> */}
          </div>
        ) : (
          <></>
        )}

        <div>
          <PrimaryButton type="submit" label="Search" className={"px-3 py-2"} />
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {searchData.length ? searchData.length : 0} )
        </h6>
        <SearchResults data={searchData} />
      </div>
    </div>
  );
}
