import React, { useState, useEffect } from "react";
import TextWithLabel from "../../../inputs/text";
import Input from "../../../inputs/input";
import PrimaryButton from "../../../buttons/primary";
import SecondaryButton from "../../../buttons/secondary";
import Select from "../../../inputs/select";
import InputDate from "../../../inputs/date";
import CheckBoxGroup from "../../../inputs/checkbox-group";
import DateWithCheckBox from "../../../inputs/date-with-checkbox";
import { get, post } from "../../../../services";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { toast, Bounce } from "react-toastify";
import CheckBox from "../../../inputs/checkbox";
import TelePhone from "../../../inputs/telephone";
import PrimaryBox from "../../../box/box-primary";
import { useStore } from "../../../../store";
import Table from "../../../table/table";

const contentAreas = [
  {
    label: "ECDC",
    value: "chilDevelopment",
    name: "chilDevelopment",
    disabled: true,
    checked: false,
  },
  {
    label: "FIS",
    value: "FIS",
    name: "FIS",
    disabled: true,
    checked: false,
  },
  {
    label: "ISR1",
    value: "ISR1",
    name: "ISR1",
    disabled: true,
    checked: false,
  },
  {
    label: "ISR2",
    value: "ISR2",
    name: "ISR2",
    disabled: true,
    checked: false,
  },
  {
    label: "ERSEA",
    value: "Ersea",
    name: "Ersea",
    disabled: true,
    checked: false,
  },
  {
    label: "PDM",
    value: "Pdm",
    name: "Pdm",
    disabled: true,
    checked: false,
  },
  {
    label: "Head Start On Site Monitoring Protocol",
    value: "Hssmp",
    name: "Hssmp",
    disabled: true,
    checked: false,
  },
  {
    label: "Class",
    value: "Class",
    name: "Class",
    disabled: true,
    checked: false,
  },
  {
    label: "New Reviewer",
    value: "Newreviewer",
    name: "Newreviewer",
    disabled: true,
    checked: false,
  },
  {
    label: "The 5 Stages of the Monitoring",
    value: "Five stages of monitoring",
    name: "Five stages of monitoring",
    disabled: true,
    checked: false,
  },
  {
    label: "DIS/MH",
    value: "dis",
    name: "dis",
    disabled: true,
    checked: false,
  },
  {
    label: "HEA/NUT/SAF/TRANS",
    value: "Hea",
    name: "Hea",
    disabled: true,
    checked: false,
  },
  {
    label: "OHS Monitoring Software",
    value: "Ohs",
    name: "Ohs",
    disabled: true,
    checked: false,
  },
  {
    label: "Monitoring Overview",
    value: "Monitorover",
    name: "Monitorover",
    disabled: true,
    checked: false,
  },
  {
    label: "Introducing to Monitoring in Fy 2011",
    value: "Im",
    name: "Im",
    disabled: true,
    checked: false,
  },
  {
    label: "Roles and Responsibilities in Fy 2011",
    value: "Roles",
    name: "Roles",
    disabled: true,
    checked: false,
  },
  {
    label: "FCP",
    value: "Fcp",
    name: "Fcp",
    disabled: true,
    checked: false,
  },
  {
    label: "RC",
    value: "rc",
    name: "rc",
    disabled: true,
    checked: false,
  },
  {
    label: "PRISM",
    value: "prism",
    name: "prism",
    disabled: true,
    checked: false,
  },
  {
    label: "Willing to bring Personal Computer",
    value: "Personal",
    name: "Personal",
    disabled: true,
    checked: false,
  },
];

export default function Edit({
  setEdit,
  reviewer: initialReviewer,
  historyData,
  columns,
}) {
  const { ssoUserID } = useStore();

  const [reviewer, setReviewers] = useState(initialReviewer || {});
  //const [comments, setComments] = useState();
  //const [contentAreaState, setContentAreaState] = useState(contentAreas);
  const [reviewersEmploymentStatus, setReviewersEmploymentStatus] = useState(
    []
  );
  const [errorFields, setErrorFields] = useState({});
  const [createVendorOptions, setVendorOption] = useState(null);
  // const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(
  //   reviewer?.employment_status || ""
  // );
  //const [status, setStatus] = useState(reviewer?.status || 'active');
  //const [, setInactiveDate] = useState(reviewer?.inactive_date || '');
  //const [reason, setReason] = useState('');
  //const [requireUpdate, setRequireUpdate] = useState(false);

  //const handleStatusChange = (e) => {
  //const newStatus = e.target.value;

  // If status is changed from active to inactive, we enforce updating date and reason
  // if (status === 'active' && newStatus === 'inactive') {
  //   setRequireUpdate(true);
  // } else {
  //   setRequireUpdate(false); // Reset if status is changed back
  // }

  //setStatus(newStatus);
  //};

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      initialReviewer.status &&
      initialReviewer.status != reviewer.status &&
      (!reviewer.statusChangeReason || reviewer.statusChangeReason == "")
    ) {
      //if(){
      toast.error("Status change need reason", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      //}
    } else {
      const formData = {
        reviewers_uid: reviewer?.reviewers_uid,
        region: "10",
        ssn: reviewer?.SSN,
        title: reviewer?.Title,
        fName: reviewer?.FName,
        lName: reviewer?.LName,
        mInitial: reviewer?.MInitial,
        suffix: reviewer?.suffix,
        homeAddress1: reviewer?.HomeAddress1,
        homeAddress2: reviewer?.HomeAddress2,
        homeCity: reviewer?.HomeCity,
        homeState: reviewer?.HomeState,
        homeZip: reviewer?.HomeZip,
        homePhone: reviewer?.HomePhone,
        homeFax: reviewer?.HomeFax,
        cellularPhone: reviewer?.cellular_phone,
        fedexAddress1: reviewer?.FedexAddress1,
        fedexAddress2: reviewer?.FedexAddress2,
        fedexCity: reviewer?.FedexCity,
        fedexState: reviewer?.FedexState,
        fedexZip: reviewer?.FedexZip,
        workAddress1: reviewer?.WorkAddress1,
        workAddress2: reviewer?.WorkAddress2,
        workAddress3: reviewer?.WorkAddress3,
        workCity: reviewer?.WorkCity,
        workState: reviewer?.WorkState,
        workZip: reviewer?.WorkZip,
        workPhone: reviewer?.WorkPhone,
        workFax: reviewer?.WorkFax,
        airport: reviewer?.Airport,
        airport2: reviewer?.Airport2,
        region01: reviewer.Region01 ? reviewer.Region01 : 0,
        region02: reviewer.region02 ? reviewer.region02 : 0,
        region03: reviewer.region03 ? reviewer.region03 : 0,
        region04: reviewer.region04 ? reviewer.region04 : 0,
        region05: reviewer.region05 ? reviewer.region05 : 0,
        region06: reviewer.region06 ? reviewer.region06 : 0,
        region07: reviewer.region07 ? reviewer.region07 : 0,
        region08: reviewer.region08 ? reviewer.region08 : 0,
        region09: reviewer.region09 ? reviewer.region09 : 0,
        region10: reviewer.region10 ? reviewer.region10 : 0,
        region11: reviewer.region11 ? reviewer.region11 : 0,
        region12: reviewer.region12 ? reviewer.region12 : 0,
        email: reviewer?.email,
        userId: ssoUserID,
        status: reviewer?.status,
        inactiveDate: reviewer?.inactive_date,
        deliveryAddress: reviewer?.delivery_address,
        workEmail: reviewer?.workemail,
        companyName: reviewer?.company_name,
        reviewerType: reviewer?.reviewer_type,
        granteeId1: null,
        granteeId2: null,
        homeRegion: reviewer?.home_region,
        coachTrainee: reviewer?.coach_trainee,
        directDeposit: reviewer?.direct_deposit,
        navVendorId: reviewer?.NAV_Vendor_ID,
        masVendorId: reviewer?.mas_vendor_id,
        deltekVendorId: reviewer?.deltek_vendor_id,
        deficientStatus: reviewer?.deficient_status,
        deficientHoldDate: reviewer?.deficient_hold_date,
        statusChangeReason: reviewer?.statusChangeReason,
        adminHoldReason: "",
        admin_hold_date: reviewer?.admin_hold_date,
        admin_hold_reason: reviewer?.admin_hold_reason,
      };
      const errorMapFields = {
        fName: "First Name",
        lName: "Last Name",
        homeAddress1: "Home Address 1",
        homeCity: "Home City",
        homeState: "Home State",
        homeZip: "Home Zip",
        homePhone: "Home Phone",
        homeFax: "Home Fax",
        cellularPhone: "Cellular Phone",
        workPhone: "Work Phone",
        workFax: "Work Fax",
      };
      let errorExists = false;
      const errorObj = {};
      Object.keys(formData).map((item) => {
        if (
          !errorExists &&
          [
            "fName",
            "lName",
            "homeAddress1",
            "homeCity",
            "homeState",
            "homeZip",
            "homePhone",
          ].indexOf(item) !== -1
        ) {
          if (!formData[item] || formData[item].trim().length === 0) {
            errorExists = true;
            errorObj[item] = `Error - ${errorMapFields[item]} is required`;
          }
        }
        /*if (!errorExists && ['homePhone', 'cellularPhone', 'workPhone', 'workFax'].indexOf(item) !== -1) {
          if (formData[item] !== null && formData[item].trim().length > 0) {
            const phoneRegex = /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/;
            if (!phoneRegex.test(formData[item])) {
              errorExists = true;
              errorObj[item] = `Error - ${errorMapFields[item]} is invalid`
            }
          }
        }*/
      });
      console.log("error fields", errorObj, formData);
      if (Object.keys(errorObj).length > 0) {
        setErrorFields(errorObj);
        return;
      }

      // Make the API call to update the reviewer details
      post("reviewers/updateReviewer", formData)
        .then((response) => {
          console.log("Update successful", response);
          // Perform any actions you need after successful submission
          if (response) {
            toast.success("Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });

            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        })
        .catch((error) => {
          console.error("Error updating reviewer", error);
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  };

  useEffect(() => {
    get(`reviewers/getReviewersEmploymentStatus`)
      .then((res) => {
        if (res instanceof Array && res.length > 0) {
          const tempData = res.map((item) => ({
            label: item.employment_status_desc,
            value: item.employment_status,
          }));
          setReviewersEmploymentStatus(tempData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // const handleSelectChange = (e) => {
  //   setSelectedEmploymentStatus(e.target.value); // Update the selected employment status
  // };

  const handleChange = (e) => {
    if (e.target.type === "checkbox" && e.target.name) {
      if (e.target.name === "direct_deposit") {
        setReviewers((prev) => {
          return {
            ...prev,
            [e.target.name]: e.target.checked,
          };
        });
      } else {
        setReviewers((prev) => {
          return {
            ...prev,
            [e.target.name]: e.target.checked ? 1 : 0,
          };
        });
      }
    } else if (e.target.name && e.target.value) {
      console.log(e.target.value, "targteValue");
      if (e.target.type === "time") {
        if (e.target.value != "") {
          const value = e.target.value;
          const splitTime = value.split(":");
          setReviewers((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs.utc(
                prev[e.target.name]
                  ? prev[e.target.name]
                  : dayjs.utc(new Date()).format("YYYY-MM-DD")
              )
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        }
      } else {
        setReviewers((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target.name) {
      setReviewers((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const getRegionSelection = (val) => {
    if (val) {
      return val === 1 || val === true ? true : false;
    } else {
      return false;
    }
  };
  const createVendorID = () => {
    get(
      `reviewers/generateDeltekVendorId?input_reviewers_uid=${reviewer?.reviewers_uid}&input_user_code=${ssoUserID}`
    )
      .then((res) => {
        console.log(res);
        if (res) {
          toast.success("Created Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          setTimeout(() => {
            //window.location.reload()
          }, 200);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  console.log(reviewer, "reviw-------------------");

  return (
    <PrimaryBox>
      <form
        className="flex flex-col gap-3 text-left mb-3"
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <div className="flex gap-3">
          <div className="w-[200px]">
            <TextWithLabel label="Danya ID" value={reviewer?.SSN} />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[88px]">
            <Input
              label="Title"
              name="Title"
              id="Title"
              value={reviewer?.Title || ""}
              //onChange={handleChange}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="First Name"
              name="FName"
              id="FName"
              value={reviewer?.FName || ""}
              required
              isErrorOccurred={errorFields?.fName}
              // onChange={handleChange}
            />
          </div>
          <div className="w-[88px]">
            <Input
              label="MI"
              name="MInitial"
              id="MInitial"
              value={reviewer?.MInitial || ""}
              //onChange={handleChange}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="Last Name"
              name="LName"
              id="LName"
              value={reviewer?.LName || ""}
              required
              isErrorOccurred={errorFields?.lName}
              //onChange={handleChange}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="Suffix"
              name="suffix"
              id="suffix"
              value={reviewer?.suffix || ""}
              //onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex gap-3">
          <div className="w-[400px]">
            <Input
              label="Home Address"
              name="HomeAddress1"
              id="HomeAddress1"
              required
              isErrorOccurred={errorFields?.homeAddress1}
              value={reviewer?.HomeAddress1}
            />
          </div>
          {/* <div className="flex gap-3"> */}
          <div className="w-[400px]">
            <Input
              label="Home Address2"
              name="HomeAddress2"
              id="HomeAddress2"
              value={reviewer?.HomeAddress2}
            />
          </div>
        </div>
        {/* </div> */}
        <div className="flex gap-3">
          <div className="w-[200px]">
            <Input
              label="City"
              name="HomeCity"
              id="HomeCity"
              value={reviewer?.HomeCity}
              required
              isErrorOccurred={errorFields?.homeCity}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="State"
              name="HomeState"
              id="HomeState"
              value={reviewer?.HomeState}
              required
              isErrorOccurred={errorFields?.homeState}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="Zip Code"
              name="HomeZip"
              id="HomeZip"
              value={reviewer?.HomeZip}
              required
              isErrorOccurred={errorFields?.homeZip}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[200px]">
            <TelePhone
              label="Home Phone/Fax "
              name="HomePhone"
              id="HomePhone"
              value={reviewer?.HomePhone}
              required
              isErrorOccurred={errorFields?.homePhone}
            />
          </div>
          <div className="w-[200px]">
            <TelePhone
              label="Cellular"
              name="cellular_phone"
              id="cellular_phone"
              value={reviewer?.cellular_phone}
              isErrorOccurred={errorFields?.cellularPhone}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[350px]">
            <Input
              label="Company Name(optional)"
              name="company_name"
              id="company_name"
              value={reviewer?.company_name || ""}
              //onChange={handleChange}
            />
          </div>
          <div className="w-[200px]">
            <Select
              label="Reviewer Type"
              options={[
                { label: "Consultant", value: "Consultant" },
                { label: "Peer", value: "Peer" },
                { label: "Non Reviewer", value: "Non Reviewer" },
                { label: "NFRTL", value: "NFRTL" },
              ]}
              name="reviewer_type"
              id="reviewer_type"
              Select={reviewer?.reviewer_type}
            />
          </div>
        </div>

        <div>
          <div className="flex flex-col gap-3">
            <div className="w-[200px]">
              <TextWithLabel
                label="Deficiency Status"
                name="deficient_status"
                id="deficient_status"
                value={reviewer?.deficient_status || ""}
                //onChange={handleChange}
              />
            </div>
            <div className="flex gap-3">
              <div className="w-[200px]">
                <Select
                  label="Reviewer Status"
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "Inactive", value: "INACTIVE" },
                  ]}
                  name="status"
                  id="status"
                  selected={reviewer?.status}
                  //onchange={handleStatusChange}
                />
              </div>
              <div className="w-[200px]">
                <InputDate
                  label="Inactive Date"
                  name="inactive_date"
                  id="inactive_date"
                  defaultValue={dayjs.utc(reviewer.inactive_date).format(
                    "YYYY-MM-DD"
                  )}
                  //onChange={(e) => setInactiveDate(e.target.value)}
                />
              </div>
              <div className="w-[600px]">
                <Input
                  label="Reason"
                  type="textArea"
                  name="statusChangeReason"
                  id="statusChangeReason"
                  //required={true}
                  //value={reason}
                  //onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>

            <div className="flex gap-3">
              <div className="w-[400px]">
                <Input
                  label="Work Address"
                  name="WorkAddress1"
                  id="WorkAddress1"
                  value={reviewer?.WorkAddress1}
                />
              </div>
              <div className="w-[200px]">
                <Input
                  label="City"
                  name="WorkCity"
                  id="WorkCity"
                  value={reviewer?.WorkCity}
                />
              </div>
              <div className="w-[200px]">
                <Input
                  label="State"
                  name="WorkState"
                  id="WorkState"
                  value={reviewer?.WorkState}
                />
              </div>
              <div className="w-[200px]">
                <Input
                  label="Zip Code"
                  name="WorkZip"
                  id="WorkZip"
                  value={reviewer?.WorkZip}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-[200px]">
                <TelePhone
                  label="Phone"
                  name="WorkPhone"
                  id="WorkPhone"
                  value={reviewer?.WorkPhone}
                  isErrorOccurred={errorFields?.workPhone}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Fax"
                  name="WorkFax"
                  id="WorkFax"
                  value={reviewer?.WorkFax}
                  isErrorOccurred={errorFields?.workFax}
                />
              </div>
              <div className="w-[400px]">
                <Input
                  label="Email Address"
                  name="email"
                  id="email"
                  value={reviewer?.email}
                />
              </div>
              <div className="w-[400px]">
                <Input
                  label="Work Email Address"
                  name="workemail"
                  id="workemail"
                  value={reviewer?.workemail}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-[200px]">
                <Input
                  label="Vendor ID"
                  name="deltek_vendor_id"
                  id="deltek_vendor_id"
                  value={reviewer?.deltek_vendor_id}
                />
              </div>
              <div className="w-[200px] pt-10">
                <CheckBox
                  label="Direct Deposit"
                  name="direct_deposit"
                  id="direct_deposit"
                  checked={reviewer && reviewer.direct_deposit}
                />
              </div>
              <div className="w-[200px]">
                <Input
                  label="NAV Vendor ID"
                  name="NAV_Vendor_ID"
                  id="NAV_Vendor_ID"
                  value={reviewer?.NAV_Vendor_ID}
                />
              </div>
            </div>
            <div className="flex gap-3"></div>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[400px]">
            <Select
              label="Employment Status"
              options={reviewersEmploymentStatus}
              name="employment_status"
              id="employment_status"
            />
          </div>
          <div className="w-[400px]">
            <Input label="Employer Name" name="" id="" />
          </div>
          <div className="w-[200px]">
            <Input
              label="Fed/EHS. Grantee ID"
              name="granteeid_1"
              id="granteeid_1"
              value={reviewer?.granteeid_1}
            />
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <div className="w-[400px]">
              <Input
                label="FedEx Address"
                name="FedexAddress1"
                id="FedexAddress1"
                value={reviewer?.FedexAddress1}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-[200px]">
              <Input
                label="City"
                name="FedexCity"
                id="FedexCity"
                value={reviewer?.FedexCity}
              />
            </div>
            <div className="w-[200px]">
              <Input
                label="State"
                name="FedexState"
                id="FedexState"
                value={reviewer?.FedexState}
              />
            </div>
            <div className="w-[200px]">
              <Input
                label="Zip Code"
                name="FedexZip"
                id="FedexZip"
                value={reviewer?.FedexZip}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[400px]">
            <Input
              label="Airport"
              name="Airport"
              id="Airport"
              value={reviewer?.Airport}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-[200px]">
            <Select
              label="Admin Hold/Hold Date"
              name="admin_hold_date"
              id="admin_hold_date"
              options={[
                { label: "Select One", value: "SelectOne" },
                { label: "Release", value: "Release" },
                { label: "On Admin Hold", value: "OnAdminHold" },
              ]}
              value={reviewer?.admin_hold_date}
            />
          </div>
          <div className="w-[400px]">
            <Input
              label="Hold Reason"
              value={reviewer?.admin_hold_reason}
              name="admin_hold_reason"
              id="admin_hold_reason"
              type="textArea"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <div className="w-4/6">
              <CheckBoxGroup
                label="Regions"
                groups={[...Array(12)].map((_, i) => {
                  return {
                    name:
                      (i == 0 ? "R" : "r") +
                      `egion` +
                      (i < 9 ? "0" : "") +
                      `${i + 1}`,
                    id:
                      (i == 0 ? "R" : "r") +
                      `egion` +
                      (i < 9 ? "0" : "") +
                      `${i + 1}`,
                    label: (i < 9 ? "0" : "") + `${i + 1}`,
                    checked: getRegionSelection(
                      reviewer[
                        (i == 0 ? "R" : "r") +
                          `egion` +
                          (i < 9 ? "0" : "") +
                          `${i + 1}`
                      ]
                    ),
                  };
                })}
                //handleChange={handleCheckboxChange}
              />
            </div>
          </div>
          <div className="w-full text-sm">
            <CheckBoxGroup
              className="text-lg"
              label="Content Area"
              groups={contentAreas}
              //handleChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <TextWithLabel label="Test Status" />
            <div className="flex gap-3">
              <div className="w-[200px]">
                <Select
                  label="Computer Literacy"
                  options={[
                    { label: "Not Applicable", value: "NotApplicable" },
                    { label: "Pass", value: "Pass" },
                    { label: "Fail", value: "Fail" },
                  ]}
                  name=""
                  id=""
                />
              </div>
              <div className="w-[400px]">
                <Input
                  label="Comments"
                  //value={comments}
                  name="Comments"
                  id="Comments"
                  type="textArea"
                  //onchange={handleCommentsChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <TextWithLabel label="Online Trainings (Mm/Dd/Yy)" />
            <div className="flex gap-3">
              <div className="flex flex-col gap-3">
                <DateWithCheckBox label="ECD" id="" name="" />
                <DateWithCheckBox label="ERSEA" id="" name="" />
                <DateWithCheckBox
                  label="Head start onsite Monitoring Program"
                  id=""
                  name=""
                />
                <DateWithCheckBox label="New TReviewer" id="" name="" />
              </div>
              <div className="flex flex-col gap-3">
                <DateWithCheckBox label="FIS" id="" name="" />
                <DateWithCheckBox label="PDM" id="" name="" />
                <DateWithCheckBox label="Class" id="" name="" />
                <div className="flex items-center space-x-4">
                  <DateWithCheckBox
                    label="The 5 stages of a monitoring process"
                    id=""
                    name=""
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <DateWithCheckBox label="DIS/MH" id="" name="" />
                <DateWithCheckBox
                  label="OHS Monitoring Software"
                  id=""
                  name=""
                />
                <DateWithCheckBox
                  label="Roles and Responsibilties in FY 2011"
                  id=""
                  name=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-5">
          <SecondaryButton
            className={"py-3 px-10"}
            onClick={() => setEdit(false)}
            label={"Cancel"}
            type="button"
          />
          <PrimaryButton
            type="submit"
            className={"py-3 px-10"}
            label={"Update"}
            //onClick={handleFormSubmit}
          />{" "}
        </div>
        <div>
          {!reviewer.deltek_vendor_id && (
            <div className="flex gap-3 mt-5">
              <div className="min-w-[200px]">
                <Select
                  onChange={(e) => setVendorOption(e.target.value)}
                  options={[
                    { label: "Select", value: null },
                    { label: "Create New Vendor ID", value: "create" },
                  ]}
                  label=""
                />
              </div>
              <PrimaryButton
                disabled={!createVendorOptions}
                type="button"
                label="Create Vendor ID"
                className={"p-1"}
                onClick={createVendorID}
              />
            </div>
          )}
        </div>
      </form>
      <div className="flex flex-col gap-3 text-left">
        <TextWithLabel className="text-lg" label="Status Change History" />
        <Table columns={columns} data={historyData} />
      </div>
    </PrimaryBox>
  );
}
