import React, { useState, useEffect } from "react";
import PrimaryBox from "../../components/box/box-primary";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import { DynamicTable } from "../../components/table/tableWithTotal";

import { ReactComponent as TrashSvg } from "../../assets/icons/trash.svg";

import { ReactComponent as EditSvg } from "../../assets/icons/edit.svg";
import { get } from "../../services";

import { toast, Bounce } from "react-toastify";

export default function EnterMoneyOwed({ handleDelete, handleMaintain }) {
  const [moneyOwedList, setMoneyOwedList] = useState([]);
  const [reviewID, setReviewID] = useState("");
  const [danyaID, setDanyaID] = useState("");
  const [reviewerInfo, setReviwerInfo] = useState({});

  useEffect(() => {
    get(`ageing/ageingMoneyOwnedList`)
      .then((res) => {
        if (res && res.length > 0) {
          setMoneyOwedList(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const validate = () => {
    if (reviewID && danyaID) {
      setReviwerInfo({});
      get(
        `ageing/ageingMoneyOwnedValidation?tripId=${reviewID}&danyaId=${danyaID}`
      )
        .then((res) => {
          if (res && res.length > 0) {
            if (
              res &&
              res[0].ValidationResults &&
              res[0].ValidationResults !== ""
            ) {
              toast.error(res[0].ValidationResults, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            } else {
              get(
                `ageing/ageingIncompleteMoneyOwnedReviewerInfo?tripId=${reviewID}&danyaId=${danyaID}`
              )
                .then((res) => {
                  if (res && res.length > 0) {
                    setReviwerInfo(res[0]);
                  }
                })
                .catch((err) => {
                  console.error("error", err);
                });
            }
          } else {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }
        })
        .catch(() => {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    } else {
      toast.error("Enter Review ID and Danya ID", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const getObjDetails = (id, type) => {
    get(`ageing/ageingMoneOwedReviewerDetails?tripDetailsUid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          if (type == "delete") {
            handleDelete(res[0]);
          } else if (type == "maintain") {
            handleMaintain(res[0]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_name",
      headerName: "Reviewer",
    },
    {
      field: "rev_date",
      headerName: "Review Date",
    },
    {
      field: "",
      headerName: (
        <div className="w-full flex justify-center">Delete/Maint</div>
      ),
      cellRenderer: (_, item) => {
        return (
          <div className="flex gap-3 items-center justify-center">
            <p
              className="text-indigo-600 cursor-pointer"
              onClick={() => getObjDetails(item.trip_details_uid, "delete")}
            >
              <TrashSvg className="text-xs text-red-600" width="20" />
            </p>
            <p
              className="text-indigo-600 cursor-pointer"
              onClick={() => getObjDetails(item.trip_details_uid, "maintain")}
            >
              <EditSvg className="text-xs text-indigo-600" width="20" />
            </p>
          </div>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Entered By",
    },
    {
      field: "create_ts",
      headerName: "Entered On",
      type: "date",
      time: true,
    },
  ];
  return (
    <div>
      <PrimaryBox>
        <div className="flex flex-col gap-3 text-left">
          <div className="flex gap-3 items-center">
            <div>
              <Input
                name="reviewID"
                label="Review ID"
                onChange={(e) => setReviewID(e.target.value)}
              />
            </div>
            <div>
              <Input
                name="danyaID"
                label="Danya ID"
                onChange={(e) => setDanyaID(e.target.value)}
              />
            </div>
            <div className="h-full mt-auto">
              <PrimaryButton
                onClick={() => validate()}
                label="Validate"
                className={"p-2 "}
              />
            </div>
          </div>
          <div>
            {reviewerInfo && (
              <div className="flex flex-col gap-1 text-sm my-3 text-indigo-600 font-[600]">
                <div>{reviewerInfo.rev_name}</div>
                <div>{reviewerInfo.HomeAddress1}</div>
                <div>{reviewerInfo.city_name}</div>
              </div>
            )}
          </div>
          <div>
            <div>
              <Input className="w-[80px]" required={true} label="Money Owed" />
            </div>
          </div>
          <div>
            <PrimaryButton label="Update Now" className={"p-2"} />
          </div>
          <h6 className="mt-10 font-semibold">UnProcessed Transaction</h6>
          <div className="mt-3">
            <DynamicTable data={moneyOwedList} columns={columns} />
          </div>
        </div>
      </PrimaryBox>
    </div>
  );
}
