import React, { useState } from "react";
import PrimaryBox from "../../components/box/box-primary";
import EnterIncomplete from "../../components/ter/enter-incomplete";
import EnterMoneyOwed from "../../components/ter/enter-money-owed";
// import SecondaryTab from "../../components/tabs/secondary";
import PrimaryModal from "../../components/modals/primary";
import TextWithLabel from "../../components/inputs/text";
import PrimaryButton from "../../components/buttons/primary";
import Input from "../../components/inputs/input";
import PrimaryTab from "../../components/tabs/primary";
import { useLocation } from "react-router-dom";
import MultiSelect from "../../components/inputs/multi-select";
import {  } from "../../services";

import { toast, Bounce } from "react-toastify";
import { post, deleteMethod } from "../../services";
import { useStore } from "../../store";
import MultiText from "../../components/inputs/multi-text";
const reasons = [
  {
    label: "TER Need signature",
    value: "ter_signature",
  },
  {
    label: "COP Needs signature",
    value: "cop_signature",
  },
  {
    label: "Lodging Receipt",
    value: "lodging",
  },
  {
    label: "Car Rental Receipt",
    value: "car_rental",
  },
  {
    label: "Gasoline Receipt",
    value: "gasoline",
  },
  {
    label: "Taxis/Shuttle Receipt",
    value: "taxis",
  },
  {
    label: "Tolls Receipt",
    value: "tolls",
  },
  {
    label: "Parking Receipt",
    value: "parking",
  },
  {
    label: "Electronic Travel Expense Report Not Submitted",
    value: "",
  },
  {
    label: "COP Not Submitted",
    value: "cop_missing",
  },
  {
    label: "Pre-Site Materials Review Checklist Not Submitted",
    value: "preSite_missing",
  },
  {
    label:
      "Pre-Site Materials Review Checklist Needs signature(s)",
    value: "preSite_signature",
  },
  {
    label:
      "Grantee Contact and Address Confirmation Form Not Submitted",
    value: "Grantee_missing",
  },
  {
    label:
      "Grantee Contact and Address Confirmation Form needs signature(s)",
    value: "grantee_signature",
  },
  {
    label: "Miscellaneous",
    value: "misc",
  },
]

export default function EnterIncompleteTER() {
  const [deleteObj, setDeleteId] = useState(null);
  const [maintainObj, setMaintainID] = useState(null);
  const [ selectedReasons, setSelectedReasons] = useState([]);
  

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get("tab");



  const { ssoUserID } = useStore();
  const [comments, setComments] = useState('');

  const handleDelete = (obj) => {
    setDeleteId(obj);
    setMaintainID(null);
    const tempSelectedReasons = []
    if(obj){
      Object.keys(obj).forEach(key => {
        if(obj[key] == '1'){
          tempSelectedReasons.push(key)
        }
        if(key == "misc_desc"){
          setComments(obj['misc_desc'])
        }else{
          setComments(obj['comments'])
        }
      })
    }
    setSelectedReasons(tempSelectedReasons)
  };
  const handleMaintain = (obj) => {
    setDeleteId(null);
    setMaintainID(obj);
    const tempSelectedReasons = []
    if(obj){
      Object.keys(obj).forEach(key => {
        if(obj[key] == '1'){
          tempSelectedReasons.push(key)
        }
        if(key == "misc_desc"){
          setComments(obj['misc_desc'])
        }else{
          setComments(obj['comments'])
        }
      })
    }
    setSelectedReasons(tempSelectedReasons)
  };

  const tabs = [
    {
      label: "Enter Incomplete TER",
      tab: (
        <div className="mt-3">
          <EnterIncomplete
            handleDelete={handleDelete}
            handleMaintain={handleMaintain}
          />
        </div>
      ),
    },
    {
      label: "Enter Money Owed TER",
      tab: (
        <div className="mt-3">
          <EnterMoneyOwed
            handleDelete={handleDelete}
            handleMaintain={handleMaintain}
          />
        </div>
      ),
    },
  ];

  const deleteIncomplete = () => {
    if(deleteObj){
      deleteMethod(`ageing/ageingIncompleteTerDelete?tripDetailsUid=${deleteObj}`).then(() => {
        
        toast.success('Successfully Deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        setDeleteId(null)
        setMaintainID(null)
        setTimeout(() => {
          window.location.reload()
        }, 300);
        
      }).catch(err => {
        console.log(err)
        toast.error('Delete Failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })
    }
   
  }
  
  const submitIncomplete = () => {
    console.log(comments, 'comments', selectedReasons, maintainObj)
    if(maintainObj){
      if(selectedReasons && selectedReasons.length >0 ){
        var defaultValues= {
          "trip_details_uid": maintainObj.trip_details_uid,
          "ter_signature": 0,
          "cop_signature": 0,
          "lodging": 0,
          "car_rental": 0,
          "gasoline": 0,
          "taxis": 0,
          "tolls": 0,
          "parking": 0,
          "misc": 1,
          "misc_desc": selectedReasons.includes('misc') ? comments : null,
          "ter_missing": 0,
          "cop_missing": 0,
          "comments": selectedReasons.filter(d => d != 'misc').length >0 ? comments : null,
          "user_id": ssoUserID,
          "preSite_missing": 0,
          "preSite_signature": 0,
          "Grantee_missing": 0,
          "grantee_signature": 0
      }

      var tempObj = {};
      Object.keys(defaultValues).forEach(key => {
        if(selectedReasons.includes(key)){
          tempObj[key] = 1
        }else{
          tempObj[key] = defaultValues[key]
        }
      })

      post(`ageing/ageingIncompleteTerUpdate`, tempObj).then(() => {
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        setDeleteId(null)
        setMaintainID(null)
        setTimeout(() => {
          window.location.reload()
        }, 300);
      }).catch(err => {
        console.error(err)
        toast.error('Update Failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })

      }else{
        toast.error('Select any reason', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }else{
      toast.error('Reviwer not found', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
    
  }

  const getReason = (reason) => {
    if(reason){
      const filterReason = reasons.filter(d => d.value == reason);
      if(filterReason && filterReason.length >0){
        return filterReason[0].label
      }else{
        return ''
      }
    }else{
      return ''
    }
  }

  return (
    <div>
      <PrimaryBox>
        <div className="flex flex-col gap-3 text-left">
          <h6 className="font-semibold text-xl pb-3">
            {" "}
            {defaultTab == 1
              ? "Enter Money Owed TER"
              : "Enter Incomplete TER"}{" "}
          </h6>
          <PrimaryTab tabs={tabs} selected={defaultTab} />

          {deleteObj && defaultTab == 1 && (
            <PrimaryModal
              isOpened={deleteObj ? true : false}
              handleModal={() => setDeleteId(null)}
            >
              <div className="p-2">
                <div className="flex flex-col gap-3">
                <TextWithLabel label="Review ID" value={deleteObj &&  deleteObj.reviewID} />
                  <div className="flex flex-col gap-1">
                    <TextWithLabel label="Danya ID" value={deleteObj &&  deleteObj.DanysId}  />
                    <MultiText label="" seperator=" " value={['Title','FName', 'lname', 'MInitial']} data={deleteObj}/>
                    <TextWithLabel label="" value={deleteObj && deleteObj.HomeAddress1} />
                    <MultiText label="" seperator=" " value={['HomeCity','HomeState', 'HomeZip']} data={deleteObj}/>
                  </div>
                  <TextWithLabel label="Money Owed($)" value="$100.00" />

                  <PrimaryButton label="Delete Now" className={"p-2"} />
                </div>
              </div>
            </PrimaryModal>
          )}

          {deleteObj && defaultTab != 1 && (
            <PrimaryModal
              isOpened={deleteObj ? true : false}
              handleModal={() => setDeleteId(null)}
            >
              <div className="p-2">
                <div className="flex flex-col gap-3">
                  <TextWithLabel label="Review ID" value={deleteObj &&  deleteObj.reviewID} />
                  <div className="flex flex-col gap-1">
                    <TextWithLabel label="Danya ID" value={deleteObj &&  deleteObj.DanysId}  />
                    <MultiText label="" seperator=" " value={['Title','FName', 'lname', 'MInitial']} data={deleteObj}/>
                    <TextWithLabel label="" value={deleteObj && deleteObj.HomeAddress1} />
                    <MultiText label="" seperator=" " value={['HomeCity','HomeState', 'HomeZip']} data={deleteObj}/>
                  </div>
                  <TextWithLabel label="Reasons" value="" />
                 <div className="flex gap-3"> <div className="w-4/6">
                  {selectedReasons.map((reason, i) => {
                    return  <TextWithLabel key={i} label="" value={getReason(reason)} />
                  })}
                 </div>
                 <p>-</p>
                 <TextWithLabel label="" onChange={(e) => setComments(e.target.value)} value={comments}/></div>

                  <PrimaryButton onClick={() => deleteIncomplete()} label="Delete Now" className={"p-2"} />
                </div>
              </div>
            </PrimaryModal>
          )}

          {maintainObj && defaultTab == 1 && (
            <PrimaryModal
              isOpened={maintainObj ? true : false}
              handleModal={() => setMaintainID(null)}
            >
              <div className="p-2">
                <div className="flex flex-col gap-3">
                <TextWithLabel label="Review ID" value={maintainObj &&  maintainObj.reviewID} />
                  <div className="flex flex-col gap-1">
                    <TextWithLabel label="Danya ID" value={maintainObj &&  maintainObj.DanysId}  />
                    <MultiText label="" seperator=" " value={['Title','FName', 'lname', 'MInitial']} data={maintainObj}/>
                    <TextWithLabel label="" value={maintainObj && maintainObj.HomeAddress1} />
                    <MultiText label="" seperator=" " value={['HomeCity','HomeState', 'HomeZip']} data={maintainObj}/>
                  </div>
                  <Input label="Money Owed($)" value="" />

                  <PrimaryButton label="Update Now" className={"p-2"} />
                </div>
              </div>
            </PrimaryModal>
          )}
          {maintainObj && defaultTab != 1 && (
            <PrimaryModal
              isOpened={maintainObj ? true : false}
              handleModal={() => setMaintainID(null)}
            >
              <div className="p-2">
                <div className="flex flex-col gap-3">
                <TextWithLabel label="Review ID" value={maintainObj &&  maintainObj.reviewID} />
                  <div className="flex flex-col gap-1">
                    <TextWithLabel label="Danya ID" value={maintainObj &&  maintainObj.DanysId}  />
                    <MultiText label="" seperator=" " value={['Title','FName', 'lname', 'MInitial']} data={maintainObj}/>
                    <TextWithLabel label="" value={maintainObj && maintainObj.HomeAddress1} />
                    <MultiText label="" seperator=" " value={['HomeCity','HomeState', 'HomeZip']} data={maintainObj}/>
                  </div>
                  <div className="flex gap-3 h-full w-full">
                    <div>
                      <MultiSelect
                        options={reasons}
                        label="Reason(s)"
                        selectedValues={selectedReasons}
                        onChange={setSelectedReasons}
                      />
                    </div>
                    <div className="mt-auto w-full">
                      <Input type="textArea" className="w-full" label="" value={comments} onChange={(e) => setComments(e.target.value)} />
                    </div>
                  </div>
                        
                  <PrimaryButton onClick={() => submitIncomplete()} label="Update Now" className={"p-2"} />
                </div>
              </div>
            </PrimaryModal>
          )}
        </div>
      </PrimaryBox>
    </div>
  );
}
