import React from "react";
import PropTypes from "prop-types";

const CheckBoxGroup = ({ label, groups, handleChange, alignmentType }) => {
  if (!groups || groups.length === 0) {
    return null; // Return null if there are no groups
  }

  return (
    <div className="flex flex-col gap-3">
      {label && (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      )}
      <div>
        <div
          className={`flex gap-3 ${
            alignmentType && alignmentType === "as" ? "flex-col" : "flex-wrap"
          }`}
        >
          {groups.map((group, i) => (
            <div key={i} className="flex">
              {
                handleChange ?<input
                type="checkbox"
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
                id={group.value}
                name={group.name} // Bind correct name for form submission
                value={group.value}
                checked={group.checked} // Bind checked state from parent
                onChange={(e) => handleChange(e, group.value)} // Handle change with group value
                disabled={group.disabled} // Handle disabled state
              /> : <input
              type="checkbox"
              className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
              id={group.value}
              name={group.name} // Bind correct name for form submission
              value={group.value}
              checked={group.checked} // Bind checked state from parent
              //onChange={(e) => handleChange(e, group.value)} // Handle change with group value
              disabled={group.disabled} // Handle disabled state
            />
              }
              
              <label
                htmlFor={group.value}
                className="text-sm text-gray-500 ms-2 dark:text-neutral-400 cursor-pointer"
              >
                {group.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CheckBoxGroup.propTypes = {
  label: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired, // Ensure this is bool
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired, // handleChange must be provided
  alignmentType: PropTypes.string,
};

export default CheckBoxGroup;
