import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AccordianPrimary from "../../../accordian/primary";
import TextWithLabel from "../../../inputs/text";
import GeneralTISTab from "./tabs/general";
import TravelAgencyTISTab from "./tabs/travelAgency";
import HotelInfo from "./tabs/hotelInfo";
import TravelInfo from "./tabs/travelInfo";
import CashAdvance from "./tabs/cashAdvance";
import LocalPOV from "./tabs/localPOV";
import FlightInfo from "./tabs/flightInfo";
import PaymentValidation from "./tabs/paymentValidation";
import PrimaryButton from "../../../buttons/primary";
import SecondaryButton from "../../../buttons/secondary";
import { get, post } from "../../../../services/index";
import Select from "../../../inputs/select";
import AccordianAction from "../../../accordian/accordian-action";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import AirTickets from "./tables/air-tickets";
import CarRentals from "./tables/car-rentals";
import Loader from "../../../loader";
import { useLocation } from "react-router-dom";
import { useStore } from "../../../../store";

import { toast, Bounce } from "react-toastify";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const TISInputTabDetail = () => {
  const { id } = useParams();
  const { ssoUserID } = useStore();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [reviewers, setReviewers] = useState({});
  const [selectedReviewer, setCurrentReviewer] = useState(null);
  const [trip_details_uid, setTripDetailsUId] = useState(null);
  const [accordianAction, setAccordianAction] = useState("expandAll");
  const [errorDetails, setErrorDetails] = useState({});

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search);
  const detailsID = urlParams.get('detailsID');

  useEffect(() => {
    if (id) {
      get(`reviews/tis_input_reviewers?review_id=${id}`)
        .then((res) => {
          if (res && res.length > 0) {
            if (res[0] && res[0].trip_details_uid) {
              setReviewers(
                res?.map((d) => {
                  var temp = {};
                  temp["label"] = d?.rev_name;
                  temp["value"] = d?.trip_details_uid;
                  return temp;
                })
              );
              console.log(detailsID, 'detailsID')
              getTISData(detailsID ? detailsID : res[0].trip_details_uid);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }, []);

  const getTISData = (rID) => {
    setLoading(true);
    setTripDetailsUId(rID);
    setCurrentReviewer(rID)
    get(`reviews/tis_input_details?trip_details_uid=${rID}`)
      .then((res) => {
        console.log(res, "ress");
        if (res && res.length > 0) {
          setData(res[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const changeReviewer = (e) => {
    e.preventDefault();
    console.log(e.target.value, "eeeeeee");
    if (e.target.value) {
      setCurrentReviewer(e.target.value);
      getTISData(e.target.value);
    }
  };

  const handleDateValidate = (dateOne, dateTwo, dateOneName, dateTwoName) => {
    const date1 = new Date(dateOne);
    const date2 = new Date(dateTwo);
    const diffTime = date2 - date1;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30);
    if (diffMonths > 12) {
      return { [dateTwoName]: `Error - ${dateTwoName} should be with in Fiscal Year` }
    }
    if (diffDays < 0) {
      return { [dateOneName]: `Error - ${dateOneName} should be prior to ${dateTwoName}` }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting dta", data);
    const errorFields = {};

    Object.keys(data).map((item) => {
      if (data['TravelMode'] === 'AIR') {
        if (item === 'arrival_arrival_date' || item === 'depart_depart_date') {
          if (!data['arrival_arrival_date']) {
            errorFields['arrival_arrival_date'] = 'Arrival Date is required';
          }
          if (!data['depart_depart_date']) {
            errorFields['depart_depart_date'] = 'Departure Date is required';
          }
          if (data['arrival_arrival_date'] && data['depart_depart_date']) {
            const dateError = handleDateValidate(data['arrival_arrival_date'], data['depart_depart_date'], 'Arrival Date', 'Departure Date');
            if (dateError) {
              if (dateError['Arrival Date']) {
                errorFields['arrival_arrival_date'] = dateError['Arrival Date'];
              }
              if (dateError['Departure Date']) {
                errorFields['depart_depart_date'] = dateError['Departure Date'];
              }
            }
          }
        }
      }
      if (item === 'Return_Date' || item === 'Depart_date') {
        if (!data['Return_Date']) {
          errorFields['Return_Date'] = 'Return Date (Reviewers) is required';
        }
        if (!data['Depart_Date']) {
          errorFields['Depart_Date'] = 'Departure Date (Reviewers) is required'
        }
        if (data['Return_Date'] && data['Depart_Date']) {
          const dateError = handleDateValidate(data['Depart_Date'], data['Return_Date'], 'Departure Date', 'Return Date');
          console.log("Date Error", dateError);
          if (dateError) {
            if (dateError['Return Date']) {
              errorFields['Return_Date'] = dateError['Return Date'];
            }
            if (dateError['Departure Date']) {
              errorFields['Depart_Date'] = dateError['Departure Date'];
            }
          }
        }
      }
      if (item === 'Arrival_date' || item === 'Departure_date') {
        if (!data['Arrival_date']) {
          errorFields['Arrival_date'] = 'Grantee Arrival Date is required';
        }
        if (!data['Departure_date']) {
          errorFields['Departure_date'] = 'Grantee Departure Date is required';
        }
        if (data['Arrival_date'] && data['Departure_date']) {
          const dateError = handleDateValidate(data['Arrival_date'], data['Departure_date'], 'Grantee Arrival Date', 'Grantee Departure Date');
          if (dateError) {
            if (dateError['Grantee Arrival Date']) {
              errorFields['Arrival_date'] = dateError['Grantee Arrival Date'];
            }
            if (dateError['Grantee Departure Date']) {
              errorFields['Departure_date'] = dateError['Grantee Departure Date'];
            }
          }
        }
      }
      if (item === "TravelMode") {
        if (!data[item] || data[item] === 'Select One') {
          errorFields[item] = `Travel Mode is required`;
        } else {
          if (data[item] === 'RENTAL CAR') {
            if (!data['Rental_Car']) {
              errorFields['Rental_Car'] = `Rental Car is required`
            }
            if (isNaN(data['Rental_Car'])) {
              errorFields['Rental_Car'] = `Rental Car contains invalid characters`
            }
            if (!data['CarSize'] || data['CarSize'] === 'Select One') {
              errorFields['CarSize'] = `Car Size is required`
            }
            if (!data['RentalAgency'] || data['RentalAgency'] === 'Select One') {
              errorFields['RentalAgency'] = `Rental Agency is required`
            }
          }
          if (data[item] === 'POV' || data[item] === 'LOCAL POV') {
            console.log("summ", typeof data['POV_Miles'])
            if (!data['POV_Miles']) {
              errorFields['POV_Miles'] = `POV Miles is required`
            }
            if (isNaN(data['POV_Miles'])) {
              errorFields['POV_Miles'] = `POV Miles contains invalid characters`
            }
            if (typeof data['POV_Miles'] === 'number' && data['POV_Miles'] < 0) {
              errorFields['POV_Miles'] = `POV Miles should be greater than zero`
            }
          }
          if (data[item] === 'AIR') {
            if (!data['Airfare']) {
              errorFields['Airfare'] = `Air Fare is required`
            }
            if (isNaN(data['Airfare'])) {
              errorFields['Airfare'] = `Air Fare contains invalid characters`
            }
            if (typeof data['Airfare'] === "number" && data['Airfare'] < 0) {
              errorFields['Airfare'] = `Air Fare should be greater than zero`
            }
          }
        }
      }
      if (item === "ComponentArea") {
        console.log("data item", item, data[item])
        if (!data[item] || data[item] === 'Select one') {
          errorFields[item] = `${item} is required`
        }
      }
      if (item === 'meals') {
        if (data['TravelMode'] === 'LOCAL POV') {

          if (!data[item]) {
            console.log("Enters here for meals", data['TravelMode'], data[item], item)
            errorFields[item] = `${item} is required`
          }
          if (data[item] && !isNaN(data[item])) {
            errorFields[item] = `${item} contains invalid characters`
          }
          if (data[item] && isNaN(data[item])) {
            errorFields[item] = `${item} contains invalid characters`
          }
        }
      }
      if (item === 'MiscCAAmount' || item === 'MiscEncumbAmount' || item === 'MiscCADescript' || item === 'MiscEncumbDescript' || item === 'HonorariaDays' || item === 'mi_days') {
        if (item === 'MiscCAAmount' || item === 'MiscEncumbAmount' || item === 'HonorariaDays' || item === 'mi_days') {
          if (isNaN(data[item])) {
            errorFields[item] = `${item} contains invalid characters`
          }
        }
        if (item === 'MiscCAAmount' && data[item] && !data['MiscCADescript']) {
          errorFields['MiscCADescript'] = `Misc C/A Desc is required`
        }
        if (item === 'MiscEncumbAmount' && data[item] && !data['MiscEncumbDescript']) {
          errorFields['MiscEncumbDescript'] = `Encumb Desc is required`
        }
      }
    });
    console.log("final erro fields", errorFields)
    setErrorDetails(errorFields);

    if (Object.keys(errorFields).length > 0) {
      return;
    } else {
      post("reviews/tis_update_trip_details", {...data, "userId": ssoUserID})
        .then((res) => {
          console.log(res);
          if (res) {
            toast.success('Success', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  };

  const handleChange = (e) => {
    console.log(e.target?.type, "e.target");
    if (e.target.type === "time") {
      const value = e.target.value;
      const splitTime = value.split(":");
      console.log(splitTime, "splitTime");
      setData((prev) => {
        return {
          ...prev,
          [e.target.name]: dayjs.utc(prev[e.target.name])
            .set("hour", splitTime[0])
            .set("minute", splitTime[1]),
        };
      });
    }else if (e.target && e.target.type === "checkbox") {
      setData((prev) => {
        return { ...prev, [e.target.name]: e.target.checked };
      });
    } else if (e.target.name && e.target.value) {
      setData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  console.log(data, "data");

  const accordians = [
    {
      label: "General",
      content: <GeneralTISTab data={data} errors={errorDetails} />,
    },
    {
      label: "Travel Agency Info",
      content: <TravelAgencyTISTab data={data} errors={errorDetails} />,
    },
    {
      label: "Hotel Info",
      content: <HotelInfo data={data} trip_details_uid={trip_details_uid} errors={errorDetails} />,
    },
    {
      label: "Travel Info Last Uploaded On (Air) (Car)",
      content: <TravelInfo data={data} id={id} errors={errorDetails} />,
      info: "4/19/23, 10:36:15 PM",
    },
    {
      label: "Cash Advance",
      content: <CashAdvance data={data} errors={errorDetails} />,
    },
    {
      label: "For Local POV's Only (if Applicable)",
      content: <LocalPOV data={data} errors={errorDetails} />,
    },
    {
      label: "Flight Info",
      content: <FlightInfo data={data} errors={errorDetails} />,
    },
    {
      label: "For Payment Validation Only",
      content: <PaymentValidation data={data} />,
    },
  ];
  return (
    <>
      {loading ? (
        <div><Loader /></div>
      ) : data && Object.keys(data).length > 0 ? (
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <div className="flex">
            <h4 className="text-justify font-bold text-[24px]">
              Reviewer Info
            </h4>
            <div className="ml-auto">
              <AccordianAction accordianAction={accordianAction} handleAccordian={(d) => setAccordianAction(d)} />
            </div>
          </div>

          <div className="flex text-left px-3 py-3 w-full">
            <div className="flex flex-col gap-3 w-full">
              <div className="w-400">
                <Select
                  label="Reviewer"
                  options={reviewers}
                  onChange={changeReviewer}
                  selected={selectedReviewer}
                  value={selectedReviewer}
                />
              </div>
              <div className="flex w-full">
                <div className="w-3/6 flex flex-col gap-3">
                  <div className="w-60">
                    <TextWithLabel
                      label="Address"
                      value={`${data?.HomeAddress1 ? `${data?.HomeAddress1},` : ""} ${data?.HomeAddress2 ? `${data?.HomeAddress2},` : ""}
                       ${data?.HomeCity ? `${data?.HomeCity},` : ""} ${data?.HomeState ? data?.HomeState : ""}`}
                    />
                  </div>
                  <div className="w-60">
                    <TextWithLabel
                      label="Phone (Home)"
                      value={formatPhoneNumber(data.HomePhone ? data.HomePhone : "")}
                    />
                  </div>
                </div>
                <div className="w-3/6 flex flex-col gap-3">
                  <div className="w-60">
                    <TextWithLabel label="Phone (Work)" value={(data.WorkPhone)} />
                  </div>
                  <div className="w-60">
                    <TextWithLabel label="Maintain Email" value={(data.conf_email)} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ml-auto">
              <div className="w-120">
                <TextWithLabel label="Training Info" value="" />
                <div className="w-60 text-[13px] flex flex-col gap-2 font-[500]">
                  <div>Prism</div>
                  <div>QA 00/00/00</div>
                  <div>EHS 00/00/00</div>
                  <div>RC 00/00/00</div>
                  <div>SA Lang Follow Up Yes</div>
                  <div>Comp Lit UD Training - Systems CLASS ERSEA DIS/MH</div>
                  <div>FCP NewReviewer Intro Protocol</div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="pb-8">
            <div className="border rounded bg-white border-[#E5E7EB] p-3">
              <AccordianPrimary accordians={accordians} accordianAction={accordianAction} />
            </div>
            <div className="flex gap-2 mt-3">
              <SecondaryButton className={"py-3 px-10"} type="button" onClick={()=> window.location.reload()} label={"Cancel"} />
              <PrimaryButton
                type="submit"
                className={"py-3 px-10"}
                label={"Update"}
              />
            </div>
            <div className="flex flex-col gap-3 mt-3">
              <AirTickets uid={trip_details_uid}/>
              <CarRentals uid={trip_details_uid}/>
            </div>
          </div>
        </form>
      ) : (
        <div>No Data Found</div>
      )}
    </>
  );
};

export default TISInputTabDetail;
