import React from "react";
import InputDate from "../../../inputs/date";
import InputTime from "../../../inputs/time";
import Input from "../../../inputs/input";
import PrimaryButton from "../../../buttons/primary";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { post } from "../../../../services";
import { toast, Bounce } from "react-toastify";
import { useStore } from "../../../../store";

export default function UpdateTis({ data, handleChange }) {
  const { ssoUserID } = useStore();

  const submit = () => {
    const base = "reviews";
    console.log(data, 'dataaa')
    if (data && data.a_update && data.a_update.toLowerCase() == "yes") {
      const temp = {
        trip_uid: data.trip_uid,
        arrival_date: data.arrival_date,
        arrival_time: dayjs.utc(data.arrival_date).format('MM-DD-YYYY')+' '+data.arrival_time,
        "userId": ssoUserID
      };
      callPost(base + "/" + "batch_update_arrival_datetime", temp, 'Arrival Date and Arrival Time');
    }else if (data && data.a_city_update && data.a_city_update.toLowerCase() == "yes") {
      const temp = {
        trip_uid: data.trip_uid,
        arrival_city: data.arrival_city,
        "userId": ssoUserID
      };
      callPost(base + "/" + "batch_update_arrival_city", temp, 'Arrival City');
    }else if (data && data.d_update && data.d_update.toLowerCase() == "yes") {
      const temp = {
        trip_uid: data.trip_uid,
        departure_date: data.departure_date,
        departure_time: dayjs.utc(data.departure_date).format('MM-DD-YYYY')+' '+data.departure_time,
        "userId": ssoUserID
      };
      callPost(base + "/" + "batch_update_departure_datetime", temp, 'Departure Date and Departure Time');
    }else if (data && data.d_city_update && data.d_city_update.toLowerCase() == "yes") {
      const temp = {
        trip_uid: data.trip_uid,
        departure_city: data.departure_city,
        "userId": ssoUserID
      };
      callPost(base + "/" + "batch_update_departure_city", temp, 'Departure City');
    }else{
      toast.error('Kindly Select Something', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const callPost = (url, params, msg) => {
    post(url, params)
      .then((res) => {
        if (res) {
          console.log(res);
          toast.success(msg + ' '+ 'Updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(msg + ' '+ 'Update Failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  // console.log(dayjs(data?.departure_date).toISOString().split('T')[0], 'strignjs')

  return (
    <div className="py-3">
      <form onChange={handleChange}>
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <div className="w-[300px] flex gap-3">
              <InputDate
                label="Arrival Date"
                name="arrival_date"
                defaultValue={
                  data?.arrival_date &&
                  dayjs.utc(data?.arrival_date).toISOString().split('T')[0]
                }
              />
              <InputTime
                label="Arrival Time"
                name="arrival_time"
                value={data?.arrival_time}
              />
            </div>

            <Input
              label="Update"
              name="a_update"
              id="a_update"
              type="checkbox"
             
            />
          </div>
          <div className="flex gap-3">
            <div className="w-[300px] flex gap-3">
              <Input label="Arrival City" name="arrival_city" value={data?.arrival_city} />
            </div>
            <Input
              label="Update"
              type="checkbox"
              name="a_city_update"
              id="a_city_update"
              
            />
          </div>
          <div className="flex gap-3">
            <div className="w-[300px] flex gap-3">
              <InputDate
                label="Departure Date"
                name="departure_date"
                defaultValue={
                  data?.departure_date &&
                  dayjs.utc(data?.departure_date).toISOString().split('T')[0]
                }
              />
              <InputTime
                label="Departure Time"
                name="departure_time"
                value={data?.departure_time}
              />
            </div>
            <Input
              label="Update"
              type="checkbox"
              name="d_update"
              id="d_update"
            />
          </div>
          <div className="flex gap-3">
            <div className="w-[300px] flex gap-3">
              <Input
                label="Departure City"
                name="departure_city"
                value={data?.departure_city}
              />
            </div>
            <Input
              label="Update"
              name="d_city_update"
              id="d_city_update"
              type="checkbox"
             
            />
          </div>
        </div>
        <div>
          <PrimaryButton
            type="button"
            onClick={() => submit()}
            label={"Update Now"}
            className={"px-3 py-2 mt-5"}
          />
        </div>
      </form>
    </div>
  );
}
