/* eslint-disable react/prop-types */
import React, {useState} from "react"


export default function SecondaryTab({tabs}) {
 
    const [selectedTab, setTab] = useState(0)
  
  return (
    <div className="w-full">
      <div className="flex gap-2">
        {tabs.map((tab, i) => {
          return (
        <div className={`text-[14px] p-2 cursor-pointer font-semibold ${selectedTab == i ? 'text-indigo-600 border-b-2 border-indigo-600 ' : ''}`} key={i} onClick={() => setTab(i)}>
              {tab?.label}
            </div>
          );
        })}
      </div>
      <div>{tabs[selectedTab]?.tab}</div>
    </div>
  );
}
