import React, { useState } from "react";
import InputDate from "../../components/inputs/date";
import PrimaryButton from "../../components/buttons/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { DynamicTable } from "../../components/table/tableWithTotal";

import { get, post } from "../../services";

import { toast, Bounce } from "react-toastify";
import Loader from "../../components/loader";

export default function EnterCheckMailedDate() {
  const [checkDate, setCheckDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mailedDate, setMailedDate] = useState("");

  const columns = [
    {
      field: "reviewer",
      headerName: "Reviewer",
      searchable: true,
      width: 100,
      cellRenderer: (col, item) => {
        var name = "";
        if (item.FName) {
          name += item.FName;
        }
        if (item.LName) {
          name += " " + item.LName;
        }
        return <div>{name}</div>;
      },
    },
    {
      field: "trip_id",
      headerName: "Trip ID",
      searchable: true,
      width: 50
    },
    {
      field: "Name",
      headerName: "Grantee Name",
      searchable: true
    },
    {
      field: "check_amount",
      headerName: "Check Amount",
      amount: true,
      searchable: true,
      width: 50
    },
    {
      field: "check_mail_date",
      headerName: "Mailed On",
      type: "date",
      edit: true,
      searchable: true,
      width: 50
    },
  ];

  const searchData = () => {
    if (checkDate) {
      setLoading(true);
      get(`expenseInquiry/checkMailDateDisplayData?checkDate=${checkDate}`)
        .then((res) => {
          if (res && res.length > 0) {
            setData(res);
          }else{
            setData([])
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setData([])
        });
    } else {
      toast.error("Enter Check Date", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const changeMailedDate = () => {
    if (mailedDate) {
      const getExistingRecords = [...data]
      setData([]);
      setTimeout(() => {
        setData(
          getExistingRecords.map((d) => {
            return { ...d, check_mail_date: mailedDate };
        })
        );
      },0)
    } else {
      toast.error("Enter Mailed Date", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  console.log(checkDate);

  const update = async() => {
    setLoading(true)
    const responses = await Promise.all(
        data.map(async (d) => {

          var tempData = {
            "tripDetailsUid": d.expense_header_uid,
            "CheckMailedDate": d.check_mail_date
        }
      
        
          const response = await post('ter/terCheckMailedUpdateCheckMailed', tempData);
          console.log(response, "resss");
          if (response) {
            return response;
          } else {
            return response;
          }
        })
      );
      if(responses.length > 0) {
        setLoading(false)
        toast.success("Updated The Items Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        // window.location.reload()
      }else{
        setLoading(false)
        toast.error("Update Items Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
  }

  return (
    <div className="p-5 text-left bg-white shadow-md">
      <TitlePrimary title={"Enter Check Mailed Date"} />
      {loading && <Loader />}
      <div className="flex flex-col gap-6 mt-3">
        <div className="flex gap-3">
          <div>
            <InputDate
              label="Check Date"
              onChange={(e) => setCheckDate(e.target.value)}
            />
          </div>
          <div className="mt-auto">
            <PrimaryButton
              onClick={() => searchData()}
              label="Display Data "
              className="p-2"
            />
          </div>
          <div className="mt-auto">
            {/* <PrimaryButton label="Generate Thank You Letter" className="p-2" /> */}
          </div>
        </div>

        <div>
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
          <DynamicTable columns={columns} data={data} />
        </div>

        <div className="flex gap-3">
          <div>
            <InputDate
              label="Enter Mailed Date"
              onChange={(e) => setMailedDate(e.target.value)}
            />
          </div>
          <div className="mt-auto">
            <PrimaryButton
              label="Apply All "
              className="p-2"
              onClick={() => changeMailedDate()}
            />
          </div>
        </div>
        <div className="mt-auto">
          <PrimaryButton label="Update " className="p-2" onClick={() => update()} />
        </div>
      </div>
    </div>
  );
}
