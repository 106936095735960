import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
import InputDate from "../../components/inputs/date";
// import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useNavigate } from "react-router-dom";
import TitlePrimary from "../../components/typhography/title-primary";
import { DynamicTable } from "../../components/table/tableWithTotal";
// import Select from '../../components/inputs/select';
import MultiSelect from "../../components/inputs/multi-select";

import { get, post } from "../../services";
import Loader from "../../components/loader";

import { toast, Bounce } from "react-toastify";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// import dayjs from "dayjs";

export default function TER() {
  const navigate = useNavigate();
  // const [barcodes, setBarcodes] = useState([""]);

  const [data, setData] = useState([]);
  const [reviewers, setReviewers] = useState({});
  const [selectedReviewer, setSelectReviewer] = useState({});
  const [revieweID, setReviewID] = useState({});
  const [terDate, setTERDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tempSelectedReviwers = Object.values(selectedReviewer).flat()

        const responses = await Promise.all(
          
          tempSelectedReviwers.map(async (d) => {
            const secondPart = d.split("-");

            var url = `ter/travelExpenseReportReceivedDateTerViewByTripDetailsUid?tripDetailsUid=${secondPart[1]}`;

            const response = await get(url);
            if (response && response.length > 0) {
              return response[0];
            } else {
              return response;
            }
          })
        );
        setData(
          responses.map((d) => {
            if (terDate) {
              //d.new_ter_date = terDate
              return { ...d, entered_new_ter_date: new Date(terDate) };
            } else {
              return { ...d };
            }
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    if (selectedReviewer && Object.keys(reviewers).length > 0) {
      setLoading(true);
      fetchData();
    }
  }, [selectedReviewer]);

  const handleRedirectToNotes = (val) => {
    navigate("/ter/notes/" + val);
  };

  const columns = [
    {
      field: "FName",
      headerName: "REVIEWER",
      cellRenderer: (col, item) => {
        var name = "";
        if (item.FName) {
          name += item.FName;
        }
        if (item.LName) {
          name += " " + item.LName;
        }
        return <div>{name}</div>;
      },
      searchable: true,
    },
    {
      field: "Name",
      headerName: "GRANTEE",
      searchable: true,
    },
    {
      field: "ter_date",
      headerName: "TER/NEW DATE",
      searchable: true,
      width: "200px",
      cellRenderer: (_, item) => {
        return (
          <div>
            <span className="text-blue-600 font-[500]">
              {item.ter_date && dayjs.utc(item.ter_date).format("MM-DD-YYYY")}
            </span>
            <span>{item.ter_date && item.new_ter_date && "/"}</span>
            <span className="text-red-600 font-[500]">
              {item.new_ter_date &&
                dayjs.utc(item.new_ter_date).format("MM-DD-YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      field: "entered_new_ter_date",
      headerName: "ENTER TER DATE",
      type: "date",
      edit: true,
      searchable: true,
    },
    {
      field: "reviewer",
      headerName: "Notes",
      cellRenderer: (_, item) => {
        return (
          <div
            onClick={() => handleRedirectToNotes(item.expense_header_uid)}
            className="text-indigo-600 cursor-pointer"
          >
            Notes
          </div>
        );
      },
    },
    {
      field: "isSelected",
      headerName: "NEW TER",
      type: "checkbox",
      edit: true,
    },
  ];

  // const handleChange = (e) => {
  //   if(e.target.value){
  //     getData(e.target.value)
  //     setSelectReviewer(e.target.value)
  //   }
  // }

  // const getData =  (val) =>{
  //   setData([])
  //   get(`ter/travelExpenseReportReceivedDateTerViewByTripDetailsUid?tripDetailsUid=${val}`).then(res => {
  //       if(res && res.length >0){
  // setData(res.map(d => {
  //   if(d.new_ter_date){
  //     return d
  //   }else if(terDate){
  //     //d.new_ter_date = terDate
  //     return {...d, new_ter_date: new Date(terDate)}
  //   }
  // }))
  //       }
  //   }).catch(err => {
  //     console.error(err)
  //   })
  // }

  const getCall = (event, i) => {

    const reviewIDTemp = [];

    var hasError = false;

    Object.keys(revieweID).map((key) => {
      if (reviewIDTemp.includes(revieweID[key])) {
        hasError = true;
      } else {
        reviewIDTemp.push(revieweID[key]);
      }
    });

    if (hasError) {
      toast.error("Duplicate Review ID found", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } else {
      if (terDate) {
        setReviewers((prev) => {
          return { ...prev, [i]: [] };
        });
        var tempItems = {...selectedReviewer};

        tempItems[i] = []
       

        setSelectReviewer({...tempItems});
        setLoading(true);
        setData([]);
        setSelectedItems({});
        get(`ter/travelExpenseReportReviewerList?tripId=${revieweID[i]}`)
          .then((res) => {
            if (res && res.length > 0) {
              setReviewers((prev) => {
                return {
                  ...prev,
                  [i]: res.map((d) => {
                    var temp = {};
                    temp.label = d.Reviewer;
                    temp.value = i + "-" + d.trip_details_uid;
                    return temp;
                  }),
                };
              });
              //getData(res[0].trip_details_uid)
              // setSelectReviewer(res[0].trip_details_uid)
            }
          })
          .catch((err) => {
            console.error(err);
          });
        setLoading(false);
      } else {
        toast.error("Enter TER Received Date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }
  };

  const handleKeyDown = (event, i) => {
    if (event.key == "Enter" && revieweID[i]) {
      getCall(event, i);
    }
  };

  // const addMore = () => {
  //   setBarcodes([...barcodes, ""]);
  // };

  // const removeOne = (indx) => {
  //   setBarcodes(barcodes.filter((_, i) => i != indx));
  // };

  const updateColumnData = (value, rowIndex, field) => {
    setSelectedItems({
      ...selectedItems,
      [rowIndex + "" + field]: value,
      ffff: "jsdhsjdh",
    });

    setData(
      data.map((d, i) => {
        if (i == rowIndex) {
          d[field] = value;
          return d;
        } else {
          return d;
        }
      })
    );
  };

  const submit = async () => {
    const responses = await Promise.all(
      data.map(async (d, i) => {
        var tempData = {
          expenseHeaderUid: d.expense_header_uid
        };

        if (selectedItems[i + "" + "isSelected"]) {
          tempData["newTerDate"] = selectedItems[
            i + "" + "entered_new_ter_date"
          ]
            ? selectedItems[i + "" + "entered_new_ter_date"]
            : terDate;
        } else {
          tempData["terDate"] = selectedItems[i + "" + "entered_new_ter_date"]
            ? selectedItems[i + "" + "entered_new_ter_date"]
            : terDate;
        }

        const response = await post(
          "ter/travelExpenseReportReceivedDateUpdateDate",
          tempData
        );
        if (response) {
          return response;
        } else {
          return response;
        }
      })
    );
    if (responses.length > 0) {
      setLoading(false);
      toast.success("Updated The Items Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      // window.location.reload()
    } else {
      setLoading(false);
      toast.error("Update Items Failed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const handleSelectedReviewer = (rowIndex, items) => {
    setSelectReviewer((prev) => ({
      ...prev,
      [rowIndex]: items,
    }));
  };

  return (
    <div className="p-5 text-left bg-white shadow-md">
      <TitlePrimary title={"Enter TER Received Date"} />
      {loading && <Loader />}
      <div className="flex flex-col gap-6 mt-10">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <div className="w-[200px]">
              <InputDate
                label="TER Received Date"
                onChange={(e) => setTERDate(e.target.value)}
              />
            </div>
          </div>
          {[...Array(5)].map((_, i) => {
            return (
              <div key={i} className="flex gap-3">
                <div className="w-[200px]">
                  <Input
                    label={i==0 ? "Review ID":""}
                    onChange={(e) =>
                      setReviewID((prev) => ({
                        ...prev,
                        [`review`+i]: e.target.value,
                      }))
                    }
                    onKeyDown={(e) => handleKeyDown(e, "review"+i)}
                    onBlur={(e) => getCall(e, "review"+i)}
                  />
                </div>
                <div className="w-[450px]">
                  <MultiSelect
                    label={ i ==0 ?
                      "Select Reviewers" : ""
                    }
                    options={(reviewers && reviewers["review"+i]) || []}
                    selectedValues={selectedReviewer[i] || []}
                    onChange={(items) => handleSelectedReviewer(i, items)}
                    placeholder={"Select Reviewers"}
                    multiRows={true}
                    multiRowId ={i}
                  />
                </div>

                <div className="mt-auto mb-[10px]">
                {
                  reviewers &&
                  reviewers["review"+i]  && reviewers["review"+i].length >0 && <div className="w-[25px] h-[25px] p-1 rounded-full flex items-center justify-center bg-indigo-700 text-white">{reviewers["review"+i].length}</div>
                }
                </div>
              </div>
            );
          })}
          
        </div>
        <div>
          <DynamicTable
            columns={columns}
            data={data}
            updateColumnData={updateColumnData}
          />
        </div>
        <div>
          <div className="w[200px]">
            <PrimaryButton
              label="Update"
              className={"px-3 py-2"}
              onClick={() => submit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}






