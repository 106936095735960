import React from "react";
import TextWithLabel from "../../../../inputs/text";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import MultiText from "../../../../inputs/multi-text";
import TextPhone from "../../../../inputs/phone-text";

export default function TravelerInfo({details}) {
  return (
    <div className="px-7 py-2 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="SSN" value={details?.SSN} />
        </div>
        <div className="w-[200px]">
          <MultiText label="Traveler" seperator=" " value={['Title','FName', 'lname', 'MInitial', 'suffix']} data={details}/>
        </div>
        <div className="w-[400px]">
          <MultiText
            label="Traveler's Address"
            seperator="," value={['HomeAddress1','HomeCity', 'HomeState', 'HomeZip']} data={details}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextPhone label="Home" value={details?.HomePhone} />
        </div>
        <div className="w-[200px]">
          <TextPhone label="Work Phone" value={details?.WorkPhone} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Request" value={details.Request_Date? dayjs.utc(details.Request_Date).format("MM/DD/YYYY") : ''} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Check Needed" value={details.Check_Needed? dayjs.utc(details.Check_Needed).format("MM/DD/YYYY") : ''}/>
        </div>
      </div>
    </div>
  );
}
