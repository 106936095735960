import React, { useState } from "react";
// import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/hotel-reconciliation/completed-results";
import InputDate from "../../components/inputs/date";
import Select from "../../components/inputs/select";


import { toast, Bounce } from "react-toastify";

export default function HotelReconciliationCompleted() {

  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [displayBy, setDisplayBy] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData && formData.display_by) {


      setDisplayBy(formData?.display_by)
      console.log(formData);
      setData([]);
      var url = "hotelReconsile/hotelReconsileCompletedReview";
      if (formData && formData.display_by && formData.display_by === 'reviewer') {
        url = "hotelReconsile/hotelReconsileCompletedReviewer";
      }
      if (!formData['region']) {
        formData['region'] = 0
      }
      Object.keys(formData).forEach((key, i) => {
        //if(key !== 'display_by'){
        if (i === 0) {
          url += `?${key}=${formData[key]}`;
        } else {
          url += `&${key}=${formData[key]}`;
          // }
        }

      });
      get(url)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error('Select any Display By', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };


  return (
    <div className="py-10 text-left">
      <TitlePrimary title={`Completed List`} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 mt-10"
      >




        <div className="flex gap-6">
          <div className="flex gap-6">
            <InputDate label="From Date" name="fromDate" id="fromDate" />
            <InputDate label="To Date" name="toDate" id="toDate" />
            <div className="w-[200px]">
              <Select label="Display By" name="display_by" id="display_by" options={[{ label: "Select One", value: null }, { label: "Review", value: "review" }, { label: "Reviewer", value: "reviewer" }]} />

            </div>
            <div className="w-[100px]">
              <Select name="region" id="region" label="Region" options={[{ label: "All", value: 0 }, ...[...Array(13)].map((_, i) => {
                return { label: `${i + 1}`, value: i + 1 };
              })]} />
            </div>

          </div>

          <div className="flex gap-6">
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Display Now"
                className={"px-3 py-2 "}
              />
            </div>

          </div>
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
      {
          displayBy &&  <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
        }
        <SearchResults data={data} display={displayBy}/>
      </div>
    </div>
  );
}
