import React from "react";
import Table from "../table/table";
export default function RRCChangeLogs({data}) {

  const columns = [
    {
      field:'create_ts',
      headerName:'Date',
      type:'date'
    },
    {
      field:'reason',
      headerName:'Reason'
    },
    {
      field:'reason_type',
      headerName:'Description '
    },
    {
      field:'userName',
      headerName:'Entered By'
    }
]

  return (
    <Table columns={columns} data={data}  />
  );
}
