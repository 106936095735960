/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types'


const MultiText = ({ label,value,data, className, seperator }) => {
  var tempValue = "";

  if(value && value.length >0){
    value.forEach((v, i) => {
      if(i == 0){
        tempValue = data[v] ? data[v] : ''
      }else {
        if(data[v]){
          tempValue += ((tempValue ? seperator : '') + data[v])
        }
        
      }
    })
  }else{
    tempValue = value
  }

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}
      {
        tempValue ? <p className="font-semibold text-[14px]" dangerouslySetInnerHTML={{__html:tempValue}}></p> : <></>
      }
      
    </div>
  );
};


MultiText.prototype = {
    value : PropTypes.string,
    label: PropTypes.string
}

export default MultiText;
