import React from 'react'
import { DynamicTable } from '../table/tableWithTotal';


export default function CashAdvanceStatus({data}) {
    const columns = [
        {
          headerName: "PROCESS DATE",
          field:"advance_processed",
          sortable: true,
          type:"date",
          time:true,
          width:200
        },
        {
          headerName: "CHECK DATE",
          field:"check_date",
          type:"date",
          // time:true,
          sortable: true,
        },
        {
          headerName: "CHECK #",
          field:"check_number",
          sortable: true
        },
        {
          headerName: "AMOUNT",
          field:"check_amount",
          sortable: true,
          amount:true
        },
        {
            headerName: "CASH ADV",
            field:"check_amount",
            sortable: true,
            amount:true
          },
          {
            headerName: "MAILED DATE",
            field:"packets_mailed",
            sortable: true,
            type:"date"
          },
      ];
    
  return (
    <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Cash Advance Status</h6>
        <DynamicTable columns={columns} data={data} />
    </div>
  )
}
