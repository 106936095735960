import React, { useEffect, useState } from "react";
import PrimaryBox from "../../../box/box-primary";
import { useParams } from "react-router-dom";
import { get } from "../../../../services";
import TextWithLabel from "../../../inputs/text";
import UpdateTis from "./update-tis";
import UpdateHotel from "./update-hotel";
import DeleteHotel from "./delete-hotel";
import ReviewCancellation from "./review-cancellation";
import SecondaryTab from "../../../tabs/secondary";

export default function BatchUpdate() {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    get(`reviews/batch_update_review_details?trip_id=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setData(res[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleChange = (e) => {
    if(e.target.type == "checkbox"){
      setData(prev=>{
        return {...prev, [e.target.name]:  e.target.checked ? "Yes" : "No"}
      })

    }else if(e.target.name && e.target.value){
      setData(prev=>{
        return {...prev, [e.target.name]: e.target.value}
      })
     
    }else if(e.target.name){
      setData(prev=>{
        return {...prev, [e.target.name]: ""}
      }) 
    }
  }

  const tabs = [
    {
      label: "Update TIS Information",
      tab: (
        <div className="mt-3">
            <UpdateTis data={data} id={id}  handleChange={handleChange}/>
        </div>
      ),
    },
    {
      label: "Update Hotel Information",
      tab: (
        <div className="mt-3">
            <UpdateHotel  id={id} hotelData={data}/>
        </div>
      ),
    },
    {
      label: "Delete Hotel",
      tab: (
        <div className="mt-3">
            <DeleteHotel id={id} hotelData={data}/>
        </div>
      ),
    },
    {
      label: "Review Cancellation",
      tab: (
        <div className="mt-3">
            <ReviewCancellation  id={id} hotelData={data}/>
        </div>
      ),
    }
  ]

  var softwareId = "";

  if (data && data.grantee_name) {
    softwareId = data.grantee_name;
  }

  if (data && data.trip_id && !softwareId.includes(data.trip_id)) {
    softwareId += data.trip_id;
  }

  return (
    <PrimaryBox>
      <div className="flex flex-col gap-3 mt-5 text-left">
        <div className="flex gap-3">
          <TextWithLabel
            label="Grantee Name/Review"
            value={softwareId}
          />
          <TextWithLabel label="Review Dates" value={data?.review_date} />
        </div>
        <div className="mt-5">
          <SecondaryTab tabs={tabs} />
        </div>
      </div>
    </PrimaryBox>
  );
}
