import React, { useState, useEffect, useRef } from "react";
const MultiSelect = ({
  options,
  selectedValues,
  onChange,
  placeholder,
  label,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const handleOptionClick = (value) => {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((item) => item !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSelectAll = () => {
    if (isAllSelected()) {
      onChange([]); // Deselect all
    } else {
      onChange(options.map((option) => option.value)); // Select all
    }
  };
  const isAllSelected = () => {
    return selectedValues && options && selectedValues.length === options.length;
  };
  const filteredOptions = options.filter((option) =>
    option?.label?.toLowerCase().includes(search?.toLowerCase())
  );
  const selectedLabels = selectedValues && selectedValues.length >0 ? selectedValues
    .map((value) => options.find((option) => option.value === value)?.label)
    .filter(Boolean) : [];
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={dropdownRef}
      className="relative  flex flex-col gap-3 text-left w-[450px]"
    >
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
          {required && <span className="text-red-600"> *</span>}
        </label>
      ) : (
        <></>
      )}
      <div
        role="button"
        className="border border-gray-300 rounded-md px-4 py-2
cursor-pointer flex items-center justify-between"
        onClick={toggleDropdown}
        tabIndex={"0"}
        onKeyDown={(e) => e.key == "Enter" && toggleDropdown()}
      >
        <span
          className="text-gray-600 truncate max-w-full"
          style={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {selectedLabels.length > 0
            ? selectedLabels.join(", ")
            : placeholder || "Select options"}
        </span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isOpen && (
        <div
          className="absolute z-10 w-full bg-white border
border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg"
        >
          <input
            type="text"
            className="w-full px-4 py-2 border-b border-gray-300
focus:outline-none"
            placeholder="Search..."
            value={search}
            onChange={handleSearch}
          />
          <ul className="py-2">
            <li
              className={`px-4 text-sm py-2 cursor-pointer hover:bg-gray-100 ${
                isAllSelected() ? "bg-gray-100" : ""
              }`}
              onClick={handleSelectAll}
            >
              <input
                type="checkbox"
                checked={isAllSelected()}
                onChange={handleSelectAll}
                className="mr-2"
              />
              Select All
            </li>

            {filteredOptions.map(({ label, value }) => (
              <li
                key={value}
                className={`px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 ${
                  selectedValues && selectedValues.length>0 && selectedValues.includes(value) ? "bg-gray-100" : ""
                }`}
                onClick={() => handleOptionClick(value)}
              >
                <input
                  type="checkbox"
                  checked={selectedValues && selectedValues.length>0 && selectedValues.includes(value)}
                  onChange={() => handleOptionClick(value)}
                  className="mr-2"
                />
                {label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default MultiSelect;
