import React from "react";
import Table from "../table/table.jsx";
import { DynamicTable } from "../table/tableWithTotal.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const CompletedSearchResults = ({ data, display }) => {
  const columns = [
    {
      field: "reviewer",
      headerName: "Reviewer",
    },
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
    },
    {
      field: "Name",
      headerName: "Hotel Names",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
    },
    {
      field: "actual_amount",
      headerName: "Actual",
    },
    {
      field: "variance",
      headerName: "Variance",
    },
    {
      field: "",
      headerName: "Recn.On",
    },
    {
      field: "",
      headerName: "Recn.By",
    },
  ];
  const columns2 = [
   
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
    },
    {
      field: "Name",
      headerName: "Hotel Names",
      totalLabel:"Review Total"
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate:"sum"
    },
    {
      field: "actual_amount",
      headerName: "Actual",
      aggregate:"sum"
    },
    {
      field: "variance",
      headerName: "Variance",
      aggregate:"sum"
    },
    {
      field: "recn_on",
      headerName: "Recn.On",
    },
    {
      field: "recn.by",
      headerName: "Recn.By",
    },
  ];
 //data = tempData;

  if(display && display == 'review'){
    return <DynamicTable columns={columns2} data={data} groupByField="trip_id"/> 
  }else if(display && display == 'reviewer'){
    return <Table columns={columns} data={data} />; 
  }else{
    return ''
  }
  
};

export default CompletedSearchResults;
