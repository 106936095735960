import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "../../../inputs/select";
import AccordianPrimary from "../../../accordian/primary";
import TravelerInfo from "./tabs/traveler-info";
import TripInfo from "./tabs/trip-info";
import Invoice from "./tabs/invoice";
import Honoraria from "./tabs/honoraria";
// import HotelExpenses from "./tabs/hotel-expenses";
// import CarExpenses from "./tabs/car-expenses";
// import MiscellaneousExpense from "./tabs/miscellaneous-expense";
import CashAdvanceDetails from "./tabs/cash-advance";
import TERDetails from "./tabs/ter-details";
import TextWithLabel from "../../../inputs/text";
import PrimaryButton from "../../../buttons/primary";
import AccordianAction from "../../../accordian/accordian-action";
import { get } from "../../../../services";

export default function ProcessTER() {
  const { id } = useParams();
  const [accordianAction, setAccordianAction] = useState("expandAll");
  const [reviewerList, setReviewerList] = useState([])
  const [reviewerDetails, setReviewerDetails] = useState({})
  const [selectedReviewer, setSelectReviewer] = useState(null)

  useEffect(() => {
    get(`ter/terProcessReviewerList?tripId=${id}`).then(res => {
      if(res && res.length >0) {
        setReviewerList(res.map(d => {
          return {
            label : d.rev_name,
            value: d.trip_details_uid
          }
        }))
        getDetails(res[0].trip_details_uid)
        setSelectReviewer(res[0].trip_details_uid)
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const getDetails = (trip_details) => {
    get(`ter/terProcessHeaderDetails?tripDetailsUid=${trip_details}`).then(res => {
      if(res && res.length >0) {
       console.log(res)
       setReviewerDetails(res[0])
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const handleChange = (e) => {
    e.preventDefault()

    if(e.target && e.target.value){
      setSelectReviewer(e.target.value)
      getDetails(e.target.value)
    }
  }
  

  const accordians = [
    {
      label: "Traveler Info",
      content: (
        <div>
          <TravelerInfo details={reviewerDetails} selectedReviewer={selectedReviewer}/>
        </div>
      ),
    },
    {
      label: "Trip Info",
      content: (
        <div>
          <TripInfo  details={reviewerDetails}/>
        </div>
      ),
    },
    {
      label: "Invoice",
      content: (
        <div>
          <Invoice details={reviewerDetails}/>
        </div>
      ),
    },
    {
      label: "Expenses",
      content: (
        <div>
          <Honoraria />
        </div>
      ),
    },
    // {
    //   label: "Hotel Expenses",
    //   content: (
    //     <div>
    //       <HotelExpenses />
    //     </div>
    //   ),
    // },
    // {
    //   label: "Car Expenses",
    //   content: (
    //     <div>
    //       <CarExpenses id={selectedReviewer}/>
    //     </div>
    //   ),
    // },
    // {
    //   label: "Miscellaneous Expense",
    //   content: (
    //     <div>
    //       <MiscellaneousExpense />
    //     </div>
    //   ),
    // },
    {
      label: "Cash Advance Details",
      content: (
        <div>
          <CashAdvanceDetails id={selectedReviewer}/>
        </div>
      ),
    },
    {
      label: "TER Details",
      content: (
        <div>
          <TERDetails id={selectedReviewer}/>
        </div>
      ),
    },
  ];
  return (
    <div className="py-5 text-left">
      <div className="mb-2 flex">
        <h5 className="font-semibold text-xl">Process TER</h5>
        <div className="ml-auto">
            <AccordianAction accordianAction={accordianAction} handleAccordian={(d) => setAccordianAction(d)} />
          </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-[548px]">
          <Select label="Reviewer" name="" id="" options={reviewerList} onChange={(e) => handleChange(e)}/>
        </div>
        <div className="border rounded bg-white border-[#E5E7EB] p-2">
          <AccordianPrimary accordians={accordians} accordianAction={accordianAction}/>
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
        <div className="flex flex-col gap-2">
          <div className="flex w-full">
            <div>
              <h6 className="font-semibold text-[14px]">Totals</h6>
            </div>
            <div className="ml-auto flex gap-4">
              <div className="w-[200px]">
                <TextWithLabel label="ENCUMBRANCE" value="$2,337.90" />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="CASH ADVANCE" value="$0.00" />
              </div>
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <Select label="Process Status" options={[]} />
            </div>
            <div className="mt-auto">
              <PrimaryButton label="Update" className={"px-4 py-3"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
