import React, { useState, useEffect } from "react";
import Table from "../../components/table/table.jsx";
import { get } from "../../services/index.js";
import PrimaryModal from "../../components/modals/primary.jsx";
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EmailTemplates = () => {
  const [data, setData] = useState([])
  const [modalId, setModalid] = useState()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    get(`reviews/tis-email-templates`).then(res => {
      if (res && res.length > 0) {
        setData(res)
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const handleView = (id) => {
    setModalid(id)
    const filteredTemplates = data.filter(d => d.PayProcEmailTemplate_uid == id)
    console.log("filtered templated", filteredTemplates[0]);
    const blocksFromHTML = convertFromHTML(filteredTemplates[0].emailTemplate);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setEditorState(EditorState.createWithContent(content))
  }



  const columns = [

    {
      field: "memberType",
      headerName: "Member Type",
    },
    {
      field: "emailTemplate",
      headerName: "Action",
      cellRenderer: ({ data }) => {
        return <button onClick={() => handleView(data.PayProcEmailTemplate_uid)}>View</button>
      }
    }
  ];

  const template = data.filter(d => d.PayProcEmailTemplate_uid == modalId)
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  return <div>

    <Table columns={columns} data={data} />
    <PrimaryModal isOpened={modalId ? true : false} handleModal={() => setModalid(null)}>
      {
        template && template.length > 0 && template[0].emailTemplate ? <div style={{
          padding: '50px',
          maxHeight: '90vh',
          overflowY: 'scroll'
        }}>
          {/* <div dangerouslySetInnerHTML={{ __html: template[0].emailTemplate }} /> */}
          {editorState && <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />}

        </div> : <></>
      }
    </PrimaryModal>
  </div>;
};

export default EmailTemplates;
