import React, { useState } from "react";
import Input from "../../components/inputs/input";
import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useNavigate } from "react-router-dom";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/profile-maintenance/search-results";
import { toast, Bounce } from "react-toastify";


export default function ProfileMaintenance() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setData([]);
    var url = "user/search";
    Object.keys(formData).forEach((key, i) => {
      if (i === 0) {
        url += `?${key}=${formData[key]}`;
      } else {
        url += `&${key}=${formData[key]}`;
      }
    });
    get(url)
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          // setFormData({})
          setData(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const addNew = () => {
    if(formData && formData.userId){
      navigate(`/profile-maintenance/add-new/${formData.userId}`)
    }else{
      toast.error('Please enter user ID', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }

  return (
    <div className="py-10 text-left">
      <TitlePrimary title={"Search User"} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 mt-10"
      >
        <h6>Search Option</h6>
        <div className="w-[200px]">
          <Input
            label="User ID"
            id="userId"
            name="userId"
            value={formData.userId ? formData.userId : ""}
            disabled={formData.firstName || formData.lastName}
          />
        </div>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input
              label="First Name"
              id="firstName"
              name="firstName"
              value={formData.firstName ? formData.firstName : ""}
              disabled={formData.userId}
            />
          </div>
          <div className="w-[200px] mt-auto">
            <Input
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formData.lastName ? formData.lastName : ""}
              disabled={formData.userId}
            />
          </div>
        </div>

        <div className="flex gap-6">
          <div className="w[200px]">
            <PrimaryButton
              type="submit"
              label="Search Now"
              className={"px-3 py-2"}
            />
          </div>
          <div className="w[200px]">
            <TertiaryButton
              type="button"
              label="Add New"
              className={"px-3 py-2"}
              onClick={() => addNew()}
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
        <SearchResults data={data} />
      </div>
    </div>
  );
}
