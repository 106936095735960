import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
import Select from "../../components/inputs/select";
import PrimaryButton from "../../components/buttons/primary";

import HotelDetailsEdit from "../../components/reviews/hotel-view/edit";
import HotelDetailsView from "../../components/reviews/hotel-view/view";
import SecondaryButton from "../../components/buttons/secondary";
import Input from "../../components/inputs/input";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get, post } from "../../services";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import TertiaryButton from "../../components/buttons/tertiary";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { toast, Bounce } from 'react-toastify';
import TelePhone from '../../components/inputs/telephone';
import { useStore } from "../../store";
import Loader from "../../components/loader";
import { setCancel } from "../../store/actions";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

export default function ViewHotelDetails() {

  const { ssoUserID, dispatch } = useStore()
  const { id, hotelID, mode } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hotelDetails, setHotelDetails] = useState({});
  const [viewHotel, setViewHotel] = useState({});
  const [isEditEnabled, setEditEnabled] = useState(
    mode && mode === "edit" ? true : false
  );
  const [isAddNewEnabled, setAddNewEnabled] = useState(false);
  const [hotelsAdded, setHotelsAdded] = useState([]);

  const [hotelSearchedList, setHotelSearchedList] = useState([]);

  const [selectedHotelsAdded, setSelectHotelAdded] = useState(null);
  const [selectedHotelSearch, setSelectHotelSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isSearchHotelSelected, setSelectSearchHotel] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);


  const handleCheckboxChange = (event, value) => {
    const checked = event.target.checked;

    // Update hotelDetails state
    setHotelDetails((prevDetails) => ({
      ...prevDetails,
      [value]: checked ? "Yes" : "No", // Update the state based on the checkbox
    }));
  };

  useEffect(() => {
    if (hotelID && hotelID == "new") {
      setEditEnabled(true);
      setHotelDetails({});
      setAddNewEnabled(true);
      setSearchData(() => {
        return {};
      });
    }
  }, []);

  useEffect(() => {
    if (hotelID != "new") {
      get(`reviews/get_assigned_hotels?ReviewId=${id}`)
        .then((res) => {
          if (res && res.length > 0) {
            setHotelsAdded(
              res.map((s) => {
                var temp = {};
                temp["label"] = s.HotelDesc;
                temp["value"] = s.trip_hotel_uid;
                return { ...temp, ...s };
              })
            )
            const filterCurrentHotel = res.filter(d => d.hotel_uid == hotelID);

            if (filterCurrentHotel && filterCurrentHotel.length > 0) {
              setSelectHotelAdded(filterCurrentHotel[0].trip_hotel_uid)
              getHotels(filterCurrentHotel[0])
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const getHotels = async (currentHotel) => {
    if (currentHotel && currentHotel.hotel_uid) {
      await get(`reviews/hotel_detail?hotel_uid=${currentHotel.hotel_uid}`)
        .then((res) => {
          console.log(res, "res");
          if (res && res.length > 0) {
            setHotelDetails(res[0])
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (currentHotel && currentHotel.trip_hotel_uid) {
      await get(`reviews/view_hotel?trip_hotel_uid=${currentHotel.trip_hotel_uid}`)
        .then((res) => {
          if (res && res.length > 0) {
            console.log(res[0], 'res000')
           res.map(d => {
              return {...d, ...getDays(d)}
            })
           // console.log(test)
            setViewHotel(res[0])
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setLoading(false)
  }
  const getDays = (item) => {
    var MIDays = ""
    var noofnights = ""
    if(item.firstnight && item.lastnight){
      if(!item.MIDays){
        MIDays = dayjs(item.lastnight) .diff(dayjs(item.firstnight), 'day') + 1.5;
      }else{
        MIDays = item.MIDays
      }
      if(!item.noofnights){
        noofnights = dayjs(item.lastnight) .diff(dayjs(item.firstnight), 'day') + 1
      }else{
        noofnights = item.noofnights
      }
    }
    return {MIDays, noofnights}
  }

  const changeHotelSearch = (e) => {
    if (e && e.target.value) {
      setSelectHotelSearch(e.target.value)
    }
  };

  const changeHotelAdded = (e) => {
    setLoading(true)
    if (e && e.target.value) {
      setAddNewEnabled(false);
      setEditEnabled(false)
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == e.target.value);
      if (filterCurrentHotel && filterCurrentHotel.length > 0) {
        setSelectHotelAdded(filterCurrentHotel[0].trip_hotel_uid)
        getHotels(filterCurrentHotel[0])
      }
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.name && e.target.value) {
      if (e.target.type === "time") {
        const value = e.target.value;
        const splitTime = value.split(":");
        console.log(splitTime, "splitTime");
        setSearchData((prev) => {
          return {
            ...prev,
            [e.target.name]: dayjs.utc(prev[e.target.name])
              .set("hour", splitTime[0])
              .set("minute", splitTime[1])
              .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          };
        });
      } else {
        setSearchData((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target.name) {
      setSearchData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const onSearch = (e) => {
    if (e) {
      e.preventDefault();
      const formEntrries = searchData;
      var query = `review_id=${id}`;

      if (formEntrries.city) {
        query += `&city=${formEntrries.city}`;
      }
      if (formEntrries.zip) {
        query += `&zip=${formEntrries.zip}`;
      }

      if (formEntrries.phone) {
        query += `&phone=${formEntrries.phone}`;
      }

      get(`reviews/search_hotels?${query}`)
        .then((res) => {
          if (res && res.length > 0) {
            setHotelSearchedList(
              res.map((s) => {
                var temp = {};
                temp["label"] = s.HotelDescription;
                temp["value"] = s.hotel_uid;
                return temp;
              })
            );
            setSelectHotelSearch(res[0].hotel_uid)
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const addNew = () => {
    setEditEnabled(false);
    setHotelDetails(() => {
      return {};
    });
    setViewHotel(() => {
      return {};
    });
    setAddNewEnabled(true);
    setSearchData(() => {
      return {};
    });
    setSelectSearchHotel(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const URI_CREATE_HOTEL = isAddNewEnabled
      ? "reviews/create_hotel"
      : "reviews/update_hotel";
    const URI_CREATE_HOTEL_REVIEW =
      isAddNewEnabled || isSearchHotelSelected
        ? "reviews/create_hotel_review"
        : "reviews/update_hotel_info";

    var hotelReq = {
      Name: hotelDetails?.Name,
      Address: hotelDetails?.Address,
      address2: hotelDetails?.Address,
      City: hotelDetails?.City,
      State: hotelDetails?.State,
      County: hotelDetails?.County,
      Zip: hotelDetails?.Zip,
      Phone: hotelDetails.Phone ?  hotelDetails.Phone.replace(/[^\d]/g, "") : '',
      fax: hotelDetails.fax ?  hotelDetails.fax.replace(/[^\d]/g, "") : '',
      UserId: ssoUserID,
    };

    if (!isAddNewEnabled && !isSearchHotelSelected) {
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == selectedHotelsAdded);
      hotelReq["Hotel_uid"] = filterCurrentHotel[0].hotel_uid;
    }

    var reviewRequest = { ...viewHotel, ...getDays(viewHotel) };
    console.log("reviewRequest", reviewRequest, viewHotel);
    if (isAddNewEnabled || isSearchHotelSelected) {
      reviewRequest["trip_id"] = id
    } else {
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == selectedHotelsAdded);
      reviewRequest["trip_hotel_uid"] = filterCurrentHotel[0].trip_hotel_uid
      if (reviewRequest && reviewRequest.hotel_uid) {
        delete reviewRequest.hotel_uid;
      }
      if (reviewRequest && reviewRequest.trip_uid) {
        delete reviewRequest.trip_uid;
      }
    }

    const reviewInfoMap = {
      Lodging: 'Conus Lodging',
      Meals: 'Meals & Incidentals',
     // MIDays: 'M&I Days',
      meeting_room: 'Meeting Room',
      Booked_rate: 'Booked Rate',
      taxes: 'Taxes',
     // noofnights: 'No of Nights',
      NoRooms: 'Number of Rooms',
      Name: 'Hotel Name',
      Address: 'Hotel Address 1',
      City: 'Hotel City',
      State: 'Hotel State',
      County: 'Hotel Country',
      Zip: 'Hotel Zip',
      Phone: 'Hotel Phone',
    }
    const errorFields = {}
    let errorExists = false;
    console.log("enters to this case", isEditEnabled, isAddNewEnabled);
    if (isAddNewEnabled || isEditEnabled) {

      Object.keys(reviewInfoMap).map(item => {
        const requests ={...reviewRequest, ...hotelReq}
        console.log("coming 1st map", item, reviewRequest);
        if (!requests[item]) {
          errorExists = true;
          errorFields[item] = `${reviewInfoMap[item]} is required`;
        }
        if (!errorExists && item === 'Lodging' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
          }
          else {
            if (reviewRequest['meeting-room'] === "") {
              reviewRequest['meeting-room'] = 0;
            }
            if (reviewRequest['meeting-room'] === 0) {
              if (reviewRequest[item] < 1) {
                reviewRequest[item] = ""
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            } else {
              if (reviewRequest[item] < 0) {
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            }
          }
        }
        if (!errorExists && item === 'Meals' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
          }
          else {
            if (reviewRequest['meeting-room'] === "") {
              reviewRequest['meeting-room'] = 0;
            }
            if (reviewRequest['meeting-room'] === 0) {
              if (reviewRequest[item] < 1) {
                reviewRequest[item] = ""
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            } else {
              if (reviewRequest[item] < 0) {
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            }
          }
        }
        if (!errorExists && item === 'Booked_rate' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
          }
          else {
            if (reviewRequest['meeting-room'] === "") {
              reviewRequest['meeting-room'] = 0;
            }
            if (reviewRequest['meeting-room'] === 0) {
              if (reviewRequest[item] < 1) {
                reviewRequest[item] = ""
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            } else {
              if (reviewRequest[item] < 0) {
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            }
          }
        }
        if (!errorExists && item === 'taxes' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
          }
          else {
            if (reviewRequest['meeting-room'] === "") {
              reviewRequest['meeting-room'] = 0;
            }
            if (reviewRequest['meeting-room'] === 0) {
              if (reviewRequest[item] < 1) {
                reviewRequest[item] = ""
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
              else {
                if (reviewRequest[item] < 0) {
                  errorExists = true;
                  errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
                }
              }
            }
            if (!errorExists && reviewRequest['Booked_rate']) {
              if (reviewRequest[item] < 0) {
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
            }
          }
        }
        if (!errorExists && item === 'NoRooms' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
          }
          else {
            if (reviewRequest['meeting-room'] === "") {
              reviewRequest['meeting-room'] = 0;
            }
            if (reviewRequest['meeting-room'] === 0) {
              if (reviewRequest[item] < 1) {
                reviewRequest[item] = ""
                errorExists = true;
                errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
              }
              else {
                if (reviewRequest[item] < 0) {
                  errorExists = true;
                  errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`;
                }
              }
            }
          }
        }
        if (!errorExists && ['taxes', 'Booked_rate', 'Meals', 'Loadging', 'meeting_room'].indexOf(item) !== -1 && reviewRequest[item] !== "") {
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`
          } else {
            if (!isNaN(reviewRequest[item])) {
              const splitTaxes = reviewRequest[item].toString().split(".");
              if (splitTaxes[1] > 0) {
                if (splitTaxes[1].length > 2) {
                  errorFields[item] = `${reviewInfoMap[item]} contains more than 2 decimals`
                }
              }
            } else {
              errorExists = true;
              errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`
            }
          }
        }
        if (!errorExists && item === 'MIDays' && reviewRequest[item]) {
          if (isNaN(reviewRequest[item])) {
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`
          }
        }
        if (!errorExists && ['noofnights', 'NoRooms'].indexOf(item) !== -1 && reviewRequest[item]) {
          console.log("coming here for check", item);
          if (isNaN(reviewRequest[item])) {
            errorExists = true;
            errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`
          } else {
            if (!isNaN(reviewRequest[item])) {
              if (reviewRequest[item].toString().split(".")[1] || reviewRequest[item].toString().split(",")[1]) {
                errorFields[item] = `${reviewInfoMap[item]} allowed only integers`
              }
            } else {
              errorExists = true;
              errorFields[item] = `${reviewInfoMap[item]} contains invalid characters`
            }
          }
        }
        if (!errorExists && !reviewRequest['firstnight']) {
          errorFields['firstnight'] = `First night date is required`
        }
        if (!errorExists && !reviewRequest['lastnight']) {
          errorFields['lastnight'] = `Last night date is required`
        }
        if (!errorExists && reviewRequest['firstnight'] && reviewRequest['lastnight']) {
          const date1 = new Date(reviewRequest['firstnight']);
          const date2 = new Date(reviewRequest['lastnight']);
          const diffTime = date2 - date1;
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          //const diffMonths = date2.getMonth() - date1.getMonth() + 12 * (date2.getFullYear() - date1.getFullYear());
          console.log("diff months", diffDays);
          if (diffDays < 0) {
            errorFields['firstnight'] = `Error - First Night Date should be prior to Last Night Date`
          }
        }
      })
    }
    console.log("final error fields", errorFields)
    if (Object.keys(errorFields).length > 0) {
      setErrorDetails(errorFields);
      return;
    }

    console.log(navigate)

    if (isAddNewEnabled) {
      var tempData = {
        name: hotelDetails?.Name,
        address: hotelDetails?.Address,
        city: hotelDetails?.City,
        zip: hotelDetails?.Zip,
        "userId": ssoUserID
      };
      post(`reviews/check_duplicate_hotel`, tempData)
        .then((res) => {
          console.log(res, 'ress')
          if (res === true) {
            toast.error('Hotel already exists. Try different one', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          } else if (res && !res.data) {
            post(URI_CREATE_HOTEL, {...hotelReq, "userId": ssoUserID})
              .then((res) => {
                console.log(res);
                if (res && res.length > 0) {
                  reviewRequest["hotel_uid"] = res[0].Hotel_uid
                  post(URI_CREATE_HOTEL_REVIEW, {...reviewRequest, "userId": ssoUserID})
                    .then((res) => {
                      console.log(res);
                      if (res) {
                        toast.success('Success', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                          transition: Bounce,
                        });
                        setTimeout(() => {
                          navigate(`/reviews/details/${id}`);
                        }, 200);
                        
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      toast.error('Create Failed', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                      });
                    });
                }
              })
              .catch((err) => {
                console.error(err);
                toast.error('Something went wrong', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  transition: Bounce,
                });
              });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    } else if (isSearchHotelSelected) {
      reviewRequest["hotel_uid"] = selectedHotelSearch
      post(URI_CREATE_HOTEL_REVIEW, {...reviewRequest, "userId": ssoUserID})
        .then((res) => {
          console.log(res);
          if (res) {
            toast.success('Success', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            setTimeout(() => {
              navigate(`/reviews/details/${id}`);
            }, 200);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    } else if (isEditEnabled) {
      post(URI_CREATE_HOTEL, {...hotelReq, "userId": ssoUserID})
        .then((res) => {
          console.log(res);
          if (res) {
            post(URI_CREATE_HOTEL_REVIEW, {...reviewRequest, "userId": ssoUserID})
              .then((res) => {
                console.log(res);
                if (res) {
                  toast.success('Success', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                  });
                  setTimeout(() => {
                    navigate(`/reviews/details/${id}`);
                  }, 200);
                }
              })
              .catch((err) => {
                console.error(err);
                toast.error('Something went wrong', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  transition: Bounce,
                });
              });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value, "e.target", e.target.type);
    var isViewSection = false;

    if (e.target && e.target.id) {
      const splitID = e.target.id.split("-")
      if (splitID && splitID.length > 0 && splitID[0] == "view") {
        isViewSection = true
      }
    }

    if (e.target.name && e.target.value) {
      if (e.target.type === "time") {
        const value = e.target.value;
        const splitTime = value.split(":");
        console.log(splitTime, "splitTime");
        if (isViewSection) {
          setViewHotel((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs.utc(prev[e.target.name])
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        } else {
          setHotelDetails((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs.utc(prev[e.target.name])
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        }

      } else {
        if (isViewSection) {
          setViewHotel((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        } else {
          setHotelDetails((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        }

      }
    } else if (e.target.name) {
      if (isViewSection) {

        setViewHotel((prev) => {
          return { ...prev, [e.target.name]: "" };
        });
      } else {

        setHotelDetails((prev) => {
          return { ...prev, [e.target.name]: "" };
        });
      }
    }
  };

  const selectHotel = () => {
    if (selectedHotelSearch) {
      setAddNewEnabled(false);
      setEditEnabled(false);

      setTimeout(() => {
        get(`reviews/hotel_detail?hotel_uid=${selectedHotelSearch}`)
          .then((res) => {
            console.log(res, "res");
            if (res && res.length > 0) {
              setHotelDetails(res[0])
              setViewHotel({})
              setSelectSearchHotel(true)
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      }, 100);
    }
  };

  if (loading) {
    return <div><Loader /></div>;
  }

  const cistyStateZip =
    (hotelDetails?.City || hotelDetails?.State || hotelDetails?.Zip) &&
    `${hotelDetails?.City}, ${hotelDetails?.State}, ${hotelDetails?.Zip}`;

  return (
    <div className="flex flex-col gap-3 mb-3 w-full text-left">
      <PrimaryBreadCrumbs
        label="Review Details"
        path={`/reviews/details/${id}`}
      />

      <div className="text-left">
        <TitlePrimary
          title={` Review ID: ${id}: ${isAddNewEnabled ? "Add" : isEditEnabled ? "Edit" : "View"} Hotel Details`}
        />
      </div>
      <div className="flex gap-3">
        <div className="w-8/12">
          <Select
            label="Hotel"
            className="max-w-full"
            options={hotelSearchedList}
            selected={selectedHotelSearch}
            onChange={changeHotelSearch}
          />
        </div>
        <div className="mt-auto">
          <TertiaryButton
            onClick={selectHotel}
            label="Select This Hotel"
            className="px-4 py-2"
          />
        </div>
      </div>

      <form onSubmit={onSearch} onChange={handleSearchChange}>
        <div className="flex gap-3">
          <div className="w-[400px]">
            <Input
              label="city"
              name="city"
              id="city"
              autocomplete="off"
              value={searchData?.city ? searchData?.city : ""}
            />
          </div>
          <div className="w-[205px]">
            <Input
              label="zip"
              name="zip"
              id="zip"
              autocomplete="off"
              value={searchData?.zip ? searchData?.zip : ""}
            />
          </div>
          <div className="w-[205px]">
            <TelePhone
              label="phone"
              name="phone"
              id="phone"
              autocomplete="off"
value={searchData?.phone ? formatPhoneNumber(searchData?.phone) : ""}
            />
          </div>
          <div className="mt-auto">
            <PrimaryButton label="Search" className={"py-2 px-3"} />
          </div>
        </div>
      </form>

      <div className="flex gap-3">
        <div className="w-8/12">
          <Select
            label="Hotel Added"
            className="max-w-full"
            options={hotelsAdded}
            onChange={changeHotelAdded}
            selected={selectedHotelsAdded}
            
          />
        </div>
        <div className="mt-auto flex gap-3 items-center">
          {!isSearchHotelSelected ? (
            !isAddNewEnabled ? (
              <TertiaryButton
                onClick={() => setEditEnabled(true)}
                label="Edit"
                className="px-4 py-2"
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {isEditEnabled ? (
            <></>
          ) : (
            <TertiaryButton
              onClick={addNew}
              label="Add New"
              className="px-4 py-2"
            />
          )}
        </div>
      </div>

      <form
        className="flex flex-col gap-3"
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <div className="h-[1px] bg-gray-300 w-full "></div>

        <div className="w- bg-white shadow-md p-2">
          <h6 className="mb-5 font-semibold text-[20px]">Hotel Info</h6>
          <div className="my-5">
            <CheckBoxGroup
              alignmentType="col"
              groups={[
                {
                  label: "Do Not Show In Team Summary",
                  value: "Not_in_TS", // Use a key from the hotelDetails state
                  checked: hotelDetails?.Not_in_TS === "Yes", // Use "Yes" to determine checked state
                },
                {
                  label: "Travel Order Letter",
                  value: "Travel_ord_letter", // Use a key from the hotelDetails state
                  checked: hotelDetails?.Travel_ord_letter === "Yes", // Use "Yes" to determine checked state
                },
              ]}
              handleChange={handleCheckboxChange} // Pass the handleChange function
            />

          </div>

          <div className="flex flex-col items-start justify-start gap-3 w-full">
            <div className="flex gap-3 w-full">
              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Hotel Name"
                    name="Name"
                    id="Name"
                    value={hotelDetails?.Name}
                    required
                    isErrorOccurred={errorDetails?.Name || ''}
                  />
                ) : (
                  <TextWithLabel
                    label="Hotel Name"
                    value={hotelDetails?.Name}
                  />
                )}
              </div>

              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Address"
                    name="Address"
                    id="Address"
                    value={hotelDetails?.Address}
                    required
                    isErrorOccurred={errorDetails?.Address || ''}
                  />
                ) : (
                  <TextWithLabel
                    label="Address"
                    value={hotelDetails?.Address}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-3 w-full">
              <div className="w-[600px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <div className="flex gap-3">
                    <Input
                      label="City"
                      name="City"
                      id="City"
                      value={hotelDetails?.City}
                      required
                      isErrorOccurred={errorDetails?.City || ''}
                    />
                    <Input
                      label="State"
                      name="State"
                      id="State"
                      value={hotelDetails?.State}
                      maxLength="2"
                      size="sm"
                      required
                      isErrorOccurred={errorDetails?.State || ''}
                    />
                    <Input
                      label="Zip"
                      name="Zip"
                      id="Zip"
                      maxLength="10"
                      value={hotelDetails?.Zip}
                      required
                      isErrorOccurred={errorDetails?.Zip || ''}
                    />
                  </div>
                ) : (
                  <TextWithLabel
                    label="City/State/Zip"
                    value={cistyStateZip ? cistyStateZip : ""}
                  />
                )}
              </div>

              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="County"
                    name="County"
                    id="County"
                    value={hotelDetails?.County}
                    required
                    isErrorOccurred={errorDetails?.County || ''}
                  />
                ) : (
                  <TextWithLabel label="County" value={hotelDetails?.County} />
                )}
              </div>
            </div>
            <div className="flex gap-3 w-full">
              <div className="w-[200px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <TelePhone
                    label="Phone"
                    name="Phone"
                    id="Phone"
                    //maxLength="10"
                    value={hotelDetails?.Phone}
                    required
                    isErrorOccurred={errorDetails?.Phone || ''}
                  />
                ) : (
                  <TextWithLabel label="Phone" value={formatPhoneNumber(hotelDetails?.Phone)} />
                )}
              </div>

              <div className="w-[200px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <TelePhone
                    label="Fax"
                    name="fax"
                    id="fax"
                   // maxLength="10"
                    value={hotelDetails?.fax}
                  />
                ) : (
                  <TextWithLabel label="Fax" value={formatPhoneNumber(hotelDetails?.fax)} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-gray-300 w-full "></div>

        <div className="w-full bg-white shadow-md p-2">
          <div className="flex items-center">
            <h6 className="mb-3 font-semibold text-[16px]">Review Info</h6>
          </div>
          {
            isSearchHotelSelected || isEditEnabled || isAddNewEnabled ? <HotelDetailsEdit hotelDetails={viewHotel} errorDetails={errorDetails} /> : <HotelDetailsView hotelDetails={viewHotel} errorDetails={errorDetails} />
          }
          {isEditEnabled || isSearchHotelSelected || isAddNewEnabled ? (
            <div className="flex gap-3 mt-3">
              <SecondaryButton
                onClick={() => dispatch(setCancel(true))}
                label="Cancel"
                className={"px-4 py-2"}
                type="button"
              />
              <PrimaryButton type="submit" label="Submit" className={"px-4 py-2"} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}
