/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
import { URI_grantees } from "../../config/constants";
import PrimaryButton from "../../components/buttons/primary";
// import Table from "../../components/table";
import { useNavigate } from "react-router-dom";
import { get } from "../../services";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import TitlePrimary from "../../components/typhography/title-primary";
import Loader from "../../components/loader";
import {DynamicTable} from "../../components/table/tableWithTotal";
import InputDate from "../../components/inputs/date";
import Select from '../../components/inputs/select'

const RPAssignmentReport = () => {
  const [searchParams, setSearchParams] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    get(`rrc/rpAssignmentList`)
      .then((res) => {
        if (res && res.length > 0) {
          setOptions([
            { label: "Select one", value: "null" },
            ...res.map((d) => {
              d["label"] = d.RP_name;
              d["value"] = d.rrcUid;
              return d;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    },[])

  const handleChange = (e) => {
    if (e.target && e.target.value && e.target.name) {
      setSearchParams((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setSearchParams((prev) => {
        return {...prev, [e.target.name] : ''};
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(searchParams, 'searchParams')
    if (searchParams && (searchParams.planner || searchParams.planner == "0") && searchParams.endDate && searchParams.startDate) {
      const inputKey = Object.keys(searchParams)[0];
      const inputValue = searchParams[inputKey];

      get(`rrc/rpAssignmentReviewList?rrcUid=${searchParams.planner}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`)
        .then((res) => {
          console.log(res, "response");
          setSearchResults(res);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
      console.error("no values to Submit. pls eneter something");
    }
  };

  const handleGranteeId = (id) => {
    navigate("/grantees/details/" + id);
  };

  const cols = [
    {
      field: "region",
      headerName: "Region",
      width:60,
      searchable:true
    },
    {
      field: "trip_id",
      headerName: "Trip ID",
      searchable:true
    },
    {
      field: "rev_date",
      headerName: "Review Dates",
      searchable:true
    },
    {
      field: "grantee",
      headerName: "Grantee",
      searchable:true
    },
    {
      field: "sub_type",
      headerName: "Review Type",
      searchable:true
      // type:"date"
    },
    {
      field: "FirstName",
      headerName: "Review Planner",
      searchable:true
      // type:"date"
    },
    {
      field: "team_completed",
      headerName: "Team Completed",
      type:"date",
      
      time:true,
      searchable:true
    },
    {
      field: "sent_to_danya",
      headerName: "Send To Danya",
      type:"date",
      time:true,
      searchable:true
    },
  ];

  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">Grantee ID</div>,
      selector: (row) => row.fed_grantee_id,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE NAME</div>,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleGranteeId(row.grantee_uid)}
        >
          {row.Name}
        </div>
      ),
    },
    {
      name: <div className="uppercase text-md text-gray-600">CITY, STATE</div>,
      cell: (row) => (
        <div>
          {row.City}, {row.State}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">LAST ACTIVITY</div>
      ),
      selector: (row) => row.CreateTs,
      sortable: true,
      cell: (row) => {
        if (row && row.CreateTs) {
          return <div>{dayjs.utc(row.CreateTs).format("YYYY/MM/DD")}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
  ];

  return (
    <div>
      <div className="flex flex-col gap-4 text-left my-6">
        <TitlePrimary title={"Review Planner Assignment Report"} />
        <form
          onSubmit={handleSubmit}
          onChange={handleChange}
          className="flex flex-col gap-4"
        >
          <div className="flex flex-col gap-4">
            <div>
              <Select
                label="Review Planner"
                options={options}
                name="planner"
              />
            </div>
            <div className="flex gap-4">
              <div>
                <InputDate
                  label="Span"
                  name="startDate"
                  
                />
              </div>
              <div className="mt-auto">
                <InputDate
                  label=""
                  name="endDate"
                />
              </div>
            </div>
            <div className="mt-3">
              <PrimaryButton
                type="submit"
                label="Search Now"
                className="w-[126px] h-[46px]"
              />
            </div>
          </div>
        </form>
      </div>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <div className="mb-10">
        <div className="flex justify-start mb-5">
          {"Search Results (" + searchResults.length + ")"}
        </div>
        <DynamicTable columns={cols} data={searchResults} />
      </div>
    </div>
  );
};

export default RPAssignmentReport;
