/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import PrimaryButton from '../buttons/primary'
import { useStore } from '../../store'
import { setCancel } from '../../store/actions';

export default function CancelConfirmationModal() {
    const { cancelled, dispatch } = useStore();

    useEffect(() => {
        if(cancelled){
            setOpened(true)
        }
    }, [cancelled])
    

    const [isOpened, setOpened] = useState(false)
    const handleModal = () => {
        setOpened(false)
        dispatch(setCancel(false))
    }

    if(isOpened){
        return (
            <div className='fixed top-0 left-0 right-0 z-10  w-full h-full bottom-0 bg-black/70 flex items-center justify-center'>
                <div className='bg-white shadow-lg  min-w-[400px] min-h-[50px] rounded-md p-3'>
                    <div className='flex'>
                    <div className='ml-auto cursor-pointer' onClick={() => handleModal(false)}>x</div>
                    </div>
                    <div className='flex flex-col gap-3 justify-center items-center'>
                    <div>Are you sure you want to cancel</div>
                    <div>
                        <PrimaryButton label="Confirm" className={"p-2"} onClick={() => window.location.reload()}/>
                    </div>
                    </div>
                </div>
            </div>
          )
    }else{
        return null
    }
 
}
