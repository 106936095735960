import React from "react";
// import DataTable from "react-data-table-component";
import Table from "../../../table/table";

export default function SearchResultsTable({ data, handleCheckBox, searchGridRef }) {
  console.log(handleCheckBox)
  const columns = [
    {
      field: "ssn",
      headerName: "DANYA ID",
    },
    {
      field: "fname",
      headerName: "RTL Name",
      cellRenderer : ({data}) => {
        var name = '';

        if(data.title){
          name = data.title;
        }
        if(data.fname){
          name += " "+ data.fname;
        }
        if(data.lname){
          name += " " + data.lname;
        }

        return <span>{name}</span>
      }
      
    },
    {
      field: "email",
      headerName: "EMAIL",
    },
    {
     
      headerName: "ADD TO REVIEW",
      headerCheckboxSelection:true,
      checkboxSelection:true,
      type:"action"
      // cellRenderer: function ({ data, node }) {
      //   //if (node && node.rowIndex) {
      //     return (
      //       <div>
      //         <input
      //           type="checkbox"
      //           id={node.rowIndex}
      //           checked={data.selected}
      //           onClick={handleCheckBox}
      //         />
      //       </div>
      //     );
      //   // } else {
      //   //   return <></>;
      //   // }
      // },
    },
  ];
  // const columns = [
  //   {
  //     name: <div className="uppercase text-md text-gray-600">DANYA ID</div>,
  //     selector: (row) => row.ssn,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
  //     selector: (row) => row.fname,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">EMAIL</div>,
  //     selector: (row) => row.email,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">ADD TO REVIEW</div>,
  //     selector: (row) => row.reviewers_uid,
  //     sortable: true,
  //     cell:(row, i) =>{

  //       return <div>
  //           <input type="checkbox" id={i} checked={row.selected} onClick={handleCheckBox}/>
  //       </div>
  //     }
  //   },
  // ];

  return <Table gridRef={searchGridRef} columns={columns} data={data} rowSelection="multiple"/>;
}
