import React from "react";
import TextWithLabel from "../../../../inputs/text";
import { formatDate, formatTime } from '../../../../../utils/formatDate'

export default function Invoice({details}) {
  return (
    <div className="px-7 py-2 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel
            label="Airline/Train Ticket Total Cost"
            value={details?.Airfare1}
          />
        </div>
        <div className="w-[200px]">
        <TextWithLabel label="E-Ticket" value={details.e_tickets ? 'Yes' : ''} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" label="Team Meeting" value={formatDate(details?.MeetingDate) + ' ' + formatTime(details?.MeetingTime)} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel type="date" label="Exit Meeting" value={formatDate(details?.ExitDate) + ' ' + formatTime(details?.ExitTime)} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Misc Info" value={details?.MiscInfo} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[200px]">
          <TextWithLabel label="Travel Mode" value={details?.TravelMode} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Rental Car Agency" value={details?.RentalAgency} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Carsize" value={details?.CarSize} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Estimated Cost" value={details?.rental_car1} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Other Description" value={details?.othe_Description} />
        </div>
      </div>
    </div>
  );
}
