import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
// import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useParams } from "react-router-dom";
import { get, post } from "../../services";
import Select from "../../components/inputs/select";
import TelePhone from "../../components/inputs/telephone";
// import TextWithLabel from "../../components/inputs/text";
import InputDate from "../../components/inputs/date";
import PrimaryBox from "../../components/box/box-primary";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import RadioGroup from "../../components/inputs/radio-group";

import { toast, Bounce } from "react-toastify";
import LayoutSecondary from "../../components/layouts/secondary";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { useStore } from "../../store";

// const roles = [
//   {
//     label: "RPS",
//     value: "RPS",
//   },
//   {
//     label: "RP",
//     value: "RP",
//   },
//   {
//     label: "RRC",
//     value: "RRC",
//   },
//   {
//     label: "LA",
//     value: "LA",
//   },
// ];

export default function ProfileMaintenanceDetails() {
  // const navigate = useNavigate()
  const { ssoUserID } = useStore();

  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);

  const [roles, setRoles] = useState([]);
  const [regions, setRegions] = useState([]);
  const [errorDetails, setErrorDetails] = useState({});

  useEffect(() => {
    if (id) {
      get(`user/userProfileUserView?userCode=${id}`)
        .then((res) => {
          if (res && res.length > 0) {
            setFormData(res[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    get(`user/userProfileRoleList`)
      .then((res) => {
        if (res && res.length > 0) {
          setRoles(res.map((item) => ({
            label: item.UserRole,
            value: item.UserRole,
          })));
        }
      })
      .catch((err) => {
        console.error(err);
      });
    get(`user/userProfileRegionList`)
      .then((res) => {
        if (res && res.length > 0) {
          setRegions(res.map((item) => ({
            label: item.RegionDisplay,
            value: item.RegionValue,
          })));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("updated form data", formData);
    setData([]);
    var url = "user/update";
    var temp = { ...formData, SSOUserId: formData.SSOUserCode , user_code: id, inactive_date: formData.user_status === 'Active' ? null : formData.inactive_date }
    const errorFormMapper = {
      fname: 'First Name',
      lname: 'Last Name',
      address1: 'Address Line 1',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      email: 'Email',
      inactive_date: 'In Active'
    }
    const errorFields = {}
    Object.keys(formData).map(item => {
      if (['fname', 'lname', 'address1', 'city', 'state', 'zip', 'email'].indexOf(item) !== -1) {
        if (typeof formData[item] === 'string' && formData[item].trim().length === 0) {
          errorFields[item] = `${errorFormMapper[item]} is required`;
        }
      }
      if (item === 'user_status' && formData['user_status'] === 'InActive' && formData['inactive_date'] === null) {
        errorFields['inactive_date'] = `${errorFormMapper['inactive_date']} Date is required`;
      }
    });
    if (Object.keys(errorFields).length > 0) {
      setErrorDetails(errorFields);
      return;
    }
    post(url, {...temp, "userId": ssoUserID})
      .then((res) => {
        console.log(res);
        if (res) {
          // setFormData({})
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          window.location.replace('/profile-maintenance')
          // navigate('/profile-maintenance')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });

  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");

    if (e.target.type === "checkbox" && e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.checked };
      });
    } else if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  console.log(data);

  return (
    <LayoutSecondary
      bCrumpLabel={"User Search"}
      bCrumpPath={"/profile-maintenance"}
      title="Maintain A User"
    >

      <PrimaryBox>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6"
        >
          <div className="flex gap-6">
            <Input
              label="Title"
              id="title"
              name="title"
              value={formData.title}
              size="sm"
            />
            <Input
              label="First Name"
              id="fname"
              name="fname"
              value={formData.fname}
              required
              isErrorOccurred={errorDetails.fname ? errorDetails.fname : false}
            />
            <Input
              label="Middle Name"
              id="mi"
              name="mi"
              className="w-[100px]"
              value={formData.mi}
            />
            <Input
              label="Last Name"
              id="lname"
              name="lname"
              value={formData.lname}
              required
              isErrorOccurred={errorDetails.lname ? errorDetails.lname : false}
            />
          </div>
          <div className="flex flex-col gap-6 w-3/6">
            <Input
              label="Address Line 1"
              id="address1"
              name="address1"
              value={formData.address1}
              required
              isErrorOccurred={errorDetails.address1 ? errorDetails.address1 : false}
            />
            <Input
              label="Address Line 2"
              id="address2"
              name="address2"
              value={formData.address2}
            />
            <div className="flex gap-6">
              <Input
                label="City"
                id="city"
                name="city"
                value={formData.city}
                required
                isErrorOccurred={errorDetails.city ? errorDetails.city : false}
              />
              <Input
                label="State"
                id="state"
                name="state"
                className="w-[80px]"
                value={formData.state}
                required
                isErrorOccurred={errorDetails.state ? errorDetails.state : false}
              />
              <Input
                label="Zip"
                id="zip"
                name="zip"
                value={formData.zip}
                required
                isErrorOccurred={errorDetails.zip ? errorDetails.zip : false}
              />
            </div>
            <div className="flex gap-6">
              <div className="w-[200px]">
                <TelePhone
                  label="Phone"
                  id="home_phone"
                  name="home_phone"
                  value={formData.home_phone}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Fax"
                  id="work_fax"
                  name="work_fax"
                  value={formData.work_fax}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Emergency"
                  id="emergency"
                  name="emergency"
                  value={formData.emergency}
                />
              </div>
            </div>
            <div className="flex gap-6">
              <Input
                name="email"
                id="email"
                label="Email"
                className="w-[200px]"
                value={formData.email}
                required
                isErrorOccurred={errorDetails.email ? errorDetails.email : false}
              />
            </div>

            <div className="flex gap-6">
              <Select
                label="User Role"
                name="user_role"
                id="user_role"
                className="w-[150px]"
                options={roles}
                selected={formData.user_role}
              />
              <Select
                label="Region"
                name="region"
                id="region"
                className="w-[150px]"
                options={regions}
                selected={formData.region}
              />
              <div>
                <Input
                  name="SSOUserCode"
                  id="SSOUserCode"
                  label="Azure ID"
                  className="w-[400px]"
                  value={formData.SSOUserCode}
                />
              </div>
            </div>
            <div>
              <InputDate
                label="In Active Date"
                id="inactive_date"
                name="inactive_date"
                className="w-[200px]"
                value={formData.inactive_date ? dayjs.utc(formData.inactive_date).format("YYYY-MM-DD") : ''}
                isErrorOccurred={errorDetails.inactive_date ? errorDetails.inactive_date : null}
              />
            </div>
            <div className="flex gap-6">
              <RadioGroup
                label="User Status"
                groups={[
                  {
                    label: "Active",
                    value: "Active",
                    name: "user_status",
                    id: "user_status",
                    checked: formData.user_status && formData.user_status == 'Active' || false,
                  },
                  {
                    label: "In Active",
                    value: "InActive",
                    name: "user_status",
                    id: "user_status",
                    checked: formData.user_status && formData.user_status == 'InActive' || false,
                  },
                ]}
              // handleChange={handleChange}
              />
              <RadioGroup
                label="Head Start Team"
                groups={[
                  {
                    label: "Yes",
                    value: "Yes",
                    name: "head_start",
                    id: "head_start",
                    checked: formData.head_start && formData.head_start == 'Yes' || false,
                  },
                  {
                    label: "No",
                    value: "No",
                    name: "head_start",
                    id: "head_start",
                    checked: formData.head_start && formData.head_start == 'No' || false,
                  },
                ]}
              // handleChange={handleChange}
              />


            </div>
            <div className="flex flex-col gap-6">
              <CheckBoxGroup
                label="Cash Advance"
                groups={[
                  {
                    label: "Undo Cash Avance",
                    //value: true,
                    name: "Authorized_UndoCash",
                    id: "Authorized_UndoCash",
                    checked: formData.Authorized_UndoCash || false,
                  },
                  {
                    label: "Allowed To Send AP Voucher",
                    // value: true,
                    name: "Authorized_APVoucher",
                    id: "Authorized_APVoucher",
                    checked: formData.Authorized_APVoucher || false,
                  },
                  {
                    label: "Allowed To Send POs",
                    //value: true,
                    name: "Authorized_POandProject",
                    id: "Authorized_POandProject",
                    checked: formData.Authorized_POandProject || false,
                  },
                  {
                    label: "Allowed To Vendor ID",
                    //value: false,
                    name: "Authorized_Vendor",
                    id: "Authorized_Vendor",
                    checked: formData.Authorized_Vendor || false,
                  },
                ]}
              // handleChange={handleChange}
              />
              <CheckBoxGroup
                label="Team Build"
                groups={[
                  {
                    label: "Reviewer Add/Delete",
                    value: true,
                    name: "hea",
                    id: "hea",
                    //checked: data.ecd || false,
                  },
                ]}
              // handleChange={handleChange}
              />
            </div>
          </div>

          <div className="flex gap-6">
            <div className="w[200px]">
              <PrimaryButton
                type="submit"
                label="Update Now"
                className={"px-3 py-2"}
              />
            </div>
            {/* <div className="w[200px]">
              <TertiaryButton
                type="button"
                label="Add New"
                className={"px-3 py-2"}
                //onClick={() => navigate('/rtl/add-new')}
              />
            </div> */}
          </div>
        </form>
      </PrimaryBox>
    </LayoutSecondary>
  );
}
