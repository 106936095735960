import React, { useState } from "react";
import PrimaryButton from "../../components/buttons/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import Select from "../../components/inputs/select";
import { post } from "../../services";
import SearchResults from "../../components/hotel-reconciliation/search-results";
import InputDate from "../../components/inputs/date";

const searchByOptions = [
  {
    label: "Name",
    value: "hotelName",
  },
  {
    label: "City",
    value: "hotelCity",
  },
  {
    label: "Zip",
    value: "hotelZip",
  },
  {
    label: "Phone",
    value: "hotelPhone",
  },
  {
    label: "ReviewID",
    value: "tripId",
  },
];

export default function HotelReconciliation() {

  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchByType, setSearchByType] = useState("hotelName");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setData([]);
    if (searchValue) {
      console.log("search by type ", searchByType, searchValue)
      const dataObj = {
        startDate: formData?.startDate,
        endDate: formData?.endDate,
        [searchByType]: searchValue
      }
      if (searchByType === 'hotelName') {
        dataObj['option'] = 'Name'
      }
      if (searchByType === 'hotelCity') {
        dataObj['option'] = 'City'
      }
      if (searchByType === 'hotelZip') {
        dataObj['option'] = 'Zip'
      }
      if (searchByType === 'hotelPhone') {
        dataObj['option'] = 'Phone'
      }

      var url = "hotelReconsile/search"
      post(url, dataObj)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const filterSearchByOption = searchByOptions.filter(
    (d) => d.value === searchByType
  );
  const searchByTypeLabel =
    filterSearchByOption.length > 0
      ? filterSearchByOption[0].label
      : "Hotel Name";

  const handleSearchChange = (e) => {
    setSearchByType(e.target.value);
    setSearchValue("");
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };


  return (
    <div className="py-10 text-left">
      <TitlePrimary title={"Select A Hotel"} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 mt-10"
      >
        <h6>Hotel Search</h6>
        <div className="flex gap-4 w-full items-center">
          <div className="flex flex-col gap-3">
            <label className="block text-sm font-medium text-gray-600  text-left">
              Search By
            </label>
            <Select
              onChange={(val) => handleSearchChange(val)}
              options={searchByOptions}
            />
          </div>
        </div>
        <div className="flex gap-4 w-full items-center">
          <div className="flex flex-col gap-3">
            <label className="block text-sm font-medium text-gray-600  text-left">
              {searchByTypeLabel}
            </label>
            <input
              type="text"
              value={searchValue}
              onChange={(e) => handleSearchValueChange(e)}
              className="py-3 px-4 block w-96 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="flex gap-6">
          <InputDate label="Between" name="startDate" id="startDate" />
          <InputDate label="And" name="endDate" id="endDate" />
        </div>

        <div className="flex gap-6">
          <div className="w[200px]">
            <PrimaryButton
              type="submit"
              label="Search Now"
              className={"px-3 py-2"}
            />
          </div>

        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
        <SearchResults data={data} />
      </div>
    </div>
  );
}
