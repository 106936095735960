import React from "react";
// import Table from "../table/table.jsx";
import { DynamicTable } from "../table/tableWithTotal.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const ReconcBillSearchResults = ({ data }) => {
  const columns = [

    {
      field: "rev_name",
      headerName: "Reviewer",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
    },
    {
      field: "noofnights",
      headerName: "# Of Days",
    },
    {
      field: "BookedRate",
      headerName: "Booked Rate",
    },
    {
      field: "Taxes",
      headerName: "Taxes",
    },
    {
      field: "EstimatedAmount",
      headerName: "Estimated",
      aggregate:"sum"
    },
    {
      field: "actual_amount",
      headerName: "Actual",
      aggregate:"sum"
    },
    {
      field: "other_amount",
      headerName: "Other",
      aggregate:"sum"
    },
    {
      field: "rec_date",
      headerName: "Reconciled",
      type: 'date'
    }
  ];
  //data = tempData;

  return <DynamicTable columns={columns} data={data} />;
};

export default ReconcBillSearchResults;
