import React from "react";
import Input from "../inputs/input";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import InputDate from "../inputs/date";
import Select from "../inputs/select";

export default function CarRentals() {
  const navigate = useNavigate();
  const handleRedirect = (val) => {
    navigate("/travel/car-rental/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TRIP ID</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATES</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CITY</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">MISC. C/A</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">RENTAL CAR</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">RECONCILE</div>,
      selector: (row) => row.training,
      sortable: true,
      cell: (row) => {
        return (
          <div
            onClick={() => handleRedirect(row.reviewID)}
            className="text-indigo-600"
          >
            {row.training}
          </div>
        );
      },
    }
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <div className="flex flex-col gap-8 text-left my-10">
      <h6 className="font-semibold text-[20px]">Rental Car Reconciliation</h6>
      <div className="flex flex-col gap-6">
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input label="Rental Agency" />
          </div>
          <div className="w-[200px]">
            <Input label="Trip ID" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <Input label="Reviewer First Name" />
          </div>
          <div className="w-[400px]">
            <Input label="Reviewer Last Name" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <Input label="Grantee City" />
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-[200px]">
              <InputDate label="Reviewed between" />
            </div>
            <p className="mt-6 tet-xs text-gray-400">and</p>
            <div className="w-[200px] mt-auto">
              <InputDate />
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-5">
          <div className="w[200px]">
            <PrimaryButton label="Search" className={"px-3 py-2"} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 text-left">
        <div className="flex w-full items-center">
          <h6 className="text-[20px] font-[500]">Search Results</h6>
          <div className="ml-auto w-[400px]">
            <div className="w-[400px]">
              <Select label="Filter by" options={[]} />
            </div>
          </div>
        </div>
        <DataTable columns={columns} data={data} />
      </div>
    </div>
  );
}
