import React from "react";
import PrimaryBox from "../../components/box/box-primary";
import Select from "../../components/inputs/select";
import PrimaryButton from "../../components/buttons/primary";
import { DynamicTable } from "../../components/table/tableWithTotal";

export default function TerAging() {
  const columns = [
    {
        field:"",
        headerName:"Reviewer",
        totalLabel:"Total"
    },
    {
        field:"",
        headerName:"No Of Reviews",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"1-30 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"31-60 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"61-90 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"91-120 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"121-150 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"151-180 Days",
        aggregate:"sum",
    },
    {
        field:"",
        headerName:"180 Plus Days",
        aggregate:"sum",
    }
];
  return (
    <PrimaryBox>
      <div className="flex flex-col gap-3 text-left">
        <h6 className="font-semibold text-xl pb-3">TER Aging</h6>
        <div className="flex gap-3">
          <div className="w-[200px]">
            <Select options={[]} label="Fiscal Year" />
          </div>
          <PrimaryButton label="All TER(s)" className={"p-2 mt-auto"} />
          <PrimaryButton label="Outstanding Only" className={"p-2 mt-auto"} />
        </div>
        <DynamicTable columns={columns} data={[]} />
      </div>
    </PrimaryBox>
  );
}
