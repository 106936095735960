import React from "react";
import Table from "../table/table.jsx";
import { useNavigate } from "react-router-dom";
// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const SearchResults = ({ data }) => {

  const navigate = useNavigate();
  const handleTripHotelUid = (routeType, val) => {
    if (routeType === 'Rev List') {
      navigate("/hotel-reconciliation/hotel-bill-reviewers/" + val);
    }
    if (routeType === 'Reconc') {
      navigate("/hotel-reconciliation/hotel-bill-reconc/" + val);
    }
    if (routeType === 'New Reconc') {
      navigate("/hotel-reconciliation/hotel-bill-new-reconc/" + val);
    }
  };
  const columns = [
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "Name",
      headerName: "Hotel Name",
    },
    {
      field: "City",
      headerName: "Hotel City",
    },
    {
      field: "Phone",
      headerName: "Phone",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "Rev List",
      headerName: "Rev. List",
      //url: "/hotel-reconciliation",
      //urlParams: ["user_code"],
      cellRenderer: (data) => {
        return <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleTripHotelUid("Rev List", data?.data?.trip_hotel_uid)}
        >
          Rev List
        </div>
      }
    },
    {
      field: "",
      headerName: "Reconc.",
      //url: "/hotel-reconciliation",
      //urlParams: ["user_code"],
      cellRenderer: (data) => {
        return <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleTripHotelUid("Reconc", data?.data?.trip_hotel_uid)}
        >
          Reconc
        </div>
      }
    },
    {
      field: "",
      headerName: "New Reconc.",
      //url: "/hotel-reconciliation",
      //urlParams: ["user_code"],
      cellRenderer: (data) => {
        return <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleTripHotelUid("New Reconc", data?.data?.trip_hotel_uid)}
        >
          New Reconc.
        </div>
      }
    },
    {
      field: "status",
      headerName: "Status",
    }
  ];
  //data = tempData;

  return <Table columns={columns} data={data} />;
};

export default SearchResults;
