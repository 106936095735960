import React from 'react'
import Input from '../../inputs/input'
import dayjs from 'dayjs'
import InputDate from '../../inputs/date'

export default function HotelDetailsEdit({ hotelDetails, errorDetails }) {
  console.log(hotelDetails, 'hotelDetails')
  return (
    <div className="flex flex-col items-start justify-start gap-8 w-full">
      <div className="flex gap-8 w-full">
        <div className="w-[200px] text-left">
          <Input name="Lodging" id="view-Lodging" label="Conus Lodging" value={hotelDetails?.Lodging} required isErrorOccurred={errorDetails?.Lodging} />
        </div>

        <div className="w-[200px] text-left">
          <Input name="Meals" id="view-Meals" label="Meals & Incidentals" value={hotelDetails?.Meals} required isErrorOccurred={errorDetails?.Meals} />
        </div>
        <div className="w-[200px] text-left">
          <Input name="MIDays" id="view-MIDays" label="M&I Days" value={hotelDetails?.MIDays} isErrorOccurred={errorDetails?.MIDays} />
        </div>
      </div>
      <div className="flex gap-8 w-full">
        <div className="w-[200px] text-left">
          <Input name="Booked_rate" id="view-Booked_rate" label="Booked Rate" value={hotelDetails?.Booked_rate} required isErrorOccurred={errorDetails?.Booked_rate} />
        </div>

        <div className="w-[200px] text-left">
          <Input name="taxes" id="view-taxes" label="Taxes" value={hotelDetails?.taxes} required isErrorOccurred={errorDetails?.taxes} />
        </div>
        <div className="w-[200px] text-left">
          <Input name="meeting_room" id="view-meeting_room" label="Meeting Room" value={hotelDetails?.meeting_room} required isErrorOccurred={errorDetails?.meeting_room} />
        </div>
      </div>
      <div className="flex gap-8 w-full">
        <div className="w-[200px] text-left">
          <InputDate name="firstnight" id="view-firstnight" label="First Night" defaultValue={hotelDetails?.firstnight ? dayjs(hotelDetails?.firstnight).format("YYYY-MM-DD") : ""} required isErrorOccurred={errorDetails?.firstnight} />
        </div>
        <div className="w-[200px] text-left">
          <InputDate name="lastnight" id="view-lastnight" label="Last Night" defaultValue={hotelDetails?.lastnight ? dayjs(hotelDetails?.lastnight).format("YYYY-MM-DD") : ""} required isErrorOccurred={errorDetails?.lastnight} />
        </div>
        <div className="w-[200px] text-left">
          <Input name="noofnights" id="view-noofnights" label="Number Of Night" value={hotelDetails?.noofnights} isErrorOccurred={errorDetails?.noofnights} />
        </div>
      </div>
      {/* <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <Input label="Deposit Required" value="$0.00" />
      </div>

      <div className="w-[200px] text-left">
        <Input label="Estimated Max Penalty" value="$0.00" />
      </div>
      <div className="w-[200px] text-left">
        <Input label="Number Of Rooms" value="1" />
      </div>
    </div> */}

      <div className="flex gap-8 w-full">
        <div className="w-[200px] text-left">
          <Input name="confirmNumber" id="view-confirmNumber" label="Confirmation Number" value={hotelDetails?.confirmNumber} required />
        </div>

        <div className="w-[200px] text-left">
          <Input name="NoRooms" id="view-NoRooms" label="Number Of Rooms" value={hotelDetails?.NoRooms} required isErrorOccurred={errorDetails?.NoRooms} />
        </div>
      </div>

      {/* 

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <Input label="Reservation Request Date" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <Input label="Contract Due Date" value="-" />
      </div>
      <div className="w-[200px] text-left">
        <Input label="Rooming Last Due Date" value="-" />
      </div>
    </div>

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <Input label="Hotel Contact" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <Input label="Maximum Team Size" value="$0.00" />
      </div>
      <div className="w-[200px] text-left">
        <Input label="Team Size By Danya" value="0" />
      </div>
    </div>

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <Input label="Anticipated Team Size" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <Input label="RTRF Team Size" value="0" />
      </div>
    </div>
    <div className="flex gap-8 w-full">
      <div className="w-[500px] text-left">
        <Input label="Comments For Hotel Confimation" value="-" />
      </div>
    </div> */}
    </div>
  )
}
