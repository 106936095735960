import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
// import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
// import { useNavigate } from "react-router-dom";
import TitlePrimary from "../../components/typhography/title-primary";
import { get, post } from "../../services";
import Select from "../../components/inputs/select";
import TelePhone from "../../components/inputs/telephone";
// import TextWithLabel from "../../components/inputs/text";
import InputDate from "../../components/inputs/date";
import PrimaryBox from "../../components/box/box-primary";
import TextWithBadge from "../../components/inputs/text-with-badge";
import RRCChangeLogs from "../../components/rrc/change-logs";

import { toast, Bounce } from "react-toastify";

// const roles = [
//   {
//     label: "RPS",
//     value: "RPS",
//   },
//   {
//     label: "RP",
//     value: "RP",
//   },
//   {
//     label: "RRC",
//     value: "RRC",
//   },
//   {
//     label: "LA",
//     value: "LA",
//   },
// ];

export default function RRC() {
  //const navigate = useNavigate()

  const [formData, setFormData] = useState({});
  const [originalData, setOrginalData] = useState({});
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [subItems, setSubItems] = useState([]);
  const [rpLA, setrpLA] = useState([]);
  const [changeLogs, setChangeLogs] = useState([]);
  const [rrc_id, setRRCID] = useState(null);
  const [rpcList, setRpcList] = useState([])
  const [rpLAList, setRpLAList] = useState([])

  useEffect(() => {
    get("rrc/getProjRoles")
      .then((res) => {
        if (res && res.length > 0) {
          setRoles(
            res.map((d) => {
              return {
                label: d.projRole,
                value: d.projRole,
              };
            })
          );
          setOrginalData((prev) => {
            return { ...prev, role: res[0].projRole };
          });
          setFormData((prev) => {
            return { ...prev, role: res[0].projRole };
          });
          getDropDownValues(res[0].projRole);
        }
      })
      .catch((err) => {
        console.error(err);
      });
   
    
    getStatusLookup();
  }, []);
  const getStatusLookup = () => {
    get("rrc/getRPStausList")
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRPcLookup = (id) => {
    get(`rrc/getRPSList?rrc_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setRpcList(res.map((item) => ({
            label: item.RRC_NAME,
            value: item.rrc_uid,
          })))
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getLALookup = (id) => {
    get(`rrc/getRPLAList?la_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setRpLAList(res.map((item) => ({
            label: item.LA_NAME,
            value: item.LA_uid,
          })))
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRPView = (id) => {
    setRRCID(id)
    getRPcLookup(id);
   
    get(`rrc/getRPView?rrc_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          
          setFormData((prev) => {
            return { ...prev, ...res[0] };
          });
          setOrginalData((prev) => {
            return { ...prev, ...res[0] };
          });
          if (res[0].proj_role == "RP") {
            getRPLAUid(res[0].FirstName, res[0].LastName);
            getLALookup(id);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getChangeLog(id);
  };

  const getRPLAUid = (FirstName = "", LastName = "") => {
    get(`rrc/getRPLAUid?firstName=${FirstName}&lastName=${LastName}`)
      .then((res) => {
        if (res && res.length > 0) {
          setrpLA(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getChangeLog = (id) => {
    setChangeLogs([]);
    get(`rrc/getRPChangeLog?rrc_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          // setrpLA(res);
          console.log(res);
          setChangeLogs(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getDropDownValues = (role) => {
    get(`rrc/getRPList?projRole=${role}`)
      .then((res) => {
        if (res && res.length > 0) {
          setSubItems(
            res.map((d) => {
              return {
                label: d.rrc_name,
                value: d.rrc_uid,
              };
            })
          );
          
          getRPView(res[0].rrc_uid);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setData([]);

    if (originalData.rrc_status !== formData.rrc_status) {
      var tempData = {
        rrcUid: rrc_id,
        reasonType: formData.rrc_status,
        reason: formData.reason,
        userId: "tpavuluri",
        changeDate: formData.changeDate,
      };
      post("rrc/rpChangeLog", tempData)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            
            window.location.reload()
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (originalData.rrc_hold_status !== formData.rrc_hold_status) {
      var tempDataHold = {
        rrcUid: rrc_id,
        reasonType: formData.rrc_hold_status,
        reason: formData.reason1,
        userId: "tpavuluri",
        changeDate: formData.changeDate1,
      };
      post("rrc/rpChangeLog", tempDataHold)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            
            //window.location.reload()
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    var temp = {
      "rrcUid": rrc_id,
      "title":formData.Title ? formData.Title : '',
      "firstName": formData.FirstName ? formData.FirstName : '',
      "middleName": formData.MiddleName ? formData.MiddleName : '',
      "lastName": formData.LastName ? formData.LastName : '',
      "address": formData.Address ? formData.Address : '',
      "address2": formData.address2 ? formData.address2 : '',
      "city": formData.City ? formData.City : '',
      "state":formData.State ? formData.State : '',
      "zip":formData.Zip ? formData.Zip : '',
      "phone": formData.Phone ? formData.Phone : '',
      "phoneUn": formData.phone_un ? formData.phone_un : '',
      "fax": formData.Fax ? formData.Fax : '',
      "faxUn": formData.Fax_un ? formData.Fax_un : '',
      "email": formData.email ? formData.email : '',
      "cellular": formData.cellular ? formData.cellular : '',
      "cellularUn": formData.cellular_un ? formData.cellular_un : '',
      "emergency": formData.emergency ? formData.emergency : '',
      "emergencyUn": formData.emergency_un ? formData.emergency_un : '',
      "rpsUid": formData.rps_uid ? formData.rps_uid : '',
      "laUid": formData.LA_uid ? formData.LA_uid : '',
      "rrcStatus": formData.rrc_status ? formData.rrc_status : '',
      "rrcHoldStatus": formData.rrc_hold_status ? formData.rrc_hold_status : '',
      "danyaCCEmail": formData.danya_cc_email ? formData.danya_cc_email : '',
      "rpCoordinator": formData.rp_coordinator ? formData.rp_coordinator : '',
      "userId": formData.user_id ? formData.user_id : '',
      "timezone": formData.TimeZone ? formData.TimeZone : '',
      "lacUid": formData.lac_uid ? formData.lac_uid : '',
  }

    post("rrc/rpUpdate", temp)
      .then((res) => {
        console.log(res);
        if (res) {
          toast.success('Updated Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });

          setTimeout(() => {
            window.location.reload()
          }, 300);
        }
      })
      .catch((error) => {
        console.error('Error updating reviewer', error);
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");

    if (e.target.name && e.target.value) {
      if (e.target.name == "role") {
        getDropDownValues(e.target.value);
      }
      if (e.target.name == "rrc_uid") {
        getRPView(e.target.value);
      }

      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  console.log(rpLA, "rpLA", data);

  return (
    <div className="py-5 text-left flex flex-col gap-6">
      <div className="">
        <TitlePrimary title={"RP Maintenance"} />
      </div>
      <PrimaryBox>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6"
        >
          <div className="flex gap-6">
            <Select
              label="Select A Role"
              name="role"
              id="role"
              className="w-[150px]"
              options={roles}
            />
            {formData.role && formData.role == "LA" ? (
              <Select
                label="Select An LA"
                name="rrc_uid"
                id="rrc_uid"
                className="w-[350px]"
                options={subItems}
              />
            ) : (
              <></>
            )}
            {formData.role && formData.role == "RRC" ? (
              <Select
                label="Select A RRC"
                name="rrc_uid"
                id="rrc_uid"
                className="w-[350px]"
                options={subItems}
              />
            ) : (
              <></>
            )}
            {formData.role && formData.role == "RP" ? (
              <Select
                label="Select A RP"
                name="rrc_uid"
                id="rrc_uid"
                className="w-[350px]"
                options={subItems}
              />
            ) : (
              <></>
            )}
            {formData.role && formData.role == "RPS" ? (
              <Select
                label="Select A RPS"
                name="rrc_uid"
                id="rrc_uid"
                className="w-[350px]"
                options={subItems}
              />
            ) : (
              <></>
            )}
            {/* <Select
              label="Select A RP"
              name=""
              id=""
              className="w-[350px]"
              options={[]}
            /> */}
          </div>
          <div className="flex gap-6">
            <Input
              label="Title"
              id="Title"
              name="Title"
              value={formData.Title}
              size="sm"
            />
            <Input
              label="First Name"
              id="FirstName"
              name="FirstName"
              value={formData.FirstName}
            />
            <Input
              label="Middle Name"
              id="MiddleName"
              name="MiddleName"
              className="w-[100px]"
              value={formData.MiddleName}
            />
            <Input
              label="Last Name"
              id="LastName"
              name="LastName"
              value={formData.LastName}
            />
          </div>
          <div className="flex flex-col gap-6 w-3/6">
            <Input
              label="Address Line 1"
              id="Address"
              name="Address"
              value={formData.Address}
            />
            <Input
              label="Address Line 2"
              id="address2"
              name="address2"
              value={formData.address2}
            />
            <div className="flex gap-6">
              <Input label="City" id="City" name="City" value={formData.City} />
              <Input
                label="State"
                id="State"
                name="State"
                className="w-[80px]"
                value={formData.State}
              />
              <Input label="Zip" id="Zip" name="Zip" value={formData.Zip} />
            </div>
            <div className="flex gap-6">
              <div className="w-[200px]">
                <TelePhone
                  label="Phone"
                  id="Phone"
                  name="Phone"
                  value={formData.Phone}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Fax"
                  id="Fax"
                  name="Fax"
                  value={formData.Fax}
                />
              </div>
              <div className="w-[200px]">
                <TelePhone
                  label="Emergency"
                  id="emergency"
                  name="emergency"
                  value={formData.emergency}
                />
              </div>
            </div>
            <div className="flex gap-6">
              <Input
                name="email"
                id="email"
                label="Email"
                className="w-[200px]"
                value={formData.email}
              />
              <TextWithBadge
                name="userID"
                id="userID"
                label="Web User ID"
                //className="w-[200px]"
                bg="bg-[#E0E7FF]"
                className="text-[#4F46E5] w-full bg-[#E0E7FF]"
                value={formData.web_user_id}
              />
            </div>
            <div className="flex gap-6">
              {formData.role && formData.role == "RP" ? (
                <Select
                  label="LA"
                  name="LA_uid"
                  id="LA_uid"
                  className="w-[350px]"
                  options={rpLAList}
                  selected={formData.LA_uids}
                />
              ) : (
                <></>
              )}
              {formData.role &&
              (formData.role == "LA" ||
                formData.role == "RRC" ||
                formData.role == "RP") ? (
                <Select
                  label="RPC"
                  name="rps_uid"
                  id="rps_uid"
                  className="w-[350px]"
                  options={rpcList}
                  selected={formData.rps_uid}
                />
              ) : (
                <></>
              )}
            </div>
            {/* <div className="flex flex-col gap-6"></div> */}
            <div className="flex flex-col gap-6">
              <Select
                label="Status"
                name="rrc_status"
                id="rrc_status"
                className="w-[150px]"
                options={[
                  {
                    label: "Select",
                    value: null,
                  },
                  {
                    label: "Active",
                    value: "Active",
                  },
                  {
                    label: "In Active",
                    value: "In-Active",
                  },
                ]}
                selected={formData.rrc_status}
              />
              <Input
                label="Reason"
                type="textArea"
                className=""
                name="reason"
                id="reason"
                required={originalData.rrc_status !== formData.rrc_status}
              />
              <InputDate
                label="Date"
                id="changeDate"
                name="changeDate"
                className="w-[200px]"
                required={
                  originalData.rrc_status &&
                  originalData.rrc_status != formData.rrc_status
                }
              />
            </div>
            <div className="flex flex-col gap-6">
              {formData.role && formData.role == "LA" ? (
                <Select
                  label="Put LA on Hold ?"
                  name="rrc_hold_status"
                  id="rrc_hold_status"
                  selected={formData.rrc_hold_status}
                  className="w-[150px]"
                  options={[
                    {
                      label: "Select",
                      value: null,
                    },
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                />
              ) : (
                <></>
              )}
              {formData.role && formData.role == "RRC" ? (
                <Select
                  label="Put RRC on Hold ?"
                  name="rrc_hold_status"
                  id="rrc_hold_status"
                  selected={formData.rrc_hold_status}
                  className="w-[150px]"
                  options={[
                    {
                      label: "Select",
                      value: null,
                    },
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                />
              ) : (
                <></>
              )}
              {formData.role && formData.role == "RP" ? (
                <Select
                  label="Put RP on Hold ?"
                  name="rrc_hold_status"
                  id="rrc_hold_status"
                  selected={formData.rrc_hold_status}
                  className="w-[150px]"
                  options={[
                    {
                      label: "Select",
                      value: null,
                    },
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                />
              ) : (
                <></>
              )}
              {formData.role && formData.role == "RPS" ? (
                <Select
                  label="Put RPS on Hold ?"
                  name="rrc_hold_status"
                  id="rrc_hold_status"
                  selected={formData.rrc_hold_status}
                  className="w-[150px]"
                  options={[
                    {
                      label: "Select",
                      value: null,
                    },
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                />
              ) : (
                <></>
              )}

              <Input
                label="Reason"
                type="textArea"
                className=""
                name="reason1"
                id="reason1"
                required={
                  originalData.rrc_hold_status != formData.rrc_hold_status
                }
              />
              <InputDate
                className="w-[200px]"
                label="Hold Date"
                id="changeDate1"
                name="changeDate1"
                required={
                  originalData.rrc_hold_status != formData.rrc_hold_status
                }
              />
            </div>
          </div>

          <div className="flex gap-6">
            <div className="w[200px]">
              <PrimaryButton
                type="submit"
                label="Update"
                className={"px-3 py-2"}
              />
            </div>
            {/* <div className="w[200px]">
              <TertiaryButton
                type="button"
                label="Add New"
                className={"px-3 py-2"}
                //onClick={() => navigate('/rtl/add-new')}
              />
            </div> */}
          </div>
        </form>
      </PrimaryBox>
      <PrimaryBox>
        <RRCChangeLogs data={changeLogs} />
      </PrimaryBox>
    </div>
  );
}
