import React, { useEffect, useState } from "react";
import TextWithLabel from "../../../inputs/text";
import OnlineTrainings from "./online-trainings";
import Table from "../../../table/table";
import { get } from "../../../../services";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import PrimaryBox from "../../../box/box-primary";

export default function View({ reviewer }) {
  // Extracting regions from reviewer object

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get(`reviewers/getReviewerChangeReason?reviewers_uid=1328`)
      .then((res) => {
        if (res && res.length > 0) {
          setHistoryData(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const regionsData = {
    Region01: reviewer?.Region01,
    Region02: reviewer?.region02,
    Region03: reviewer?.region03,
    Region04: reviewer?.region04,
    Region05: reviewer?.region05,
    Region06: reviewer?.region06,
    Region07: reviewer?.region07,
    Region08: reviewer?.region08,
    Region09: reviewer?.region09,
    Region10: reviewer?.region10,
    Region11: reviewer?.region11,
    Region12: reviewer?.region12,
  };

  const regionsToDisplay = Object.keys(regionsData)
    .filter((region) => regionsData[region]) // Only include regions where value is true
    .map((region) => region.replace("Region", "")); // Remove "Region" from the key, leaving the number

  const columns = [
    {
      headerName: "Date",
      field: "create_ts",
    },
    {
      headerName: "Reason",
      field: "change_type",
    },
    {
      headerName: "Description",
      field: "reason",
    },
    {
      headerName: "Entered By",
      field: "user_id",
    },
  ];

  return (
    <div className="flex flex-col gap-5 text-left mb-20">
      <PrimaryBox>
        <div className="flex flex-col gap-5">
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Danya ID" value={reviewer?.SSN} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[88px]">
              <TextWithLabel label="Title" value={reviewer?.Title} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="First Name" value={reviewer?.FName} />
            </div>
            <div className="w-[88px]">
              <TextWithLabel label="MI" value={reviewer?.MInitial} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Last Name" value={reviewer?.LName} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Suffix" value={reviewer?.suffix} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel
                label="Company Name"
                value={reviewer?.company_name}
              />
            </div>
          </div>
          <div className="h-[1px] w-full bg-gray-300"></div>

          <div>
            <div className="flex flex-col gap-10">
              <div className="w-[200px]">
                <TextWithLabel
                  label="Deficiency Status"
                  value={reviewer?.deficient_status}
                />
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Reviewer Status"
                    value={reviewer?.status}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Inactive Date"
                    value={
                      reviewer?.inactive_date &&
                      dayjs(reviewer?.inactive_date).format("MM/DD/YYYY")
                    }
                  />
                </div>
                {/* <div className="w-[200px]">
              <TextWithLabel label="Reason" value={""} />
            </div> */}
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Home Address"
                    value={reviewer?.HomeAddress1}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="City" value={reviewer?.HomeCity} />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Home Address2"
                    value={reviewer?.HomeAddress2}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel label="State" value={reviewer?.HomeState} />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="Zip Code" value={reviewer?.HomeZip} />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Home Phone/Fax"
                    value={formatPhoneNumber(reviewer?.HomePhone)}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Home Region"
                    value={reviewer?.home_region}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Cellular"
                    value={formatPhoneNumber(reviewer?.cellular_phone)}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Email Address"
                    value={reviewer?.email}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Work Email Address"
                    value={reviewer?.workemail}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Vendor ID"
                    value={reviewer?.mas_vendor_id}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Direct Deposit"
                    value={reviewer?.direct_deposit ? "Yes" : "No"}
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="NAV Vendor ID"
                    value={reviewer?.NAV_Vendor_ID}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Reviewer Type"
                    value={reviewer?.reviewer_type}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="h-[1px] w-full bg-gray-300"></div>

          <div className="flex flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[400px]">
                <TextWithLabel
                  label="Employment Status"
                  value={reviewer?.employment_status}
                />
              </div>
              <div className="w-[200px]">
                <TextWithLabel
                  label="Fed/EHS. Grantee ID"
                  value={reviewer?.granteeid_1}
                />
              </div>
            </div>
            <div className="flex gap-10">
              <div className="w-[400px]">
                <TextWithLabel
                  label="Employer Name"
                  value={reviewer?.employment_status}
                />
              </div>
            </div>
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel
                  label="Work Address"
                  value={reviewer?.WorkAddress1}
                />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="City" value={reviewer?.WorkCity} />
              </div>
            </div>
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel label="State" value={reviewer?.WorkState} />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="Zip Code" value={reviewer?.WorkZip} />
              </div>
            </div>
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel
                  label="Phone"
                  value={formatPhoneNumber(reviewer?.WorkPhone)}
                />
              </div>
              <div className="w-[200px]">
                <TextWithLabel
                  label="Fax"
                  value={formatPhoneNumber(reviewer?.WorkFax)}
                />
              </div>
            </div>
          </div>

          <div className="h-[1px] w-full bg-gray-300"></div>

          <div className="flex flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[400px]">
                <TextWithLabel
                  label="FedEx Address"
                  value={reviewer?.FedexAddress1}
                />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="City" value={reviewer?.FedexCity} />
              </div>
            </div>
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel label="State" value={reviewer?.FedexState} />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="Zip Code" value={reviewer?.FedexZip} />
              </div>
            </div>
          </div>
          <div className="h-[1px] w-full bg-gray-300"></div>
          <div className="flex- flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel label="Airport" value={reviewer?.Airport} />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="Service Area" value={""} />
              </div>
            </div>
          </div>
          <div className="h-[1px] w-full bg-gray-300"></div>
          <div className="flex- flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel
                  label="Admin Hold/Hold Date"
                  value={reviewer?.admin_hold_date}
                />
              </div>
              {/* <div className="w-[200px]">
            <TextWithLabel label="Reason" value={reviewer?.reason} />
          </div> */}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="w-full">
              <div>
                <label className="block text-sm text-gray-700">Regions</label>
                <div className="flex space-x-2 pt-1.5">
                  {regionsToDisplay.map((regionNumber, index) => (
                    <span key={index} className="text-sm">
                      {regionNumber}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-[400px] pt-1 flex flex-col justify-between h-full">
              <div>
                <TextWithLabel label="Test Status" value={""} />
                <TextWithLabel value={""} />
              </div>
              <div className="mt-auto pt-3">
                <p className="text-sm text-gray-500">
                  Computer Literacy: Comments
                </p>
              </div>
              <div className="mt-auto pt-1">
                <p className="text-sm text-gray-500">Writings: Comments</p>
              </div>
            </div>
          </div>
        </div>
      </PrimaryBox>
      <div className="flex flex-col gap-5">
        <TextWithLabel label="Online Trainings Fy 2011 (Mm/Dd/Yy)" />
        <OnlineTrainings />
      </div>
      <div>
        {loading ? <></> : <Table columns={columns} data={historyData} />}
      </div>
      <div className="flex flex-col gap-10">
        {/* <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Exclude From" value={""} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Ytd Declines" value={""} />
          </div>
        </div> */}
      </div>
    </div>
  );
}
