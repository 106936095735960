import React, {useState} from "react";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TextWithLabel from "../../components/inputs/text";
import { useParams } from "react-router-dom";
import Input from "../../components/inputs/input";
import Select from "../../components/inputs/select";
import { post } from "../../services";
// import SecondaryButton from "../../components/buttons/secondary";
import PrimaryButton from "../../components/buttons/primary";
import { toast, Bounce } from "react-toastify";


const options = [
  {
    value: "selectone",
    label: "Select one",
  },
  {
    value: "started",
    label: "QC Started",
  },
  {
    value: "completed",
    label: "QC Completed",
  },
  {
    value: "accept",
    label: "Accept for Processing",
  },
];

export default function AcceptACase() {
  const { id } = useParams();
  const [data, setData] = useState({})
    


  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(granteeData)
    var temp = {...data}
    temp['trip_tracker_uid'] = '250'
    temp['userId'] = 'tpavuluri'
    post('projectInbox/updateProjectInboxAcceptTaskView', temp).then(res =>{
      console.log(res)
      if(res){
        toast.success('Updated Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
      }
    }).catch(err => {
      console.error(err)
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    })
  }

  const handleChange = (e) => {
    console.log(e.target, 'e.target')
    if(e.target.name && e.target.value){
        setData(prev=>{
        return {...prev, [e.target.name]: e.target.value}
      })
     
    }else if(e.target.name){
        setData(prev=>{
        return {...prev, [e.target.name]: ""}
      }) 
    }
  }
  return (
    <form className="text-left" onChange={handleChange} onSubmit={handleSubmit}>
      <PrimaryBreadCrumbs label="Project Inbox" path="/?tab=1" />
      <div className="flex flex-col gap-6 mt-10">
        <div className="flex gap-6">
          <TextWithLabel
            label="Case Submitted By"
            value="DEVDIR01 - Software Developer 01"
          />
          <TextWithLabel label="On" value="9/27/2024 5:41:48 PM" />
        </div>
        <TextWithLabel label="Review ID" value={id} />
        <TextWithLabel label="Case Description" value="Change Test" />
        <TextWithLabel label="Reviewer" value="MS. Elizabeth Baker" />
        <TextWithLabel label="QC Started" value="" />
        <TextWithLabel label="QC Completed" value="" />
        <div>
          <Input label="Comments (If Any)" name="comments" id="comments" type="textArea" />
        </div>
        <div className="flex gap-6">
            <Select options={options} name="option" id="option" label="Select A Option" />
            {/* <SecondaryButton type="button" label="Open Case Advance" className={"mt-auto"} /> */}
        </div>
        <div>
            {
                data && data.option &&( data.option == 'started' || data.option == 'completed')
                 && <PrimaryButton type="submit" className={"p-2"} label={"Update QC Status"}/>
            }
             {
                data && data.option &&data.option == 'accept' 
                 && <PrimaryButton type="submit" className={"p-2"} label={"Accept This Case for Processing"}/>
            }
        </div>
      </div>
    </form>
  );
}
