import React, { useState } from "react";
import Input from "../../components/inputs/input";
import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useNavigate } from "react-router-dom";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/ftl/search-results";
import Loader from "../../components/loader";

export default function RTL() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setData([]);
    setLoading(true);
    let url = "ftls/search";
    console.log("formData as",formData);
    Object.keys(formData).forEach((key) => {
      if (key === "firstName" || key === "lastName") {
        url = "ftls/search";
        if (url.indexOf('?') === -1) {
          url += `?option=Name&param1=${formData['firstName'] || ''}&param2=${formData['lastName'] || ''}`
        }
      }
      if (key === 'reviewId' && formData[key].trim().length > 0) {
        url = "ftls/search";
        if (url.indexOf('?') === -1) {
          url += `?option=reviewId&`
        }
        url += `param1=${formData['reviewId']}`
      }
      if (key === 'rtl' && formData[key].trim().length > 0) {
        url = "ftls/search";
        if (url.indexOf('?') === -1) {
          url += `?option=RTL&`
        }
        url += `param1=${formData['rtl']}`
      }
      // if (i === 0) {
      //   url += `?${key}=${formData[key]}`;
      // } else {
      //   url += `&${key}=${formData[key]}`;
      // }
    });
    get(url)
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          // setFormData({})
          setData(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  return (
    <div className="py-10 text-left">
      <TitlePrimary title={"Select an Review Team Lead"} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 mt-5"
      >
        <h6>Search Option</h6>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input
              label="First Name"
              id="firstName"
              name="firstName"
              value={formData.firstName ? formData.firstName : ""}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formData.lastName ? formData.lastName : ""}
            />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input
              label="Review ID"
              id="reviewId"
              name="reviewId"
              value={formData.reviewId ? formData.reviewId : ""}
            />
          </div>
          <div className="w-[200px]">
            <Input
              label="RTL"
              id="rtl"
              name="rtl"
              value={formData.rtl ? formData.rtl : ""}
            />
          </div>
        </div>

        <div className="flex gap-6">
          <div className="w[200px]">
            <PrimaryButton
              type="submit"
              label="Search Now"
              className={"px-3 py-2"}
            />
          </div>
          <div className="w[200px]">
            <TertiaryButton
              type="button"
              label="Add New"
              className={"px-3 py-2"}
              onClick={() => navigate("/rtl/add-new")}
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-5 text-left mt-6">
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
        {loading ? <Loader /> : <SearchResults data={data} />}
      </div>
    </div>
  );
}
