import React from "react";
import Table from "../table/table.jsx";
import { DynamicTable } from "../table/tableWithTotal.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const CompletedSearchResults = ({ data, display }) => {
  const columns = [
    {
      field: "rev_name",
      headerName: "Reviewer",
    },
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      width: "200px",
    },
    {
      field: "Name",
      headerName: "Hotel Names",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
    },
    {
      field: "actual_amount",
      headerName: "Actual",
    },
    {
      field: "variance",
      headerName: "Variance",
    },
    {
      field: "rec_date",
      headerName: "Recn.On",
      type:"date"
    },
    {
      field: "recn_by",
      headerName: "Recn.By",
    },
  ];
  const columns2 = [
    {
      field: "trip_id",
      headerName: "Review ID",
      searchable: true,
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
      searchable: true,
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      searchable: true,
    },
    {
      field: "Name",
      headerName: "Hotel Names",
      totalLabel: "Review Total",
      searchable: true,
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate: "sum",
      amount: true,
      searchable: true,
    },
    {
      field: "actual_amount",
      headerName: "Actual",
      aggregate: "sum",
      amount: true,
      searchable: true,
    },
    {
      field: "variance",
      headerName: "Variance",
      aggregate: "sum",
      amount: true,
      searchable: true,
    },
    {
      field: "recn_on",
      headerName: "Recn.On",
      searchable: true,
      type: "date",
      time: true,
    },
    {
      field: "recn_by",
      headerName: "Recn.By",
      searchable: true,
    },
  ];
  //data = tempData;

  console.log(data, 'data')

  if (display && display == "review") {
    return (
      <DynamicTable columns={columns2} data={data} groupByField="trip_id" />
    );
  } else if (display && display == "reviewer") {
    return <Table columns={columns} data={data} />;
  } else {
    return "";
  }
};

export default CompletedSearchResults;
