import React, { useState, useEffect } from "react";
import Input from "../../../../inputs/input";
import Select from "../../../../inputs/select";
import InputDate from "../../../../inputs/date";
import TextWithBadge from "../../../../inputs/text-with-badge";
import PrimaryButton from "../../../../buttons/primary";
import { get, post } from "../../../../../services";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import TertiaryButton from "../../../../buttons/tertiary";
import { toast, Bounce } from "react-toastify";
import Loader from "../../../../loader";

import { useStore } from "../../../../../store";
export default function HotelInfo({ trip_details_uid, errors }) {
  const { ssoUserID } = useStore();
  const [hotelLists, setHotelLists] = useState([]);
  const [hotelAssigned, setHotelAssigned] = useState([]);
  const [reviewersHotel, setReviewersHotel] = useState({});
  const [conusDetails, setConusDetails] = useState({});
  const [allErrors, setAllErrors] = useState(errors);
  const [selectedHotel, setSelectHotel] = useState("");
  const [isHotelSelected, setHotelSelected] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Promise.all([
      get(`reviews/tis_hotel_list?trip_detail_uid=${trip_details_uid}`),
      get(
        `reviews/tis_assigned_hotel_list?trip_details_uid=${trip_details_uid}`
      ),
    ])
      .then(([hotels, assigned]) => {
        if (hotels && hotels.length > 0) {
          setHotelLists(
            hotels
              .map((s) => {
                var temp = {};
                temp["label"] = s.name;
                temp["value"] = s.trip_hotel_uid;
                return temp;
              })
              .filter((d) => d.value != null)
          );
          setSelectHotel(hotels[0].trip_hotel_uid)
        }

        if (assigned && assigned.length > 0) {
          setHotelAssigned(
            assigned.map((s) => {
              var temp1 = {};
              temp1["label"] = s.name;
              temp1["value"] = s.reviewers_hotel_uid;
              return temp1;
            })
          );
          if (assigned[0] && assigned[0].reviewers_hotel_uid) {
            getReviewersHotel(assigned[0].reviewers_hotel_uid);
          }
        }
        // if()
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getReviewersHotel = (hotel_id) => {
    get(`reviews/tis_reviewers_hotel?reviewers_hotel_uid=${hotel_id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setReviewersHotel(res[0]);

          if (res[0].trip_hotel_uid) {
            getConusDetails(res[0].trip_hotel_uid)
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getConusDetails = (trip_hotel_uid) => {
    get(
      `reviews/tis_conus_rate?trip_hotel_uid=${trip_hotel_uid}`
    )
      .then((conus) => {
        if (conus && conus.length > 0) {
          setConusDetails(conus[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const deleteAssignedHotel = () => {
    post(`reviews/tis_delete_reviewers_hotel`, {
      reviewers_hotel_uid: reviewersHotel?.reviewers_hotel_uid,
      "userId": ssoUserID
    })
      .then((res) => {
        if (res) {
          toast.success('Success', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const handleUpdate = () => {
    const allExistingErrors = { ...errors };
    let errorOccurred = false;
    if (!reviewersHotel['BookedRate']) {
      errorOccurred = true;
      allExistingErrors['BookedRate'] = `Booked Rate is required`
    }
    if (!reviewersHotel['Taxes']) {
      errorOccurred = true;
      allExistingErrors['Taxes'] = `Taxes is required`
    }
    if (!reviewersHotel['MIDays']) {
      errorOccurred = true;
      allExistingErrors['MIDays'] = `M&I Days is required`
    }
    if (reviewersHotel['BookedRate'] && isNaN(reviewersHotel['BookedRate'])) {
      errorOccurred = true;
      allExistingErrors['BookedRate'] = `Booked Rate contains invalid characters`
    }
    if (reviewersHotel['Taxes'] && isNaN(reviewersHotel['Taxes'])) {
      errorOccurred = true;
      allExistingErrors['Taxes'] = `Taxes contains invalid characters`
    }
    if (reviewersHotel['MIDays'] && isNaN(reviewersHotel['MIDays'])) {
      errorOccurred = true;
      allExistingErrors['MIDays'] = `M&I Days contains invalid characters`
    }
    if (!reviewersHotel['FirstNight']) {
      reviewersHotel['FirstNight'] = dayjs.utc(reviewersHotel?.FirstNight).format("YYYY-MM-DD")
    }
    if (!reviewersHotel['LastNight']) {
      reviewersHotel['LastNight'] = dayjs.utc(reviewersHotel?.LastNight).format("YYYY-MM-DD")
    }
    if (reviewersHotel['FirstNight'] && reviewersHotel['LastNight']) {
      const date1 = new Date(reviewersHotel['FirstNight']);
      const date2 = new Date(reviewersHotel['LastNight']);
      const diffTime = date2 - date1;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 0) {
        errorOccurred = true;
        allExistingErrors['FirstNight'] = `Error - First Night should be prior to Last Night`
      }
    }
    console.log("allExistingErrors", allExistingErrors);
    setAllErrors(allExistingErrors)
    if (errorOccurred) {
      return;
    }
    if (isHotelSelected) {
      post(`reviews/tis_add_reviewers_hotel`, {
        ...reviewersHotel,
        reviewers_hotel_uid: `${reviewersHotel?.reviewers_hotel_uid}`,
        trip_details_uid: trip_details_uid,
        "userId": ssoUserID
      })
        .then((res) => {
          if (res) {

            toast.success('Updated Successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Failed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
         
        });
    } else {
      post(`reviews/tis_update_reviewers_hotel`, {
        ...reviewersHotel,
        reviewers_hotel_uid: `${reviewersHotel?.reviewers_hotel_uid}`,
        "userId": ssoUserID
      })
        .then((res) => {
          if (res) {
            // window.location.reload();
            toast.success('Updated Successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    //}
  };

  const handleChange = (e) => {
    if (e.target.name && e.target.value) {
      setReviewersHotel((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setReviewersHotel((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const handleHotelChange = (e) => {
    if (e.target && e.target.value) {
      setSelectHotel(e.target.value);
    }
  };

  const handleAssignedHotelHotelChange = (e) => {
    if (e.target && e.target.value) {
      //setHotelAssigned(e.target.value);
      getReviewersHotel(e.target.value)
    }
  }

  const selectHotel = () => {
    setLoading(true)
    if (selectedHotel) {
      setHotelSelected(true);
      get(`reviews/tis_review_hotel_view?trip_hotel_uid=${selectedHotel}`)
        .then((res) => {
          if (res) {
            console.log(res, "resssssss");
            if (res && res.length > 0) {
              var tempVal = { ...res[0] }

              getConusDetails(selectedHotel)

              setReviewersHotel(tempVal);
            }
          }
          setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
        });
    }
  };

  console.log(reviewersHotel?.FirstNight, 'reviewersHotel?.FirstNight')

  return (
    <form
      //onSubmit={(e) => handleUpdate(e)}
      onChange={handleChange}
      className="w-full p-3 text-left flex flex-col gap-3"
    >
      {
        loading ?<Loader /> : <div className="flex flex-col gap-3">
          <div className="flex gap-3 w-full">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full">
            <TextWithBadge
              name=""
              id=""
              bg="bg-[#E0E7FF]"
              className="text-[#4F46E5] w-full bg-[#E0E7FF]"
              value={`Number Of Hotel Booked: ${hotelAssigned ? hotelAssigned.length : 0}`}
            />
          </div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex gap-6 items-center">
              <div className="">
                <Select
                  label="Hotel List"
                  onChange={handleHotelChange}
                  selected={selectedHotel}
                  options={hotelLists}
                  className={"!max-w-full"}
                />
              </div>
              <TertiaryButton
                label="Select This Hotel"
                type="button"
                onClick={() => selectHotel()}
                className={"py-1 px-3 h-[40px] mt-auto"}
              />
            </div>
            <div className="w-3/6">
              <Select label="Hotels Assigned" className={"!max-w-full"} onChange={handleAssignedHotelHotelChange} options={hotelAssigned} />
            </div>
          </div>
          
          <div className="flex gap-3">
            <InputDate
              label="First Night"
              name="FirstNight"
              id="FirstNight"
              defaultValue={reviewersHotel && reviewersHotel.FirstNight && dayjs.utc(reviewersHotel?.FirstNight).format("YYYY-MM-DD")}
              // value={dayjs(reviewersHotel?.FirstNight).format("YYYY-MM-DD")}
              required
              isErrorOccurred={allErrors?.FirstNight}
            />
            <InputDate
              label="Last Night"
              name="LastNight"
              id="LastNight"
              defaultValue={reviewersHotel && reviewersHotel.LastNight && dayjs.utc(reviewersHotel?.LastNight).format("YYYY-MM-DD")}
              required
              isErrorOccurred={allErrors?.LastNight}
            />
            <Input
              label="Number Of Nights"
              name="NRHotelNights"
              id="NRHotelNights"
              size="sm"
              value={reviewersHotel?.NRHotelNights}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-3/6 flex flex-col gap-3">
          <div className="flex gap-3  w-4/6">
            <Input
              name="BookedRate"
              type="number"
              id="BookedRate"
              min={1}
              label="Booked Rate ($)"
              className="w-[100px]"
              value={reviewersHotel?.BookedRate}
              required
              isErrorOccurred={allErrors?.BookedRate}
            />
            <Input
              name="Taxes"
               type="number"
              id="Taxes"
              min={1}
              label="Taxes ($)"
              value={reviewersHotel?.Taxes}
              className="w-[100px]"
              required
              isErrorOccurred={allErrors?.Taxes}
            />
          </div>
          <div>
            <TextWithBadge
              bg="bg-[#E0E7FF]"
              className="text-[#4F46E5] w-3/12 text-center"
              name="lodging"
              id="lodging"
               type="number"
               min={1}
              value={conusDetails?.lodging || "Conus: -"}
            />
          </div>
          <div className="w-3/6">
            <div className="w-4/6">
              <Input
                label="Confirmation #"
                name="ConfirmNumber"
                id="ConfirmNumber"
                value={reviewersHotel?.ConfirmNumber}
              />
            </div>
          </div>
        </div>
        <div className="w-3/6 flex flex-col gap-3">
          <div className="flex gap-3 w-4/6">
            <Input
              name="MIDays"
              id="MIDays"
              label="M&I Days"
              type="number"
              min={1}
              className="w-[100px]"
              value={reviewersHotel?.MIDays}
              required
              isErrorOccurred={allErrors?.MIDays}
            />
            <Input
              name="Sleeping_Room_InternetCharge"
              id="Sleeping_Room_InternetCharge"
              label="Sleeping Room Internet Rate"
              className="w-[100px]"
              value={reviewersHotel?.Sleeping_Room_InternetCharge}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-3">
          {isHotelSelected ? (
            <></>
          ) : (
            <button
              onClick={() => deleteAssignedHotel()}
              type="button"
              className="border-none bg-none font-semibold text-red-700 px-3 py-2"
            >
              Delete Assigned Hotel
            </button>
          )}

          <PrimaryButton
            className={"px-3 py-2"}
            label={"Save Changes"}
            type="button"
            onClick={handleUpdate}
          ></PrimaryButton>
        </div>
      </div>
        </div>
      }
      
    </form>
  );
}
