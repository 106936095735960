import React from "react";
import Table from "../table/table.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const RentalCarSearchResults = ({ data }) => {
  const columns = [
    {
      field: "reviewer",
      headerName: "Reviewer",
    },
    {
      field: "trip_id",
      headerName: "Trip ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "Name",
      headerName: "Misc. C/A",
    },
    {
      field: "estimated_amount",
      headerName: "Rnetal Car",
    },
    {
      field: "actual_amount",
      headerName: "Reconcile",
    }
  ];

  return <Table columns={columns} data={data} />; 
  
};

export default RentalCarSearchResults;
