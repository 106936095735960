// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-layout-auto-height{
    min-height: 0 !important;
}

.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-container{
    min-height: 42px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/table/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".ag-layout-auto-height{\n    min-height: 0 !important;\n}\n\n.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-dark .ag-layout-print .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-quartz-auto-dark .ag-layout-print .ag-center-cols-container{\n    min-height: 42px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
