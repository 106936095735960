import React from "react";
import Table from "../table/table.jsx";

const SearchResults = ({ data }) => {
  const columns = [
    {
      field: "ftl_status",
      headerName: "Status",
    },
    {
      field: "ftl_uid",
      headerName: "RTL ID",
    },
    {
      field: "fname",
      headerName: "RTL Name",
      url: "/rtl/details",
      urlParams: ["ftl_uid"],
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "phone",
    }
  ];

  return <Table columns={columns} data={data} />;
};

export default SearchResults;
