import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";
import InputDate from "../../components/inputs/date";
import InputTime from "../../components/inputs/time";
import dayjs from "dayjs";
import Select from "../../components/inputs/select";
import TextWithBadge from "../../components/inputs/text-with-badge";
import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import { get, post } from "../../services";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { useNavigate } from "react-router-dom";
import TelePhone from "../../components/inputs/telephone";

const AddReview = () => {
  const { id, region } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [subTypes, setTypes] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [reviewInfoData, setReviewInfo] = useState({});
  const [ftlList, setFTLList] = useState([]);
  const [rrcList, setRRCList] = useState([]);


  useEffect(() => {

    Promise.all([
      get(`lookup/training_list?review_id=${id}`),
      get(`lookup/rrc_list?review_id=${id}`),
      get(`lookup/ftl_list?review_id=${id}`)
    ]).then(([trainingList, rrcList, FtlList]) => {
      if (trainingList instanceof Array && trainingList.length > 0) {
        setTypes(
          trainingList.map((s) => {
            var temp = {};
            temp["label"] = s.sub_type;
            temp["value"] = s.sub_type;
            return temp;
          })
        );
      }
      if (rrcList instanceof Array && rrcList.length > 0) {
        setRRCList(
          rrcList.map((s) => {
            var temp = {};
            temp["label"] = `${s?.FirstName} ${s?.LastName}`;
            temp["value"] = s?.rrc_uid;
            return temp;
          })
        );
      }
      if (FtlList instanceof Array && FtlList.length > 0) {
        setFTLList(
          FtlList.map((s) => {
            var temp = {};
            temp["label"] = `${s?.FName} ${s?.LName}`;
            temp["value"] = s?.FTL_UID;
            return temp;
          })
        );
      }
      setLoading(false);
    }).catch(err => {
      console.error(err)
      setLoading(false);
    })

    // get(`lookup/training_list?review_id=${id}`)
    //   .then((res) => {
    //     console.log(res, "res");
    //     if (res && res.length > 0) {
    //       setTypes(
    //         res.map((s) => {
    //           var temp = {};
    //           temp["label"] = s.sub_type;
    //           temp["value"] = s.sub_type;
    //           return temp;
    //         })
    //       );
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.error(err);
    //   });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(reviewInfoData);
    const errorMapFields = {
      name: 'Name',
      address: 'Address',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      county: 'County',
      phone: 'Phone',
      user_id: "UserId",
      MeetingPlace: 'Meeting Place',
      arrival_city: 'Arrival City',
      departure_city: 'Departure City',
      arrival_time: 'Aarrival Time',
      departure_time: 'Departure Time',
      MeetingTime: 'Meeting Time',
      ExitTime: 'Exit Time',
      arrival_date: 'Arrival Date',
      MeetingDate: 'Meeting Date',
      ExitDate: 'Exit Date',
      departure_date: 'Departure Date'
    }
    var tempReviewInfo = {
      // granteeID: reviewInfoData?.granteeID,
      // name : reviewInfoData?.name, 
      // Address : reviewInfoData?.Address, 
      // address2 : reviewInfoData?.Address, 
      // City : reviewInfoData?.City, 
      // State : reviewInfoData?.City, 
      // County : reviewInfoData?.City, 
      // ZipCode : reviewInfoData?.ZipCode, 
      // Phone : reviewInfoData?.Phone, 
      // fax : reviewInfoData?.fax, 
      region: "",
      name: reviewInfoData?.name,
      address: reviewInfoData?.address,
      address2: reviewInfoData?.address2,
      city: reviewInfoData?.city,
      state: reviewInfoData?.state,
      zip: reviewInfoData?.zip,
      county: reviewInfoData?.county,
      phone: reviewInfoData?.phone,
      fax: reviewInfoData?.fax,
      contact: reviewInfoData?.contact,
      director: reviewInfoData?.director,
      has_delegate: reviewInfoData?.has_delegate,
      is_delegate_of: reviewInfoData?.is_delegate_of,
      email: "",
      fed_grantee_id: "",
      ehs_grantee_id: "",
      user_id: "HEADSTART",
      MeetingPlace: reviewInfoData?.MeetingPlace,
      arrival_city: reviewInfoData?.arrival_city,
      departure_city: reviewInfoData?.departure_city,
      arrival_time: reviewInfoData?.arrival_time,
      departure_time: reviewInfoData?.departure_time,
      MeetingTime: reviewInfoData?.departure_time,
      ExitTime: reviewInfoData?.ExitTime,
      arrival_date: reviewInfoData?.arrival_date,
      MeetingDate: reviewInfoData?.MeetingDate,
      ExitDate: reviewInfoData?.ExitDate,
      departure_date: reviewInfoData?.departure_date
    };

    const allErrorFields = {}
    Object.keys(errorMapFields).map(item => {
      console.log("tempReviewInfo", tempReviewInfo[item])
      if (!tempReviewInfo[item]) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} is required`
      }
      if (typeof tempReviewInfo[item] === 'string' && tempReviewInfo[item].trim().length === 0) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} is required`
      }
      if (typeof tempReviewInfo[item] === 'string' && tempReviewInfo[item].includes("'")) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} contains illegal character(').`
      }
      if (item === 'MeetingDate' || item === 'ExitData') {
        if (typeof tempReviewInfo[item] === 'string') {
          const date1 = new Date(tempReviewInfo['MeetingDate']);
          const date2 = new Date(tempReviewInfo['ExitDate']);
          const diffTime = date2 - date1;
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          const diffMonths = Math.floor(diffDays / 30);
          //const diffMonths = date2.getMonth() - date1.getMonth() + 12 * (date2.getFullYear() - date1.getFullYear());
          console.log("diff months", diffDays);
          if (diffMonths > 12) {
            allErrorFields['ExitDate'] = `Error - Exit date should be with in Fiscal Year`
          }
          if (diffDays < 0) {
            allErrorFields['MeetingDate'] = `Error - Meeting Date should be prior to Exit date`
          }
        }
      }
    });
    setErrorFields(allErrorFields);
    console.log("allErrorFields", allErrorFields)

    if (Object.keys(allErrorFields).length > 0) {
      return;
    }

    const reqKeys = Object.keys(tempReviewInfo).map(d => d)

    var tempReq = {}
    tempReq["review_id"] = id
    tempReq["UserId"] = "HEADSTART"

    Object.entries(reviewInfoData).forEach(([key, value]) => {
      if (!reqKeys.includes(key)) {
        tempReq[key] = value
      }
    })

    if (!(tempReq && tempReq.rrc_uid) && rrcList.length > 0) {
      tempReq["rrc_uid"] = rrcList[0].value
    }
    // if(!(tempReq && tempReq.FTL_UID) && ftlList.length>0){
    //   tempReq["FTL_UID"] = ftlList[0].value
    // }
    if (!(tempReq && tempReq.sub_type) && subTypes.length > 0) {
      tempReq["sub_type"] = subTypes[0].value
    }

    //const resp = []

    await post(`grantees/add_grantee`, tempReviewInfo)
      .then(async (res) => {
        console.log(res);
        if (res && res.length > 0) {
          //resp.push('addGrantee')
          if (res[0] && res[0].Grantee_uid) {
            tempReq["grantee_uid"] = res[0].Grantee_uid
            await post(`reviews/update_details`, tempReq)
              .then((res) => {
                console.log(res, 'updatede');
                if (res) {
                  //resp.push("UpdateDetails")
                  navigate('/reviews')
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }

        }
      })
      .catch((err) => {
        console.error(err);
      });

    // await post(`reviews/update_details`, tempReq)
    //   .then((res) => {
    //     console.log(res, 'updatede');
    //     if(res){
    //       resp.push("UpdateDetails")
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    //   console.log(resp, 'resp')
    //   if(resp.length >= 2){
    //     navigate('/reviews')
    //   }
  };

  const handleChange = (e) => {
    console.log(e.target.value, "e.target");
    if (e.target.name && e.target.value) {
      if (e.target.type === "time") {
        const value = e.target.value;
        const splitTime = value.split(":");
        console.log(splitTime, "splitTime");
        setReviewInfo((prev) => {
          return {
            ...prev,
            [e.target.name]: dayjs(prev[e.target.name])
              .set("hour", splitTime[0])
              .set("minute", splitTime[1])
              .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          };
        });
      } else {
        setReviewInfo((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target.name) {
      setReviewInfo((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  console.log(reviewInfoData, 'reviewInfoData')

  return (
    <>
      {loading ? (
        <div>...loading</div>
      ) : (
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <div className="flex-2">
            <div>
              <PrimaryBreadCrumbs label="Review Search" path="/reviews" />

              <div className="flex justify-between">
                <TitlePrimary title={"Add a New Review"} />
              </div>
              <div className="mt-10 text-left">
                <div className="flex flex-col gap-8">
                  <h6 className="font-semibold text-[18px]">
                    Grantee/Training Location Info
                  </h6>
                  <div className="flex gap-8">
                    <div>
                      <TextWithLabel label="Review ID" value={id} />
                    </div>
                    <div>
                      <TextWithLabel label="Grantee Region" value={region} />
                    </div>
                  </div>
                  <div className="w-[192px]">
                    <Input name="granteeID" id="granteeID" label="Grantee ID" required />
                  </div>
                  <div className="flex gap-8">
                    <div className="w-[400px]">
                      <Input name="name" id="name" label="Name" required isErrorOccurred={errorFields.name ? errorFields.name : false} />
                    </div>
                    <div className="w-[400px]">
                      <Input name="address" id="address" label="Address" required isErrorOccurred={errorFields.address ? errorFields.address : false} />
                    </div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-[400px]">
                      <Input name="city" id="city" label="City" required isErrorOccurred={errorFields.city ? errorFields.city : false} />
                    </div>
                    <div className="w-[88px]">
                      <Input name="state" id="state" label="State" required maxLength="2" isErrorOccurred={errorFields.state ? errorFields.state : false} />
                    </div>
                    <div className="w-[192px]">
                      <Input name="zip" id="zip" label="Zip Code" required maxLength="10" isErrorOccurred={errorFields.zip ? errorFields.zip : false} />
                    </div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-[400px]">
                      <Input name="county" id="county" label="County" required isErrorOccurred={errorFields.county ? errorFields.county : false} />
                    </div>
                    <div className="w-[400px]">
                      <TelePhone name="phone" id="phone" label="Phone" maxLength="10" required isErrorOccurred={errorFields.phone ? errorFields.phone : false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[1px] bg-gray-300 w-full mt-10 my-4"></div>
          <div className="w-full">
            <div className="w-full">
              <div className="mt-10 text-left">
                <div className="flex flex-col gap-8">
                  <h6 className="font-semibold text-[18px]">
                    Review/Training Info
                  </h6>
                  <div className="flex flex-row gap-8 text-left">
                    <div className="w-[192px]">
                      <InputDate
                        label="Arrival Date"
                        name="arrival_date"
                        id="arrival_date"
                        required
                        isErrorOccurred={errorFields.arrival_date ? errorFields.arrival_date : ''}
                      //  value={dayjs().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="w-[192px]">
                      <InputTime
                        label="Arrival Time"
                        name="arrival_time"
                        id="arrival_time"
                        required
                        isErrorOccurred={errorFields.arrival_time ? errorFields.arrival_time : ''}
                      //value={dayjs().format("HH:mm")}
                      />
                    </div>
                    <div className="w-[400px]">
                      <Input
                        required
                        label="Arrival Place"
                        name="arrival_city"
                        id="arrival_city"
                        isErrorOccurred={errorFields.arrival_city ? errorFields.arrival_city : false}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8 text-left">
                    <div className="w-[192px]">
                      <InputDate
                        label="Meeting Date"
                        name="MeetingDate"
                        id="MeetingDate"
                        required
                        isErrorOccurred={errorFields.MeetingDate ? errorFields.MeetingDate : ''}
                      //  value={dayjs().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="w-[192px]">
                      <InputTime
                        label="Meeting Time"
                        name="MeetingTime"
                        id="MeetingTime"
                        required
                        isErrorOccurred={errorFields.MeetingTime ? errorFields.MeetingTime : ''}
                      // value={dayjs().format("HH:mm")}
                      />
                    </div>
                    <div className="w-[400px]">
                      <Input
                        required
                        label="Meeting Place"
                        name="MeetingPlace"
                        id="MeetingPlace"
                        isErrorOccurred={errorFields.MeetingPlace ? errorFields.MeetingPlace : false}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8  text-left">
                    <div className="w-[192px]">
                      <InputDate
                        label="Exit Date"
                        name="ExitDate"
                        id="ExitDate"
                        required
                        isErrorOccurred={errorFields.ExitDate ? errorFields.ExitDate : ''}
                      // value={dayjs().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="w-[192px]">
                      <InputTime
                        label="Exit Time"
                        name="ExitTime"
                        id="ExitTime"
                        required
                        isErrorOccurred={errorFields.ExitTime ? errorFields.ExitTime : ''}
                      // value={dayjs().format("HH:mm")}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8  text-left">
                    <div className="w-[192px]">
                      <InputDate
                        label="Departure Date"
                        name="departure_date"
                        id="departure_date"
                        required
                        isErrorOccurred={errorFields.departure_date ? errorFields.departure_date : ''}
                      />
                    </div>
                    <div className="w-[192px]">
                      <InputTime
                        label="Departure Time"
                        name="departure_time"
                        id="departure_time"
                        required
                        isErrorOccurred={errorFields.departure_time ? errorFields.departure_time : ''}
                      />
                    </div>
                    <div className="w-[400px]">
                      <Input
                        required
                        label="Departure Place"
                        name="departure_city"
                        id="departure_city"
                        isErrorOccurred={errorFields.departure_city ? errorFields.departure_city : false}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8  text-left">
                    <div className="w-[192px]">
                      <TextWithBadge
                        label="Review Type"
                        name=""
                        id=""
                        value={"Training"}
                        bg="bg-none"
                        className={
                          "border border-indigo-600 w-[70px] text-indigo-600 px-1 py-1"
                        }
                      />
                    </div>
                    <div className="w-[400px]">
                      <Select
                        label="Review Sub Type"
                        name="sub_type"
                        id="sub_type"
                        options={subTypes}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8  text-left">
                    <div className="w-[400px]">
                      <Select
                        label="Responsible RP"
                        name="rrc_uid"
                        id="rrc_uid"
                        options={rrcList}
                      />
                    </div>
                    <div className="w-[192px]">
                      <Select
                        label="FTL Region"
                        name="rrc_uid"
                        id="rrc_uid"
                        options={[{ label: "13", value: 13 }]}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-8  text-left">
                    <div className="w-[400px]">
                      <Select label="FTL" name="FTL_UID" id="FTL_UID" options={ftlList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 my-10">
              <SecondaryButton className={"py-3 px-10"} onClick={() => navigate('/reviews')} label={"Cancel"} />
              <PrimaryButton
                type="submit"
                className={"py-3 px-10"}
                label={"Update"}
              />{" "}
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default AddReview;
