import React from "react";
import Table from "../table/table";
import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const ReviewsDataTable = ({ reviewsData }) => {

  const columns = [
    {
      headerName: "Review ID",
      field: "review_id",
      url: "/reviews/details",
      urlParams: ["review_id"],
      minWidth: 100,
      maxWidth: 150,
      cellStyle: { textAlign: "left" }
    },
    {
      headerName: "Grantee ID",
      field: "fed_grantee_id",
      minWidth: 100,
      maxWidth: 150,
      cellStyle: { textAlign: "left" }
    },
    {
      headerName: "Grantee Name",
      field: "Name",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: "left" }
    },
    {
      headerName: "Review Date",
      field: "review_date",
      minWidth: 120,
      maxWidth: 180,
      cellStyle: { textAlign: "left" }
      // dateFilter: true,
      // type: "date",
    },
    {
      headerName: "Partial Team Completion",
      field: 'Partial Team Completion',
      cellStyle: { textAlign: "left" },
      cellRenderer: function ({ data }) {
        return `${data?.partial_team_completed ? dayjs.utc(data?.partial_team_completed).format("MM/DD/YYYY hh:mm A") : ''} - ${data?.partial_team_completed_user ? data?.partial_team_completed_user : ''}`
      }
    },
    {
      headerName: "Final Submission Date",
      field: 'Final Submission Date',
      cellStyle: { textAlign: "left" },
      cellRenderer: function ({ data }) {
        return `${data?.final_submitted_date ? dayjs.utc(data?.final_submitted_date).format("MM/DD/YYYY hh:mm A") : ''} - ${data?.final_submitted_user ? data?.final_submitted_user : ''}`
      }
    }
  ];



  return <Table columns={columns} data={reviewsData} />;
};



export default ReviewsDataTable;
