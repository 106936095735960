import React, { useState } from "react";
import LayoutSecondary from "../../components/layouts/secondary";
import Select from "../../components/inputs/select";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import { post } from "../../services";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";
// import { post } from "../../services";

import { toast, Bounce } from "react-toastify";

export default function Uploads() {
  const navigate = useNavigate();
  const { ssoUserID } = useStore();

  const [selectedItem, setSelectItem] = useState(1);
  const items = [
    {
      label: "Air Tickets Itinerary",
      value: 1,
      buttonLabel: "Process Air Tickets",
    },
    {
      label: "Air Tickets",
      value: 2,
      buttonLabel: "Process Air Tickets",
    },
    {
      label: "Car Rentals",
      value: 3,
      buttonLabel: "Process Car Rentals",
    },
    {
      label: "Hotel Data",
      value: 4,
      buttonLabel: "Process Hotel Data",
    },
    {
      label: "Checks",
      value: 5,
      buttonLabel: "Process Checks Data",
    },
  ];
  const handleUploadItem = (e) => {
    setSelectItem(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (e && e.target.data && e.target.data.value) {
      console.log(e.target.data.value, "jahfaghs");

      var URI = "";
      var URI2 = "";

      var temp = {};
      temp["user_id"] = ssoUserID;

      if (selectedItem == 1) {
        URI = "upload/air_process_input";
        URI2 = "upload/air_input_tickets_itinerary";
        temp["air_data"] = e.target.data.value;
      } else if (selectedItem == 2) {
        URI = "upload/air_process_input";
        URI2 = "upload/air_input_tickets";
        temp["air_data"] = e.target.data.value;
      } else if (selectedItem == 3) {
        URI = "upload/car_process_input";
        URI2 = "upload/car_input_rental";
        temp["car_data"] = e.target.data.value;
      } else if (selectedItem == 4) {
        URI = "upload/hotel-data";
      } else if (selectedItem == 5) {
        URI = "upload/upload_checks_process_input";
        URI2 = "upload/upload_checks_input_checks";
        temp["checks_data"] = e.target.data.value;
      }

      post(URI, temp)
        .then((res) => {
          if (res) {
            post(URI2, { input_user_code: ssoUserID })
            .then((res) => {
              if (res) {
                if (selectedItem == 1) {
                  navigate("/uploads/upload-air-ticket-itn");
                } else if (selectedItem == 2) {
                  navigate("/uploads/upload-air-ticket");
                } else if (selectedItem == 3) {
                  navigate("/uploads/upload-car-rental");
                }
                else if (selectedItem == 5) {
                  navigate("/uploads/upload-checks");
                }
              }
            })
            .catch((err) => {
              console.error(err);
              toast.error('Something went wrong. Try Different Inputs', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong. Try Different Inputs', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  };

  const filteredItem = items.filter((d) => d.value == selectedItem);

  return (
    <LayoutSecondary
      //bCrumpLabel={"User Search"}
      //bCrumpPath={"/profile-maintenance"}
      title="Uploads"
    >
      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <Select
          options={items}
          label="Select an Upload"
          onChange={handleUploadItem}
          selected={selectedItem}
        />

        <div>
          <Input
            type="textArea"
            id="data"
            name="data"
            required={true}
            className="min-h-[300px]"
            label="Enter Data"
          ></Input>
        </div>
        <div className="flex">
          <PrimaryButton
            label={filteredItem[0].buttonLabel}
            className={"min-w-[100px] p-2"}
            type="submit"
          />
        </div>
      </form>
    </LayoutSecondary>
  );
}
