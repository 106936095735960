import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../../buttons/primary";
// import DataTable from "react-data-table-component";

export default function Honoraria({ data }) {
  // const columns = [
  //   {
  //     name: <div className="uppercase text-md text-gray-600">ITEM</div>,
  //     selector: (row) => row.item,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">RATE</div>,
  //     selector: (row) => row.rate,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">DAYS/FACTOR</div>,
  //     selector: (row) => row.days,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">ENCUMBRANCE</div>,
  //     selector: (row) => row.encumbrance,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">CASH ADVANCE</div>,
  //     selector: (row) => row.cashAdvance,
  //     sortable: true,
  //   },
  // ];
  // const data = [
  //   {
  //     item: "Honoraria",
  //     rate: "$375.00",
  //     days: "4",
  //     encumbrance: "$1,500",
  //     cashAdvance: "$95.20",
  //   },
  //   {
  //     item: "M&I",
  //     rate: "$64",
  //     days: "1.75",
  //     encumbrance: "$112.00 X 0.85",
  //     cashAdvance: "",
  //   },
  // ];
  return (
    <div className="p-7 flex flex-col gap-10">
      <div>
        <CollapsibleTable data={data} />
        {/* <DataTable columns={columns} data={data} pagination /> */}
      </div>
    </div>
  );
}

const ExpandedDetails = ({ details }) => {
  return (
    <div className=" bg-gray-50 rounded-md shadow">
      <p className="text-gray-700">{details}</p>
    </div>
  );
};

const CollapsibleTable = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState({ 2: true, 3: true });
  const [isExpanded, setExpanded] = useState('');

  useEffect(() => {
    if(isExpanded && isExpanded == "expanded"){
      const temp = Object.assign({}, [...Array(data.length)].map((d)=> ({[d] : true})));
      console.log(temp, 'tempoo')
      setExpandedRows(temp)
    }else if(isExpanded && isExpanded == "collapsed"){
      setExpandedRows({})
    }
  }, [isExpanded])
  

  const toggleRow = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  return (
    <div className="w-full mx-autos">
      <div className="py-2 flex">
        {
          isExpanded && isExpanded == "expanded" && <PrimaryButton label={"Collapse All"} className={"p-2 ml-auto"} onClick={() => setExpanded('collapsed')}/>
        }

{
          (isExpanded && isExpanded == "collapsed" || isExpanded == "") && <PrimaryButton label={"Expand All"} className={"p-2 ml-auto"} onClick={() => setExpanded('expanded')}/>
        }
      
      </div>
      <table className="min-w-full border-collapse border border-gray-300 text-sm">
        <thead className="">
          <tr className="bg-indigo-600 text-white">
            <th className=" px-4 py-2 w-4/6"></th>{" "}
            {/*
Empty Header */}
            <th
              className=" px-4 py-2 w-1/6
text-left"
            >
              Encumbrance
            </th>
            <th
              className=" px-4 py-2 w-1/6
text-left"
            >
              Cash Advance
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <React.Fragment key={row.id}>
              <tr className="hover:bg-gray-50">
                <td
                  className="border border-gray-300 px-4 py-2 flex table-cell h-full
items-center space-x-2"
                >
                  {row.details && (
                    <button
                      className="text-gray-500 hover:text-gray-800
transform transition-transform"
                      onClick={() => toggleRow(row.id)}
                    >
                      {expandedRows[row.id] ? (
                        <span>−</span> // Collapse icon
                      ) : (
                        <span>+</span> // Expand icon
                      )}
                    </button>
                  )}

                  <span>{row.name}</span>
                </td>
                <td
                  className="border border-gray-300 px-4
py-2"
                >
                  {row.encumbrance}
                </td>
                <td
                  className="border border-gray-300 px-4
py-2"
                >
                  {row.cashAdvance}
                </td>
              </tr>

              {expandedRows[row.id] && row.details && (
                <tr className="bg-gray-50">
                  <td colSpan={3} className="border border-gray-300">
                    <ExpandedDetails details={row.details} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
