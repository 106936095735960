/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const DateWithCheckBox = ({
  label,
  disabled,
  className,
  isErrorOccurred,
  ...props
}) => {
  return (
    <div className="flex gap-6">
      <div className="flex gap-2 items-center w-[150px] flex-wrap">
        <input
          type="checkbox"
          className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
          id={props?.id}
          name={props?.name}
        />

        <label
          htmlFor={props?.id}
          className="text-sm text-gray-500 ms-2 dark:text-neutral-400 cursor-pointer"
        >
          {label}
        </label>
      </div>
      <input
        type={"date"}
        disabled={disabled}
        {...props}
        className={` ${disabled ? "bg-gray-200" : "bg-white"} border px-2 border-input-border  h-[46px] rounded-md shadow-sm ${isErrorOccurred ? "border-red-500" : ""} ${className ? className : ""}`}
      />
      {isErrorOccurred && (
        <p className="text-red-500 text-xs italic">{isErrorOccurred}</p>
      )}
    </div>
  );
};

DateWithCheckBox.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default DateWithCheckBox;
