import React from "react";
import SecondaryButton from "../../../buttons/secondary";
import { ReactComponent as TrashSvg } from "../../../../assets/icons/trash.svg";
import Table from "../../../table/table";
import { post } from "../../../../services";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { toast, Bounce } from "react-toastify";

export default function ReviewersTable({ reviewInfo, reviewid }) {
  const navigate = useNavigate();
  const { ssoUserID } = useStore();

  const deleteReviewer = (id) => {
    post(`reviewers/deleteReviewerFromTeam`, {
      "tripDetailsUid": id,
      "userId": ssoUserID
    })
      .then((res) => {
        if (res) {
              toast.success("Deleted Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
              setTimeout(() => {
                window.location.reload()
              }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Delete Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        
      });
  };

  const columns = [
   
    {
      field: "rev_name",
      headerName: "REVIEWER",
      urlParams: ["trip_details_uid", "reviewMainID"],
      url: "/reviews/reviewer-status",

    },
    {
      field: "email",
      headerName: "EMAIL",
    },
    {
      headerName: "Change In Status",
      field: "ChangeInStatus",
    },
    {
      headerName: "Data Entry Status",
      field: "DataEntryStatus",
    },
    {
      field: "Reviewer_Id",
      headerName: "DANYA ID",
    },
    {
      field: "cashAdvance",
      headerName: "TIS",
      cellRenderer: function ({data}) {
        console.log('dataaaa', data)
        return (
          <div>
            <SecondaryButton label="ViewTIS" type="button" onClick={() => navigate(`/reviews/details/${data.reviewMainID}?tab=2&detailsID=${data.trip_details_uid}`)}/>
          </div>
        );
      },
    },
    {
      field: "HeaderStatus",
      headerName: "NEW",
      cellRenderer: function ({ data }) {
        if (data && data.HeaderStatus == "NEW") {
          return (
            <div onClick={() => deleteReviewer(data.trip_details_uid)}>
              <TrashSvg className="text-xs text-red-600" width="20" />
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  console.log(reviewInfo, "reviewInforeviewInfo", reviewid);

  if (reviewInfo && reviewInfo.length > 0) {
    reviewInfo.map((d) => {
      d["reviewMainID"] = reviewid;
      return d;
    });
  }

  return (
    <div>
      {reviewInfo && reviewInfo.length > 0 ? (
        <Table columns={columns} data={reviewInfo} />
      ) : (
        <div>No Reviewers Found</div>
      )}
    </div>
  );
}
