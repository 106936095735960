import React, {useMemo} from "react";
import PropTypes from "prop-types";
// import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TrashSvg } from "../../assets/icons/trash.svg";
import { deleteMethod } from "../../services";
// import { useStore } from "../../store";
import { toast, Bounce } from "react-toastify";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

var filterParams = {
  comparator: (filterDate, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;

    if(dayjs.utc(filterDate).format('MM/DD/YYYY') == dayjs.utc(cellValue).format('MM/DD/YYYY')){
      return 0
    }else{
      return 1
    }

   
  },
  //minValidYear: 2000,
  //maxValidYear: 2021,
  inRangeFloatingFilterDateFormat: "MM/DD/YYYY hh:mm A",
};


const ReviewsDataTableBeingCreated = ({ reviewsData }) => {
  const navigate = useNavigate();
  // const { ssoUserID } = useStore();

  const deleteReviewer = (id) => {
    deleteMethod(`reviews/delete_being_created?reviewId=${id}`)
      .then(() => {
        // if (res) {
              toast.success("Deleted Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
              setTimeout(() => {
                window.location.reload()
              }, 100);
        // }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Delete Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        
      });
  };

  const handleReviewID = (val, region, fiscalYear) => {
    navigate("/reviews/add/" + val +'/'+ region,{state:{"fiscalYear":fiscalYear}});
  };

  const columnDefs = [
    {
      field:"trip_id",
      headerName:"Review ID",
      cellRenderer:(data) => {
        return  <div
        className=" text-indigo-600 text-md cursor-pointer"
        onClick={() => handleReviewID(data.value, data?.data?.region,data?.data?.fiscal_year)}
      >
        {data.value}
      </div>
      },
    },
    {
      field:"createts",
      headerName:"CREATION DATE",
      filter: "agDateColumnFilter", 
      // cellDataType:"date",
      cellRenderer:(data) => {
        return dayjs.utc(data.value).format('MM/DD/YYYY hh:mm A')
      },

      
      filterParams: filterParams,
    },
    {
      field:"userid",
      headerName:"CREATED BY"
    },
    {
      field: "HeaderStatus",
      headerName:"Delete",
      headerClass:"center-align-column",
      cellRenderer: function ({ data }) {
          return (
            <div className="cursor-pointer flex w-full items-center justify-center" onClick={() => deleteReviewer(data.trip_id)}>
              <TrashSvg className="text-xs text-red-600" width="20" />
            </div>
          )
      },
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    };
  }, []);
  // const columns = [
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Review ID</div>,
  //     selector: (row) => row.trip_id,
  //     sortable: true,
  //     cell: (row) => (
  //       <div
  //         className=" text-indigo-600 text-md cursor-pointer"
  //         onClick={() => handleReviewID(row.trip_id, row.region)}
  //       >
  //         {row.trip_id}
  //       </div>
  //     ),
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">CREATION DATE</div>,
  //     selector: (row) => row.createts,
  //     sortable: true,
  //     cell:(row) => {
  //       if(row.createts){
  //         return <div>{dayjs(row.createts).format("MM/DD/YYYY hh:mm A")}</div>
  //       }else{
  //         return <div>-</div>
  //       }
  //     }
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">CREATED BY</div>,
  //     selector: (row) => row.userid,
  //     sortable: true,
  //   }
  // ];

  return <div>
     {/* <DataTable columns={columns} data={reviewsData} pagination /> */}

     <div className='ag-theme-quartz' style={{ height: 500 }}>
        <AgGridReact rowData={reviewsData} columnDefs={columnDefs} defaultColDef={defaultColDef} pagination/>
      </div>
  </div>;
};

// Adding prop types validation
ReviewsDataTableBeingCreated.propTypes = {
  reviewsData: PropTypes.shape({
    recordsets: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          trip_id: PropTypes.number.isRequired,
          fed_grantee_id: PropTypes.number.isRequired,
          Name: PropTypes.string.isRequired,
          review_date: PropTypes.string.isRequired,
        }),
      ),
    ),
  }),
};

export default ReviewsDataTableBeingCreated;
