import Home from "./pages/Home";
import Reviews from "./pages/reviews/index";
import ReviewDetails from "./pages/reviews/details";
import Grantees from "./pages/grantees/index";
import GranteeDetails from "./pages/grantees/details";
import ViewReviewHotelDetails from "./pages/reviews/view-hotel-details";
import ComingSoon from "./pages/coming-soon";
import AddReview from "./pages/reviews/add-review";
import ReviewersStatus from "./pages/reviews/reviewers-status";
import Reviewers from "./pages/reviewers";
import ReviewerDetails from "./pages/reviewers/details";
import ChangeForm from "./pages/reviewers/change-form";
import Reports from "./pages/reports";
import GranteeHistory from "./pages/grantees/grantee-history";
// import Travel from "./pages/travel";
import HotelDetails from "./pages/travel/hotel-details";
import RentalDetails from "./pages/travel/rental-details";
import ProcessReviewsSendEmail from "./pages/reviews/process-reviews-send-email";
// import TER from "./pages/TER";
import TerNotes from "./pages/TER/ter-notes";
import Login from "./pages/login";
import NotFound from "./pages/not-found";
import AddReviewer from "./pages/reviewers/add-reviewer";
import AcceptACase from "./pages/home/accept-a-case";
import RTL from "./pages/rtl";
import AddRTL from "./pages/rtl/add-rtl";
import RTLDetails from "./pages/rtl/details";
import RRC from "./pages/rrc";
import ProfileMaintenance from "./pages/profile-maintenance";
import ProfileMaintenanceDetails from "./pages/profile-maintenance/details";
import AddProfileMaintenanceDetails from "./pages/profile-maintenance/add-new";
import EmailTemplates from "./pages/email-templates";
import Uploads from "./pages/uploads";
import UploadAirTickets from "./pages/uploads/upload-air-tickets";
import EditUploadAirTickets from "./pages/uploads/edit-upload-air-ticket";
import UploadCarRental from "./pages/uploads/upload-car-rental";
import EditUploadCarRental from "./pages/uploads/edit-upload-car-rental";
import UploadAirTicketItinerary from "./pages/uploads/upload-air-tickets-itn";
import EditUploadAirtTicketItinerary from "./pages/uploads/edit-upload-air-ticket-itn";
import UploadChecks from "./pages/uploads/upload-checks";


export const pagesWithRoutes = [
  {
    path:"/",
    component:<Home />
  },
  {
    path:"/accept-a-case/:id",
    component:<AcceptACase />
  },
  {
    path:"/reviews",
    component:<Reviews />
  },
  {
    path:"/login",
    component:<Login />,
    public:true
  },
  {
    path:"/reviews/details/:id",
    component:<ReviewDetails />
  },
  {
    path:"/grantees",
    component:<Grantees />
  },
  {
    path:"/grantees/details/:id",
    component:<GranteeDetails />
  },
  {
    path:"/reviews/:id/hotel/:hotelID/:mode",
    component:<ViewReviewHotelDetails />
  },
  {
    path:"/coming-soon",
    component:<ComingSoon />
  },
  {
    path:"/reviews/add/:id/:region",
    component:<AddReview />
  },
  {
    path:"/reviews/:id/send-email",
    component:<ProcessReviewsSendEmail />
  },
  {
    path:"/reviews/reviewer-status/:id/:reviewer_id",
    component:<ReviewersStatus />
  },
  {
    path:"/reviewers",
    component:<Reviewers />
  },
  {
    path:"/reviewers/add",
    component:<AddReviewer />
  },
  {
    path:"/reviewers/details/:id",
    component:<ReviewerDetails />
  },
  {
    path:"/reviewers/change-form/:id/:id1",
    component:<ChangeForm />
  },
  {
    path:"/reports",
    component:<Reports />
  },
  {
    path:"/grantees/history/:id",
    component:<GranteeHistory />
  },
  // {
  //   path:"/travel",
  //   component:<Travel />,
  // },
  {
    path:"/travel/hotel/:id",
    component:<HotelDetails />
  },
  {
    path:"/travel/car-rental/:id",
    component:<RentalDetails />
  },
  // {
  //   path:"/ter",
  //   component:<TER />
  // },
  {
    path:"/ter/notes/:id",
    component:<TerNotes />
  },
  {
    path:"/rtl",
    component:<RTL />
  },
  {
    path:"/rtl/details/:id",
    component:<RTLDetails />
  },
  {
    path:"/rtl/add-new",
    component:<AddRTL />
  },
  {
    path:"/rrc",
    component:<RRC />
  },
  {
    path:"/profile-maintenance",
    component:<ProfileMaintenance />
  },
  {
    path:"/profile-maintenance/details/:id",
    component:<ProfileMaintenanceDetails />
  },
  {
    path:"/profile-maintenance/add-new/:id",
    component:<AddProfileMaintenanceDetails />
  },
  {
    path:"/email-templates",
    component:<EmailTemplates />
  },
  {
    path:"/uploads",
    component:<Uploads />
  },
  {
    path:"/uploads/upload-air-ticket",
    component:<UploadAirTickets />
  },
  {
    path:"/uploads/upload-air-ticket/:id",
    component:<EditUploadAirTickets />
  },
  {
    path:"/uploads/upload-car-rental",
    component:<UploadCarRental />
  },
  {
    path:"/uploads/upload-car-rental/:id",
    component:<EditUploadCarRental />
  },
  {
    path:"/uploads/upload-air-ticket-itn",
    component:<UploadAirTicketItinerary />
  },
  {
    path:"/uploads/upload-air-ticket-itn/:id",
    component:<EditUploadAirtTicketItinerary />
  },
  {
    path:"/uploads/upload-checks",
    component:<UploadChecks />
  },
  {
    path:"*",
    component:<NotFound />
  }
]