import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ViewEditDetailTab from "../../components/reviews/tabs/ViewEditDetailTab";
import { URI_review_info } from "../../config/constants";
import PrimaryTab from "../../components/tabs/primary";
import ProcessReviewEditTab from "../../components/reviews/tabs/ProcessReviewEditTab";
import TISInputTabDetail from "../../components/reviews/tabs/TISTab/index";
import { get } from "../../services";
import UpdateAtCostLetterTab from "../../components/reviews/tabs/update-at-cost-letter-tab";
import ProcessTER from "../../components/reviews/tabs/processTER/index";
import ReviewersTab from "../../components/reviews/tabs/reviewers";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


const ReviewSearchDetails = () => {
  const { id } = useParams();
  const [reviewSearchData, setReviewSearchData] = useState({});
  const [loading, setLoading] = useState(true)
 const location = useLocation()
 const urlParams = new URLSearchParams(location.search);
 const defaultTab = urlParams.get('tab');

  useEffect(() => {
    if(id){
      get(`${URI_review_info}?review_id=${id}`).then(res=> {
        console.log(res, 'res')
        if(res){
          setLoading(false)
          setReviewSearchData(()=>{
            var d ={...res}
            return d
          })
        }
       
      }).catch(err => {
        setLoading(false)
        console.error(err)
      })
    }
  }, []);

  console.log(reviewSearchData, 'reviewSearchData')

  const tabs = [
    {
      label: "View/Edit Details",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            {
              reviewSearchData  && Object.keys(reviewSearchData).length > 0 ? <ViewEditDetailTab reviewInfo={reviewSearchData}/> : <div>No Data found</div>
            }
            
          </div>
        </div>
      ),
    },
    {
      label: "Reviewers",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <ReviewersTab reviewInfo={reviewSearchData} id={id} tripUID={reviewSearchData?.reviewInfo && reviewSearchData?.reviewInfo[0]?.trip_uid}/>
          </div>
        </div>
      ),
    },
    {
      label: "Process Reviews",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <ProcessReviewEditTab />
          </div>
        </div>
      ),
    },
    {
      label: "TIS Input",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <TISInputTabDetail />
          </div>
        </div>
      ),
    },
    {
      label: "Update Cost Letter",
      tab: <div><UpdateAtCostLetterTab /></div>,
    },
    {
      label: "Process TER",
      tab: (<div><ProcessTER /></div>),
    },
  ];
  return (
    <>
      <div className="flex-2">
        <div>
        <PrimaryBreadCrumbs label="Review Search" path="/reviews" />
          <div className="flex justify-between">
          <TitlePrimary title={`Reviews: ${id}`} />
          </div>

          <div className="my-5">
            {
              loading ? <div>..loading</div> :<PrimaryTab tabs={tabs} selected={defaultTab}/>
            }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewSearchDetails;
