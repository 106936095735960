import React from "react";
import PrimaryBreadCrumbs from "../breadcrumbs/primary";

export default function LayoutSecondary({
  children,
  bCrumpLabel,
  bCrumpPath,
  title,
}) {
  return (
    <div className="flex flex-col gap-8 text-left">
      {
        bCrumpLabel && bCrumpPath && <PrimaryBreadCrumbs label={bCrumpLabel} path={bCrumpPath} />
      }
      <div className="flex flex-col gap-6">
        {title && <h6 className="font-semibold text-[16px]">{title}</h6>}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}
