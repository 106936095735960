import React from "react";

import InputDate from "../../inputs/date";
import TertiaryButton from "../../buttons/tertiary";
import Table from "../../table/table";


export default function NewReviews() {
  const columns = [
    {
      field:"submit_type",
      headerName:"SUBMIT TYPE",
      cellRenderer:function({value}){
        const type=value
        return <div className={` w-[100px] h-[30px] flex items-center justify-center ${type == "Final"? 'bg-[#E0E7FF]' : type==="Partial"? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
            {type}
        </div>
      }
    },
    {
      field:"trip_id",
      headerName:"REVIEW ID",
      url:'/accept-a-case',
      urlParams:['reviewID']
    },
    {
      field:"review_date",
      headerName:"REVIEW DATE"
    },
    {
      field:"Name",
      headerName:"Grantee name"
    },
    {
      field:"qc_status",
      headerName:"QC STATUS",
      cellRenderer: function ({value}){
        const type=value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "In Progress"? 'bg-[#FEF3C7] text-[#92400E]' : type==="Not Started"? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
            {type}
        </div>
      }
    },
    {
      field:"user_id",
      headerName:"SENT BY",
      url:'/',
      urlParams:['sentBy']
    },
    {
      field:"creation_ts",
      headerName:"RECEIVED ON"
    }
  ]
  
  const data = [
    {
      submit_type: "Final",
      trip_id: "01234F2C",
      review_date: "01234F2C",
      Name: "Putnam County Board of Education",
      qc_status: "In Progress",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
    },
    {
      submit_type: "Partial",
      trip_id: "01234F2C",
      review_date: "01234F2C",
      Name: "Putnam County Board of Education",
      qc_status: "Not Started",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
    },
  ];
  return (
    <div className="flex flex-col gap-6">
      <h6 className="font-semibold text-[20px]">New Reviews</h6>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <InputDate label="From Date" />
        </div>
        <div className="w-[100px] mt-auto">
          <TertiaryButton label="Filter" className={"p-2"} />
        </div>
      </div>
      <Table columns={columns} data={data} />
    </div>
  );
}
