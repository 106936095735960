import React from "react";
import Table from "../../../table/table";

export default function OnlineTrainings() {
  const columns = [
    {
      field:'sa',
      headerName:'SA'
    },
    {
      field:'trained',
      headerName:'Trained'
    },
    {
      field:'trainedOn',
      headerName:'Trained On'
    }
  ]
 
  const data = [
    {
      sa: "ECD",
      trained: "NO",
      trainedOn: "",
    },
    {
      sa: "FIS",
      trained: "NO",
      trainedOn: "",
    },
  ];
  return (
    <Table columns={columns} data={data}  />
  );
}
