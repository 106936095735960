import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import './style.css'

var filterParams = {
  comparator: (filterDate, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;

    if (
      dayjs(filterDate).format("MM/DD/YYYY") ==
      dayjs(cellValue).format("MM/DD/YYYY")
    ) {
      return 0;
    } else {
      return 1;
    }
  },
  //minValidYear: 2000,
  //maxValidYear: 2021,
  inRangeFloatingFilterDateFormat: "MM/DD/YYYY HH:MM A",
};

export default function Table({ data, columns, filter = true, pagination = true }) {
  const navigate = useNavigate();
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: filter,
      floatingFilter: filter,
      resizable:true
    };
  }, []);

  const columnDefs =
    columns && columns.length > 0
      ? columns.map((c) => {
          var temp = {...c};
          temp["field"] = c.field;
          temp["headerName"] = c.headerName;

          if(c && c.type && c.type === 'action'){
            temp['filter'] = false
          }else{
            //temp['floatingFilter'] = true;
            temp['filter']= true;
          }

          if (c.cellRenderer) {
            temp["cellRenderer"] = c.cellRenderer;
          } else if (c.url && c.urlParams && c.urlParams.length > 0) {
            temp["cellRenderer"] = function ({ value, data }) {
              var URI = c.url;
              c.urlParams.forEach((d) => {
                console.log(URI, 'testURI', d, data[d])
                URI += ("/" + data[d]);
              });


              return (
                <div
                  className=" text-indigo-600 text-md cursor-pointer text-left"
                  onClick={() => navigate(URI)}
                >
                  {value}
                </div>
              );
            };
          } else if (c.type && c.type == "date") {
            temp["cellRenderer"] = function (data) {
              if(c.time && c.time === 'off'){
                return dayjs(data.value).format("MM/DD/YYYY")
              }else{
                return dayjs(data.value).format("MM/DD/YYYY HH:MM A");
              }
              
            };
            temp["filterParams"] = filterParams;
          }
          else if (c.type && c.type == "phone") {
            temp["cellRenderer"] = function (data) {
              return formatPhoneNumber(data.value)
            };
          }
          return temp;
        })
      : [];

      console.log(columnDefs, defaultColDef, 'datagdffgjhgfgjhg')

  return (
    <div className={`ag-theme-quartz ${data&& data.length >= 10 ? 'h-[500px]' :''}`} >
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        domLayout= {data&& data.length < 10 ?  "autoHeight" : ''}
        pagination = {pagination}
      />
    </div>
  );
}
