import React from "react";
import Table from "../table/table.jsx";

import { DynamicTable } from "../table/tableWithTotal.jsx";

const CompletedSearchResults = ({ data, display }) => {
  const columns = [
    {
      field: "rev_name",
      headerName: "Reviewer",
      searchable: true,
    },
    {
      field: "trip_id",
      headerName: "Review ID",
      searchable: true,
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
      searchable: true,
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      searchable: true,
    },
    {
      field: "Name",
      headerName: "Hotel Name",
      searchable: true,
    },
    {
      field: "add_hotel",
      headerName: "CA",
      searchable: true,
    },
    {
      field: "NRHotelNights",
      headerName: "#Nights",
      searchable: true,
      totalLabel: "Total",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate: "sum",
      amount: true,
      searchable: true,
      
    },
  ];
  const columns2 = [
    {
      field: "trip_id",
      headerName: "Review ID",
      searchable: true,
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
      searchable: true,
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      searchable: true,
    },
    {
      field: "Name",
      headerName: "Hotel Name",
      searchable: true,
    },
    {
      field: "add_hotel",
      headerName: "CA",
      // totalLabel: "Review Total",
      searchable: true,
    },
    {
      field: "NRHotelNights",
      headerName: "#Nights",
      searchable: true,
      totalLabel: "Total",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate: "sum",
      amount: true,
      searchable: true,
     
    },
  ];
  //data = tempData;

  if (display && display == "review") {
    return (
      <DynamicTable columns={columns2} data={data} groupByField="trip_id" />
    );
  } else if (display && display == "reviewer") {
    return <Table columns={columns} data={data} />;
  } else {
    return "";
  }
};

export default CompletedSearchResults;
