import React from "react";

import InputDate from "../../inputs/date";
import TertiaryButton from "../../buttons/tertiary";
import Table from "../../table/table";


export default function NewReviews({ data }) {
  const columns = [
    {
      field: "submit_type",
      headerName: "SUBMIT TYPE",
      cellRenderer: function ({ value }) {
        const type = value
        return <div className={` w-[100px] h-[30px] flex items-center justify-center ${type == "Final" ? 'bg-[#E0E7FF]' : type === "Partial" ? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
          {type}
        </div>
      }
    },
    {
      field: "trip_id",
      headerName: "REVIEW ID",
      url: '/accept-a-case',
      urlParams: ['trip_tracker_uid']
    },
    {
      field: "review_date",
      headerName: "REVIEW DATE"
    },
    {
      field: "Name",
      headerName: "Grantee name"
    },
    {
      field: "qc_status",
      headerName: "QC STATUS",
      cellRenderer: function ({ value }) {
        const type = value
        if(type){
          return <div dangerouslySetInnerHTML={{__html:type}} className={`w-[100px] h-[30px] flex items-center justify-center ${type == "In Progress" ? 'bg-[#FEF3C7] text-[#92400E]' : type === "Not Started" ? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
          
          </div>
        }
        
      }
    },
    {
      field: "user_id",
      headerName: "SENT BY",
      url: '/',
      urlParams: ['sentBy']
    },
    {
      field: "creation_ts",
      headerName: "RECEIVED ON",
      type:"date"
    }
  ]

  return (
    <div className="flex flex-col gap-3">
      <h6 className="font-semibold text-[20px]">New Reviews ({data.length})</h6>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <InputDate label="From Date" />
        </div>
        <div className="w-[100px] mt-auto">
          <TertiaryButton label="Filter" className={"p-2"} />
        </div>
      </div>
      {
        data && data.length >0 && <Table columns={columns} data={data} />
      }
      
    </div>
  );
}
