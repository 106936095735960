import React , {useState, useEffect} from "react";
import TextWithLabel from "../../components/inputs/text";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary';
import { get } from "../../services";
import { useParams } from "react-router-dom";


export default function ChangeForm() {

  const { id } = useParams();
  
  const [changes, setChanges] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {

    get(`reviewers/changes?reviewers_uid=${id}`)
    .then(res => {
        if(res && res.length >0){
          setChanges(res[0])
        }
        setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })

   
  }, [])

  if(loading){
    return <div>...loading</div>
  }

  console.log(changes)

  return (
    <div className="flex flex-col gap-7 text-left mb-20">
      <PrimaryBreadCrumbs label="Reviewer History" path={`/reviewers/details/${id}?tab=1`} />      
      <div className="flex justify-between">
      <TitlePrimary title={"Change Form"} />
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-10">
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Traveler" value="Ms. Susan Aichele" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Review ID/Grantee" value="054NC022 / Duplin County Board of Education" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="SSN" value="xxx-xx-5543" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Review Dates" value="01/23/05-01/28/05" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Phone (Home)" value="(215) 755-6873" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Phone (Work)" value="(215) 755-6873" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Date" value="1/24/2005 12:28:43 AM" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Reason" value="Due to inclement weather Reviewer arrived late and was assigned a rental car." />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Type" value="-" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Sub Type" value="-" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Other Sub Type" value="-" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Entered By" value="REGION04" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Ticket Owed to Danya" value="No" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Danya Check #" value="" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Check Owed to Danya" value="No" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Other Check #" value="" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Check Amount" value="$0.00" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Amount" value="$0.00" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Date Rec’d Check" value="" />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="To Acctg" value="" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <TextWithLabel label="Personal Check #" value="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
