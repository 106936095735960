import { SET_ROLES, SET_USER_INFO, SET_SSO_DETAILS } from "./constants"

export const setRoles = (payload) => ({
   type: SET_ROLES,
   payload
})

export const setUserInfo = (payload) => ({
    type: SET_USER_INFO,
    payload
})

export const setSSODetails = (payload) => ({
    type: SET_SSO_DETAILS,
    payload
})