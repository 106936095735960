import React from 'react'
import DataTable from "react-data-table-component";


export default function CashAdvanceStatus({data}) {
    const columns = [
        {
          name: <div className="uppercase text-md text-gray-600">PROCESS DATE</div>,
          selector: (row) => row.advance_processed,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">CHECK DATE</div>,
          selector: (row) => row.checkdate,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">CHECK #</div>,
          selector: (row) => row.checkID,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">AMOUNT</div>,
          selector: (row) => row.amount,
          sortable: true,
        },
        {
            name: <div className="uppercase text-md text-gray-600">CASH ADV</div>,
            selector: (row) => row.advanceamt,
            sortable: true,
          },
          {
            name: <div className="uppercase text-md text-gray-600">MAILED DATE</div>,
            selector: (row) => row.mailedDate,
            sortable: true,
          },
      ];

      console.log(data, 'datatata')
    
  return (
    <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Cash Advance Status</h6>
        <DataTable columns={columns} data={data} />
    </div>
  )
}
