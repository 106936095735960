import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../buttons/primary";
import View from "./view";
import Edit from "./edit";
import { get } from "../../../../services";
import Loader from "../../../loader";

export default function ReviewerViewEdit({reviewer}) {
  const [isEditEnabled, setEdit] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      headerName: "Date",
      field: "create_ts",
      type:'date'
    },
    {
      headerName: "Reason",
      field: "change_type",
    },
    {
      headerName: "Description",
      field: "reason",
    },
    {
      headerName: "Entered By",
      field: "user_id",
    },
  ];

  useEffect(() => {
    get(`reviewers/getReviewerChangeReason?reviewers_uid=${reviewer?.reviewers_uid}`)
      .then((res) => {
        if (res && res.length > 0) {
          setHistoryData(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if(loading){
    return <Loader />
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center">
        <h6 className="font-[500] text-[18px]">{isEditEnabled? "Edit Details" : " View Details"}</h6>
        <div className="ml-auto">
          <PrimaryButton
            onClick={() => setEdit(!isEditEnabled)}
            label={isEditEnabled ? "Cancel" : "Edit"}
            className={"p-2"}
          />
        </div>
      </div>
      {isEditEnabled ? <Edit setEdit={setEdit} reviewer={reviewer} historyData={historyData} columns={columns}/> : <View reviewer={reviewer} historyData={historyData} columns={columns}/>}
    </div>
  );
}
