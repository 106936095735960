import React from "react";
import TextWithLabel from "../inputs/text";
import { getSetValue } from "../../utils";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const modifyDate = (dateRes) => {
  if (dateRes && dateRes !== "Invalid Date") {
    return dateRes;
  } else {
    return "";
  }
};

export default function ForDanyaUse({data}) {
  var temp;
  if(data && data.length >0){
    temp = data[0]
  }
  return (
    <div className="flex flex-col gap-6 text-left">
      <h6 className="text-[20px] font-[500]">For Danya Use Only</h6>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Check Received On" value={modifyDate(dayjs.utc(getSetValue(temp?.check_rcvd)).format("MM/DD/YYYY hh:mm:ss A")) } />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Personal/Danya Check" value={modifyDate(dayjs.utc(getSetValue(temp?.personal_check_rcvd)).format("MM/DD/YYYY hh:mm:ss A"))} />
        </div>
        <div className="w-[200px]">
        <TextWithLabel label="Check#" value={getSetValue(temp && temp.danya_check_rcvd)} />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
        <TextWithLabel label="Check Amount" value={getSetValue(temp && temp.amount_rcvd)} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="To Accounting" value={getSetValue(temp && temp.to_accounting)} />
        </div>
      </div>
    </div>
  );
}
