import React, { useState } from "react";
// import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/hotel-reconciliation/variance";
import InputDate from "../../components/inputs/date";
import { toast, Bounce } from "react-toastify";
import Select from "../../components/inputs/select";

const reviewdata = [
  {
    trip_id: "T001",
    rev_dates: "2024-12-01 to 2024-12-05",
    hotel_nights: 4,
    Name: "Grand Plaza Hotel",
    estimated_amount: 800.0,
    actual_amount: 750.0,
    variance: 50.0,
    recn_on: "2024-12-06",
    recn_by: "John Doe",
  },
  {
    trip_id: "T001",
    rev_dates: "2024-12-01 to 2024-12-05",
    hotel_nights: 4,
    Name: "Grand Plaza Hotel",
    estimated_amount: 800.0,
    actual_amount: 750.0,
    variance: 50.0,
    recn_on: "2024-12-06",
    recn_by: "John Doe",
  },
  {
    trip_id: "T002",
    rev_dates: "2024-11-20 to 2024-11-22",
    hotel_nights: 2,
    Name: "Cozy Stay Inn",
    estimated_amount: 400.0,
    actual_amount: 420.0,
    variance: -20.0,
    recn_on: "2024-11-23",
    recn_by: "Jane Smith",
  },
  {
    trip_id: "T003",
    rev_dates: "2024-12-10 to 2024-12-12",
    hotel_nights: 2,
    Name: "Royal Retreat",
    estimated_amount: 600.0,
    actual_amount: 580.0,
    variance: 20.0,
    recn_on: "2024-12-13",
    recn_by: "Michael Lee",
  },
  {
    trip_id: "T004",
    rev_dates: "2024-12-05 to 2024-12-09",
    hotel_nights: 4,
    Name: "Sunrise Hotel",
    estimated_amount: 1000.0,
    actual_amount: 950.0,
    variance: 50.0,
    recn_on: "2024-12-10",
    recn_by: "Alice Green",
  },
  {
    trip_id: "T005",
    rev_dates: "2024-11-15 to 2024-11-18",
    hotel_nights: 3,
    Name: "Seaside Resort",
    estimated_amount: 750.0,
    actual_amount: 770.0,
    variance: -20.0,
    recn_on: "2024-11-19",
    recn_by: "Robert Brown",
  },
];

export default function HotelReconciliationVariance() {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [displayBy, setDisplayBy] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData && formData.display_by) {
      console.log(formData);
      setData([]);
      setDisplayBy(formData?.display_by);
      var url = "hotelReconsile/hotelReconsileVarianceReview";
      if (
        formData &&
        formData.display_by &&
        formData.display_by === "reviewer"
      ) {
        url = "hotelReconsile/hotelReconsileVarianceReviewer";
      }
      if (!formData["region"]) {
        formData["region"] = 0;
      }
      Object.keys(formData).forEach((key, i) => {
        //if(key !== 'display_by'){
        if (i === 0) {
          url += `?${key}=${formData[key]}`;
        } else {
          url += `&${key}=${formData[key]}`;
          // }
        }
      });
      get(url)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error("Select any Display By", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  return (
    <div className="py-10 text-left">
      <TitlePrimary title={"Variance List"} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 mt-10"
      >
        <div className="flex gap-6">
          <div className="flex gap-6">
            <InputDate label="From Date" name="fromDate" id="fromDate" />
            <InputDate label="To Date" name="toDate" id="toDate" />
            <div className="w-[200px]">
              <Select
                label="Display By"
                name="display_by"
                id="display_by"
                options={[
                  { label: "Select One", value: null },
                  { label: "Review", value: "review" },
                  { label: "Reviewer", value: "reviewer" },
                ]}
              />
            </div>
            <div className="w-[100px]">
              <Select
                name="region"
                id="region"
                label="Region"
                options={[
                  { label: "All", value: 0 },
                  ...[...Array(13)].map((_, i) => {
                    return { label: `${i + 1}`, value: i + 1 };
                  }),
                ]}
              />
            </div>
          </div>

          <div className="flex gap-6">
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Display Now"
                className={"px-3 py-2 "}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        {displayBy && (
          <h6 className="font-semibold text-[18px] m-0 p-0">
            Search Results ( {data.length ? data.length : 0} )
          </h6>
        )}
        <SearchResults data={reviewdata} display={displayBy} />
      </div>
    </div>
  );
}
