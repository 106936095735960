import React from "react";
import { DynamicTable } from "../table/tableWithTotal";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const ReconcBillSearchResults = ({ data, updateReviewerHotel }) => {
  const columns = [

    {
      field: "rev_name",
      headerName: "Reviewers",
      searchable: true
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      // width:'200px',
      searchable: true
    },
    {
      field: "noofnights",
      headerName: "# Of Days",
      edit: true,
      aggregate: "sum",
      searchable: true
    },
    {
      field: "BookedRate",
      headerName: "Booked Rate",
      aggregate:"sum",
      amount:true,
      searchable: true
    },
    {
      field: "Taxes",
      headerName: "Taxes",
      aggregate:"sum",
      amount:true,
      searchable: true
    },
    {
      field: "EstimatedAmount",
      headerName: "Estimated",
      aggregate: "sum",
      amount:true,
      searchable: true
    },
    {
      field: "actual_amount",
      headerName: "Actual",
      aggregate: "sum",
      edit: true,
      amount:true,
      searchable: true
    },
    {
      field: "internet_amount",
      headerName: "Internet",
      aggregate: "sum",
      edit: true,
      amount:true,
      searchable: true
    },
    {
      field: "other_amount",
      headerName: "Other",
      aggregate: "sum",
      edit: true,
      amount:true,
      searchable: true
    },

    {
      field: "rec_date",
      headerName: "Reconciled",
      type:"date",
      searchable: true,
      time:true
    }
  ];
  //data = tempData;

  return <DynamicTable columns={columns} data={data} updateColumnData={(rowIndex, field, value) => updateReviewerHotel(rowIndex, field, value)} pagination={false} />;
};

export default ReconcBillSearchResults;
