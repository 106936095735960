import React from "react";
//import { useNavigate } from "react-router-dom";

export default function GranteeHistory() {
  //const navigate = useNavigate()
  /*const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">FTL</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATE</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600"># OF KIDS</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW TYPE</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWERS</div>,
      selector: (row) => row.training,
      sortable: true,
      cell: (row) => {
        return <div onClick={() => navigate('/grantees/history/1234')} className="text-indigo-600">{row.reviewers}</div>;
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600">HOTELS</div>,
      selector: (row) => row.training,
      sortable: true,
      cell: (row) => {
        return <div className="text-indigo-600">{row.training}</div>;
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600">STATUS</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
      reviewers:"Team Summary"
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
      reviewers:"Team Summary"
    },
  ];*/
  return (
    <>
    <div>Under Construction</div>
    {/* 
    <PrimaryBox >
      <div className="my-3 flex flex-col gap-3 text-left">
      <h6 className="text-justify font-bold text-xl">Grantee History</h6>
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="Number Of Trips Found" value="2" />
        </div>
        <div className="w-[200px] whitespace-nowrap">
          <TextWithLabel
            label="Address"
            value="1601 South Gordon Cooper Dr. Shawnee"
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="Fed. Grantee ID" value="" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="State" value="OK" />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="EHS Grantee ID" value="" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Zip" value="74801" />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel
            label="Name"
            value="Citizen Potawatomi Nation Early Head Start Program"
          />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Phone" value="(405) 275-3121" />
        </div>
      </div>
      <div>
        <DataTable columns={columns} data={data} />
      </div>
      </div>
    </PrimaryBox>
    */}
    </>
  );
}
