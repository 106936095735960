import React from 'react'
// import Completed from './completed'
// import Outstanding from './outstanding'

export default function Inbox() {
  return (
    <div className="flex flex-col text-left gap-3 mt-5">
     
      {/* <Outstanding />
      <Completed /> */}


      <div className='flex items-center justify-center w-full mt-10'>
          Under Construction
      </div>

    </div>
  )
}
