import React from "react";
// import Table from "../table/table.jsx";
import { DynamicTable } from "../table/tableWithTotal.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const ReconcBillNewMiscSearchResults = ({ data, updateMiscData }) => {
  const columns = [
    {
      field: "total",
      headerName: "",
      type: 'action'
    },
    {
      field: "mtgrm_misc",
      headerName: "Meeting Room-Misc",
      edit: true,
      type: 'action',
      aggregate: "sum"
    },
    {
      field: "slprm_misc",
      headerName: "Sleeping Room-Misc",
      edit: true,
      type: 'action',
      aggregate: "sum"
    },
    {
      field: "internet_misc",
      headerName: "Meeting Room Internet Charge",
      edit: true,
      type: 'action',
      aggregate: "sum"

    },
    {
      field: "other_misc",
      headerName: "Other-Misc",
      edit: true,
      type: 'action',
      aggregate: "sum"
    },
    {
      field: "noshow_misc",
      headerName: "No Show Misc",
      edit: true,
      type: 'action',
      aggregate: "sum"
    },
  ];
  //data = tempData;

  return <DynamicTable columns={columns} data={data} updateColumnData={(rowIndex, field, value) => updateMiscData(rowIndex, field, value)} />;
};

export default ReconcBillNewMiscSearchResults;
