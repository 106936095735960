import React, { useState, useEffect } from "react";
import PrimaryBox from "../../components/box/box-primary";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import { DynamicTable } from "../../components/table/tableWithTotal";
import MultiSelect from "../inputs/multi-select";

import { ReactComponent as TrashSvg } from "../../assets/icons/trash.svg";

import { ReactComponent as EditSvg } from "../../assets/icons/edit.svg";

import { toast, Bounce } from "react-toastify";
import { get, post } from "../../services";
import { useStore } from "../../store";

export default function EnterIncomplete({ handleDelete, handleMaintain }) {
  const { ssoUserID } = useStore();

  const [reviewID, setReviewID] = useState("");
  const [danyaID, setDanyaID] = useState("");
  const [comments, setComments] = useState("");
  const [reviewerInfo, setReviwerInfo] = useState({});

  const [incompleteList, setIncompleteList] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);

  useEffect(() => {
    get(`ageing/ageingIncompleteTerList`)
      .then((res) => {
        if (res && res.length > 0) {
          setIncompleteList(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const submit = () => {
    console.log(comments, "comments", selectedReasons);
    if (reviewerInfo && reviewerInfo.trip_details_uid) {
      if (selectedReasons && selectedReasons.length > 0) {
        var defaultValues = {
          trip_details_uid: reviewerInfo.trip_details_uid,
          ter_signature: 0,
          cop_signature: 0,
          lodging: 0,
          car_rental: 0,
          gasoline: 0,
          taxis: 0,
          tolls: 0,
          parking: 0,
          misc: 1,
          misc_desc: selectedReasons.includes("misc") ? comments : null,
          ter_missing: 0,
          cop_missing: 0,
          comments:
            selectedReasons.filter((d) => d != "misc").length > 0
              ? comments
              : null,
          user_id: ssoUserID,
          preSite_missing: 0,
          preSite_signature: 0,
          Grantee_missing: 0,
          grantee_signature: 0,
        };

        var tempObj = {};
        Object.keys(defaultValues).forEach((key) => {
          if (selectedReasons.includes(key)) {
            tempObj[key] = 1;
          } else {
            tempObj[key] = defaultValues[key];
          }
        });

        if(selectedReasons.includes("misc") && !comments){
          toast.error("Miscellaneous reason is required", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }else{
          post(`ageing/ageingIncompleteTerInsert`, tempObj)
          .then(() => {
            toast.success("Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            setTimeout(() => {
              window.location.reload()
            }, 300);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Update Failed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          });
        }

        
      } else {
        toast.error("Select any reason", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    } else {
      toast.error("Reviwer not found", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const validate = () => {
    if (reviewID && danyaID) {
      setReviwerInfo({});
      get(
        `ageing/ageingIncompleteTerValidation?tripId=${reviewID}&danyaId=${danyaID}`
      )
        .then((res) => {
          if (res && res.length > 0) {
            if (
              res &&
              res[0].ValidationResults &&
              res[0].ValidationResults !== ""
            ) {
              toast.error(res[0].ValidationResults, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            } else {
              
              get(
                `ageing/ageingIncompleteMoneyOwnedReviewerInfo?tripId=${reviewID}&danyaId=${danyaID}`
              )
                .then((res) => {
                  if (res && res.length > 0) {
                    setReviwerInfo(res[0]);
                  }
                })
                .catch((err) => {
                  console.error("error", err);
                });
            }
          } else {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }
        })
        .catch(() => {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    } else {
      toast.error("Enter Review ID and Danya ID", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const getObjDetails = (id, type) => {
    get(`ageing/ageingIncompleteTerView?tripDetailsUid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          if (type == "delete") {
            handleDelete(res[0]);
          } else if (type == "maintain") {
            handleMaintain(res[0]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_name",
      headerName: "Reviewer",
    },
    {
      field: "rev_date",
      headerName: "Review Date",
    },

    {
      field: "",
      headerName: (
        <div className="w-full flex justify-center">Delete/Maint</div>
      ),
      cellRenderer: (_, item) => {
        return (
          <div className="flex gap-3 items-center justify-center">
            <p
              className="text-indigo-600 cursor-pointer"
              onClick={() => getObjDetails(item.trip_details_uid, "delete")}
            >
              <TrashSvg className="text-xs text-red-600" width="20" />
            </p>
            <p
              className="text-indigo-600 cursor-pointer"
              onClick={() => getObjDetails(item.trip_details_uid, "maintain")}
            >
              <EditSvg className="text-xs text-indigo-600" width="20" />
            </p>
          </div>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Entered By",
    },
    {
      field: "create_ts",
      headerName: "Entered On",
      type: "date",
      time: true,
    },
  ];
  return (
    <div>
      <PrimaryBox>
        <div className="flex flex-col gap-3 text-left">
          <div className="flex gap-3 items-center">
            <div>
              <Input
                name="reviewID"
                label="Review ID"
                onChange={(e) => setReviewID(e.target.value)}
              />
            </div>
            <div>
              <Input
                name="danyaID"
                label="Danya ID"
                onChange={(e) => setDanyaID(e.target.value)}
              />
            </div>
            <div className="h-full mt-auto">
              <PrimaryButton
                onClick={() => validate()}
                label="Validate"
                className={"p-2 "}
              />
            </div>
          </div>
          <div>
            {reviewerInfo && (
              <div className="flex flex-col gap-1 text-sm my-3 text-indigo-600 font-[600]">
                <div>{reviewerInfo.rev_name}</div>
                <div>{reviewerInfo.HomeAddress1}</div>
                <div>{reviewerInfo.city_name}</div>
              </div>
            )}
          </div>
          <div className="flex gap-3 h-full w-full">
            <div>
              <MultiSelect
                options={[
                  {
                    label: "TER Need signature",
                    value: "ter_signature",
                  },
                  {
                    label: "COP Needs signature",
                    value: "cop_signature",
                  },
                  {
                    label: "Lodging Receipt",
                    value: "lodging",
                  },
                  {
                    label: "Car Rental Receipt",
                    value: "car_rental",
                  },
                  {
                    label: "Gasoline Receipt",
                    value: "gasoline",
                  },
                  {
                    label: "Taxis/Shuttle Receipt",
                    value: "taxis",
                  },
                  {
                    label: "Tolls Receipt",
                    value: "tolls",
                  },
                  {
                    label: "Parking Receipt",
                    value: "parking",
                  },
                  {
                    label: "Electronic Travel Expense Report Not Submitted",
                    value: "",
                  },
                  {
                    label: "COP Not Submitted",
                    value: "cop_missing",
                  },
                  {
                    label: "Pre-Site Materials Review Checklist Not Submitted",
                    value: "preSite_missing",
                  },
                  {
                    label:
                      "Pre-Site Materials Review Checklist Needs signature(s)",
                    value: "preSite_signature",
                  },
                  {
                    label:
                      "Grantee Contact and Address Confirmation Form Not Submitted",
                    value: "Grantee_missing",
                  },
                  {
                    label:
                      "Grantee Contact and Address Confirmation Form needs signature(s)",
                    value: "grantee_signature",
                  },
                  {
                    label: "Miscellaneous",
                    value: "misc",
                  },
                ]}
                label="Reason(s)"
                selectedValues={selectedReasons}
                onChange={setSelectedReasons}
              />
            </div>
            <div className="mt-auto w-full">
              <Input
                type="textArea"
                className="w-full"
                label=""
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>
          <div>
            <PrimaryButton
              disabled={Object.keys(reviewerInfo).length <=0}
              onClick={submit}
              label="Update Now"
              className={"p-2"}
            />
          </div>
          <h6 className="mt-3 font-semibold">UnProcessed Transaction</h6>
          <div className="mt-3">
            <DynamicTable data={incompleteList} columns={columns} />
          </div>
        </div>
      </PrimaryBox>
    </div>
  );
}
