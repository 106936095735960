import React, { useState, useEffect } from "react";
import Table from "../../../../table/table";

import { get } from "../../../../../services/index";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const AirTickets = ({ uid }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (uid) {
      get(`reviews/tis_airtickets_upload?trip_details_uid=${uid}`)
        .then((res) => {
          if (res && res.length > 0) {
            setData(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [uid]);

  const columns = [
    {
      headerName: "Invoice Date",
      field: "invoice_date",
      type: "date",
      time: "off",
    },
    {
      headerName: "Ticket #",
      field: "ticket_number",
    },
    {
      headerName: "Arrival Location",
      field: "",
      minWidth:200,
      cellRenderer: ({ data }) => {
        var str1 = "";
        if (data.return_date) {
          str1 += dayjs.utc(data.return_date).format("MM/DD/YYYY");
        }
        if (data.return_time) {
          str1 += data.return_time + "/";
        }
        if (data.return_airline) {
          str1 += data.return_airline;
        }
        if (data.return_flight_no) {
          str1 += ":" + data.return_flight_no;
        }

        return <div>{str1}</div>;
      },
    },
    {
      headerName: "Departure Location",
      field: "",
      minWidth:200,
      cellRenderer: ({ data }) => {
        var str1 = "";
        if (data.depart_date) {
          str1 += dayjs.utc(data.depart_date).format("MM/DD/YYYY");
        }
        if (data.depart_time) {
          str1 += data.depart_time + "/";
        }
        if (data.depart_airline) {
          str1 += data.depart_airline;
        }
        if (data.depart_flight_no) {
          str1 += ":" + data.depart_flight_no;
        }

        return <div>{str1}</div>;
      },
    },
    {
      headerName: "Ticket Amount",
      field: "ticket_amount",
    },
    {
      headerName: "Fee",
      field: "agency_fee",
    },
    {
      headerName: "Message/Warning",
      field: "message_warning",
      cellRenderer: ({ value }) => {
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
      },
    },
    {
      headerName: "Uploaded On",
      field: "createts",
      type: "date",
      minWidth:180,
    },
  ];

  return (
    <div className="flex flex-col gap-6 text-left">
      <h6 className="font-semibold">Air Tickets</h6>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default AirTickets;
